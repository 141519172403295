import React from 'react';
import styled from '@emotion/styled';
import breakfastStrawberry from 'assets/images/breakfastStrawberry.svg';
import lunchSandwich from 'assets/images/lunchSandwich.svg';
import dinnerBroccoli from 'assets/images/dinnerBroccoli.svg';
import bedtimeMoon from 'assets/images/bedtimeMoon.svg';

const Step1Table = ({ showHint1, hintRef }) => {
    return (
        <Table ref={hintRef}>
            <Caption>Typical Daily Doses</Caption>
            <tbody>
                <tr>
                    <TH scope="row">
                        Breakfast <StyledImage src={breakfastStrawberry} />
                    </TH>
                    <TD className={showHint1 ? 'showHint1' : ''}>
                        <span>4 units</span> NovoRapid, <span>6 units</span>{' '}
                        Levemir
                    </TD>
                </tr>
                <tr>
                    <TH scope="row">
                        Lunch <StyledImage src={lunchSandwich} />
                    </TH>
                    <TD className={showHint1 ? 'showHint1' : ''}>
                        <span>3 units</span> NovoRapid
                    </TD>
                </tr>
                <tr>
                    <TH scope="row">
                        Dinner <StyledImage src={dinnerBroccoli} />
                    </TH>
                    <TD className={showHint1 ? 'showHint1' : ''}>
                        <span>3 units</span> NovoRapid
                    </TD>
                </tr>
                <tr>
                    <TH scope="row" className="last">
                        Bedtime <StyledImage src={bedtimeMoon} />
                    </TH>
                    <TD className={showHint1 ? 'showHint1 last' : 'last'}>
                        <span>6 units</span> Levemir
                    </TD>
                </tr>
            </tbody>
        </Table>
    );
};

export default Step1Table;

// Color vars not shared in app theme
const tableCaptionBackground = '#6969B3';
const tableHeaderBackground = '#FCDDBD';
const tableDataBackground = '#DCD6F7';

const Table = styled.table`
    background: ${(props) => props.theme.colors.lightBackground};
    width: 50rem;
    font-size: 1.4rem;
    line-height: 2.5rem;
    letter-spacing: ${(props) => props.theme.letterSpacing.sm};
    margin-top: 4rem;
    border-spacing: 0 0.6rem;
`;

const StyledImage = styled.img`
    height: 3.2rem;
    position: absolute;
    left: -1.7rem;
    top: 12%;
    filter: drop-shadow(0px 5px 20px rgba(53, 53, 53, 0.25));
`;

const Caption = styled.caption`
    text-align: center;
    background: ${tableCaptionBackground};
    color: ${(props) => props.theme.colors.lightText};
    width: 100%;
    font-size: 1.8rem;
    font-family: 'MontserratBold', sans-serif;
    padding: 2rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
`;

const TH = styled.th`
    background: ${tableHeaderBackground};
    position: relative;
    width: 14.5rem;
    font-family: 'MontserratBold', sans-serif;
    border-right: 0.6rem solid ${(props) => props.theme.colors.lightBackground};

    &.last {
        border-bottom-left-radius: 1rem;
    }
`;

const TD = styled.td`
    background: ${tableDataBackground};
    text-align: center;
    padding: 1rem;
    font-family: 'MontserratMedium', sans-serif;

    &.last {
        border-bottom-right-radius: 1rem;
    }

    &.showHint1 {
        span {
            color: ${(props) => props.theme.colors.success};
        }
    }
`;
