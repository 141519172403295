import React from 'react';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';
import Container from './Container';
import P from './fonts/P';
import StyledImage from './StyledImage';
import landscapeView from 'assets/icons/landscapeView.svg';

const UseDesktopBanner = ({ marginTop, marginBottom }) => {
    const [globalState] = useGlobalContext();
    const { deviceSize } = globalState;
    return (
        <Container
            className="flex-all-centered"
            background="primaryDarkest"
            marginTop={marginTop}
            marginBottom={marginBottom}
            padding="1.2rem 3rem 1.6rem 3rem"
            marginLeft={deviceSize === 'mobile' ? '-2.5rem' : '-4.8rem'}
            width="100vw"
        >
            <StyledImage
                src={landscapeView}
                alt="the process of rotating phone to the landscape view"
                marginRight="1.6rem"
            />
            <P className="body-2" color="lightText" textAlign="left">
                Activity works best on a landscape view or desktop
            </P>
        </Container>
    );
};

export default UseDesktopBanner;
