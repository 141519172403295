let FormatCoursesViewCount = (viewCount) => {
    if (viewCount > 999 && viewCount < 950000) {
        viewCount = Math.round(viewCount / 1000) + 'K';
    }

    if (viewCount >= 950000) {
        viewCount = Math.round(viewCount / 1000000) + 'M';
    }

    return viewCount;
};

export default FormatCoursesViewCount;
