import Container from 'assets/styledComponents/Container';
import P from 'assets/styledComponents/fonts/P';
import React from 'react';
import TabContent from './TabButtons';

const PdfDescriptionMobile = ({ page }) => {
    let activePage = TabContent.find((item) => item.id === page);
    return (
        <>
            {activePage ? (
                <Container
                    className="flex-center-aligned"
                    marginBottom="2.4rem"
                    padding="2.6rem 1.6rem"
                    background="tertiary"
                    borderRadius="2rem"
                    width="100%"
                    minHeight="10.4rem"
                >
                    <P className="body-2" textAlign="left">
                        {activePage.id}
                        {'. '}
                        {activePage.content}
                    </P>
                </Container>
            ) : (
                ''
            )}
        </>
    );
};

export default PdfDescriptionMobile;
