import { React } from 'react';
import calculateTotalLessons from 'utils/helpers/CalculateTotalLessons';
import CarouselCourseCard from 'components/CourseCard/LandingPageCarousel/CarouselCourseCard';

const ShowLoggedOutCards = (allCourses, globalState) => {
    return allCourses?.map((c) => {
        let smallImage =
            c.courseImage.image.formats &&
            c.courseImage.image.formats.small &&
            c.courseImage.image.formats.small.url;
        let nonOptimizedImage = c.courseImage.image.url;

        return (
            <CarouselCourseCard
                course={c}
                key={c.id}
                imageURL={smallImage ? smallImage : nonOptimizedImage}
                title={c.title}
                description={c.description}
                id={c.id}
                slug={c.slug}
                totalLessons={calculateTotalLessons(c)}
                progress={'0'}
                devicesize={globalState.deviceSize}
            />
        );
    });
};

export default ShowLoggedOutCards;
