import { React } from 'react';
import ShowCoursesLoggedInUser from './ShowCoursesLoggedInUser';
import { useGlobalContext } from '../../GlobalContext/GlobalProvider';

const AllCoursePageAuthContent = ({
    view,
    filterValue,
    allCourses,
    myCourses,
    coursesToShow,
    setCoursesToShow,
}) => {
    const [globalState] = useGlobalContext();
    const isDesktop =
        globalState.deviceSize === 'desktopSm' ||
        globalState.deviceSize === 'desktopMd';

    const numOfCourses = {
        all: allCourses && allCourses.length,
        inProgress: 0,
        completed: 0,
    };

    for (let course in myCourses) {
        if (myCourses[course].courseCompleted) {
            numOfCourses.completed++;
        }
        if (!myCourses[course].courseCompleted) {
            numOfCourses.inProgress++;
        }
    }
    return (
        <ShowCoursesLoggedInUser
            myCourses={myCourses}
            allCourses={allCourses}
            coursesToShow={coursesToShow}
            setCoursesToShow={setCoursesToShow}
            view={view}
            searchValue={filterValue}
            isGeneralCoursesPage="true"
            isDesktop={isDesktop}
        />
    );
};

export default AllCoursePageAuthContent;
