const FormatEstimatedTime = (timeInMinutes) => {
    let result;
    let minutesExpression;
    let minutes = timeInMinutes % 60;
    minutes === 1
        ? (minutesExpression = '1 min')
        : (minutesExpression = `${minutes} mins`);

    if (timeInMinutes < 60) {
        result = minutesExpression;
    } else if (timeInMinutes >= 60) {
        result = `${Math.floor(timeInMinutes / 60)} H ${
            minutes === 0 ? '' : minutesExpression
        }`;
    }

    return result;
};

export default FormatEstimatedTime;
