import { React, useState, useEffect } from 'react';
import PageContentContainer from '../assets/styledComponents/PageContentContainer';
import ScrollContainer from '../assets/styledComponents/ScrollContainer';
import MyCoursesDashboardHeader from '../components/Courses/MyCoursesDashboardHeader';
import ShowCoursesLoggedInUser from '../components/Courses/ShowCoursesLoggedInUser';
import { useGlobalContext } from '../GlobalContext/GlobalProvider';
import Layout from '../types/layout';
import { GET_ALL_COURSES } from '../graphql/queries';
import { useQuery } from '@apollo/client';
import sortCourses from 'utils/helpers/SortCourses';
import sortMyCourses from 'utils/helpers/SortMyCourses';

const DashboardMyCoursesPage = () => {
    const [globalState] = useGlobalContext();
    const isDesktop =
        globalState.deviceSize === 'desktopSm' ||
        globalState.deviceSize === 'desktopMd';
    const [filterValue, setFilterValue] = useState('');
    const getCourses = useQuery(GET_ALL_COURSES);
    const allCourses = getCourses && getCourses.data && getCourses.data.courses;
    const myCourses = globalState && globalState.user.trackingLog.courseTracker;

    const [view, setView] = useState(Layout.CARD_VIEW);
    const [coursesToShow, setCoursesToShow] = useState('inProgress');

    const numOfCourses = {
        inProgress: 0,
        completed: 0,
    };

    const onSearchChange = (value) => {
        setFilterValue(value);
    };

    for (let course in myCourses) {
        if (myCourses[course].courseCompleted) {
            numOfCourses.completed++;
        }
        if (!myCourses[course].courseCompleted) {
            numOfCourses.inProgress++;
        }
    }

    useEffect(() => {
        setView(Layout.CARD_VIEW);
    }, [isDesktop]);

    return (
        <ScrollContainer>
            <PageContentContainer marginRight="7rem" marginLeft="4.5rem">
                <MyCoursesDashboardHeader
                    myCourses={myCourses}
                    coursesToShow={coursesToShow}
                    setCoursesToShow={setCoursesToShow}
                    view={view}
                    setView={setView}
                    numOfCourses={numOfCourses}
                    onSearchChange={onSearchChange}
                    isDesktop={isDesktop}
                />

                <ShowCoursesLoggedInUser
                    myCourses={myCourses && sortMyCourses(myCourses)}
                    allCourses={allCourses && sortCourses(allCourses)}
                    coursesToShow={coursesToShow}
                    setCoursesToShow={setCoursesToShow}
                    view={view}
                    setView={setView}
                    searchValue={filterValue}
                    isDashboard={true}
                    isDesktop={isDesktop}
                />
            </PageContentContainer>
        </ScrollContainer>
    );
};

export default DashboardMyCoursesPage;
