export const stepItems = [
    { id: 1, title: 'Calculate your total daily dose' },
    { id: 2, title: 'Decide what percentage of insulin to give' },
    {
        id: 3,
        title: 'Calculate your illness bolus dose using the percentage of your total daily dose',
    },
    {
        id: 4,
        title: 'Check your blood glucose and ketones 3 hours after giving your illness dose',
    },
];
