import PageContentContainer from 'assets/styledComponents/PageContentContainer';

import PageHeaderWithSquare from 'assets/styledComponents/PageHeaderWithSquare';
import SearchBar from 'components/Courses/SearchBar';
import ShowAllCoursesMobile from 'components/Courses/ShowAllCoursesMobile';
import React from 'react';

const AllCoursesPageContentMobile = ({
    onSearchChange,
    view,
    filterValue,
    myCourses,
    allCourses,
    coursesToShow,
    setCoursesToShow,
    authUser,
}) => {
    return (
        <PageContentContainer>
            <PageHeaderWithSquare headerTitle="MODULES" isMobile={true} />
            <SearchBar
                onSearchChange={onSearchChange}
                isMobile={true}
                marginBottom="2.4rem"
            />
            <div className="flex-column-centered">
                <ShowAllCoursesMobile
                    authUser={authUser}
                    myCourses={myCourses}
                    allCourses={allCourses}
                    coursesToShow={coursesToShow}
                    setCoursesToShow={setCoursesToShow}
                    view={view}
                    searchValue={filterValue}
                />
            </div>
        </PageContentContainer>
    );
};

export default AllCoursesPageContentMobile;
