import React from 'react';
import styled from '@emotion/styled';

const MobileSignInOrUpContainer = ({ children }) => {
    return (
        <FixedContainer className="flex-center-justified">
            {children}
        </FixedContainer>
    );
};

const FixedContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 5;
    padding: 0 2.4rem;
    background: ${(props) => props.theme.colors.lightBackground};
    :hover {
        cursor: pointer;
    }
`;

export default MobileSignInOrUpContainer;
