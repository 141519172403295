import React from 'react';
import H2 from 'assets/styledComponents/fonts/H2';
import P from 'assets/styledComponents/fonts/P';
import Content from './Content';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';

const TipsForIllnessManagement = () => {
    const [globalState] = useGlobalContext();
    let devicesize = globalState.deviceSize;
    return (
        <div>
            <H2
                marginBottom="2.6rem"
                className={devicesize === 'mobile' ? 'header-4' : 'header-3'}
            >
                7 tips to follow when you are sick:
            </H2>

            {devicesize !== 'mobile' && (
                <P className="body-4" marginBottom="2.4rem">
                    {' '}
                    Select each step to learn more.{' '}
                </P>
            )}

            <Content />
        </div>
    );
};

export default TipsForIllnessManagement;
