import Container from 'assets/styledComponents/Container';
import StyledImage from 'assets/styledComponents/StyledImage';
import React, { useState, useEffect } from 'react';

import InteractiveActivityButton from 'assets/styledComponents/buttons/InteractiveActivityButton';
import checked from 'assets/icons/checked.svg';
import styled from '@emotion/styled';
import P from 'assets/styledComponents/fonts/P';
import { StyledHintButton } from '../../SharedComponentsInteractive';

const TableActivityMobile = ({
    showHint,
    devicesize,
    correctAnswer,
    table,
    tableHint,
    activityData,
    hint,
    gameId,
    handleHintClickB,
    hintRef,
}) => {
    const initialState =
        gameId === '2'
            ? { option21: false, option22: false, option23: false }
            : { option31: false, option32: false, option33: false }; //

    const [checkedItem, setCheckedItem] = useState({
        ...initialState,
    });
    const [isCorrect, setIsCorrect] = useState(false);
    const [hasSubmitted, setHasSubmitted] = useState(false);

    useEffect(() => {
        setHasSubmitted(false);
    }, [isCorrect]);

    const handleClick = (itemName, itemId) => {
        setCheckedItem({
            ...initialState,
            [itemName]: true,
        });
        setIsCorrect(itemId === correctAnswer);
    };

    const handleSubmitActivity = () => {
        if (!hasSubmitted) {
            setHasSubmitted(true);
            return;
        }

        setHasSubmitted(false);
        setIsCorrect(false);
        setCheckedItem({
            ...initialState,
        });
    };

    const handleTryAgain = (e) => {
        e.preventDefault();
        setIsCorrect(false);
        setCheckedItem({
            ...initialState,
        });
    };

    const valSelected = () => {
        return gameId === '2'
            ? checkedItem.option21 ||
                  checkedItem.option22 ||
                  checkedItem.option23
            : checkedItem.option31 ||
                  checkedItem.option32 ||
                  checkedItem.option33;
    };

    return (
        <>
            <Container
                marginLeft={devicesize === 'mobile' ? '-2.5rem' : '-4.8rem'}
                marginTop={devicesize === 'mobile' ? '2.4rem' : '3.2rem'}
                ref={hintRef}
            >
                <StyledImage
                    src={showHint ? tableHint : table}
                    alt="5-10-15-20 Rules of High Blood Glucose"
                    width={
                        devicesize === 'tablet'
                            ? 'calc(100vw - 5.6rem)'
                            : '100vw'
                    }
                />
            </Container>
            <Container className="flex-column-centered" marginTop="3.2rem">
                {activityData.map((item) => {
                    let itemName = 'option' + item.id;

                    return (
                        <Label
                            htmlFor={itemName}
                            key={item.id}
                            onClick={() => handleClick(itemName, item.id)}
                            className="body-1 flex-center-aligned"
                        >
                            <Input
                                type="radio"
                                className="checkbox flex-all-centered"
                                id={itemName}
                                checked={checkedItem[itemName] === true}
                                onChange={() => handleClick(itemName, item.id)}
                                name={itemName}
                            />
                            {item.label}
                        </Label>
                    );
                })}
            </Container>

            {/* Buttons  */}
            <Container
                marginTop="3.2rem"
                marginBottom="4.8rem"
                className="flex-all-centered"
            >
                {isCorrect && hasSubmitted ? (
                    <InteractiveActivityButton
                        isCorrect={true}
                        onClick={handleTryAgain}
                        className="secondary"
                    />
                ) : (
                    <InteractiveActivityButton
                        isCorrect={false}
                        onClick={handleSubmitActivity}
                        className={
                            !valSelected()
                                ? 'disabledBtn'
                                : hasSubmitted
                                ? 'incorrect'
                                : 'secondary'
                        }
                        hasError={hasSubmitted && !isCorrect}
                    />
                )}
                <StyledHintButton
                    padding="1.2rem 2rem"
                    onClick={() => handleHintClickB()}
                />
            </Container>

            {isCorrect && hasSubmitted && hint ? (
                <P
                    className={
                        devicesize === 'desktopSm' || devicesize === 'desktopMd'
                            ? 'body-4'
                            : 'body-2'
                    }
                    marginBottom="1.6rem"
                >
                    {hint}
                </P>
            ) : (
                ''
            )}
        </>
    );
};

const Label = styled.label`
    width: 20rem;
    margin-bottom: 0.8rem;
    padding: 0.5rem 4.8rem 0.5rem 0.5rem;

    :last-of-type {
        margin-bottom: 0;
    }
`;

const Input = styled.input`
    display: inline-block;

    &.checkbox {
        margin-right: 1.6rem;
        min-width: 2.4rem;
        min-height: 2.4rem;

        -moz-appearance: none;
        -webkit-appearance: none;
        -o-appearance: none;
        outline: 1px solid ${(props) => props.theme.colors.greyText};
        border-radius: 0.5rem;
    }

    :checked {
        background-image: url(${checked});
        background-repeat: no-repeat;
        background-position: center;
    }

    :hover {
        cursor: pointer;
    }
`;

export default TableActivityMobile;
