import React from 'react';
import Hero from 'components/LandingPage/Hero';
import CarouselSection from 'components/LandingPage/CarouselSection';
import ShowHighlights from 'components/LandingPage/ShowHighlights';
import Footer from 'components/Footer/Footer';
import CreatedBy from 'components/LandingPage/CreatedBy';
import CTASection from 'components/LandingPage/CTASection';

const LandingPage = () => {
    return (
        <div>
            <Hero />
            <CarouselSection />
            <CreatedBy />
            <ShowHighlights />
            <CTASection />
            <Footer />
        </div>
    );
};

export default LandingPage;
