import { React } from 'react';
import styled from '@emotion/styled';
import Container from 'assets/styledComponents/Container';
import highBloodSugarStep2 from 'assets/images/highBloodSugarStep2.svg';
import Step2TableRow from './Step2TableRow';
import { Table2Data } from './Table2Data';
import { Caption, Table } from './SharedComponents';

const Step2Table = ({
    isAnswer2Correct,
    setIsAnswer2Correct,
    hasSubmitted2,
    setValSelected,
    showHint2,
    valSelected,
    hintRef,
}) => {
    return (
        <Container position="relative" width="fit-content" ref={hintRef}>
            <CorrectColBorder
                role="presentation"
                className={
                    (hasSubmitted2 && isAnswer2Correct) || showHint2
                        ? 'correct-border-flash'
                        : ''
                }
            />
            <CorrectRowBorder
                role="presentation"
                className={
                    (hasSubmitted2 && isAnswer2Correct) || showHint2
                        ? 'correct-border-flash'
                        : ''
                }
            />

            <Table>
                <Caption table2>
                    {' '}
                    <StyledImage src={highBloodSugarStep2} />
                    5-10-15-20 Rules of High Blood Glucose
                </Caption>

                <colgroup colSpan="2"></colgroup>
                <colgroup colSpan="3"></colgroup>

                <tbody>
                    <tr>
                        <TH1 colSpan="2" scope="colgroup">
                            If the Ketones are:
                        </TH1>
                        <TH1 colSpan="3" scope="colgroup">
                            Give this much rapid-acting insulin
                        </TH1>
                    </tr>
                    <tr>
                        <TH2 scope="col">Urine Ketones</TH2>
                        <TH2 scope="col">Blood Ketones</TH2>
                        <TH2 scope="col">BG 10.1 - 14.9**</TH2>
                        <TH2 scope="col">BG 15.1 - 20</TH2>
                        <TH2 scope="col">BG Over 20</TH2>
                    </tr>

                    {Table2Data.map((item) => {
                        return (
                            <Step2TableRow
                                key={item.id}
                                data={item.items}
                                rowNumber={item.id}
                                isAnswer2Correct={isAnswer2Correct}
                                setIsAnswer2Correct={setIsAnswer2Correct}
                                hasSubmitted2={hasSubmitted2}
                                setValSelected={setValSelected}
                                valSelected={valSelected}
                            />
                        );
                    })}
                </tbody>
            </Table>
        </Container>
    );
};

export default Step2Table;

// Color vars not shared in app theme

const tableHeaderBackground = '#7DE2D1';
const tableHeader2Background = '#56CBF9';

const tableHeader1Height = '6rem';

const StyledImage = styled.img`
    height: 10rem;
`;

const TH1 = styled.th`
    background: ${tableHeaderBackground};
    font-family: 'MontserratBold', sans-serif;
    font-size: 1.6rem;
    vertical-align: middle;
    height: ${tableHeader1Height};
`;

const TH2 = styled.th`
    background: ${tableHeader2Background};
    font-family: 'MontserratBold', sans-serif;
    font-size: 1.4rem;
`;

const CorrectColBorder = styled.div`
    width: 16.4rem;
    border: 0.4rem solid ${(props) => props.theme.colors.success};
    height: 32rem;
    position: absolute;
    bottom: 0.5rem;
    right: 17.6rem;
    z-index: -1;
    &.correct-border-flash {
        animation-name: flash;
        animation-duration: 5s;
        @keyframes flash {
            0% {
                border: 0.4rem solid ${(props) => props.theme.colors.success};
                z-index: 5;
            }

            100% {
                border: none;
                z-index: -1;
            }
        }
    }
`;

const CorrectRowBorder = styled.div`
    width: calc(100% - 1.2rem);
    height: 5.2rem;
    position: absolute;
    bottom: 6.6rem;
    right: 0.6rem;
    z-index: -1;
    &.correct-border-flash {
        animation-name: flash;
        animation-duration: 5s;
        @keyframes flash {
            0% {
                border: 0.4rem solid ${(props) => props.theme.colors.success};
                z-index: 5;
            }

            100% {
                border: none;
                z-index: -1;
            }
        }
    }
`;
