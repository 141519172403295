import React from 'react';
import StyledImage from 'assets/styledComponents/StyledImage';
import P from 'assets/styledComponents/fonts/P';
import Container from 'assets/styledComponents/Container';
import { Span } from './SharedComponents';
import steps from './StepsContent';
import nextChevron from 'assets/images/interactiveBook-course2/nextChevron.svg';
import prevChevron from 'assets/images/interactiveBook-course2/prevChevron.svg';
import NextOrPrevPageButton from '../Course2/NextOrPrevPageButton';

const Step2 = ({ setActivePage }) => {
    const { Step2 } = steps;
    const { highlightContent, content, image } = Step2;
    return (
        <>
            <Container
                width="50%"
                className="flex-column-center-justified"
                padding="0 4rem"
            >
                <P marginBottom="1.5rem">
                    <Span>{highlightContent}</Span>
                </P>
                <P>{content}</P>
                <NextOrPrevPageButton
                    className="flex-all-centered prev"
                    image={prevChevron}
                    alt={'orange chevron pointing left'}
                    setActivePage={setActivePage}
                    setActivePageNum={1}
                    title={'PREV'}
                />
            </Container>
            <Container width="50%" className="flex-all-centered">
                <StyledImage src={image} width="75%" />
                <NextOrPrevPageButton
                    className={'flex-all-centered next'}
                    image={nextChevron}
                    alt={'orange chevron pointing right'}
                    setActivePage={setActivePage}
                    setActivePageNum={3}
                    title={'NEXT'}
                />
            </Container>
        </>
    );
};

export default Step2;
