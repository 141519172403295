import styled from '@emotion/styled';

const StyledImage = styled.img`
    /* customizable */
    margin: ${(props) => props.margin};
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
    padding: ${(props) => props.padding};
    width: ${(props) => props.width};
    min-width: ${(props) => props.minWidth};
    max-width: ${(props) => props.maxWidth};
    height: ${(props) => props.height};
    min-height: ${(props) => props.height};
    max-height: ${(props) => props.maxHeight};
    color: ${(props) => props.color && props.theme.colors[props.color]};
    background: ${(props) =>
        props.background && props.theme.colors[props.background]};
    text-align: ${(props) => props.textAlign};
    object-fit: ${(props) => props.objectFit};
    position: ${(props) => props.position};
    top: ${(props) => props.top};
    right: ${(props) => props.right};
    bottom: ${(props) => props.bottom};
    left: ${(props) => props.left};
    border-radius: ${(props) => props.borderRadius};
    float: ${(props) => props.float};
    cursor: ${(props) => props.cursor};
`;

export default StyledImage;
