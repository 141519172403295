import { useEffect, useState } from 'react';
import { useGlobalContext } from '../GlobalContext/GlobalProvider';
import { updateDeviceSize } from '../GlobalContext/actionVariables';
import breakPoints from 'types/breakPoints';

const useUpdateDeviceSize = () => {
    const [, dispatch] = useGlobalContext();
    const [deviceSize, setDeviceSize] = useState('');

    const getDeviceSize = () => {
        const mobile = matchMedia(`(max-width: ${breakPoints.TABLET - 1}px)`);
        const tablet = matchMedia(
            `(min-width: ${breakPoints.TABLET}px) and (max-width: ${
                breakPoints.DESKTOP_SM - 1
            }px)`
        );
        const desktopSm = matchMedia(
            `(min-width: ${breakPoints.DESKTOP_SM}px) and (max-width: ${
                breakPoints.DESKTOP_MD - 1
            }px)`
        );
        const desktopMd = matchMedia(
            `(min-width: ${breakPoints.DESKTOP_MD}px)`
        );

        if (mobile.matches) return 'mobile';
        if (tablet.matches) return 'tablet';
        if (desktopSm.matches) return 'desktopSm';
        if (desktopMd.matches) return 'desktopMd';
    };

    const updateUserDeviceSize = () => {
        const size = getDeviceSize();
        setDeviceSize(size);

        dispatch({
            type: updateDeviceSize,
            payload: size,
        });
    };
    useEffect(() => {
        updateUserDeviceSize();
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        window.addEventListener('resize', updateUserDeviceSize);
        return () => {
            window.removeEventListener('resize', updateUserDeviceSize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.innerWidth]);

    return { deviceSize, updateUserDeviceSize };
};

export default useUpdateDeviceSize;
