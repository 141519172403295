import styled from '@emotion/styled';
import React, { useRef, useState } from 'react';
import InteractiveActivityButton from 'assets/styledComponents/buttons/InteractiveActivityButton.js';
import AnswerBox from './AnswerBox';
import FoodOption from './FoodOption';
import { StyledHintButton } from '../../SharedComponentsInteractive';

const Game = ({ options, correctAnswers, bounce, devicesize, onHintClick }) => {
    const optionsRef = useRef();

    const [isNewGame, setIsNewGame] = useState(true);
    const [allAnswersCorrect, setAllAnswersCorrect] = useState(false);
    const [correctSelections, setCorrectSelections] = useState([]);
    const [errors, setErrors] = useState([]);

    const createEmptyArray = () => {
        const array = [];
        for (let i = 0; i < correctAnswers.length; ++i) {
            array.push(null);
        }
        return array;
    };

    const [selectedItems, setSelectedItems] = useState(createEmptyArray());

    // both error array and test array only getting the last item that was right or wrong and not any earlier items
    const validateAnswers = () => {
        let correctAnswersAmount = 0;
        let nonNullItems = selectedItems.filter((answer) => answer);
        if (nonNullItems.length === 0) {
            return;
        }

        let incorrectArray = [];
        let correctArray = [];

        nonNullItems.forEach((answer) => {
            if (correctAnswers.includes(answer.label)) {
                correctArray.push(answer.label);
                correctAnswersAmount++;
            } else {
                incorrectArray.push(answer.label);
            }
        });
        if (correctAnswersAmount === correctAnswers.length) {
            setAllAnswersCorrect(true);
        }
        setErrors(incorrectArray);
        setCorrectSelections([...correctSelections, ...correctArray]);
        setIsNewGame(false);
    };

    const setVisible = (item) => {
        const optionsImgs = optionsRef.current && optionsRef.current.children;
        for (let img of optionsImgs) {
            if (img.firstChild.firstChild.src.includes(item.img)) {
                // Complex condition to make it possible to replace selected items
                img.style.visibility = 'visible';
            }
        }
    };

    const tryAgain = () => {
        selectedItems.forEach((item, index) => {
            if (item && errors.includes(item.label)) {
                setItemByIndex(null, index);
                setVisible(item);
            }
        });
        setIsNewGame(true);
        setErrors([]);
    };

    const setItemByIndex = (item, index) => {
        const tmp = selectedItems;
        const previousItem = tmp[index];
        tmp[index] = item;
        setSelectedItems([...tmp]);
        if (previousItem) {
            setVisible(previousItem);
        }
    };

    const handleTryAgainAfterAllCorrect = () => {
        selectedItems.forEach((item, index) => {
            setItemByIndex(null, index);
            setVisible(item);
        });
        setCorrectSelections([]);
        setAllAnswersCorrect(false);
        setIsNewGame(true);
        setErrors([]);
    };

    return (
        <>
            <FoodOptionsContainer ref={optionsRef}>
                {options.map((option) => (
                    <FoodOption
                        key={option.label}
                        targetKey="box"
                        label={option.label}
                        image={option.image}
                        isCorrect={
                            option.label &&
                            correctAnswers.includes(option.label)
                        }
                        isIncorrect={option && errors.includes(option.label)}
                        bounce={bounce}
                    />
                ))}
            </FoodOptionsContainer>
            <AnswersContainer devicesize={devicesize}>
                {selectedItems.map((selectedItem, id) => {
                    return (
                        <AnswerBox
                            key={id}
                            targetKey="box"
                            item={selectedItem}
                            setItem={(newItem) => setItemByIndex(newItem, id)}
                            isNewGame={isNewGame}
                            isCorrect={
                                selectedItem &&
                                correctSelections.includes(selectedItem.label)
                            }
                            isIncorrect={
                                selectedItem &&
                                errors.includes(selectedItem.label)
                            }
                        />
                    );
                })}
            </AnswersContainer>
            <ButtonContainer className="flex-all-centered">
                {allAnswersCorrect ? (
                    <InteractiveActivityButton
                        isCorrect={true}
                        onClick={() => handleTryAgainAfterAllCorrect()}
                        className="secondary"
                    />
                ) : isNewGame ? (
                    <InteractiveActivityButton
                        isCorrect={false}
                        onClick={() => validateAnswers()}
                        className={
                            !selectedItems.includes(null) || !isNewGame
                                ? 'secondary'
                                : 'disabledBtn'
                        }
                    />
                ) : (
                    <InteractiveActivityButton
                        onClick={() => tryAgain()}
                        isCorrect={false}
                        hasError={!allAnswersCorrect && errors.length}
                        className={
                            !allAnswersCorrect && errors.length
                                ? 'incorrect'
                                : ''
                        }
                    />
                )}
                <StyledHintButton
                    padding="1.2rem 2rem"
                    onClick={() => onHintClick()}
                >
                    Hint
                </StyledHintButton>
            </ButtonContainer>
        </>
    );
};

// color vars
const blueGreen = `#CAE8E8`;
const topSurface = `#FCCEA7`;
const frontSurface = `#F0B67F`;

const FoodOptionsContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: ${blueGreen};
    height: 25rem;
    padding: 2rem;
    margin-bottom: 4rem;

    border-radius: 2rem;
    position: relative;

    :after {
        position: absolute;
        content: ' ';
        width: 100%;
        background: ${frontSurface};
        height: 4rem;
        bottom: 0;
        border-radius: 0 0 2rem 2rem;
    }

    :before {
        position: absolute;
        content: ' ';
        width: 100%;
        background: ${topSurface};
        height: 4rem;
        bottom: 4rem;
    }
`;

const AnswersContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 2.4rem;
    padding: 0 3rem;

    /* The 3 'BoxComponent' answer circles are inside spans from the library element */
    span {
        width: ${(props) =>
            props.devicesize === 'tablet' ? '14rem' : '21rem'};
        height: ${(props) =>
            props.devicesize === 'tablet' ? '14rem' : '21rem'};

        span {
            height: 100%;
            width: 100%;

            div {
                height: inherit;
                width: inherit;
            }
        }
    }
`;

const ButtonContainer = styled.div`
    margin-top: 4rem;
    margin-bottom: 7rem;
`;

export default Game;
