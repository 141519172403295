import React from 'react';
import styled from '@emotion/styled';

const CircularNumber = ({ number, marginRight, zIndex }) => {
    return (
        <Container marginRight={marginRight} zIndex={zIndex}>
            <Number className="body-1 flex-all-centered">{number}</Number>
        </Container>
    );
};

const Container = styled.div`
    margin-right: ${(props) =>
        props.marginRight ? props.marginRight : '2rem'};
    z-index: ${(props) => props.zIndex};
`;

const Number = styled.div`
    background: ${(props) => props.theme.colors.secondary};
    color: ${(props) => props.theme.colors.lightText};
    min-height: 4rem;
    min-width: 4rem;
    border-radius: 50%;
`;

export default CircularNumber;
