import gql from 'graphql-tag';

export const GET_LANDING_PAGE_HERO = gql`
    query getLandingPageHero {
        landingPage {
            id
            created_at
            updated_at
            hero {
                id
                image {
                    id
                    alternativeText
                    formats
                    url
                }
            }
        }
    }
`;

export const GET_LANDING_PAGE_HIGHLIGHTS = gql`
    query getLandingPageHighlights {
        landingPage {
            id
            highlights {
                id
                title
                content
                animatedCharacter {
                    id
                    image {
                        id
                        formats
                        url
                    }
                    imageAlternativeText
                }
                sectionImage {
                    id
                    image {
                        id
                        formats
                        url
                    }
                    imageAlternativeText
                }
            }
        }
    }
`;

export const GET_LANDING_PAGE_CTA_SECTION = gql`
    query getLandingPageCTA {
        landingPage {
            id
            finalCTASection {
                id
                sectionTitle
                buttonText
                questionText
                signInLinkText
                ctaSectionImage {
                    id
                    imageAlternativeText
                    image {
                        id
                        formats
                        url
                    }
                }
            }
        }
    }
`;

export const GET_LANDING_PAGE_CREATED_BY_SECTION = gql`
    query getLandingPageCreatedBy {
        landingPage {
            id
            createdBySection {
                id
                cursiveText
                mainTitleText
                imageAlternativeText
                image {
                    id
                    formats
                    url
                }
            }
        }
    }
`;

export const GET_VIEWS = gql`
    query getCourse($courseID: ID!) {
        course(id: $courseID) {
            id
            viewCount
        }
    }
`;

export const GET_COURSE = gql`
    query getCourse($slug: String!) {
        courses(where: { slug: $slug }) {
            id
            title
            slug
            viewCount
            description
            courseImage {
                id
                alternativeText
                image {
                    id
                    url
                    formats
                }
            }
            mediaZone {
                ... on ComponentCourseComponentsPicture {
                    id
                    alternativeText
                    image {
                        id
                        url
                        formats
                    }
                }
                ... on ComponentCourseComponentsAuthor {
                    id
                    name
                    email
                }
                ... on ComponentCourseComponentsQuote {
                    id
                    quote
                    author
                }
                ... on ComponentCourseComponentsDocuments {
                    id
                    document {
                        url
                    }
                    title
                    estimate
                }
                ... on ComponentCourseComponentsVideo {
                    id
                    videoUrl
                    estimate
                }
                ... on ComponentCourseComponentsSubTitle {
                    id
                    subTitle
                }
                ... on ComponentCourseComponentsNotes {
                    id
                    description
                    estimate
                }
                ... on ComponentCourseComponentsEndPage {
                    id
                    lessonTitle
                    lessonNumber
                    endPage
                    estimatedLessonTimeInMinutes
                    id
                }
            }
            learning_tracks {
                id
                trackTitle
            }
        }
    }
`;

export const GET_FIRST_3_COURSES = gql`
    query getFirst3($id: ID!) {
        learningTrack(id: $id) {
            id
            createdAt
            updatedAt
            trackTitle
            pageDisplayNumber
            slug
            courses(limit: 3) {
                id
                title
                slug
                description
                courseImage {
                    id
                    image {
                        url
                        id
                        formats
                    }
                }
            }
        }
    }
`;

export const GET_FIRST_4_COURSES = gql`
    query getFirst4($id: ID!) {
        learningTrack(id: $id) {
            id
            created_at
            updated_at
            trackTitle
            pageDisplayNumber
            slug
            courses(limit: 4) {
                id
                title
                slug
                description
                courseImage {
                    id
                    image {
                        formats
                        id
                        url
                    }
                }
            }
        }
    }
`;

export const GET_ALL_COURSES_IN_TRACK = gql`
    query getTrack($slug: String!) {
        learningTracks(where: { slug: $slug }) {
            id
            created_at
            updated_at
            trackTitle
            trackDescription
            subtitle
            estimatedTimeInMinutes
            pageDisplayNumber
            slug
            courses {
                id
                courseImage {
                    id
                    image {
                        id
                        url
                        formats
                    }
                    alternativeText
                }
                slug
                title
                description
                viewCount
                mediaZone {
                    ... on ComponentCourseComponentsEndPage {
                        endPage
                        id
                    }
                }
            }
        }
    }
`;

export const GET_REGISTERED_USER = gql`
    query getRegisteredUser($id: ID!) {
        user(id: $id) {
            registered_user {
                firstName
                lastName
                id
            }
        }
    }
`;

export const GET_FULL_PROFILE = gql`
    query getFullProfile($id: ID!) {
        user(id: $id) {
            id
            username
            email
            registered_user {
                id
                firstName
                lastName
                group
                tracking_log {
                    id
                    courseTracker
                    savedLessonsTracker
                    goalTracker
                    badgeTracker
                    recommendedCoursesTracker
                }
                profilePic {
                    id
                    url
                    formats
                }
            }
        }
    }
`;

export const GET_ALL_TRACKERLOGS = gql`
    query getLogs($id: ID!) {
        trackingLog(id: $id) {
            id
            courseTracker
            savedLessonsTracker
            goalTracker
            badgeTracker
            recommendedCoursesTracker
        }
    }
`;

export const GET_COURSE_TRACKER = gql`
    query getLogs($id: ID!) {
        trackingLog(id: $id) {
            id
            courseTracker
        }
    }
`;

export const GET_SAVED_LESSONS_TRACKER = gql`
    query getLogs($id: ID!) {
        trackingLog(id: $id) {
            id
            savedLessonsTracker
        }
    }
`;

export const GET_GOALS_TRACKER = gql`
    query getLogs($id: ID!) {
        trackingLog(id: $id) {
            id
            goalTracker
        }
    }
`;

export const GET_ALL_COURSES = gql`
    query getAllCourses {
        courses(sort: "slug:asc") {
            id
            title
            description
            viewCount
            courseImage {
                id
                image {
                    id
                    url
                    formats
                }
            }
            slug
            mediaZone {
                ... on ComponentCourseComponentsEndPage {
                    endPage
                    id
                }
            }
            tags
        }
    }
`;

export const GET_TRACKS_PAGE_HEADER = gql`
    query getTracksPageHeader {
        tracksPage {
            id
            Header
            Description
        }
    }
`;

export const GET_COURSES_IN_TRACKS = gql`
    query getTrackCourseNames {
        learningTracks {
            id
            trackTitle
            courses {
                id
                title
            }
        }
    }
`;

export const GET_TRACKS = gql`
    query getTracks {
        learningTracks {
            id
            slug
            trackTitle
            subtitle
            trackDescription
            pageDisplayNumber
            estimatedTimeInMinutes
            underDevelopment
            trackImage {
                id
                alternativeText
                image {
                    id
                    url
                    formats
                }
            }

            courses {
                id
                title
                slug
                viewCount
                courseImage {
                    id
                    alternativeText
                    image {
                        id
                        url
                        formats
                    }
                }
            }
        }
    }
`;

export const GET_ALL_BADGES = gql`
    query getAllBadges {
        badges {
            id
            title
            description
            badgeImage {
                id
                image {
                    id
                    url
                }
                alternativeText
            }
            badgeImagePlaceholder {
                id
                imagePlaceholder {
                    id
                    url
                }
                alternativeText
            }
        }
    }
`;

export const GET_BADGE = gql`
    query getBadge($id: ID!) {
        badges(where: { id: $id }) {
            id
            title
            description
            badgeImage {
                id
                image {
                    id
                    url
                }
                alternativeText
            }
        }
    }
`;
