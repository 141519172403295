import React from 'react';
import styled from '@emotion/styled';
import savedLesson from 'assets/icons/savedLesson.svg';
import saveLesson from 'assets/icons/saveLesson.svg';
import P from '../fonts/P';

const SaveButton = ({
    handleSave,
    savedLessons,
    endPage,
    isMobile,
    saveBadge,
}) => {
    return (
        <SaveBtn
            className="flex-center-aligned"
            onClick={handleSave}
            saveBadge={saveBadge}
            isMobile={isMobile}
        >
            <img
                src={savedLessons[endPage.id] ? savedLesson : saveLesson}
                alt="Download lesson icon"
            />
            {isMobile ? (
                ''
            ) : (
                <P
                    className="petit-title-1"
                    textTransform="uppercase"
                    color={savedLessons[endPage.id] ? 'secondary' : 'darkText'}
                    marginLeft="0.5rem"
                >
                    {savedLessons[endPage.id] ? 'Saved' : 'Save'}
                </P>
            )}
        </SaveBtn>
    );
};

const SaveBtn = styled.button`
    background: ${(props) => props.theme.colors.lightBackground};
    padding: 0.2rem 1.5rem 0.1rem 1.3rem;
    border-radius: ${(props) =>
        props.saveBadge && !props.isMobile ? '0 0 1.6rem 1.6rem' : '3rem'};
    box-shadow: ${(props) =>
        props.saveBadge || props.isMobile
            ? '0px 4px 3px rgba(0, 0, 0, 0.09)'
            : ''};
    :hover {
        opacity: 0.7;
        cursor: pointer;
    }

    @media screen and (min-width: 768px) {
        margin-top: ${(props) =>
            props.saveBadge && !props.isMobile ? '-29px' : ''};
    }

    @media screen and (min-width: 1056px) {
        margin-top: ${(props) => (props.saveBadge ? '-11px' : '10px')};
    }

    @media screen and (max-width: ${(props) =>
            props.theme.breakPoints.tablet - 1}) {
        padding: 1.3rem;
    }
`;

export default SaveButton;
