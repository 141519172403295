import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from '@emotion/styled';
import P from 'assets/styledComponents/fonts/P';

const SideDashboardNavbarContent = ({ expandNavbar, dashboardNavItems }) => {
    return (
        <>
            {dashboardNavItems.map((item) => (
                <LinkAndIconContainer exact to={item.route} key={item.text}>
                    <StyledIcon src={item.icon} expanded={expandNavbar} />
                    <P
                        className="petit-title-2"
                        width={expandNavbar ? '9.8rem' : ''}
                    >
                        {expandNavbar ? item.text : ''}
                    </P>
                </LinkAndIconContainer>
            ))}
        </>
    );
};

const StyledIcon = styled.img`
    width: 2.4rem;
    height: 2.4rem;
    margin-right: ${(props) => (props.expanded === true ? '1rem' : '0')};
`;

const LinkAndIconContainer = styled(NavLink)`
    display: flex;
    background-color: ${(props) => props.theme.colors.tertiary};
    padding-left: 1.3rem;
    padding-right: 2.3rem;
    min-width: 6rem;
    height: 4.5rem;
    align-items: center;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    color: ${(props) => props.theme.colors.primaryDarkest};
    text-decoration: none;
    margin-bottom: 0.5rem;
    :hover {
        opacity: 0.8;
    }

    :first-of-type {
        :hover {
            background-color: rgba(255, 255, 255, 0.77);
        }
        &.active {
            background-color: rgba(255, 255, 255, 0.77);
        }
    }

    :nth-of-type(2) {
        :hover {
            background-color: rgba(255, 255, 255, 0.75);
        }
        &.active {
            background-color: rgba(255, 255, 255, 0.75);
        }
    }

    :nth-of-type(3) {
        :hover {
            background-color: rgba(255, 255, 255, 0.73);
        }
        &.active {
            background-color: rgba(255, 255, 255, 0.73);
        }
    }

    :nth-of-type(4) {
        :hover {
            background-color: rgba(255, 255, 255, 0.71);
        }
        &.active {
            background-color: rgba(255, 255, 255, 0.71);
        }
    }

    :last-of-type {
        :hover {
            background-color: rgba(255, 255, 255, 0.69);
        }
        &.active {
            background-color: rgba(255, 255, 255, 0.69);
        }
    }
`;

export default SideDashboardNavbarContent;
