export const Step3ListItems = [
    {
        id: 1,
        text: 'Remove one test strip from its container and close the lid.',
    },
    {
        id: 2,
        text: 'Insert the test strip into the port on the glucose meter to turn it on. If the meter does not turn on, the test strip might have been inserted incorrectly. ',
    },
    {
        id: 3,
        text: 'The screen may turn off after a certain amount of time if no sample is applied. If this happens, take the unused test strip out of the meter and re-insert it into the port to turn it back on.',
    },
];
