const filterCoursesBySearchValue = (courses, searchValue) => {
    // filtering for course tags
    const getCourseTags = (course) => {
        let fullCourse =
            courses !== undefined &&
            courses.find((courses) => courses.id === course.id);
        return fullCourse?.tags && fullCourse.tags;
    };

    const courseWithTags = courses.map((course) => ({
        ...course,
    }));

    for (const course of courseWithTags) {
        course.tags = getCourseTags(course);
    }

    const filteredTags = courseWithTags.filter(
        (c) =>
            c.tags && c.tags.toLowerCase().includes(searchValue.toLowerCase())
    );

    filteredTags.map((c) => {
        delete c.tags;
        return c;
    });

    const filteredCourseTagsIDs = filteredTags.map((c) => {
        return String(c.id);
    });

    // filtering for course descriptions
    const getCourseDescription = (course) => {
        let fullCourse =
            courses !== undefined &&
            courses.find((courses) => courses.id === course.id);
        return fullCourse?.description && fullCourse.description;
    };

    const newCourses = courses.map((course) => ({
        ...course,
    }));

    for (const course of newCourses) {
        course.description = getCourseDescription(course);
    }

    const filteredCourses = newCourses.filter(
        (c) =>
            c.description &&
            c.description.toLowerCase().includes(searchValue.toLowerCase())
    );

    filteredCourses.map((c) => {
        delete c.description;
        return c;
    });

    const filteredCourseIDs = filteredCourses.map((c2) => {
        return String(c2.id);
    });

    return courses.filter(
        (c) =>
            c.title.toLowerCase().includes(searchValue.toLowerCase()) ||
            filteredCourseIDs.includes(String(c.id)) ||
            filteredCourseTagsIDs.includes(String(c.id))
    );
};

export default filterCoursesBySearchValue;
