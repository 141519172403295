import { React, useState, useEffect } from 'react';
import Step3Table from './Step3Table';
import { InputField } from '../../SharedComponentsInteractive';
import InteractiveActivityButton from 'assets/styledComponents/buttons/InteractiveActivityButton';
import Container from 'assets/styledComponents/Container';
import P from 'assets/styledComponents/fonts/P';
import { StyledHintButton } from '../../SharedComponentsInteractive';

const Step3TableActivity = ({
    devicesize,
    showHint3b,
    handleHintClickB,
    hintRef,
}) => {
    const [isAnswer3Correct, setIsAnswer3Correct] = useState(false);
    const [hasSubmitted3, setHasSubmitted3] = useState(false);
    const [valSelected, setValSelected] = useState('');

    useEffect(() => {
        setHasSubmitted3(false);
    }, [isAnswer3Correct, valSelected]);

    const handleSubmitActivity3 = () => {
        if (!hasSubmitted3) {
            setHasSubmitted3(true);
            return;
        }

        setHasSubmitted3(false);
        setIsAnswer3Correct(false);
        setValSelected('');
    };

    const handleTryAgainAfterAllCorrect = (e) => {
        e.preventDefault();
        setValSelected('');
        setIsAnswer3Correct(false);
        setHasSubmitted3(false);
    };

    let inputFieldWidth = devicesize === 'tablet' ? '10rem' : '12rem';
    return (
        <>
            <Step3Table
                isAnswer3Correct={isAnswer3Correct}
                setIsAnswer3Correct={setIsAnswer3Correct}
                hasSubmitted3={hasSubmitted3}
                setHasSubmitted3={setHasSubmitted3}
                valSelected={valSelected}
                setValSelected={setValSelected}
                showHint3b={showHint3b}
                hintRef={hintRef}
            />

            <Container className="flex-column-centered" margin="4rem">
                <InputField
                    type="text"
                    id="result"
                    name="result"
                    placeholder="?"
                    readOnly
                    value={valSelected}
                    className={
                        !isAnswer3Correct && hasSubmitted3
                            ? 'error '
                            : isAnswer3Correct && hasSubmitted3
                            ? 'correct'
                            : ''
                    }
                    width={inputFieldWidth}
                    height="10.4rem"
                    marginTop="1.6rem"
                    boxShadow=" 0px 2px 10px rgba(0, 0, 0, 0.2)"
                />
                <P className="body-1" textAlign="center">
                    {valSelected === 1 ? 'Unit' : 'Units'}
                </P>
            </Container>

            <Container
                marginTop="4rem"
                marginBottom="5.6rem"
                className="flex-all-centered"
            >
                {hasSubmitted3 && isAnswer3Correct ? (
                    <InteractiveActivityButton
                        isCorrect={true}
                        onClick={handleTryAgainAfterAllCorrect}
                        className="secondary"
                    />
                ) : (
                    <InteractiveActivityButton
                        isCorrect={false}
                        onClick={handleSubmitActivity3}
                        className={
                            !valSelected
                                ? 'disabledBtn'
                                : hasSubmitted3
                                ? 'incorrect'
                                : 'secondary'
                        }
                        hasError={hasSubmitted3 && !isAnswer3Correct}
                    />
                )}
                <StyledHintButton
                    padding="1.2rem 2rem"
                    onClick={() => handleHintClickB()}
                />
            </Container>

            {isAnswer3Correct && hasSubmitted3 ? (
                <P
                    className={
                        devicesize === 'desktopSm' || devicesize === 'desktopMd'
                            ? 'body-4'
                            : 'body-2'
                    }
                    marginBottom="1.6rem"
                >
                    Correct. It says to give 3 units, which is more conservative
                    than doing the full calculation.
                </P>
            ) : (
                ''
            )}
        </>
    );
};

export default Step3TableActivity;
