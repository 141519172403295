import React from 'react';
import PageContentContainer from '../assets/styledComponents/PageContentContainer';
import SavedLessonCard from '../components/Courses/Lessons/SavedLessonCard/SavedLessonCard';
import NoSavedLessonCard from '../components/Courses/Lessons/SavedLessonCard/NoSavedLessonCard';
import { GET_SAVED_LESSONS_TRACKER } from '../graphql/queries';
import { useQuery } from '@apollo/client';
import { useGlobalContext } from '../GlobalContext/GlobalProvider';
import DashboardPagesHeader from '../assets/styledComponents/DashboardPagesHeader';
import savedMd from '../assets/icons/savedMd.svg';
import ScrollContainer from '../assets/styledComponents/ScrollContainer';

const DashboardSavedPage = () => {
    const [globalState, dispatch] = useGlobalContext();

    const { data: savedLessonLogData } = useQuery(GET_SAVED_LESSONS_TRACKER, {
        variables: {
            id: globalState.user ? globalState.user.trackingLogId : '',
        },
        skip: globalState.user ? false : true,
    });

    let savedLessons = savedLessonLogData
        ? savedLessonLogData.trackingLog.savedLessonsTracker
        : '';

    return (
        <ScrollContainer>
            <PageContentContainer
                marginTop="4.2rem"
                marginRight="7rem"
                marginLeft="4.5rem"
            >
                <DashboardPagesHeader icon={savedMd} pageTitle="SAVED" />
                {Object.keys(savedLessons).length ? (
                    Object.values(savedLessons).map((l) => {
                        return (
                            <SavedLessonCard
                                key={l.lessonID}
                                courseName={l.courseName}
                                lessonNum={l.lessonNum}
                                lessonName={l.lessonTitle}
                                url={l.url}
                                lessonID={l.lessonID}
                                savedLessons={savedLessons}
                                globalState={globalState}
                                dispatch={dispatch}
                            />
                        );
                    })
                ) : (
                    <NoSavedLessonCard />
                )}
            </PageContentContainer>
        </ScrollContainer>
    );
};

export default DashboardSavedPage;
