import React from 'react';
import Step3DataCell from './Step3DataCell';

const Step3TableRow = ({
    data,
    isAnswer3Correct,
    setIsAnswer3Correct,
    rowNumber,
    hasSubmitted3,
    setValSelected,
    valSelected,
}) => {
    return (
        <tr>
            {data.map((item, idx) => {
                let colNum = idx + 1;
                let textContent = colNum === 1 ? item : item[0];
                let numVal = item[1] ? item[1] : 0;

                return (
                    <Step3DataCell
                        key={idx}
                        cellTextContent={textContent}
                        cellNumericValue={numVal}
                        isAnswer3Correct={isAnswer3Correct}
                        setIsAnswer3Correct={setIsAnswer3Correct}
                        rowNumber={rowNumber}
                        colNumber={colNum}
                        hasSubmitted3={hasSubmitted3}
                        valSelected={valSelected}
                        setValSelected={setValSelected}
                    />
                );
            })}
        </tr>
    );
};

export default Step3TableRow;
