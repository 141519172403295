import styled from '@emotion/styled';

const SignInButton = styled.button`
    font-family: 'MontserratSemiBold', sans-serif;
    font-size: 1.8rem;
    border-radius: 2.5rem;
    letter-spacing: ${(props) => props.theme.letterSpacing.sm};
    padding: 1.15rem 2rem;
    margin-left: 2.6rem;

    background-color: ${(props) => props.theme.colors.secondary};
    color: ${(props) => props.theme.colors.lightText};

    :hover {
        cursor: pointer;
        opacity: 0.4;
    }
`;

export default SignInButton;
