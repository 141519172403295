import styled from '@emotion/styled';
import React from 'react';
import { DragDropContainer, DropTarget } from 'react-drag-drop-container';

function BoxItem({ label, index, img, altText }) {
    return (
        <BoxItemComponent className="flex-all-centered">
            <DragDropContainer
                targetKey="boxItem"
                dragData={{ label: label, index: index }}
                disappearDraggedElement={true}
                dragHandleClassName="grabber"
                imageName={img}
            >
                <DropTarget targetKey="boxItem">
                    <img src={img} alt={altText} />
                </DropTarget>
            </DragDropContainer>
        </BoxItemComponent>
    );
}

const BoxItemComponent = styled.div`
    /* styling foods items dragged inside the circles  */
    div {
        margin-top: -2rem;
        span {
            /* adding !important to prevent oversized food items when decreasing screen size with items already dragged into the circles */
            width: 85% !important;
            height: 85% !important;
            margin: auto;
            span {
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    margin: auto;
                    max-height: 90%;
                    max-width: 120px;
                }
            }
        }
    }
`;

export default BoxItem;
