import React from 'react';
import Header3 from './fonts/Header3';
import StyledIcon from './StyledImage';

const DashboardPagesHeader = ({ icon, pageTitle }) => {
    return (
        <div className="flex">
            <StyledIcon
                height="3.5rem"
                width="3.5rem"
                marginRight="1rem"
                src={icon}
            />
            <Header3 marginBottom="4rem">{pageTitle}</Header3>
        </div>
    );
};

export default DashboardPagesHeader;
