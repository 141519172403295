import styled from '@emotion/styled';
import React from 'react';
import doneBadge from '../../assets/icons/doneBadge.svg';
import LinkWrappingButton from '../../assets/styledComponents/buttons/LinkWrappingButton';
import CardButtonMd from '../../assets/styledComponents/buttons/CardButtonMd';
import CardSubtitle from '../../assets/styledComponents/fonts/PetitTitleSerif';
import Title from '../../assets/styledComponents/fonts/Header3';
import IconAndInfo from 'assets/styledComponents/IconAndInfo';
import P from 'assets/styledComponents/fonts/P';

const TracksCard = ({
    slug,
    trackTitle,
    trackSubtitle,
    trackDescription,
    trackImage,
    trackImageAlternativeText,
    progress,
    infoIcons,
    underDevelopment,
}) => {
    return (
        <>
            {!underDevelopment ? (
                <CardContainer className="card-shadow">
                    <TrackImage
                        src={trackImage}
                        alt={trackImageAlternativeText}
                    />
                    <InfoSection className="flex-column-space-between">
                        <div className="topOfInfoSection">
                            <div className="flex-center-aligned">
                                {progress === 100 && (
                                    <StyledDoneBadge src={doneBadge} />
                                )}
                                <div>
                                    {trackSubtitle.length > 0 && (
                                        <CardSubtitle
                                            className="maxOneLineText"
                                            color="greyText"
                                            marginBottom="0.3rem"
                                        >
                                            {trackSubtitle}
                                        </CardSubtitle>
                                    )}
                                    <Title
                                        className="maxOneLineText"
                                        color="darkText"
                                        marginBottom="0.7rem"
                                    >
                                        {trackTitle}
                                    </Title>
                                </div>
                            </div>
                            <P
                                className="body-4 maxFiveLineText"
                                color="greyText"
                                marginBottom="2rem"
                            >
                                {trackDescription}
                            </P>
                        </div>

                        <div className="bottomOfInfoSection">
                            {progress > 1 && progress < 100 ? (
                                <div className="flex-center-aligned">
                                    <ProgressBarBackground>
                                        <ProgressBarFill progress={progress} />
                                    </ProgressBarBackground>
                                    <P
                                        className="petit-title-2"
                                        color="darkText"
                                    >
                                        {progress}%
                                    </P>
                                </div>
                            ) : (
                                <div className="flex-center-aligned">
                                    {infoIcons.map((item, idx) => {
                                        return (
                                            <div key={idx}>
                                                <IconAndInfo
                                                    icon={item.icon}
                                                    text={item.title}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>

                        <ButtonContainer className="btn-container">
                            <LinkWrappingButton to={`/pathway/${slug}`}>
                                <CardButtonMd width="26.4rem">
                                    View Pathway Syllabus
                                </CardButtonMd>
                            </LinkWrappingButton>
                        </ButtonContainer>
                    </InfoSection>
                </CardContainer>
            ) : (
                <DisabledCardContainer className="card-shadow">
                    <UnderDevelopment width="66.4rem">
                        Under Development
                    </UnderDevelopment>
                    <OpacityContainer>
                        <TrackImage
                            src={trackImage}
                            alt={trackImageAlternativeText}
                        />
                        <InfoSection className="flex-column-space-between">
                            <div className="topOfInfoSection">
                                <div className="flex-center-aligned">
                                    {progress === 100 && (
                                        <StyledDoneBadge src={doneBadge} />
                                    )}
                                    <div>
                                        {trackSubtitle.length > 0 && (
                                            <CardSubtitle
                                                className="maxOneLineText"
                                                color="greyText"
                                                marginBottom="0.3rem"
                                            >
                                                {trackSubtitle}
                                            </CardSubtitle>
                                        )}
                                        <Title
                                            className="maxOneLineText"
                                            color="darkText"
                                            marginBottom="0.7rem"
                                        >
                                            {trackTitle}
                                        </Title>
                                    </div>
                                </div>
                                <P
                                    className="body-4 maxFiveLineText"
                                    color="greyText"
                                    marginBottom="2rem"
                                >
                                    {trackDescription}
                                </P>
                            </div>

                            <div className="bottomOfInfoSection">
                                {progress > 1 && progress < 100 ? (
                                    <div className="flex-center-aligned">
                                        <ProgressBarBackground>
                                            <ProgressBarFill
                                                progress={progress}
                                            />
                                        </ProgressBarBackground>
                                        <P
                                            className="petit-title-2"
                                            color="darkText"
                                        >
                                            {progress}%
                                        </P>
                                    </div>
                                ) : (
                                    <div className="flex-center-aligned">
                                        {infoIcons.map((item, idx) => {
                                            return (
                                                <div key={idx}>
                                                    <IconAndInfo
                                                        icon={item.icon}
                                                        text={item.title}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>

                            <ButtonContainer className="btn-container">
                                <LinkWrappingButton to={`/pathway/${slug}`}>
                                    <CardButtonMd width="26.4rem">
                                        View Pathway Syllabus
                                    </CardButtonMd>
                                </LinkWrappingButton>
                            </ButtonContainer>
                        </InfoSection>
                    </OpacityContainer>
                </DisabledCardContainer>
            )}
        </>
    );
};

const UnderDevelopment = styled.div`
    position: absolute;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    z-index: 2;

    font-family: 'MontserratSemiBold', sans-serif;
    font-size: 1.8rem;
    height: 4rem;
    color: white;

    background-color: ${(props) => props.theme.colors.primaryDark};
    border: 0.1rem solid ${(props) => props.theme.colors.primaryDark};

    display: flex;
    align-items: center;
    justify-content: center;
`;

const OpacityContainer = styled.div`
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    height: 4rem;

    display: flex;
    align-items: center;
    justify-content: center;

    /* customizable */
    width: ${(props) => props.width};
    opacity: 30%;
`;

const DisabledCardContainer = styled.div`
    width: 100%;
    height: 30rem;
    border-radius: 2rem;
    display: flex;
    margin-bottom: ${(props) => props.theme.spaces.md};
    position: relative;
    pointer-events: none;
`;

const CardContainer = styled.div`
    width: 100%;
    height: 30rem;
    border-radius: 2rem;
    display: flex;
    margin-bottom: ${(props) => props.theme.spaces.md};
    position: relative;
    :hover .btn-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
`;

const TrackImage = styled.img`
    width: 30rem;
    height: 30rem;
    object-fit: cover;
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
`;

const StyledDoneBadge = styled.img`
    margin-right: ${(props) => props.theme.spaces.sm};
    margin-top: -3rem;
    margin-left: -0.2rem;
`;

const InfoSection = styled.section`
    padding: 3rem 10rem 3.5rem 6rem;
    width: 100%;
    height: 30rem;
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
    background-color: ${(props) => props.theme.colors.lightBackground};
`;

const ProgressBarBackground = styled.div`
    width: 80%;
    height: 1.6rem;
    border-radius: 1rem;
    background: ${(props) => props.theme.colors.lightGreyBackground};
    margin-right: 1rem;
    margin-bottom: 0.3rem;
`;

const ProgressBarFill = styled.div`
    width: ${(props) => props.progress}%;
    height: 1.6rem;
    border-radius: 1rem;
    background: ${(props) => props.theme.colors.secondary};
`;

// overlay that displays the button when card is hovered on
const ButtonContainer = styled.div`
    width: 100%;
    background: linear-gradient(
        87.75deg,
        rgba(255, 255, 255, 0) 34.98%,
        rgba(255, 255, 255, 0.443182) 49.61%,
        #ffffff 67.25%
    );
    height: 100%;
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    display: none;
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
    padding: ${(props) => props.theme.spaces.md};
`;

export default TracksCard;
