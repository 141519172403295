import { React, useState, useEffect } from 'react';
import InteractiveActivityButton from 'assets/styledComponents/buttons/InteractiveActivityButton.js';
import {
    CalculatorContainer,
    StyledForm,
} from '../../SharedComponentsInteractive';
import equalsPrimaryDarkest from 'assets/icons/math/equalsPrimaryDarkest.svg';
import multiplyPrimaryDarkest from 'assets/icons/math/multiplyPrimaryDarkest.svg';
import StyledImage from 'assets/styledComponents/StyledImage';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';
import Container from 'assets/styledComponents/Container';
import CalcInputItem from 'assets/styledComponents/CalcInputItem';
import InputItemLabelPosition from 'types/inputItemLabelPosition';
import CalcResult from 'assets/styledComponents/CalcResult';
import { StyledHintButton } from '../../SharedComponentsInteractive';

const CalorieCalculator = ({ onHintClick }) => {
    let initialFormfields = {
        gramsPerServing: '',
        servings: '',
        result: '',
    };

    let initialErrors = {
        gramsPerServing: false,
        servings: false,
        result: false,
    };

    const [formFields, setFormFields] = useState({
        ...initialFormfields,
    });

    const [error, setError] = useState({
        ...initialErrors,
    });

    const [gramVal, setGramVal] = useState('');
    const [servingsVal, setServingsVal] = useState('');
    const [calcResult, setCalcRes] = useState('?');
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const onChange = (event) => {
        setFormFields({
            ...formFields,
            [event.target.name]: event.target.value,
        });

        if (event.target.name === 'servings') {
            setServingsVal(event.target.value);
        } else if (event.target.name === 'gramsPerServing') {
            setGramVal(event.target.value);
        }
    };

    const calculateResult = async (e) => {
        e.preventDefault();
        setHasSubmitted(true);
        let { gramsPerServing, servings } = formFields;
        let result = (+gramsPerServing * +servings).toFixed(2);
        setCalcRes(result + 'g');

        if (error.gramsPerServing || error.servings || error.result) {
            setError({
                ...initialErrors,
            });
            setFormFields({
                ...initialFormfields,
            });
            // clear input and result fields
            document.getElementById('calorie-calculator').reset();
            setGramVal('');
            setServingsVal('');
            setCalcRes('?');
        } else {
            setError({
                ...error,
                gramsPerServing: gramsPerServing != 15 ? true : false,
                servings: servings != 5 ? true : false,
                // both inputs and the result have to be correct for the result to be correct
                result:
                    result != 75 || gramsPerServing != 15 || servings != 5
                        ? true
                        : false,
            });
        }
    };

    const handleTryAgainAfterAllCorrect = (e) => {
        e.preventDefault();
        setFormFields({
            ...initialFormfields,
        });
        // clear input and result fields
        document.getElementById('calorie-calculator').reset();
        setGramVal('');
        setServingsVal('');
        setCalcRes('?');
    };

    // remove error message if user clears an input field
    useEffect(() => {
        if (!formFields.gramsPerServing.length || !formFields.servings.length) {
            setHasSubmitted(false);
            setError({
                ...error,
                gramsPerServing: false,
                servings: false,
                result: false,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formFields]);

    // responsive vars
    let [globalState] = useGlobalContext();
    let devicesize = globalState.deviceSize;

    let inputFieldWidth =
        devicesize === 'mobile'
            ? '6.8rem'
            : devicesize === 'tablet'
            ? '10rem'
            : '12rem';

    let inputFieldHeight =
        devicesize === 'mobile'
            ? '6.8rem'
            : devicesize === 'tablet'
            ? '10rem'
            : '12rem';

    let operatorMargin =
        devicesize === 'mobile'
            ? '1rem'
            : devicesize === 'tablet'
            ? '1.5rem'
            : '4rem';
    let operatorSize =
        devicesize === 'mobile'
            ? '1.6rem'
            : devicesize === 'tablet'
            ? '2rem'
            : '3.2rem';

    let marginTop =
        devicesize === 'mobile'
            ? '2.6rem'
            : devicesize === 'tablet'
            ? '3.6rem'
            : '4.2rem';

    let allCorrect = !error.gramsPerServing && !error.servings && !error.result;

    return (
        <>
            <StyledForm id="calorie-calculator" devicesize={devicesize}>
                <CalculatorContainer
                    className="flex-center-justified"
                    devicesize={devicesize}
                    background="tertiary"
                >
                    <div className="flex-column-centered">
                        <CalcInputItem
                            label="Grams in 1 serving"
                            labelPosition={InputItemLabelPosition.BELOW}
                            name="gramsPerServing"
                            value={gramVal}
                            onChange={onChange}
                            error={error}
                            hasSubmitted={hasSubmitted}
                            inputFieldWidth={inputFieldWidth}
                            inputFieldHeight={inputFieldHeight}
                            placeholder="0g"
                            unit="g"
                        />
                    </div>
                    <StyledImage
                        alt="multiplacation symbol"
                        width={operatorSize}
                        height={operatorSize}
                        marginTop={marginTop}
                        marginLeft={operatorMargin}
                        marginRight={operatorMargin}
                        src={multiplyPrimaryDarkest}
                    />
                    <div className="flex-column-centered">
                        <CalcInputItem
                            label="1/2 cup servings"
                            labelPosition={InputItemLabelPosition.BELOW}
                            name="servings"
                            value={servingsVal}
                            onChange={onChange}
                            error={error}
                            hasSubmitted={hasSubmitted}
                            inputFieldWidth={inputFieldWidth}
                            inputFieldHeight={inputFieldHeight}
                            placeholder="0g"
                            unit="g"
                        />
                    </div>

                    <StyledImage
                        alt="equals symbol"
                        width={operatorSize}
                        height={operatorSize}
                        marginTop={marginTop}
                        marginLeft={operatorMargin}
                        marginRight={operatorMargin}
                        src={equalsPrimaryDarkest}
                    />
                    <div className="flex-column-centered">
                        <CalcResult
                            label="Carbs"
                            name="result"
                            value={calcResult}
                            error={error}
                            hasSubmitted={hasSubmitted}
                            inputFieldWidth={inputFieldWidth}
                            inputFieldHeight={inputFieldHeight}
                            borderColor="tertiaryDark"
                            background="tertiaryDark"
                            devicesize={devicesize}
                        />
                    </div>
                </CalculatorContainer>

                {/* Buttons  */}
                <Container className="flex-all-centered">
                    {allCorrect && hasSubmitted ? (
                        <InteractiveActivityButton
                            isCorrect={true}
                            onClick={handleTryAgainAfterAllCorrect}
                            className="secondary"
                        />
                    ) : (
                        <InteractiveActivityButton
                            isCorrect={false}
                            onClick={calculateResult}
                            className={
                                !formFields.gramsPerServing.length ||
                                !formFields.servings.length
                                    ? 'disabledBtn'
                                    : !allCorrect
                                    ? 'incorrect'
                                    : 'secondary'
                            }
                            hasError={!allCorrect}
                        />
                    )}
                    <StyledHintButton onClick={(e) => onHintClick(e)} />
                </Container>
            </StyledForm>
        </>
    );
};

export default CalorieCalculator;
