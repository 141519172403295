import { React, useState, useEffect } from 'react';
import BadgeModal from '../DashboardHome/BadgeModal';
import badgeStatus from '../../types/badgeStatus';
import convertDate from '../../utils/helpers/ConvertDate';
import {
    BadgeIcon,
    Tooltip,
    TooltipText,
} from '../DashboardHome/SharedComponentsDashboard';
import H1 from 'assets/styledComponents/fonts/H1';
import Container from 'assets/styledComponents/Container';

const MyBadgesSection = ({ allBadges, globalState, devicesize }) => {
    const [achievedBadges, setAchievedBadges] = useState(
        globalState.user ? globalState.user.trackingLog.badgeTracker : null
    );

    const [showModal, setShowModal] = useState(false);
    const [badgeToShow, setBadgeToShow] = useState();
    // Prevent background content scrolling when badge modal open
    if (showModal) {
        document.body.style.overflow = 'hidden';
    } else if (!showModal) {
        document.body.style.overflow = 'unset';
    }

    useEffect(() => {
        setAchievedBadges(
            globalState?.user ? globalState.user.trackingLog.badgeTracker : null
        );
        return () => setAchievedBadges(null);
    }, [globalState]);

    const handleShowBadge = (badge) => {
        setBadgeToShow(badge);
        setShowModal(true);
    };

    const showAllBadges = () => {
        return (
            allBadges &&
            allBadges.map((badge) =>
                achievedBadges[badge.id] ? (
                    <Tooltip key={badge.id}>
                        <BadgeIcon
                            key={badge.id}
                            src={badge.badgeImage.image.url}
                            onClick={() =>
                                handleShowBadge({
                                    ...badge,
                                    achievementDate:
                                        achievedBadges[badge.id]
                                            .achievementDate,
                                })
                            }
                            alt={badge.title}
                            isAchieved={true}
                        />
                        <TooltipText className="body-2">
                            {badge.title}
                        </TooltipText>
                    </Tooltip>
                ) : (
                    <Tooltip key={badge.id}>
                        <BadgeIcon
                            key={badge.id}
                            src={
                                badge.badgeImagePlaceholder &&
                                badge.badgeImagePlaceholder.imagePlaceholder.url
                            }
                            onClick={() =>
                                handleShowBadge({
                                    ...badge,
                                })
                            }
                            alt={badge.title}
                            isAchieved={false}
                        />
                        <TooltipText className="body-2">
                            {badge.title}
                        </TooltipText>
                    </Tooltip>
                )
            )
        );
    };

    return (
        <Container
            className="card-shadow"
            borderRadius="2rem"
            borderColor="lightBackground"
            margin="0 auto 4rem"
            minHeight="21rem"
        >
            {showModal && (
                <BadgeModal
                    setShowModal={setShowModal}
                    showModal={showModal}
                    status={badgeStatus.EXISTING}
                    title={badgeToShow.title}
                    description={badgeToShow.description}
                    image={badgeToShow.badgeImage.image.url}
                    achievementDate={
                        badgeToShow.achievementDate
                            ? convertDate(badgeToShow.achievementDate)
                            : null
                    }
                    devicesize={devicesize}
                    badgeOutline={
                        badgeToShow.badgeImagePlaceholder.imagePlaceholder.url
                    }
                />
            )}
            <H1
                className="petit-title-1"
                textTransform="uppercase"
                padding={
                    devicesize == 'mobile'
                        ? '2rem 0rem 0rem 2.4rem'
                        : '3rem 0rem 0rem 3.3rem'
                }
            >
                My badges
            </H1>
            <Container
                className={
                    devicesize == 'mobile'
                        ? 'flex-space-between flex-wrap'
                        : 'flex-start-justified flex-wrap'
                }
                width={
                    devicesize === 'mobile'
                        ? '23.2rem'
                        : devicesize === 'tablet'
                        ? '58rem'
                        : devicesize === 'desktopSm'
                        ? '70rem'
                        : '104.4rem'
                }
                margin={
                    devicesize == 'mobile'
                        ? '2.9rem auto 0rem auto'
                        : '3.6rem auto 0rem auto'
                }
                colGap="1.9rem"
                rowGap="2rem"
                paddingBottom={devicesize == 'mobile' ? '3rem ' : '3.6rem'}
            >
                {showAllBadges()}
            </Container>
        </Container>
    );
};

export default MyBadgesSection;
