import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import BadgeModal from '../../components/DashboardHome/BadgeModal';
import { saveUser } from '../../GlobalContext/actionVariables';
import { useGlobalContext } from '../../GlobalContext/GlobalProvider';
import { UPDATE_BADGE_TRACKER } from '../../graphql/mutations';
import { GET_BADGE } from '../../graphql/queries';
import badgeStatus from '../../types/badgeStatus';

const BadgesAchievement = ({ id }) => {
    const [globalState, dispatch] = useGlobalContext();
    let devicesize = globalState.deviceSize;

    let achievedBadges = globalState?.user?.trackingLog?.badgeTracker;

    const { data: getBadge } = useQuery(GET_BADGE, {
        variables: { id },
    });

    const badge = getBadge && getBadge.badges[0];
    const [showModal, setShowModal] = useState(false);
    const [badgeToShow, setBadgeToShow] = useState(badge);

    // Prevent background content scrolling when badge modal open
    if (showModal) {
        document.body.style.overflow = 'hidden';
    } else if (!showModal) {
        document.body.style.overflow = 'unset';
    }

    useEffect(() => {
        if (badge) {
            handleBadgeAchievement(achievedBadges, badge);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [badge]);

    const [updateBadgesTracker] = useMutation(UPDATE_BADGE_TRACKER);

    const handleBadgeAchievement = (achievedBadges, badge) => {
        if (!globalState.user) return;

        if (badge && !Object.keys(achievedBadges).includes(badge.id)) {
            setShowModal(true);
            setBadgeToShow(badge);
            const updateData = {
                ...achievedBadges,
                lastBadgeAchieved: badge.id,
                dateOfLastBadgeAchieved: new Date().toISOString(),
                [badge.id]: {
                    achievementDate: new Date().toISOString(),
                },
            };
            updateBadgesTracker({
                variables: {
                    id: globalState.user.trackingLogId,
                    data: {
                        badgeTracker: updateData,
                    },
                },
            });
            dispatch({
                type: saveUser,
                payload: {
                    ...globalState.user,
                    trackingLog: {
                        ...globalState.user.trackingLog,
                        badgeTracker: updateData,
                    },
                },
            });
        }
    };

    return (
        <div className="flexColumnCentered">
            {showModal && (
                <BadgeModal
                    setShowModal={setShowModal}
                    showModal={showModal}
                    status={badgeStatus.NEW}
                    title={badgeToShow.title}
                    description={badgeToShow.description}
                    image={badgeToShow.badgeImage.image.url}
                    devicesize={devicesize}
                    badgeOutline={
                        badge.badgeImagePlaceholder.imagePlaceholder.url
                    }
                />
            )}
        </div>
    );
};

export default BadgesAchievement;
