import React from 'react';
import styled from '@emotion/styled';
import LessonPage from './Lessons/Lesson';
import FormatEstimatedTime from 'utils/helpers/FormatEstimatedTime';
import ShowIconsSection from 'utils/helpers/ShowIconsSection';
import ShowLessonCards from './ShowLessonCards';

import LinkWrappingButton from 'assets/styledComponents/buttons/LinkWrappingButton';
import bookMd from 'assets/icons/bookMd.svg';
import clockMd from 'assets/icons/clockMd.svg';
import calculateEstimatedCourseTime from 'utils/helpers/CalculateEstimatedCourseTime';
import H1 from 'assets/styledComponents/fonts/H1';
import P from 'assets/styledComponents/fonts/P';
import CardButtonLg from 'assets/styledComponents/buttons/CardButtonLg';

// handles what to render (course syllabus or one of the lessons)
const ShowCourseAndLessonPages = ({
    page,
    last,
    numberOfLessons,
    course,
    isCourseCompleted,
    globalState,
    hasCourseStarted,
    slug,
    lastLessonCompletedPage,
    arrayOfEndPages,
    lessonContent,
    lastLessonCompleted,
    isNextLessonAvaliable,
    setIsNextLessonAvaliable,
}) => {
    const pageIndex = Number(page);
    const isMobile = Boolean(globalState.deviceSize === 'mobile');
    const isTablet = Boolean(globalState.deviceSize === 'tablet');
    const isDesktopMd = Boolean(globalState.deviceSize === 'desktopMd');

    const infoIcon = [
        { icon: `${bookMd}`, title: `${numberOfLessons} Lessons` },
        {
            icon: `${clockMd}`,
            title: FormatEstimatedTime(
                course && calculateEstimatedCourseTime(course.courses[0])
            ),
        },
    ];

    // show course overview page with lesson cards
    return (
        <>
            {pageIndex === 0 ? (
                <PageLayout className={isDesktopMd ? 'flex' : ''}>
                    <CourseInfoSection
                        isMobile={isMobile}
                        isTablet={isTablet}
                        isDesktopMd={isDesktopMd}
                    >
                        <H1
                            className={isMobile ? 'header-3' : 'header-1'}
                            color="primary"
                            marginBottom={isMobile ? '2.4rem' : '4rem'}
                        >
                            {course && course.courses[0].title}
                        </H1>
                        {ShowIconsSection(infoIcon)}

                        <P
                            className={isMobile ? 'body-2' : 'body-4'}
                            marginBottom={isMobile ? '3.2rem' : '4rem'}
                        >
                            {course && course.courses[0].description}
                        </P>

                        {isCourseCompleted || !globalState.user ? (
                            <LinkWrappingButton to={`/modules/${slug}/1`}>
                                <CardButtonLg
                                    width={
                                        isDesktopMd || isMobile
                                            ? '100%'
                                            : '36rem'
                                    }
                                >
                                    View Module
                                </CardButtonLg>
                            </LinkWrappingButton>
                        ) : hasCourseStarted ? (
                            <LinkWrappingButton
                                to={`/modules/${slug}/${lastLessonCompletedPage}`}
                            >
                                <CardButtonLg
                                    width={
                                        isDesktopMd || isMobile
                                            ? '100%'
                                            : '36rem'
                                    }
                                >
                                    Resume Module
                                </CardButtonLg>
                            </LinkWrappingButton>
                        ) : (
                            <LinkWrappingButton to={`/modules/${slug}/1`}>
                                <CardButtonLg
                                    width={
                                        isDesktopMd || isMobile
                                            ? '100%'
                                            : '36rem'
                                    }
                                >
                                    Start Module
                                </CardButtonLg>
                            </LinkWrappingButton>
                        )}
                    </CourseInfoSection>
                    <LessonCardsSection
                        isMobile={isMobile}
                        isTablet={isTablet}
                        isDesktopMd={isDesktopMd}
                    >
                        {ShowLessonCards(
                            arrayOfEndPages,
                            lessonContent,
                            globalState,
                            course,
                            slug,
                            lastLessonCompleted,
                            hasCourseStarted,
                            isCourseCompleted
                        )}
                    </LessonCardsSection>
                </PageLayout>
            ) : lessonContent && lessonContent[page] ? (
                <LessonPage
                    lesson={lessonContent[page]}
                    last={last}
                    globalState={globalState}
                    currentCourse={course.courses[0]}
                    isNextLessonAvaliable={isNextLessonAvaliable}
                    setIsNextLessonAvaliable={setIsNextLessonAvaliable}
                    page={page}
                />
            ) : (
                ''
            )}
        </>
    );
};

const PageLayout = styled.div`
    width: 100%;
`;

const CourseInfoSection = styled.section`
    width: ${(props) => (props.isDesktopMd ? '40%' : '100%')};
    padding: ${(props) =>
        props.isMobile
            ? '2.8rem 2.4rem 3.2rem 2.4rem'
            : props.isTablet
            ? '4.8rem 4.8rem 5.6rem 4.8rem'
            : props.isDesktopMd
            ? '7.6rem 6.9rem 7.6rem 14rem'
            : '7.6rem 8.8rem 6.4rem 8.8rem'};
    background: ${(props) => props.theme.colors.lightGreyGradient};
`;

const LessonCardsSection = styled.section`
    width: ${(props) => (props.isDesktopMd ? '60%' : '100%')};
    padding: ${(props) =>
        props.isMobile
            ? '4rem 2.4rem 4rem 2.4rem'
            : props.isTablet
            ? '5.6rem 4.8rem 5.6rem 4.8rem'
            : props.isDesktopMd
            ? '7.6rem 14rem 7.6rem 7.1rem'
            : '6.4rem 8.8rem 8.8rem 8.8rem'};
    background-color: ${(props) => props.theme.colors.tertiary};
`;

export default ShowCourseAndLessonPages;
