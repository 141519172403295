import { React, useState, useEffect, Fragment } from 'react';
import styled from '@emotion/styled';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';
import { updateAuth } from 'GlobalContext/actionVariables';
import useUserData from 'hooks/useUserData';
import SignInOrUpContainer from './SignInOrUpContainer';
import Header3 from 'assets/styledComponents/fonts/Header3';
import StyledPetitTitle from './StyledPetitTitle';
import StyledInput from './StyledInput';
import FilledButtonMd from 'assets/styledComponents/buttons/FilledButtonMd';
import StyledLink from 'assets/styledComponents/StyledLink';
import noEntry from 'assets/icons/noEntry.svg';
import emailSent from 'assets/icons/emailSent.svg';
import BackButton from './BackButton';
import CloseModalButton from 'utils/helpers/CloseModalButton';
import H1 from 'assets/styledComponents/fonts/H1';
import P from 'assets/styledComponents/fonts/P';
import StyledImage from 'assets/styledComponents/StyledImage';
import { useInView } from 'react-intersection-observer';
import handleSubmitSignIn from './utils/handleSubmitSignIn';

const SignInForm = ({ withModal, setShowModal, setShowSignInForm }) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [formFields, setFormFields] = useState({
        email: '',
        password: '',
    });
    const [, dispatch] = useGlobalContext();
    const { loadUserData } = useUserData(true);

    const [state] = useGlobalContext();
    let userData = state.user;

    const [forgotPassword, setForgotPassword] = useState(false);

    const [ref, inView] = useInView({
        threshold: 0,
        triggerOnce: true,
    });

    const onChange = (event) => {
        setFormFields({
            ...formFields,
            [event.target.name]: event.target.value,
        });
    };

    // FORGOT  PASSWORD
    const [forgotPasswordInput, setForgotPasswordInput] = useState({
        email: '',
    });

    const [resetSuccess, setResetSuccess] = useState(false);

    const onChangeForgotPassword = (event) => {
        setForgotPasswordInput({
            ...forgotPasswordInput,
            [event.target.name]: event.target.value,
        });
    };

    const handleResetPassword = (event) => {
        event.preventDefault();

        const forgotPasswordUrl = `${process.env.REACT_APP_API_URL}/auth/forgot-password`;

        const formData = {
            email: forgotPasswordInput.email,
        };

        const forgotPW = async (url, data) => {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
            return response.json();
        };

        forgotPW(forgotPasswordUrl, formData).then((res) => {
            if (!res.statusCode) {
                console.log('Successfully sent reset password email');
                setResetSuccess(true);
            } else {
                console.log(res.message[0].messages[0].message);
                setErrorMessage("We couldn't find this email.");
            }
        });
    };

    // remove error message if user clears input fields
    useEffect(() => {
        if (!formFields.email.length && !formFields.password.length) {
            setErrorMessage('');
        }
    }, [formFields]);

    useEffect(() => {
        if (!forgotPasswordInput.email.length) {
            setErrorMessage('');
        }
    }, [forgotPasswordInput]);

    // Options to display to the user

    const ShowForgotPassword = () => {
        return forgotPassword && resetSuccess ? (
            <Fragment>
                <SansSerifHeader3
                    className="flex-center-aligned"
                    color="secondary"
                >
                    Email sent!
                    <img
                        className="paperJet"
                        src={emailSent}
                        alt="Paper jet icon"
                    />
                </SansSerifHeader3>
                <HorizontalLine margin="4rem 0" />
                <P className="body-2" color="darkText">
                    An email has been sent to {forgotPasswordInput.email} with
                    instructions on resetting your password.
                </P>
            </Fragment>
        ) : (
            forgotPassword && (
                <Fragment>
                    <BackButton setForgotPassword={setForgotPassword} />
                    <P
                        className="header-3"
                        color="secondary"
                        marginTop="1.5rem"
                    >
                        Forgot your password?
                    </P>
                    <HorizontalLine />
                    <P
                        color={errorMessage.length ? 'primary' : 'darkText'}
                        className="flex-center-aligned body-2"
                    >
                        {errorMessage.length ? (
                            <StyledImage
                                marginRight="0.8rem"
                                src={noEntry}
                                alt="Error, no entry icon"
                            />
                        ) : (
                            ''
                        )}
                        {errorMessage.length
                            ? errorMessage
                            : 'Enter the email address you used when you first signed up. We’ll send you instructions to reset your password.'}
                    </P>
                    <form action="">
                        <StyledInput
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Email"
                            aria-label="Email"
                            onChange={onChangeForgotPassword}
                            marginTop={errorMessage.length ? '2rem' : '3rem'}
                            marginBottom="2rem"
                            className={errorMessage.length && 'error'}
                            autoComplete="email"
                        />

                        <FilledButtonMd
                            width="100%"
                            margin={
                                errorMessage.length
                                    ? '6.5rem 0 2rem'
                                    : '2.5rem 0 2rem'
                            }
                            onClick={handleResetPassword}
                        >
                            Send Instructions
                        </FilledButtonMd>

                        {errorMessage.length ? (
                            <StyledPetitTitle
                                color="primary"
                                className="petit-title-2"
                                marginTop="0.5rem"
                                center
                            >
                                <span>NOT A MEMBER?&nbsp;</span>
                                <Button
                                    type="button"
                                    onClick={() => setShowSignInForm(false)}
                                >
                                    SIGN UP
                                </Button>
                            </StyledPetitTitle>
                        ) : (
                            ''
                        )}
                    </form>
                </Fragment>
            )
        );
    };

    const ShowLogin = () => {
        return !userData && !forgotPassword ? (
            <Fragment>
                <H1
                    color="primaryDarkest"
                    marginTop="-0.8rem"
                    className="header-2"
                >
                    Welcome
                </H1>
                <H1
                    color="primaryDarkest"
                    marginTop="-1rem"
                    className="header-2-serif"
                >
                    Back!
                </H1>

                <P
                    color={errorMessage.length ? 'primary' : 'darkText'}
                    marginTop="2rem"
                    className="flex-center-aligned body-2"
                >
                    {errorMessage.length ? (
                        <StyledImage
                            marginRight="0.8rem"
                            src={noEntry}
                            alt="Error, no entry icon"
                        />
                    ) : (
                        ''
                    )}
                    {errorMessage.length
                        ? errorMessage
                        : 'Enter your email and password.'}
                </P>

                <form
                    onSubmit={(event) =>
                        handleSubmitSignIn(
                            event,
                            formFields,
                            updateAuth,
                            dispatch,
                            loadUserData,
                            setErrorMessage
                        )
                    }
                >
                    <StyledInput
                        required
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Email"
                        onChange={onChange}
                        value={formFields.email}
                        aria-label="Email"
                        marginTop="1.5rem"
                        marginBottom="1rem"
                        className={errorMessage.length && 'error'}
                        autoComplete="email"
                    />
                    <StyledInput
                        required
                        type="password"
                        id="password"
                        name="password"
                        placeholder="Password"
                        onChange={onChange}
                        value={formFields.password}
                        aria-label="Password"
                        marginBottom="1rem"
                        className={errorMessage.length && 'error'}
                        autoComplete="current-password"
                    />

                    <P
                        color="primary"
                        marginTop="0.5rem"
                        role="presentation"
                        className="body-2"
                    >
                        <Button
                            type="button"
                            onClick={() => {
                                setErrorMessage('');
                                setForgotPassword(true);
                            }}
                        >
                            Forgot your password?
                        </Button>
                    </P>

                    <FilledButtonMd
                        width="100%"
                        margin="2.5rem 0 2rem"
                        type="submit"
                    >
                        Sign In
                    </FilledButtonMd>
                </form>
                <StyledPetitTitle
                    className="petit-title-2"
                    color="primary"
                    marginTop="0.5rem"
                    center
                >
                    <span>NOT A MEMBER?&nbsp;</span>
                    <Button
                        type="button"
                        onClick={() => setShowSignInForm(false)}
                    >
                        SIGN UP
                    </Button>
                </StyledPetitTitle>
            </Fragment>
        ) : (
            userData && (
                <Fragment>
                    <H1
                        marginTop="-0.8rem"
                        color="primaryDarkest"
                        className="header-2"
                    >
                        Welcome Back,
                    </H1>
                    <H1
                        color="primaryDarkest"
                        marginTop="0.5rem"
                        className="header-2-serif"
                    >
                        {userData.firstName}!
                    </H1>
                    <HorizontalLine />
                    <P className="body-1">
                        Let&apos;s go to your dashboard and continue studying!{' '}
                    </P>
                    <FilledButtonMd
                        width="100%"
                        margin="6rem 0 0.5rem"
                        dashboard
                    >
                        <StyledLink to="/dashboard">My Dashboard</StyledLink>
                    </FilledButtonMd>
                </Fragment>
            )
        );
    };

    return (
        <SignInOrUpContainer
            withModal={withModal}
            inView={inView}
            innerRef={ref}
        >
            {withModal && (
                <CloseModalButton
                    setShowModal={setShowModal}
                    setShowSignInForm={setShowSignInForm}
                    top="2.3rem"
                    right="2.3rem"
                />
            )}

            {ShowForgotPassword()}
            {ShowLogin()}
        </SignInOrUpContainer>
    );
};

const SansSerifHeader3 = styled(Header3)`
    img {
        &.paperJet {
            margin-left: 0.8rem;
            margin-top: -1rem;
        }
    }
`;

const HorizontalLine = styled.hr`
    width: 100%;
    height: 0.4rem;
    background-color: ${(props) => props.theme.colors.secondary};
    border-radius: 3rem;
    margin: ${(props) => (props.margin ? props.margin : '3rem 0')};
`;

const Button = styled.button`
    background: transparent;
    color: inherit;
    :hover {
        cursor: pointer;
    }
`;

export default SignInForm;
