import { React, Fragment, useState } from 'react';
import NamedSectionContainer from './NamedSectionContainer';
import { format } from 'date-fns';
import DaysOfTheWeekBar from './DaysOfTheWeekBar';
import HasStudyPlan from './HasStudyPlan';
import NoStudyPlan from './NoStudyPlan';
import CreateStudyPlan from './CreateStudyPlan';

const WeekProgressSection = ({
    isDesktopSm,
    goals,
    globalState,
    dispatch,
    isOnDashboardHomePage,
    height,
    width,
    padding,
    devicesize,
}) => {
    let daysArr = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

    let todayName = format(new Date(), 'EEE');

    let hasGoals = Object.keys(goals).length;
    let { daysPerWeekGoal, totalVisitsForWeek, daysVisited } = goals;

    let daysRemaining = daysPerWeekGoal - totalVisitsForWeek;

    const [showCreateStudyPlan, setShowCreateStudyPlan] = useState(false);

    return (
        <NamedSectionContainer
            title="This Week"
            gridAreaName="thisWeek"
            height={height}
            width={width}
            isOnDashboardHomePage={isOnDashboardHomePage}
            padding={padding}
            devicesize={devicesize}
            headerPadding={
                devicesize === 'mobile'
                    ? '2rem 2rem 0rem 1.6rem'
                    : '2.8rem 0rem 0rem 0rem'
            }
        >
            {showCreateStudyPlan ? (
                <CreateStudyPlan
                    isDesktopSm={isDesktopSm}
                    daysArr={daysArr}
                    goals={goals}
                    hasGoals={hasGoals}
                    globalState={globalState}
                    dispatch={dispatch}
                    setShowCreateStudyPlan={setShowCreateStudyPlan}
                    todayName={todayName}
                    isOnDashboardHomePage={isOnDashboardHomePage}
                />
            ) : (
                !showCreateStudyPlan && (
                    <Fragment>
                        <DaysOfTheWeekBar
                            daysArr={daysArr}
                            todayName={todayName}
                            daysVisited={daysVisited}
                            isOnDashboardHomePage={isOnDashboardHomePage}
                        />

                        {hasGoals && !showCreateStudyPlan ? (
                            <HasStudyPlan
                                daysRemaining={daysRemaining}
                                daysPerWeekGoal={daysPerWeekGoal}
                                totalVisitsForWeek={totalVisitsForWeek}
                                setShowCreateStudyPlan={setShowCreateStudyPlan}
                                isOnDashboardHomePage={isOnDashboardHomePage}
                            />
                        ) : (
                            <NoStudyPlan
                                setShowCreateStudyPlan={setShowCreateStudyPlan}
                                isOnDashboardHomePage={isOnDashboardHomePage}
                            />
                        )}
                    </Fragment>
                )
            )}
        </NamedSectionContainer>
    );
};

export default WeekProgressSection;
