// function to create initials placeholder
const CreatePlaceholder = (firstName, lastName) => {
    let firstInitial = firstName[0].toUpperCase();
    let lastInitial = lastName[0].toUpperCase();
    let initials = firstInitial + lastInitial;

    let size = 1000;

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = canvas.height = size;
    context.fillStyle = '#ffffff';
    context.fillRect(0, 0, size, size);

    // Background color
    context.fillStyle = '#7D8095';
    context.fillRect(0, 0, size, size);

    // Text color
    context.fillStyle = '#E1EDF0';
    context.textBaseline = 'middle';
    context.textAlign = 'center';
    context.font = `normal 700 ${size / 22}rem sans-serif`;
    context.fillText(initials, size / 2, size / 2);

    return canvas.toDataURL();
};

export default CreatePlaceholder;
