import React from 'react';
import styled from '@emotion/styled';
import Container from 'assets/styledComponents/Container';
import H3 from 'assets/styledComponents/fonts/H3';
import H4 from 'assets/styledComponents/fonts/H4';
import StyledImage from 'assets/styledComponents/StyledImage';
import step3Img from 'assets/images/interactiveBook-course2/step3Img.svg';
import { PageContainer } from './SharedComponents';
import { Step3ListItems } from './Step3ListItems';
import nextChevron from 'assets/images/interactiveBook-course2/nextChevron.svg';
import prevChevron from 'assets/images/interactiveBook-course2/prevChevron.svg';
import NextOrPrevPageButton from './NextOrPrevPageButton';

const Step3 = ({ devicesize, setActivePage }) => {
    return (
        <PageContainer>
            <H3 className="header-3" textAlign="center" width="50%">
                Step 3
            </H3>
            <Container className="flex-all-centered" height="100%">
                <Container width="50%" className="flex-all-centered">
                    <StyledImage
                        src={step3Img}
                        width={'23rem'}
                        maxWidth="80%"
                        marginTop="-5rem"
                    />
                    <NextOrPrevPageButton
                        className="flex-all-centered prev"
                        image={prevChevron}
                        alt={'orange chevron pointing left'}
                        setActivePage={setActivePage}
                        setActivePageNum={2}
                        title={'PREV'}
                    />
                </Container>
                <Container
                    width="50%"
                    marginTop="-5rem"
                    padding={
                        devicesize === 'tablet'
                            ? '0 2rem 0 3rem'
                            : '0 4rem 0 5rem'
                    }
                >
                    <H4
                        className="body-2"
                        color="success"
                        marginBottom="2.5rem"
                        marginLeft="-2.4rem"
                    >
                        Prepare your meter:
                    </H4>

                    <UL>
                        {Step3ListItems.map((item) => {
                            return (
                                <LI
                                    key={item.id}
                                    className="flex-center-aligned"
                                    devicesize={devicesize}
                                >
                                    {item.text}
                                </LI>
                            );
                        })}
                    </UL>
                </Container>
            </Container>
            <NextOrPrevPageButton
                className={'flex-all-centered next'}
                image={nextChevron}
                alt={'orange chevron pointing right'}
                setActivePage={setActivePage}
                setActivePageNum={4}
                title={'NEXT'}
            />
        </PageContainer>
    );
};

const UL = styled.ul`
    height: 100%;
    padding-bottom: 1.8rem;
`;

const LI = styled.li`
    position: relative;
    margin-bottom: 2rem;

    :before {
        position: absolute;
        content: ' ';
        border-radius: 50%;
        width: 0.8rem;
        height: 0.8rem;
        background: ${(props) => props.theme.colors.success};
        bottom: 50%;
        left: ${(props) =>
            props.devicesize === 'tablet' ? '-1.8rem' : '-2.4rem'};
    }
`;

export default Step3;
