import CourseCard from 'components/CourseCard/CourseCard';
import React from 'react';
import calculateTotalLessons from 'utils/helpers/CalculateTotalLessons';
import filterCoursesBySearchValue from 'utils/helpers/filterCoursesBySearchValue';
import CourseSearchNoResult from 'assets/styledComponents/CourseSearchNoResult';

const ShowAllCoursesMobile = ({
    myCourses,
    searchValue,
    allCourses,
    authUser,
}) => {
    let filteredItems = allCourses
        ? filterCoursesBySearchValue(allCourses, searchValue).map((c) => {
              let progress = 0;
              const completedLessons =
                  myCourses && myCourses[c.id]
                      ? Number(
                            Object.keys(myCourses[c.id].lessonsCompleted).length
                        )
                      : null;

              const myCourse = myCourses && myCourses[c.id];
              progress =
                  myCourse && completedLessons === 0
                      ? '0'
                      : Math.floor(
                            (completedLessons !== null
                                ? completedLessons /
                                  myCourses[c.id].totalLessons
                                : 0) * 100
                        ) + '';

              if (authUser) {
                  const currentCourse = myCourses.map((c2) => {
                      if (c2.id == c.id) {
                          return c2;
                      } else return;
                  });

                  const currentCourseObject = currentCourse.filter(
                      (element) => {
                          if (element != undefined) return element;
                      }
                  );

                  const courseObject = currentCourseObject[0];

                  const completedLessons =
                      myCourses && courseObject
                          ? Object.keys(courseObject.lessonsCompleted).length
                          : null;

                  const myCourse = courseObject;
                  progress =
                      myCourse && completedLessons === 0
                          ? '0'
                          : Math.floor(
                                (completedLessons !== null
                                    ? completedLessons /
                                      courseObject.totalLessons
                                    : 0) * 100
                            ) + '';
              }

              // use Strapi small optimized image size if available,
              // else use original uploaded image

              let smallImage =
                  c.courseImage.image.formats &&
                  c.courseImage.image.formats.small &&
                  c.courseImage.image.formats.small.url;
              let nonOptimizedImage = c.courseImage.image.url;

              return (
                  <CourseCard
                      course={c}
                      key={c.id}
                      imageURL={smallImage ? smallImage : nonOptimizedImage}
                      title={c.title}
                      description={c.description}
                      intro={null}
                      courseURL={`/`}
                      progress={progress}
                      id={c.id}
                      slug={c.slug}
                      lastLesson={myCourse && myCourse.lastLesson}
                      lastPage={myCourse && myCourse.lastPageIndex}
                      totalLessons={calculateTotalLessons(c)}
                      mobileView={true}
                      width="27.2rem"
                      height="50rem"
                      imageHeight="18rem"
                      margin="2rem 2.4rem 2.4rem 2.4rem"
                      progressBarWidth="18.8rem"
                      marginBottom="1.6rem"
                      isMobile={true}
                  />
              );
          })
        : '';

    return filteredItems && filteredItems.length === 0 ? (
        <CourseSearchNoResult searchvalue={searchValue} />
    ) : (
        filteredItems
    );
};

export default ShowAllCoursesMobile;
