import styled from '@emotion/styled';

// Hint button
export const StyledHintButton = styled.button`
    :hover {
        cursor: pointer;
    }
    padding: 2rem;
    border-radius: 2rem;
    border: 0.2rem gray solid;
    background: white;
`;

// shared between tables 2 and 3

export const Table = styled.table`
    background: ${(props) => props.theme.colors.lightBackground};
    width: 84.5rem;
    font-size: 1.4rem;
    line-height: 2.5rem;
    letter-spacing: ${(props) => props.theme.letterSpacing.sm};
    margin-top: 3.2rem;
    border-spacing: 0.6rem;
    position: relative;
`;

const captionHeight = '13rem';
const table2CaptionBackground = '#BEE9E8';
const table3CaptioBackground = '#FFCACA';

export const Caption = styled.caption`
    text-align: center;
    background: ${(props) =>
        props.table2 ? table2CaptionBackground : table3CaptioBackground};
    height: ${captionHeight}
    color: ${(props) => props.theme.colors.darkText};
    width: 100%;
    font-size: 2rem;
    font-family: 'MontserratBold', sans-serif;
    padding: 2rem;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    /* side borders for trimming caption to table border spacing */
    border-right: 0.6rem solid ${(props) => props.theme.colors.lightBackground};
    border-left: 0.6rem solid ${(props) => props.theme.colors.lightBackground};
`;
