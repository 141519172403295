import React from 'react';
import PageContentContainer from '../assets/styledComponents/PageContentContainer';
import DashboardPagesHeader from '../assets/styledComponents/DashboardPagesHeader';
import tracksMd from '../assets/icons/tracksMd.svg';
import ShowTracksCards from '../components/Tracks/ShowTracksCards';
import ScrollContainer from '../assets/styledComponents/ScrollContainer';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';

const DashboardMyTracksPage = () => {
    const [globalState] = useGlobalContext();
    const isMobile = globalState.deviceSize === 'mobile';

    return (
        <ScrollContainer>
            <PageContentContainer
                marginTop="4rem"
                marginRight="7rem"
                marginLeft="4.5rem"
            >
                <DashboardPagesHeader icon={tracksMd} pageTitle="MY PATHWAYS" />
                <ShowTracksCards isMobile={isMobile} />
            </PageContentContainer>
        </ScrollContainer>
    );
};

export default DashboardMyTracksPage;
