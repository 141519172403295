import React from 'react';
import styled from '@emotion/styled';
import passwordResetSuccess from '../../assets/images/passwordResetSuccess.svg';
import Header3 from '../../assets/styledComponents/fonts/Header3';
import FilledButtonMd from '../../assets/styledComponents/buttons/FilledButtonMd';
import LinkWrappingButton from '../../assets/styledComponents/buttons/LinkWrappingButton';
import StyledImage from '../../assets/styledComponents/StyledImage';
import Container from '../../assets/styledComponents/Container';
import { useGlobalContext } from '../../GlobalContext/GlobalProvider';

const ResetPasswordSuccess = () => {
    const [globalState] = useGlobalContext();
    const { auth } = globalState;

    return (
        <Container className="flex-column-centered" textAlign="center">
            <StyledImage
                marginTop="-2rem"
                src={passwordResetSuccess}
                alt="Padlock with wings"
            />
            <HorizontalLine />
            <Header3>
                You have successfully <br />
                changed your password!
            </Header3>
            <LinkWrappingButton to={auth ? '/dashboard' : '/'}>
                <FilledButtonMd marginTop="5.5rem" minWidth="26rem">
                    {auth ? 'Go to my Dashboard' : 'Go Home'}
                </FilledButtonMd>
            </LinkWrappingButton>
        </Container>
    );
};

const HorizontalLine = styled.hr`
    width: 39rem;
    height: 0.8rem;
    background-color: ${(props) => props.theme.colors.tertiary};
    border-radius: 1rem;
    margin-top: -1.5rem;
    margin-bottom: 3rem;
`;

export default ResetPasswordSuccess;
