import { React, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import SignInOrUpContainer from './SignInOrUpContainer';
import StyledBody2 from './StyledBody2';
import StyledInput from './StyledInput';
import { useGlobalContext } from '../../GlobalContext/GlobalProvider';
import { updateAuth } from '../../GlobalContext/actionVariables';
import useUserData from '../../hooks/useUserData';
import FilledButtonMd from '../../assets/styledComponents/buttons/FilledButtonMd';
import StyledPetitTitle from './StyledPetitTitle';
import noEntry from '../../assets/icons/noEntry.svg';
import ToggleUserGroup from '../../utils/helpers/ToggleUserGroup';
import CloseModalButton from '../../utils/helpers/CloseModalButton';
import { cx } from '@emotion/css';
import H1 from 'assets/styledComponents/fonts/H1';
import handleSubmitSignUp from './utils/handleSubmitSignUp';

const SignUpForm = ({ setShowSignInForm, withModal, setShowModal }) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [pwLengthError, setPwLengthError] = useState(false);
    const history = useHistory();

    const [formFields, setFormFields] = useState({
        username: '',
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        // default group must be patient
        group: 'patient',
    });

    const { loadUserData } = useUserData(null, true);
    const [, dispatcher] = useGlobalContext();

    // remove error message if user clears a pw field
    useEffect(() => {
        if (!formFields.password.length || !formFields.confirmPassword.length) {
            setErrorMessage('');
        }
    }, [formFields]);

    const onChange = (event) => {
        setFormFields({
            ...formFields,
            [event.target.name]: event.target.value,
        });
    };

    return (
        <SignInOrUpContainer isSignUp withModal={withModal}>
            {withModal && (
                <CloseModalButton
                    setShowModal={setShowModal}
                    setShowSignInForm={setShowSignInForm}
                    top="2.3rem"
                    right="2.3rem"
                />
            )}

            <H1 className="header-2" color="primaryDarkest" marginTop="-0.8rem">
                Learn
            </H1>
            <H1
                className="header-2-serif"
                color="primaryDarkest"
                marginTop="-1rem"
            >
                Diabetes
            </H1>
            <StyledBody2
                color={errorMessage.length ? 'primary' : 'darkText'}
                marginTop={pwLengthError ? '0.5rem' : '2.5rem'}
                marginBottom="1.5rem"
                className="flex-center-aligned body-2"
            >
                {errorMessage.length ? (
                    <img
                        className={cx(
                            { ['twoLineErrorText']: pwLengthError },
                            { [undefined]: !pwLengthError }
                        )}
                        src={noEntry}
                        alt="Error, no entry icon"
                    />
                ) : (
                    ''
                )}
                {errorMessage.length
                    ? errorMessage
                    : 'Sign up with your email.'}
            </StyledBody2>

            <form
                onSubmit={(event) =>
                    handleSubmitSignUp(
                        event,
                        formFields,
                        setPwLengthError,
                        setErrorMessage,
                        dispatcher,
                        updateAuth,
                        loadUserData,
                        history
                    )
                }
            >
                <div className="flex-space-between">
                    <StyledInput
                        required
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                        onChange={onChange}
                        value={formFields.firstName}
                        aria-label="First Name"
                        marginRight="0.5rem"
                        marginBottom="1rem"
                        autoComplete="given-name"
                    />
                    <StyledInput
                        required
                        type="text"
                        name="lastName"
                        placeholder="Last Name"
                        onChange={onChange}
                        value={formFields.lastName}
                        aria-label="Last Name"
                        marginLeft="0.5rem"
                        marginBottom="1rem"
                        autoComplete="family-name"
                    />
                </div>
                <StyledInput
                    required
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    onChange={onChange}
                    value={formFields.email}
                    aria-label="Email"
                    marginBottom="1rem"
                    autoComplete="email"
                />
                <StyledInput
                    required
                    type="password"
                    id="password"
                    name="password"
                    placeholder="Password"
                    onChange={onChange}
                    className={cx(
                        { ['error']: errorMessage.length },
                        { [undefined]: !errorMessage.length }
                    )}
                    value={formFields.password}
                    aria-label="Password"
                    marginBottom="1rem"
                    autoComplete="new-password"
                />
                <StyledInput
                    required
                    type="password"
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    onChange={onChange}
                    className={cx(
                        { ['error']: errorMessage.length },
                        { [undefined]: !errorMessage.length }
                    )}
                    value={formFields.confirmPassword}
                    aria-label="Confirm Password"
                    marginBottom="1rem"
                    autoComplete="new-password"
                />

                <ToggleUserGroup
                    group={formFields.group}
                    onChange={onChange}
                    isSignUp
                />

                <FilledButtonMd type="submit" margin="3rem 0 2rem" width="100%">
                    Sign Up
                </FilledButtonMd>
            </form>
            <StyledPetitTitle className="petit-title-2" color="primary" center>
                <span>ALREADY HAVE AN ACCOUNT?&nbsp;</span>
                <button type="button" onClick={() => setShowSignInForm(true)}>
                    SIGN IN
                </button>
            </StyledPetitTitle>
        </SignInOrUpContainer>
    );
};

export default SignUpForm;
