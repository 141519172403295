import styled from '@emotion/styled';
import Body1 from 'assets/styledComponents/fonts/Body1';

// info section below days bar

export const InformationSection = styled.div`
    margin: ${(props) =>
        props.devicesize === 'mobile'
            ? '2.4rem 1.8rem'
            : props.isOnDashboardHomePage
            ? '1.6rem 0 0rem'
            : '3.5rem 14rem 2rem'};
    height: ${(props) => (props.devicesize === 'mobile' ? 'auto' : '10.5rem')};
`;

export const ImageContainer = styled.div`
    width: 10.5rem;
    height: 100%;
`;

export const DescriptiveText = styled(Body1)`
    /* customing styling for db homepage */
    width: ${(props) => props.width};
    max-width: ${(props) => props.maxWidth};
    font-family: ${(props) => props.isOnDashboardHomePage && 'MontserratBold'};
    font-size: ${(props) => props.isOnDashboardHomePage && '1.2rem'};
    line-height: ${(props) => props.isOnDashboardHomePage && '1.7rem'};
    margin-left: ${(props) => props.marginLeft};
    text-align: ${(props) => props.textAlign};
    span {
        color: ${(props) => props.theme.colors.secondary};
    }
`;
