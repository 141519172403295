import React from 'react';
import styled from '@emotion/styled';
import PetitTitle2 from '../../assets/styledComponents/fonts/PetitTitle2';
import StyledIcon from '../../assets/styledComponents/StyledImage';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';

const ShowIconsSection = (infoIcon) => {
    const [globalState] = useGlobalContext();
    let { deviceSize } = globalState;
    const isMobile = Boolean(deviceSize === 'mobile');

    return (
        <InfoIconContainer isMobile={isMobile}>
            {infoIcon.map((item) => (
                <IconAndText key={item.icon}>
                    <StyledIcon
                        width={isMobile ? '2.4rem' : '3.6rem'}
                        height={isMobile ? '2.4rem' : '3.6rem'}
                        src={item.icon}
                        alt=""
                    />
                    <PetitTitle2 color="darkText">{item.title}</PetitTitle2>
                </IconAndText>
            ))}
        </InfoIconContainer>
    );
};

const InfoIconContainer = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: ${(props) => (props.isMobile ? '2.4rem' : '4rem')};
`;

const IconAndText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: ${(props) => props.theme.spaces.sm};
    bottom: 0;
`;

export default ShowIconsSection;
