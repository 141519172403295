const TabContent = [
    {
        id: 1,
        content:
            'The carbohydrates in grains and starchy foods such as cereals, noodles, potato and rice.',
    },
    {
        id: 2,
        content: `Fruit contains natural sugars so they also need to be included in your carb count.

        Try to find your favourite fruits on the list.`,
    },
    {
        id: 3,
        content:
            'Starchy vegetables and beans or legumes, depending on your portion size.',
    },
    {
        id: 4,
        content: 'Milk, some milk alternatives and yogurt.',
    },
    {
        id: 5,
        content:
            'As well as snacks such as cookies, crackers, ice cream and other sweet foods.',
    },
];

export default TabContent;
