import React from 'react';
import Header2 from '../../assets/styledComponents/fonts/Header2';
import Body1 from '../../assets/styledComponents/fonts/Body1';
import HomeButton from './HomeButton';
import designThree404 from '../../assets/images/designThree404.svg';
import Container from '../../assets/styledComponents/Container';

const DesignThree = () => {
    return (
        <Container className="flex-all-centered" height="100vh" width="110rem">
            <Container width="42rem" margin>
                <Header2 margin="0 0 3.5rem" color="darkText">
                    This Page is Lost.
                </Header2>

                <Body1 color="greyText">
                    Something&apos;s wrong. <br /> This page is missing or you
                    may have typed the address incorrectly.
                </Body1>

                <HomeButton />
            </Container>
            <Container margin="0 0 0 4rem">
                <img
                    src={designThree404}
                    alt="An illustration of 404 text floating in space, with a rocket in the center of the zero"
                />
            </Container>
        </Container>
    );
};

export default DesignThree;
