import { React, useState, useEffect } from 'react';
import P from 'assets/styledComponents/fonts/P';
import {
    CalculatorContainer,
    StyledForm,
    StyledHintButton,
} from '../../SharedComponentsInteractive';
import StyledImage from 'assets/styledComponents/StyledImage';
import cupOfBerries from 'assets/images/interactive-course5-2/cupOfBerries.svg';
import equalsPrimaryDarkest from 'assets/icons/math/equalsPrimaryDarkest.svg';
import minusPrimaryDarkest from 'assets/icons/math/minusPrimaryDarkest.svg';
import Container from 'assets/styledComponents/Container';
import InteractiveActivityButton from 'assets/styledComponents/buttons/InteractiveActivityButton';
import InputItemLabelPosition from 'types/inputItemLabelPosition';
import CalcInputItem from 'assets/styledComponents/CalcInputItem';
import CalcResult from 'assets/styledComponents/CalcResult';
import HintModal from '../../HintModal';
import hint1A from 'assets/images/interactive-course5-2/hint1A.svg';
import hint1B from 'assets/images/interactive-course5-2/hint1B.svg';

const ActivityCalc = ({ devicesize }) => {
    let initialFormfields = {
        carbs: '',
        fibre: '',
        result: '',
    };

    let initialErrors = {
        carbs: false,
        fibre: false,
        result: false,
    };

    const [formFields, setFormFields] = useState({
        ...initialFormfields,
    });

    const [error, setError] = useState({
        ...initialErrors,
    });

    const [carbVal, setCarbVal] = useState('');
    const [fibreVal, setFibreVal] = useState('');
    const [calcResult, setCalcRes] = useState('?');
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const onChange = (event) => {
        setFormFields({
            ...formFields,
            [event.target.name]: event.target.value,
        });

        if (event.target.name === 'carbs') {
            setCarbVal(event.target.value);
        } else if (event.target.name === 'fibre') {
            setFibreVal(event.target.value);
        }
    };

    const calculateResult = async (e) => {
        e.preventDefault();
        setHasSubmitted(true);
        let { carbs, fibre } = formFields;
        let result = (+carbs - +fibre).toFixed(2);
        setCalcRes(result + 'g');

        if (error.carbs || error.fibre || error.result) {
            setError({
                ...initialErrors,
            });
            setFormFields({
                ...initialFormfields,
            });
            // clear input and result fields
            document.getElementById('calorie-king-calculator-1').reset();
            setCarbVal('');
            setFibreVal('');
            setCalcRes('?');
        } else {
            setError({
                ...error,
                carbs: carbs != 14.7 ? true : false,
                fibre: fibre != 8 ? true : false,
                // both inputs and the result have to be correct for the result to be correct
                result:
                    result != 6.7 || carbs != 14.7 || fibre != 8 ? true : false,
            });
        }
    };

    const handleTryAgainAfterAllCorrect = (e) => {
        e.preventDefault();
        setFormFields({
            ...initialFormfields,
        });
        // clear input and result fields
        document.getElementById('calorie-king-calculator-1').reset();
        setCarbVal('');
        setFibreVal('');
        setCalcRes('?');
    };

    // remove error message if user clears an input field
    useEffect(() => {
        if (!formFields.carbs.length || !formFields.fibre.length) {
            setHasSubmitted(false);
            // for submit button text
            setError({
                ...error,
                carbs: false,
                fibre: false,
                result: false,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formFields]);

    const [showModal, setShowModal] = useState(false);

    // Prevent background content scrolling when modal open
    if (showModal) {
        document.body.style.overflow = 'hidden';
    } else if (!showModal) {
        document.body.style.overflow = 'unset';
    }

    // responsive vars
    let inputFieldWidth =
        devicesize === 'mobile'
            ? '6.8rem'
            : devicesize === 'tablet'
            ? '10rem'
            : '12rem';

    let inputFieldHeight =
        devicesize === 'mobile'
            ? '6.8rem'
            : devicesize === 'tablet'
            ? '10rem'
            : '12rem';

    let operatorMargin =
        devicesize === 'mobile'
            ? '1rem'
            : devicesize === 'tablet'
            ? '1.5rem'
            : '4rem';
    let operatorWidth =
        devicesize === 'mobile'
            ? '1.6rem'
            : devicesize === 'tablet'
            ? '2rem'
            : '3.2rem';

    let allCorrect = !error.carbs && !error.fibre && !error.result;

    const onHintClick = (e) => {
        e.preventDefault();
        setShowModal(true);
    };

    return (
        <>
            {showModal && (
                <HintModal
                    setShowModal={setShowModal}
                    showModal={showModal}
                    imgA={hint1A}
                    altA="a screenshot of search results for raspberries with a green circle around the 'raspberries, raw' search result"
                    maxHeightA="21rem"
                    imgB={hint1B}
                    altB="a screenshot of search results for raspberries with green circles around the '1 cup serving size' and '14.7 g carbs and 8g fiber' areas"
                    maxHeightB="25rem"
                />
            )}

            <StyledForm id="calorie-king-calculator-1" devicesize={devicesize}>
                <CalculatorContainer
                    className="flex-all-centered"
                    devicesize={devicesize}
                    background="tertiary"
                >
                    {devicesize === 'mobile' ? (
                        ''
                    ) : (
                        <Container
                            className="flex-column-centered"
                            marginRight={
                                devicesize === 'tablet' ? '2rem' : '6.4rem'
                            }
                        >
                            <StyledImage
                                src={cupOfBerries}
                                alt="An illustration of a cup of berries"
                                height={
                                    devicesize === 'tablet' ? '8rem' : '10.4rem'
                                }
                                marginBottom={
                                    devicesize === 'tablet'
                                        ? '3.6rem'
                                        : '1.6rem'
                                }
                            />
                            <P className="body-1" color="primaryDarkest">
                                1 Cup
                            </P>
                        </Container>
                    )}

                    <div className="flex-column-centered">
                        <CalcInputItem
                            label="Carbs"
                            labelPosition={InputItemLabelPosition.BELOW}
                            name="carbs"
                            value={carbVal}
                            onChange={onChange}
                            error={error}
                            hasSubmitted={hasSubmitted}
                            inputFieldWidth={inputFieldWidth}
                            inputFieldHeight={inputFieldHeight}
                            placeholder="0g"
                            unit="g"
                        />
                    </div>
                    <StyledImage
                        alt="minus symbol"
                        width={operatorWidth}
                        marginTop="-3rem"
                        marginLeft={operatorMargin}
                        marginRight={operatorMargin}
                        src={minusPrimaryDarkest}
                    />
                    <div className="flex-column-centered">
                        <CalcInputItem
                            label="Fibre"
                            labelPosition={InputItemLabelPosition.BELOW}
                            name="fibre"
                            value={fibreVal}
                            onChange={onChange}
                            error={error}
                            hasSubmitted={hasSubmitted}
                            inputFieldWidth={inputFieldWidth}
                            inputFieldHeight={inputFieldHeight}
                            placeholder="0g"
                            unit="g"
                        />
                    </div>

                    <StyledImage
                        alt="equals symbol"
                        width={operatorWidth}
                        marginTop="-3rem"
                        marginLeft={operatorMargin}
                        marginRight={operatorMargin}
                        src={equalsPrimaryDarkest}
                    />
                    <div className="flex-column-centered">
                        <CalcResult
                            label="Carbs"
                            name="result"
                            value={calcResult}
                            error={error}
                            hasSubmitted={hasSubmitted}
                            inputFieldWidth={inputFieldWidth}
                            inputFieldHeight={inputFieldHeight}
                            borderColor="tertiaryDark"
                            background="tertiaryDark"
                            devicesize={devicesize}
                        />
                    </div>
                </CalculatorContainer>

                {/* Buttons  */}
                <Container className="flex-all-centered">
                    {allCorrect && hasSubmitted ? (
                        <InteractiveActivityButton
                            isCorrect={true}
                            onClick={handleTryAgainAfterAllCorrect}
                            className="secondary"
                        />
                    ) : (
                        <InteractiveActivityButton
                            isCorrect={false}
                            onClick={calculateResult}
                            className={
                                !formFields.carbs.length ||
                                !formFields.fibre.length
                                    ? 'disabledBtn'
                                    : error.carbs || error.fibre || error.result
                                    ? 'incorrect'
                                    : 'secondary'
                            }
                            hasError={
                                error.carbs || error.fibre || error.result
                            }
                        />
                    )}
                    <StyledHintButton onClick={(e) => onHintClick(e)}>
                        Hint
                    </StyledHintButton>
                </Container>
            </StyledForm>
        </>
    );
};

export default ActivityCalc;
