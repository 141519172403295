import React from 'react';
import Activity1 from './Activity1';
import Activity2 from './Activity2';

const RecipeCalculation = () => {
    return (
        <div>
            <Activity1 />
            <Activity2 />
        </div>
    );
};

export default RecipeCalculation;
