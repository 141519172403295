import React from 'react';
import { useGlobalContext } from '../GlobalContext/GlobalProvider';
import EditProfile from '../components/ProfilePage/EditProfile';
import PageMainContainer from '../assets/styledComponents/PageMainContainer';
import ProfilePic from '../components/ProfilePage/ProfilePic';
import styled from '@emotion/styled';

const ProfilePage = () => {
    const [state] = useGlobalContext();
    let data = state.user;

    return (
        <PageContainer className="below-navbar">
            <ProfilePic data={data} />
            <EditProfile data={data} />
        </PageContainer>
    );
};

const PageContainer = styled(PageMainContainer)`
    overflow-y: hidden;
`;

export default ProfilePage;
