import React from 'react';
import trackCardCourse from '../../assets/icons/trackCardCourse.svg';
import trackCardClock from '../../assets/icons/trackCardClock.svg';
import FormatEstimatedTime from '../../utils/helpers/FormatEstimatedTime';
import PetitTitle2 from '../styledComponents/fonts/PetitTitle2';
import StyledIcon from '../styledComponents/StyledImage';

const TrackIconAndInfo = ({ numOfCourses, estimatedTime }) => {
    const infoIcons = [
        { icon: `${trackCardCourse}`, title: `${numOfCourses} MODULES` },
        {
            icon: `${trackCardClock}`,
            title: `${FormatEstimatedTime(estimatedTime)}`,
        },
    ];

    const IconAndInfo = ({ icon, text }) => {
        return (
            <div className="flex-center-aligned ">
                <StyledIcon
                    height="2.4rem"
                    width="2.4rem"
                    marginRight="0.5rem"
                    src={icon}
                />
                <PetitTitle2 marginRight="0.5rem">{text}</PetitTitle2>
            </div>
        );
    };

    return (
        <div className="flex-center-aligned">
            {infoIcons.map((item, idx) => {
                return (
                    <div key={idx}>
                        <IconAndInfo icon={item.icon} text={item.title} />
                    </div>
                );
            })}
        </div>
    );
};

export default TrackIconAndInfo;
