const ageGroups = Object.freeze({
    LESS_6_MONTHS: 'Less than 6 months old',
    MONTHS_6_9: '6-9 Months Old',
    MONTHS_9_12: '9-12 Months Old',
    MONTHS_12_24: '12-24 Months Old',
    TODLER_SCHOOL: 'Toddler-School Age',
    ADOLESCENT: 'Adolescent',
    ALL: 'All ages recomendations and reminders',
});

export default ageGroups;
