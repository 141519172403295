import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import React, { useCallback, useEffect, useState } from 'react';
import Body2 from '../../assets/styledComponents/fonts/Body2';
import Header4 from '../../assets/styledComponents/fonts/Header4';
import { GET_COURSE } from '../../graphql/queries';
import Layout from '../../types/layout';
import CourseCardDetails from './CourseCardDetails';

const CardInfoSection = ({
    progress,
    title,
    description,
    lastLesson,
    slug,
    totalLessons,
    lastPage,
    margin,
    progressBarWidth,
    isMobile,
}) => {
    const [isNotInProgress] = useState(progress === '0' || progress === '100');

    const { data: course } = useQuery(GET_COURSE, {
        variables: { slug },
    });

    const [headerLinesCount, headerRef] = useElementLinesCount();
    const [descriptionLinesCount, setDescriptionLinesCount] =
        useState(headerLinesCount);

    useEffect(() => {
        setDescriptionLinesCount(calculateDescriptionLines(headerLinesCount));
        return () => setDescriptionLinesCount(0);
    }, [headerLinesCount]);

    function useElementLinesCount() {
        const [count, setCount] = useState(null);
        const ref = useCallback((node) => {
            if (node !== null) {
                setCount(countLinesInElement(node));
            }
        }, []);
        return [count, ref];
    }

    const countLinesInElement = (el) => {
        let divHeight = el.offsetHeight;
        let lineHeight = 26; // line height for course header
        return Math.round(divHeight / lineHeight);
    };

    const calculateDescriptionLines = (headerLinesCount) => {
        if (headerLinesCount === 1) {
            return 2;
        }
        return 1;
    };

    return (
        <Info margin={margin}>
            <Header4
                className="maxTwoLineText"
                innerRef={headerRef}
                marginBottom={headerLinesCount === 1 ? `1.6rem` : `0.8rem`}
            >
                {title}
            </Header4>
            <Description
                color="greyText"
                lines={
                    isNotInProgress
                        ? descriptionLinesCount + 1
                        : descriptionLinesCount
                }
            >
                {description}
            </Description>
            <CourseCardDetails
                view={Layout.CARD_VIEW}
                course={course}
                slug={slug}
                progress={progress}
                lastLesson={lastLesson}
                totalLessons={totalLessons}
                lastPage={lastPage}
                isCardView={true}
                margin={margin}
                progressBarWidth={progressBarWidth}
                isMobile={isMobile}
            />
        </Info>
    );
};

const Info = styled.div`
    margin: ${(props) => props.margin};
    /* height needed to keep icons fixed at one position */
    height: 13.4rem;
    position: relative;
`;

const Description = styled(Body2)`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: ${(props) => props.lines};
    -webkit-box-orient: vertical;
    text-decoration: none;
    color: ${(props) => props.theme.colors.greyText};
`;

export default CardInfoSection;
