import React from 'react';
import CarouselCourseCard from 'components/CourseCard/LandingPageCarousel/CarouselCourseCard';
import calculateTotalLessons from 'utils/helpers/CalculateTotalLessons';

const ShowLoggedInCards = (globalState, allCourses, myCourses) => {
    return allCourses?.map((c) => {
        const completedLessons =
            myCourses && myCourses[c.id]
                ? Number(Object.keys(myCourses[c.id].lessonsCompleted).length)
                : null;

        const myCourse = myCourses && myCourses[c.id];
        const progress =
            myCourse && completedLessons === 0
                ? '0'
                : Math.floor(
                      (completedLessons !== null
                          ? completedLessons / myCourses[c.id].totalLessons
                          : 0) * 100
                  ) + '';

        let smallImage = c?.courseImage?.image?.formats?.small?.url;
        let nonOptimizedImage = c.courseImage.image.url;

        return (
            <CarouselCourseCard
                course={c}
                key={c.id}
                imageURL={smallImage ? smallImage : nonOptimizedImage}
                title={c.title}
                description={c.description}
                intro={null}
                courseURL={`/`}
                progress={progress}
                id={c.id}
                slug={c.slug}
                lastLesson={myCourse && myCourse.lastLesson}
                lastPage={myCourse && myCourse.lastPageIndex}
                totalLessons={calculateTotalLessons(c)}
                devicesize={globalState.deviceSize}
            />
        );
    });
};

export default ShowLoggedInCards;
