import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import OutlinedButtonMd from '../../assets/styledComponents/buttons/OutlinedButtonMd';
import { UPDATE_PROFILE } from '../../graphql/mutations';
import { useMutation } from '@apollo/client';
import { useGlobalContext } from '../../GlobalContext/GlobalProvider';
import { saveUser } from '../../GlobalContext/actionVariables';
import ToastNotification from '../../assets/styledComponents/ToastNotification';
import Body1 from '../../assets/styledComponents/fonts/Body1';
import ToggleUserGroup from '../../utils/helpers/ToggleUserGroup';
import ResetPasswordModal from '../ResetPassword/ResetPasswordModal';
import P from 'assets/styledComponents/fonts/P';
import Container from 'assets/styledComponents/Container';

const EditProfile = ({ data }) => {
    const [globalState, dispatch] = useGlobalContext();

    const [showNotification, setShowNotification] = useState(false);
    const [formFields, setFormFields] = useState(data);
    const [showModal, setShowModal] = useState(false);

    const isMobile = Boolean(globalState.deviceSize === 'mobile');

    // Prevent background content scrolling when reset pw modal open
    if (showModal) {
        document.body.style.overflow = 'hidden';
    } else if (!showModal) {
        document.body.style.overflow = 'unset';
    }

    useEffect(() => {
        setFormFields(data);
    }, [data]);

    const [isEditingProfile, setIsEditingProfile] = useState(false);
    const [changeProfile] = useMutation(UPDATE_PROFILE, {
        onCompleted: () => {
            setIsEditingProfile(false),
                setShowNotification(true),
                setTimeout(() => {
                    setShowNotification(false);
                }, 5000);
        },
        onError: (error) => console.log('An error occured ', error),
    });

    const { id, registeredUserID, email, firstName, lastName, group } =
        formFields;

    const onChange = (event) => {
        setIsEditingProfile(true);
        setFormFields({
            ...formFields,
            [event.target.name]: event.target.value,
        });
    };

    const handleCancel = () => {
        setFormFields({
            ...formFields,
            firstName: data.firstName,
            lastName: data.lastName,
            group: data.group,
        });
        setIsEditingProfile(false);
    };

    const handleResetPassword = (event) => {
        event.preventDefault();

        const forgotPasswordUrl = `${process.env.REACT_APP_API_URL}/auth/forgot-password`;

        const formData = {
            email: email,
        };

        const forgotPW = async (url, data) => {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
            return response.json();
        };

        forgotPW(forgotPasswordUrl, formData).then((res) => {
            if (!res.statusCode) {
                setShowModal(true);
            } else {
                console.log(
                    'An error occured when trying to reset password ',
                    res.message[0].messages[0].message
                );
            }
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        changeProfile({
            variables: {
                userID: id,
                registeredID: registeredUserID,
                firstName: firstName,
                lastName: lastName,
                group: group,
            },
            optimisticResponse: {
                updateRegisteredUser: {
                    registeredUser: {
                        __typename: 'RegisteredUser',
                        group: group,
                        firstName: firstName,
                        id: registeredUserID,
                        lastName: lastName,
                    },
                    __typename: 'updateRegisteredUserPayload',
                },
                updateUser: {
                    user: {
                        id: id,
                        __typename: 'UsersPermissionsUser',
                    },
                    __typename: 'updateUserPayload',
                },
            },
        });

        dispatch({
            type: saveUser,
            payload: formFields,
        });
    };

    return (
        <Container
            className="flex-column-centered"
            margin={isMobile ? '0 2.4rem 2.4rem 2.4rem' : ''}
        >
            {showModal && (
                <ResetPasswordModal
                    setShowModal={setShowModal}
                    showModal={showModal}
                    userEmail={email}
                    isMobile={isMobile}
                />
            )}
            <StyledForm onSubmit={handleSubmit} isMobile={isMobile}>
                <FormFieldContainer className="emailStyle" isMobile={isMobile}>
                    <StyledLabel htmlFor="Email" isMobile={isMobile}>
                        Email
                    </StyledLabel>
                    <ViewField isMobile={isMobile}>{email}</ViewField>
                </FormFieldContainer>
                <FormFieldContainer isMobile={isMobile}>
                    <StyledLabel htmlFor="First Name" isMobile={isMobile}>
                        First Name
                    </StyledLabel>
                    <StyledInput
                        required
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                        onChange={onChange}
                        value={firstName}
                        isMobile={isMobile}
                    />
                </FormFieldContainer>
                <FormFieldContainer isMobile={isMobile}>
                    <StyledLabel htmlFor="Last Name" isMobile={isMobile}>
                        Last Name
                    </StyledLabel>
                    <StyledInput
                        required
                        type="text"
                        name="lastName"
                        placeholder="Last Name"
                        onChange={onChange}
                        value={lastName}
                        isMobile={isMobile}
                    />
                </FormFieldContainer>
                <FormFieldContainer isMobile={isMobile}>
                    <StyledLabel htmlFor="Group" isMobile={isMobile}>
                        Group
                    </StyledLabel>

                    <ToggleUserGroup
                        group={group}
                        onChange={onChange}
                        isMobile={isMobile}
                    />
                </FormFieldContainer>
                <FormFieldContainer isMobile={isMobile}>
                    <StyledLabel isMobile={isMobile}>Password</StyledLabel>

                    <PasswordButton
                        onClick={handleResetPassword}
                        isMobile={isMobile}
                    >
                        <P className="body-4">Reset Password</P>
                    </PasswordButton>
                </FormFieldContainer>
                <Container width={isMobile ? '100%' : ''}>
                    <FormButtonsSection isMobile={isMobile}>
                        {showNotification && (
                            <ToastNotification
                                message="Profile Updated"
                                success
                            />
                        )}
                        <FormActionButton
                            type="submit"
                            disabled={isEditingProfile ? false : true}
                            className={isEditingProfile ? '' : 'disabledBtn'}
                            isMobile={isMobile}
                        >
                            Update Profile
                        </FormActionButton>
                        <FormActionButton
                            type="button"
                            disabled={isEditingProfile ? false : true}
                            className={isEditingProfile ? '' : 'disabledBtn'}
                            onClick={handleCancel}
                            isMobile={isMobile}
                        >
                            Cancel
                        </FormActionButton>
                    </FormButtonsSection>
                </Container>
            </StyledForm>
        </Container>
    );
};

const StyledForm = styled.form`
    width: ${(props) => (props.isMobile ? '100%' : '60.5rem')};
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const FormFieldContainer = styled.div`
    display: flex;
    flex-direction: ${(props) => (props.isMobile ? 'column' : '')};
    width: 100%;
    align-items: ${(props) => (props.isMobile ? 'left' : 'center')};
    justify-content: space-between;
    margin-bottom: ${(props) =>
        props.isMobile ? '3.2rem' : props.theme.spaces.xs};
    &.emailStyle {
        justify-content: left;
    }
`;

const PasswordButton = styled(OutlinedButtonMd)`
    margin-top: 0;
    width: ${(props) => (props.isMobile ? '100%' : ' 40rem')};
    text-decoration: none;
`;
const StyledLabel = styled.label`
    font-family: 'MontserratBold';
    color: ${(props) => props.theme.colors.darkText};
    font-size: ${(props) => props.theme.fontSizes.sm};
    text-transform: uppercase;
    margin-bottom: ${(props) => (props.isMobile ? '1rem' : '')};
`;

const StyledInput = styled.input`
    width: ${(props) => (props.isMobile ? '100%' : '40rem')};
    margin-left: ${(props) => (props.isMobile ? '' : '2rem')};
    padding: 1rem;
    font-family: 'MontserratSemiBold';
    color: ${(props) => props.theme.colors.darkText};
    font-size: 1.8rem;
    border: 0.1rem solid ${(props) => props.theme.colors.lightGrey};
    border-radius: 0.5rem;

    :focus {
        border: 0.1rem solid ${(props) => props.theme.colors.primary};
    }
`;

const ViewField = styled(Body1)`
    padding-left: ${(props) => (props.isMobile ? '' : '15.5rem')};
`;

const FormButtonsSection = styled.div`
    display: flex;
    flex-direction: ${(props) => (props.isMobile ? 'column' : '')};
    align-items: ${(props) => (props.isMobile ? 'left' : 'center')};
    padding-top: ${(props) => (props.isMobile ? '1.6rem' : '6rem')};
    /* relative as ToastNotifications is a child of this component */
    position: ${(props) => (props.isMobile ? '' : 'relative')};
`;

const FormActionButton = styled(OutlinedButtonMd)`
    width: ${(props) => (props.isMobile ? '100%' : 'fit-content;')};
    padding-left: ${(props) => props.theme.spaces.md};
    padding-right: ${(props) => props.theme.spaces.md};
    margin-right: ${(props) => props.theme.spaces.xxs};
    margin-bottom: ${(props) => (props.isMobile ? '1.6rem' : '')};
`;

export default EditProfile;
