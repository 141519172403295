import styled from '@emotion/styled';

export const ListItem = styled.li`
    position: relative;
    margin-left: ${(props) =>
        props.devicesize === 'desktopSm' || props.devicesize === 'desktopMd'
            ? '7.5rem'
            : '2rem'};
    margin-bottom: ${(props) =>
        props.devicesize === 'desktopSm' || props.devicesize === 'desktopMd'
            ? '4rem'
            : '1.6rem'};
    margin-right: 1.5rem;

    :last-of-type {
        margin-bottom: 0;
    }

    :before {
        position: absolute;
        content: ' ';
        border-radius: 50%;
        width: ${(props) =>
            props.devicesize === 'desktopSm' || props.devicesize === 'desktopMd'
                ? '0.8rem'
                : '0.4rem'};
        height: ${(props) =>
            props.devicesize === 'desktopSm' || props.devicesize === 'desktopMd'
                ? '0.8rem'
                : '0.4rem'};
        background: ${(props) =>
            props.devicesize === 'desktopSm' || props.devicesize === 'desktopMd'
                ? props.theme.colors.primary
                : props.theme.colors.darkText};
        color: ${(props) => props.theme.colors.darkText};
        bottom: calc(50% - 0.3rem);
        top: ${(props) =>
            props.devicesize === 'desktopSm' || props.devicesize === 'desktopMd'
                ? '0.9rem'
                : '0.6rem'};
        left: -2.4rem;
    }

    &.list-with-link {
        margin-bottom: 2.4rem;
        :before {
            bottom: calc(60%);
        }
        :last-of-type {
            margin-bottom: 0;
        }
    }
`;
