import React, { useState, useEffect } from 'react';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';
import {
    StyledForm,
    CalculatorContainer,
} from '../../SharedComponentsInteractive';
import CalcInputItem from 'assets/styledComponents/CalcInputItem';
import Container from 'assets/styledComponents/Container';
import InteractiveActivityButton from 'assets/styledComponents/buttons/InteractiveActivityButton';
import StyledImage from 'assets/styledComponents/StyledImage';
import plusPrimaryDarkest from 'assets/icons/math/plusPrimaryDarkest.svg';
import equalsPrimaryDarkest from 'assets/icons/math/equalsPrimaryDarkest.svg';
import InputItemLabelPosition from 'types/inputItemLabelPosition';
import CalcResult from 'assets/styledComponents/CalcResult';
import { StyledHintButton } from '../../SharedComponentsInteractive';

const Step1Calc = ({ handleHintClick }) => {
    let initialFormfields = {
        breakfastNovoRapid: '',
        breakfastLevemir: '',
        lunchNovoRapid: '',
        dinnerNovoRapid: '',
        bedtimeLevemir: '',
        result: '',
    };

    let initialErrors = {
        breakfastNovoRapid: false,
        breakfastLevemir: false,
        lunchNovoRapid: false,
        dinnerNovoRapid: false,
        bedtimeLevemir: false,
        result: false,
    };

    const [formFields, setFormFields] = useState({
        ...initialFormfields,
    });

    const [error, setError] = useState({
        ...initialErrors,
    });

    const [breakfastNovoRapid, setBreakfastNovoRapid] = useState('');
    const [breakfastLevemir, setBreakfastLevemir] = useState('');
    const [lunchNovoRapid, setLunchNovoRapid] = useState('');
    const [dinnerNovoRapid, setDinnerNovoRapid] = useState('');
    const [bedtimeLevemir, setBedtimeLevemir] = useState('');
    const [calcResult, setCalcRes] = useState('?');
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const hasMissingLength =
        !formFields.breakfastNovoRapid.length ||
        !formFields.breakfastLevemir.length ||
        !formFields.lunchNovoRapid.length ||
        !formFields.dinnerNovoRapid.length ||
        !formFields.bedtimeLevemir.length;

    const onChange = (e) => {
        setFormFields({
            ...formFields,
            [e.target.name]: e.target.value,
        });
        if (e.target.name === 'breakfastNovoRapid') {
            setBreakfastNovoRapid(e.target.value);
        } else if (e.target.name === 'breakfastLevemir') {
            setBreakfastLevemir(e.target.value);
        } else if (e.target.name === 'lunchNovoRapid') {
            setLunchNovoRapid(e.target.value);
        } else if (e.target.name === 'dinnerNovoRapid') {
            setDinnerNovoRapid(e.target.value);
        } else if (e.target.name === 'bedtimeLevemir') {
            setBedtimeLevemir(e.target.value);
        }
    };

    const calculateResult = async (e) => {
        e.preventDefault();
        setHasSubmitted(true);
        let {
            breakfastNovoRapid,
            breakfastLevemir,
            lunchNovoRapid,
            dinnerNovoRapid,
            bedtimeLevemir,
        } = formFields;

        let result =
            +breakfastNovoRapid +
            +breakfastLevemir +
            +lunchNovoRapid +
            +dinnerNovoRapid +
            +bedtimeLevemir;
        setCalcRes(result);
        if (
            error.breakfastNovoRapid ||
            error.breakfastLevemir ||
            error.lunchNovoRapid ||
            error.dinnerNovoRapid ||
            error.bedtimeLevemir ||
            error.result
        ) {
            setError({
                ...initialErrors,
            });
            setFormFields({
                ...initialFormfields,
            });
            // clear input and result fields
            setBreakfastNovoRapid('');
            setBreakfastLevemir('');
            setLunchNovoRapid('');
            setDinnerNovoRapid('');
            setBedtimeLevemir('');
            setCalcRes('?');
        } else {
            setError({
                ...error,
                breakfastNovoRapid: breakfastNovoRapid != 4 ? true : false,
                breakfastLevemir: breakfastLevemir != 6 ? true : false,
                lunchNovoRapid: lunchNovoRapid != 3 ? true : false,
                dinnerNovoRapid: dinnerNovoRapid != 3 ? true : false,
                bedtimeLevemir: bedtimeLevemir != 6 ? true : false,
                // all inputs and the result have to be correct for the result to be correct
                result:
                    result != 22 ||
                    breakfastNovoRapid != 4 ||
                    breakfastLevemir != 6 ||
                    lunchNovoRapid != 3 ||
                    dinnerNovoRapid != 3 ||
                    bedtimeLevemir != 6
                        ? true
                        : false,
            });
        }
    };

    const handleTryAgainAfterAllCorrect = (e) => {
        e.preventDefault();
        setFormFields({
            ...initialFormfields,
        });
        // clear input and result fields
        setBreakfastNovoRapid('');
        setBreakfastLevemir('');
        setLunchNovoRapid('');
        setDinnerNovoRapid('');
        setBedtimeLevemir('');
        setCalcRes('?');
    };

    // remove error message if user clears an input field
    useEffect(() => {
        if (hasMissingLength) {
            // for input box styling
            setHasSubmitted(false);
            // for submit button text
            setError({
                ...error,
                breakfastNovoRapid: false,
                breakfastLevemir: false,
                lunchNovoRapid: false,
                dinnerNovoRapid: false,
                bedtimeLevemir: false,
                result: false,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formFields]);

    const allCorrect =
        !error.breakfastNovoRapid &&
        !error.breakfastLevemir &&
        !error.lunchNovoRapid &&
        !error.dinnerNovoRapid &&
        !error.bedtimeLevemir &&
        !error.result;

    let [globalState] = useGlobalContext();
    let devicesize = globalState.deviceSize;

    // responsive vars
    let inputFieldWidth =
        devicesize === 'mobile' || devicesize === 'tablet'
            ? '6.8rem'
            : '7.5rem';

    let inputFieldHeight =
        devicesize === 'mobile' || devicesize === 'tablet' ? '6.8rem' : '10rem';

    let operatorWidth =
        devicesize === 'mobile' || devicesize === 'tablet'
            ? '1.6rem'
            : '2.4rem';

    let inputFieldMaxWidth =
        devicesize === 'mobile'
            ? '8rem'
            : devicesize === 'tablet'
            ? '10rem'
            : '17rem';
    return (
        <StyledForm devicesize={devicesize}>
            <CalculatorContainer
                className="flex-all-centered"
                devicesize={devicesize}
                background="tertiary"
            >
                <Container
                    className={devicesize === 'mobile' ? 'flex-wrap ' : 'flex'}
                    width={devicesize === 'mobile' ? '27.2rem' : ''}
                >
                    <div className="flex-column-centered">
                        <CalcInputItem
                            label="Units Breakfast NovoRapid"
                            labelPosition={InputItemLabelPosition.BELOW}
                            name="breakfastNovoRapid"
                            value={breakfastNovoRapid}
                            onChange={onChange}
                            error={error}
                            hasSubmitted={hasSubmitted}
                            inputFieldWidth={inputFieldWidth}
                            inputFieldHeight={inputFieldHeight}
                            maxWidth={inputFieldMaxWidth}
                            placeholder="0"
                        />
                    </div>
                    <Container>
                        <StyledImage
                            alt="plus symbol"
                            marginTop={
                                devicesize === 'mobile' ||
                                devicesize === 'tablet'
                                    ? '3rem'
                                    : '4rem'
                            }
                            width={operatorWidth}
                            src={plusPrimaryDarkest}
                        />
                    </Container>

                    <div className="flex-column-centered">
                        <CalcInputItem
                            label="Units Breakfast Levemir"
                            labelPosition={InputItemLabelPosition.BELOW}
                            name="breakfastLevemir"
                            value={breakfastLevemir}
                            onChange={onChange}
                            error={error}
                            hasSubmitted={hasSubmitted}
                            inputFieldWidth={inputFieldWidth}
                            inputFieldHeight={inputFieldHeight}
                            maxWidth={inputFieldMaxWidth}
                            placeholder="0"
                        />
                    </div>
                    <Container>
                        <StyledImage
                            alt="plus symbol"
                            width={operatorWidth}
                            marginTop={
                                devicesize === 'mobile' ||
                                devicesize === 'tablet'
                                    ? '3rem'
                                    : '4rem'
                            }
                            src={plusPrimaryDarkest}
                        />
                    </Container>
                    <div className="flex-column-centered">
                        <CalcInputItem
                            label="Units Lunch NovoRapid"
                            labelPosition={InputItemLabelPosition.BELOW}
                            name="lunchNovoRapid"
                            value={lunchNovoRapid}
                            onChange={onChange}
                            error={error}
                            hasSubmitted={hasSubmitted}
                            inputFieldWidth={inputFieldWidth}
                            inputFieldHeight={inputFieldHeight}
                            maxWidth={inputFieldMaxWidth}
                            placeholder="0"
                        />
                    </div>
                    <Container
                        className={
                            devicesize === 'mobile'
                                ? 'flex-center-justified'
                                : ''
                        }
                        width={devicesize === 'mobile' ? '33rem' : ''}
                    >
                        <StyledImage
                            alt="plus symbol"
                            width={operatorWidth}
                            marginTop={
                                devicesize === 'mobile'
                                    ? '2rem'
                                    : devicesize === 'tablet'
                                    ? '3rem'
                                    : '4rem'
                            }
                            src={plusPrimaryDarkest}
                        />
                    </Container>

                    <div className="flex-column-centered">
                        <CalcInputItem
                            label="Units Dinner NovoRapid"
                            labelPosition={InputItemLabelPosition.BELOW}
                            name="dinnerNovoRapid"
                            value={dinnerNovoRapid}
                            onChange={onChange}
                            error={error}
                            hasSubmitted={hasSubmitted}
                            inputFieldWidth={inputFieldWidth}
                            inputFieldHeight={inputFieldHeight}
                            maxWidth={inputFieldMaxWidth}
                            marginTop={devicesize === 'mobile' ? '2rem' : ''}
                            placeholder="0"
                        />
                    </div>
                    <Container>
                        <StyledImage
                            alt="plus symbol"
                            width={operatorWidth}
                            marginTop={
                                devicesize === 'mobile'
                                    ? '5rem'
                                    : devicesize === 'tablet'
                                    ? '3rem'
                                    : '4rem'
                            }
                            src={plusPrimaryDarkest}
                        />
                    </Container>
                    <div className="flex-column-centered">
                        <CalcInputItem
                            label="Units Bedtime Levemir"
                            labelPosition={InputItemLabelPosition.BELOW}
                            name="bedtimeLevemir"
                            value={bedtimeLevemir}
                            onChange={onChange}
                            error={error}
                            hasSubmitted={hasSubmitted}
                            inputFieldWidth={inputFieldWidth}
                            inputFieldHeight={inputFieldHeight}
                            maxWidth={inputFieldMaxWidth}
                            marginTop={devicesize === 'mobile' ? '2rem' : ''}
                            placeholder="0"
                        />
                    </div>

                    <Container>
                        <StyledImage
                            alt="equals symbol"
                            width={operatorWidth}
                            marginTop={
                                devicesize === 'mobile'
                                    ? '5rem'
                                    : devicesize === 'tablet'
                                    ? '3rem'
                                    : '4rem'
                            }
                            src={equalsPrimaryDarkest}
                        />
                    </Container>

                    <div className="flex-column-centered">
                        <CalcResult
                            label="Total Daily Dose (in units)"
                            name="result"
                            value={calcResult}
                            error={error}
                            hasSubmitted={hasSubmitted}
                            inputFieldWidth={'10rem'}
                            marginLeft={'2rem'}
                            inputFieldHeight={inputFieldHeight}
                            borderColor="tertiaryDark"
                            background="tertiaryDark"
                            maxWidth={inputFieldMaxWidth}
                            marginTop={devicesize === 'mobile' ? '2rem' : ''}
                        />
                    </div>
                </Container>
            </CalculatorContainer>

            <Container marginBottom="4.8rem" className="flex-all-centered">
                {allCorrect && hasSubmitted ? (
                    <InteractiveActivityButton
                        isCorrect={true}
                        onClick={handleTryAgainAfterAllCorrect}
                        className="secondary"
                    />
                ) : (
                    <InteractiveActivityButton
                        isCorrect={false}
                        onClick={calculateResult}
                        className={
                            hasMissingLength
                                ? 'disabledBtn'
                                : !allCorrect
                                ? 'incorrect'
                                : 'secondary'
                        }
                        hasError={!allCorrect}
                    />
                )}
                <StyledHintButton onClick={(e) => handleHintClick(e)} />
            </Container>
        </StyledForm>
    );
};

export default Step1Calc;
