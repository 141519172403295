import React, { useEffect, useRef } from 'react';
import { useGlobalContext } from '../../GlobalContext/GlobalProvider';
import ShowProfileAvatar from '../../utils/helpers/ShowProfileAvatar';
import styled from '@emotion/styled';

const Dropdown = ({ children, isOpened, setIsOpened }) => {
    const [state] = useGlobalContext();
    let data = state.user;
    const ref = useRef();

    const toggleDropdown = () => {
        setIsOpened(!isOpened);
    };

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (isOpened && ref.current && !ref.current.contains(e.target)) {
                setIsOpened(false);
            }
        };

        document.addEventListener('mousedown', checkIfClickedOutside);

        return () => {
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [isOpened, setIsOpened]);

    return (
        <div className="wrapper" ref={ref}>
            {data ? (
                <Avatar
                    onClick={toggleDropdown}
                    src={ShowProfileAvatar(data)}
                    alt="user avatar"
                />
            ) : (
                ''
            )}
            {isOpened && <Menu>{children}</Menu>}
        </div>
    );
};

const Avatar = styled.img`
    position: relative;
    margin-left: 2.6rem;
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    border: 0.1rem solid ${(props) => props.theme.colors.lightGrey};
    :hover {
        cursor: pointer;
        opacity: 0.3;
    }
`;

const Menu = styled.div`
    position: absolute;
    z-index: 5;
    right: 1rem;
    margin-top: ${(props) => props.theme.fontSizes.sm};
    padding-left: 2.5rem;
    width: 21rem;
    height: 10rem;
    display: flex;
    border-radius: 1rem;
    flex-direction: column;
    justify-content: center;
    background-color: ${(props) => props.theme.colors.lightText};
    filter: drop-shadow(rgba(0, 0, 0, 0.2) 0 2px 10px);
    :before {
        content: '';
        display: block;
        position: absolute;
        left: 13.8rem;
        bottom: 100%;
        width: 0;
        height: 0;
        border: 1.15rem solid transparent;
    }
    :after {
        content: '';
        display: block;
        position: absolute;
        left: 13.8rem;
        bottom: 100%;
        width: 0;
        height: 0;
        border: 1.15rem solid transparent;
        border-bottom-color: ${(props) => props.theme.colors.lightText};
    }
`;

export default Dropdown;
