import styled from '@emotion/styled';
import { nanoid } from 'nanoid';
import React, { Suspense } from 'react';
import BoxItem from './BoxItem';

const DropTarget = React.lazy(() => import('./DropTarget'));

const AnswerBox = ({
    targetKey,
    item,
    setItem,
    isCorrect,
    isIncorrect,
    isNewGame,
}) => {
    const handleDrop = (e) => {
        const newItem = {
            label: e.dragData.label,
            uid: nanoid(),
            img: e.dragData.img,
        };
        if (!isNewGame) {
            return;
        }
        setItem(newItem);
        e.containerElem.style.visibility = 'hidden';
    };

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <DropTarget onHit={handleDrop} targetKey={targetKey}>
                <DropTarget onHit={handleDrop} targetKey="boxItem">
                    <Circle isCorrect={isCorrect} isIncorrect={isIncorrect}>
                        {item && (
                            <BoxItem
                                key={item.uid}
                                uid={item.uid}
                                index={item.uid}
                                label={item.label}
                                img={item.img}
                            >
                                {item.label}
                            </BoxItem>
                        )}
                    </Circle>
                </DropTarget>
            </DropTarget>
        </Suspense>
    );
};

// color var

const Circle = styled.div`
    border: 3px solid
        ${(props) =>
            props.isIncorrect
                ? props.theme.colors.alertRed
                : props.isCorrect
                ? props.theme.colors.success
                : props.theme.colors.tertiaryDark};
    border-radius: 50%;
`;

export default AnswerBox;
