import React from 'react';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';
import {
    InputField,
    InputWrapper,
} from 'components/Courses/InteractiveModules/SharedComponentsInteractive';
import InputItemLabelPosition from 'types/inputItemLabelPosition';
import LimitInputValues from 'utils/helpers/LimitInputValues';
import P from './fonts/P';

const CalcInputItem = ({
    label,
    labelPosition,
    name,
    value,
    onChange,
    error,
    hasSubmitted,
    inputFieldWidth,
    inputFieldHeight,
    maxWidth,
    marginTop,
    borderColor,
    background,
    placeholder,
}) => {
    // responsive vars
    const [globalState] = useGlobalContext();
    const devicesize = globalState.deviceSize;
    const isTablet = Boolean(devicesize === 'tablet');

    return (
        <>
            {labelPosition === InputItemLabelPosition.ABOVE && (
                <P
                    className={devicesize === 'mobile' ? 'body-2' : 'body-1'}
                    textAlign="center"
                    marginBottom="1.6rem"
                    whiteSpace={`nowrap`}
                    maxWidth={maxWidth}
                    color="primaryDarkest"
                >
                    {label}
                </P>
            )}
            <InputWrapper
                width={
                    inputFieldWidth
                        ? inputFieldWidth
                        : isTablet
                        ? '11rem'
                        : '12rem'
                }
                color="primaryDarkest"
                devicesize={devicesize}
            >
                <InputField
                    type="text"
                    id={name}
                    name={name}
                    value={value}
                    placeholder={placeholder}
                    onChange={onChange}
                    onKeyDown={LimitInputValues}
                    min="0"
                    max="99.9"
                    maxLength={5}
                    className={
                        error[name] && hasSubmitted
                            ? 'error underline'
                            : !error[name] && hasSubmitted
                            ? 'correct underline '
                            : 'underline'
                    }
                    width={inputFieldWidth}
                    height={inputFieldHeight}
                    devicesize={devicesize}
                    marginTop={marginTop}
                    background={background}
                    borderColor={borderColor}
                />
            </InputWrapper>
            {labelPosition === InputItemLabelPosition.BELOW && (
                <P
                    className={devicesize === 'mobile' ? 'body-2' : 'body-1'}
                    textAlign="center"
                    maxWidth={maxWidth}
                    color="primaryDarkest"
                >
                    {label}
                </P>
            )}
        </>
    );
};

export default CalcInputItem;
