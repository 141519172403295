import React from 'react';
import BadgesAchievement from '../assets/styledComponents/BadgesAchievement';
import PageContentContainer from '../assets/styledComponents/PageContentContainer';
import ScrollContainer from '../assets/styledComponents/ScrollContainer';
import DashboardHomeHeader from '../components/DashboardHome/DashboardHomeHeader';
import MyStudyContainer from '../components/DashboardHome/MyStudyContainer';
import StudyPlanAndBadgesContainer from '../components/DashboardHome/StudyPlanAndBadgesContainer';
import { useGlobalContext } from '../GlobalContext/GlobalProvider';
import badgesType from '../types/badgesType';
import MyStudySectionMobile from './MyStudySectionMobile';

const Dashboard = () => {
    const [globalState] = useGlobalContext();
    let data = globalState.user;
    const isDesktop =
        globalState.deviceSize === 'desktopSm' ||
        globalState.deviceSize === 'desktopMd';

    const isDesktopSm = globalState.deviceSize === 'desktopSm';

    const isTablet = globalState.deviceSize === 'tablet';

    const isMobile = globalState.deviceSize === 'mobile';

    return (
        <ScrollContainer>
            <PageContentContainer
                marginTop="4rem"
                marginLeft="4.5rem"
                marginRight="4.5rem"
            >
                <BadgesAchievement id={badgesType.FIRST_ONE} />
                {isDesktop || isTablet ? (
                    <DashboardHomeHeader data={data} />
                ) : (
                    ''
                )}
                {isMobile ? (
                    <MyStudySectionMobile data={data} />
                ) : (
                    <MyStudyContainer
                        data={data}
                        isDesktopSm={isDesktopSm}
                        isTablet={isTablet}
                    />
                )}
                <StudyPlanAndBadgesContainer
                    isDesktop={isDesktop}
                    isDesktopSm={isDesktopSm}
                />
            </PageContentContainer>
        </ScrollContainer>
    );
};

export default Dashboard;
