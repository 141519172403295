import { React, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';
import { updateAuth } from 'GlobalContext/actionVariables';
import useUserData from 'hooks/useUserData';
import MobileSignInOrUpContainer from './MobileSignInOrUpContainer';
import StyledPetitTitle from './StyledPetitTitle';
import StyledInput from './StyledInput';
import FilledButtonMd from 'assets/styledComponents/buttons/FilledButtonMd';
import noEntry from 'assets/icons/noEntry.svg';
import emailSent from 'assets/icons/emailSent.svg';
import BackButton from './BackButton';
import CloseModalButton from 'utils/helpers/CloseModalButton';
import H1 from 'assets/styledComponents/fonts/H1';
import P from 'assets/styledComponents/fonts/P';
import StyledImage from 'assets/styledComponents/StyledImage';
import Container from 'assets/styledComponents/Container';
import handleSubmitSignIn from './utils/handleSubmitSignIn';
import handleResetPassword from './utils/handleResetPassword';

const MobileSignInForm = ({
    setShowMobileSignUpForm,
    setShowMobileSignInForm,
}) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [formFields, setFormFields] = useState({
        email: '',
        password: '',
    });
    const [, dispatch] = useGlobalContext();
    const { loadUserData } = useUserData(true);

    const [globalState] = useGlobalContext();
    let userData = globalState.user;

    const [forgotPassword, setForgotPassword] = useState(false);

    const onChange = (event) => {
        setFormFields({
            ...formFields,
            [event.target.name]: event.target.value,
        });
    };

    // FORGOT  PASSWORD
    const [forgotPasswordInput, setForgotPasswordInput] = useState({
        email: '',
    });

    const [resetSuccess, setResetSuccess] = useState(false);

    const onChangeForgotPassword = (event) => {
        setForgotPasswordInput({
            ...forgotPasswordInput,
            [event.target.name]: event.target.value,
        });
    };

    // remove error message if user clears input fields
    useEffect(() => {
        if (!formFields.email.length && !formFields.password.length) {
            setErrorMessage('');
        }
    }, [formFields]);

    useEffect(() => {
        if (!forgotPasswordInput.email.length) {
            setErrorMessage('');
        }
    }, [forgotPasswordInput]);

    // Options to display to the user

    const ShowForgotPassword = () => {
        return forgotPassword && resetSuccess ? (
            <Container maxWidth="39rem" marginTop="9rem">
                <EmailSentHeader
                    className="flex-center-aligned header-3"
                    color="secondary"
                >
                    Email sent!
                    <img
                        className="paperJet"
                        src={emailSent}
                        alt="Paper jet icon"
                    />
                </EmailSentHeader>
                <HorizontalLine margin="4rem 0" />
                <P className="body-2" color="darkText">
                    An email has been sent to {forgotPasswordInput.email} with
                    instructions on resetting your password.
                </P>
            </Container>
        ) : (
            forgotPassword && (
                <Container maxWidth="39rem" marginTop="7rem">
                    <BackButton setForgotPassword={setForgotPassword} />
                    <P
                        className="header-3"
                        color="secondary"
                        marginTop="1.5rem"
                    >
                        Forgot your password?
                    </P>
                    <HorizontalLine />
                    <P
                        color={errorMessage.length ? 'primary' : 'darkText'}
                        className="flex-center-aligned body-2"
                    >
                        {errorMessage.length ? (
                            <StyledImage
                                marginRight="0.8rem"
                                src={noEntry}
                                alt="Error, no entry icon"
                            />
                        ) : (
                            ''
                        )}
                        {errorMessage.length
                            ? errorMessage
                            : 'Enter the email address you used when you first signed up. We’ll send you instructions to reset your password.'}
                    </P>
                    <form action="">
                        <StyledInput
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Email"
                            aria-label="Email"
                            onChange={onChangeForgotPassword}
                            marginTop={errorMessage.length ? '2rem' : '3rem'}
                            marginBottom="2rem"
                            className={errorMessage.length && 'error'}
                            autoComplete="email"
                        />

                        <FilledButtonMd
                            width="100%"
                            margin={
                                errorMessage.length
                                    ? '6.5rem 0 2rem'
                                    : '2.5rem 0 2rem'
                            }
                            onClick={(event) =>
                                handleResetPassword(
                                    event,
                                    forgotPasswordInput,
                                    setResetSuccess,
                                    setErrorMessage
                                )
                            }
                        >
                            Send Instructions
                        </FilledButtonMd>

                        {errorMessage.length ? (
                            <StyledPetitTitle
                                color="primary"
                                className="petit-title-2"
                                marginTop="0.5rem"
                                center
                            >
                                <span>NOT A MEMBER?&nbsp;</span>
                                <Button
                                    type="button"
                                    onClick={() => {
                                        setShowMobileSignUpForm(true);
                                        setShowMobileSignInForm(false);
                                    }}
                                >
                                    SIGN UP
                                </Button>
                            </StyledPetitTitle>
                        ) : (
                            ''
                        )}
                    </form>
                </Container>
            )
        );
    };

    const ShowLogin = () => {
        return !userData && !forgotPassword ? (
            <Container maxWidth="39rem" marginTop="7rem">
                <H1
                    color="primaryDarkest"
                    marginTop="-0.8rem"
                    className="header-2"
                >
                    Welcome
                </H1>
                <H1
                    color="primaryDarkest"
                    marginTop="-1rem"
                    className="header-2-serif"
                >
                    Back!
                </H1>

                <P
                    color={errorMessage.length ? 'primary' : 'darkText'}
                    marginTop="2rem"
                    className="flex-center-aligned body-2"
                >
                    {errorMessage.length ? (
                        <StyledImage
                            marginRight="0.8rem"
                            src={noEntry}
                            alt="Error, no entry icon"
                        />
                    ) : (
                        ''
                    )}
                    {errorMessage.length
                        ? errorMessage
                        : 'Enter your email and password.'}
                </P>

                <form
                    onSubmit={(event) =>
                        handleSubmitSignIn(
                            event,
                            formFields,
                            updateAuth,
                            dispatch,
                            loadUserData,
                            setErrorMessage
                        )
                    }
                >
                    <StyledInput
                        required
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Email"
                        onChange={onChange}
                        value={formFields.email}
                        aria-label="Email"
                        marginTop="1.5rem"
                        marginBottom="1rem"
                        className={errorMessage.length && 'error'}
                        autoComplete="email"
                    />
                    <StyledInput
                        required
                        type="password"
                        id="password"
                        name="password"
                        placeholder="Password"
                        onChange={onChange}
                        value={formFields.password}
                        aria-label="Password"
                        marginBottom="1rem"
                        className={errorMessage.length && 'error'}
                        autoComplete="current-password"
                    />

                    <P
                        color="primary"
                        marginTop="0.5rem"
                        role="presentation"
                        className="body-2"
                    >
                        <Button
                            type="button"
                            onClick={() => {
                                setErrorMessage('');
                                setForgotPassword(true);
                            }}
                        >
                            Forgot your password?
                        </Button>
                    </P>

                    <FilledButtonMd
                        width="100%"
                        margin="2.5rem 0 2rem"
                        type="submit"
                    >
                        Sign In
                    </FilledButtonMd>
                </form>
                <StyledPetitTitle
                    className="petit-title-2"
                    color="primary"
                    marginTop="0.5rem"
                    center
                >
                    <span>NOT A MEMBER?&nbsp;</span>
                    <Button
                        type="button"
                        onClick={() => {
                            setShowMobileSignUpForm(true);
                            setShowMobileSignInForm(false);
                        }}
                    >
                        SIGN UP
                    </Button>
                </StyledPetitTitle>
            </Container>
        ) : (
            ''
        );
    };

    return (
        <MobileSignInOrUpContainer>
            <CloseModalButton
                mobileSignInOrUp={true}
                setShowMobileSignInForm={setShowMobileSignInForm}
                top="2.3rem"
                right="2.3rem"
            />
            {ShowForgotPassword()}
            {ShowLogin()}
        </MobileSignInOrUpContainer>
    );
};

const EmailSentHeader = styled(P)`
    img {
        &.paperJet {
            margin-left: 0.8rem;
            margin-top: -1rem;
        }
    }
`;

const HorizontalLine = styled.hr`
    width: 100%;
    height: 0.4rem;
    background-color: ${(props) => props.theme.colors.secondary};
    border-radius: 3rem;
    margin: ${(props) => (props.margin ? props.margin : '3rem 0')};
`;

const Button = styled.button`
    background: transparent;
    color: inherit;
    :hover {
        cursor: pointer;
    }
`;

export default MobileSignInForm;
