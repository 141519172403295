import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import styled from '@emotion/styled/macro';
import LinkButton from '../../assets/styledComponents/buttons/LinkButton';
import { useGlobalContext } from '../../GlobalContext/GlobalProvider';
import { GET_ALL_BADGES } from '../../graphql/queries';
import SectionHeader from './SectionHeader';
import {
    HeaderContainer,
    Tooltip,
    TooltipText,
    BadgeIcon,
} from './SharedComponentsDashboard';
import Container from 'assets/styledComponents/Container';

const BadgesSection = ({ handleShowBadge, devicesize }) => {
    const getBadges = useQuery(GET_ALL_BADGES, {
        onError: (error) => console.log('An error occured ', error),
    });
    const allBadges = getBadges?.data?.badges;

    const dashboardHomeBadges =
        devicesize === 'tablet'
            ? {
                  7: 'STRATEGIC_LEARNER',
                  4: 'OFF_THE_GROUND',
                  2: 'FIRST_OF_MANY',
                  8: 'FIRST_ONE',
                  9: 'TRACK_STAR',
              }
            : {
                  7: 'STRATEGIC_LEARNER',
                  4: 'OFF_THE_GROUND',
                  2: 'FIRST_OF_MANY',
                  8: 'FIRST_ONE',
                  9: 'TRACK_STAR',
                  3: 'HUMBLE_BEGINNINGS',
              };

    const badgesHomePage = allBadges?.filter(
        (badge) => badge.id in dashboardHomeBadges
    );

    const [globalState] = useGlobalContext();
    const [achievedBadges, setAchievedBadges] = useState(
        globalState.user ? globalState.user.trackingLog.badgeTracker : null
    );

    useEffect(() => {
        setAchievedBadges(
            globalState.user ? globalState.user.trackingLog.badgeTracker : null
        );
        return () => setAchievedBadges(null);
    }, [globalState]);

    return (
        <Container
            width="100%"
            marginTop={devicesize === 'mobile' ? '2.6rem' : '3.3rem'}
        >
            {devicesize === 'mobile' ? (
                <SectionHeader
                    title="Badges"
                    marginTop="1.4rem"
                    marginBottom="1.4rem"
                />
            ) : (
                <HeaderContainer>
                    <SectionHeader title="Badges" />
                    <LinkButton
                        linkTitle="go to the page"
                        path={`dashboard/studyplan`}
                    />
                </HeaderContainer>
            )}
            <BadgesContainer
                className="card-shadow flex-column-center"
                devicesize={devicesize}
            >
                <Badges className="flex-space-between" devicesize={devicesize}>
                    {allBadges &&
                        badgesHomePage.map((badge) =>
                            achievedBadges[badge.id] ? (
                                <Tooltip key={badge.id}>
                                    <BadgeIcon
                                        key={badge.id}
                                        src={badge.badgeImage.image.url}
                                        onClick={() =>
                                            handleShowBadge({
                                                ...badge,
                                                achievementDate:
                                                    achievedBadges[badge.id]
                                                        .achievementDate,
                                            })
                                        }
                                        alt={badge.title}
                                        isAchieved={true}
                                    />
                                    <TooltipText className="body-2">
                                        {badge.title}
                                    </TooltipText>
                                </Tooltip>
                            ) : (
                                <Tooltip key={badge.id}>
                                    <BadgeIcon
                                        key={badge.id}
                                        src={
                                            badge.badgeImagePlaceholder &&
                                            badge.badgeImagePlaceholder
                                                .imagePlaceholder.url
                                        }
                                        alt={badge.title}
                                        isAchieved={false}
                                        onClick={() =>
                                            handleShowBadge({
                                                ...badge,
                                            })
                                        }
                                    />
                                    <TooltipText className="body-2">
                                        {badge.title}
                                    </TooltipText>
                                </Tooltip>
                            )
                        )}
                </Badges>
            </BadgesContainer>
        </Container>
    );
};

const BadgesContainer = styled.div`
    //min-width: 35rem;
    padding-top: 3.9rem;
    height: ${(props) =>
        props.devicesize === 'mobile'
            ? '40.2rem'
            : props.devicesize === 'tablet'
            ? '15.6rem'
            : '28rem'};
    border-radius: 2rem;
`;
const Badges = styled.div`
    max-width: ${(props) =>
        props.devicesize === 'mobile'
            ? '21rem'
            : props.devicesize === 'tablet'
            ? '56rem'
            : props.devicesize === 'desktopSm'
            ? '30rem'
            : '33rem'};
    flex-wrap: wrap;
    margin: 0 auto;
`;
export default BadgesSection;
