// note /macro is included to allow component selectors
import styled from '@emotion/styled/macro';
import P from 'assets/styledComponents/fonts/P';

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 2rem 2.8rem;
`;

export const BadgeIcon = styled.img`
    margin-bottom: 0.8rem;
    width: 9.6rem;
    height: 9.6rem;
    text-align: center;
    :hover {
        opacity: ${(props) => (props.isAchieved ? '0.4' : '1')};
        cursor: pointer;
    }
`;

export const TooltipText = styled(P)`
    visibility: hidden;
    width: 100%;
    font-size: 1.2rem;
    position: absolute;
    z-index: 2;
    margin-top: -2rem;
    opacity: 0.8;
`;

export const Tooltip = styled.div`
    position: relative;
    display: inline-block;
    text-align: center;
    &:hover {
        /* import emotion macros to allow component selectors */
        ${TooltipText} {
            visibility: visible;
        }
    }
`;
