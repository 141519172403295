// for desktop logged IN only
export const authNavOptions = [
    {
        title: 'Dashboard',
        href: '/dashboard',
    },
    {
        title: 'Modules',
        href: '/modules',
    },
    {
        title: 'Pathways',
        href: '/pathways',
    },
];

// for desktop and mobile logged OUT users
export const nonAuthNavOptions = [
    {
        title: 'Modules',
        href: '/modules',
    },
    {
        title: 'Pathways',
        href: '/pathways',
    },
];

// for mobile logged IN only
export const mobileAuthNavOptions = [
    {
        title: 'Dashboard',
        href: '/dashboard',
    },
    {
        title: 'Modules',
        href: '/modules',
    },
    {
        title: 'Pathways',
        href: '/pathways',
    },
    {
        title: 'Profile / Settings',
        href: '/profile',
    },
];
