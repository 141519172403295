import styled from '@emotion/styled';
import React from 'react';
import Tip from './Tip';
import TipMobile from './TipMobile';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';

const Content = () => {
    const content = [
        {
            id: 1,
            title: 'Check blood glucose and ketones every 2-3 hours',
            description: `Check blood glucose and ketones every 2-3 hours. You should check more often if you are vomiting, as this may be a sign of ketones. Check the expiry date of your ketone strips. Ketone strips expire 6 months after opening the package.`,
        },
        {
            id: 2,
            title: 'Give rapid-acting insulin every 3 hours as needed',
            description: `Give rapid-acting insulin every 3 hours as needed if blood glucose is higher than 15 mmol/L AND you have ketones. The “5-10-15-20 rule” can be used to calculate how much extra insulin to give. This can prevent diabetic ketoacidosis, or DKA, which is a medical emergency.`,
        },
        {
            id: 3,
            title: 'Never miss an insulin dose',
            description: `Never miss an insulin dose. Give rapid-acting insulin to match the carbohydrates that you are eating or drinking. Remember, even if you are not eating you always need to take your basal insulin. If you are unsure of how much insulin to give, contact your diabetes team for guidance.`,
        },
        {
            id: 4,
            title: 'Eat or drink carbohydrates to maintain blood glucose levels',
            description: `Eat or drink carbohydrates to maintain blood glucose levels. If the blood glucose is low, give fast-acting sugar right away. Maple syrup or sips of juice or ginger ale may be more easily tolerated.`,
        },
        {
            id: 5,
            title: 'Drink lots of fluids to help prevent dehydration',
            description: `Drink lots of fluids to help prevent dehydration. If your blood glucose is above 10 mmol/L, drink sugar free fluids. If your blood glucose is below 10 mmol/L and you are not eating, drink fluids with carbohydrates. For example, Gatorade or Vitamin Water. If you are having trouble keeping fluids down, sipping on small amounts over time is better than drinking a large amount at once. This table shows you how much you should drink based on your body weight.`,
        },
        {
            id: 6,
            title: 'Get plenty of rest',
            description: `Get plenty of rest. You should not exercise during illness or if you have ketones. Exercise will raise glucose levels and cause the body to make more ketones.`,
        },
        {
            id: 7,
            title: 'Treat your illness',
            description: `Treat your illness. The illness may not be related to your diabetes. You
        may need to visit your family doctor or pediatrician for
        an assessment and treatment.`,
        },
    ];

    const [globalState] = useGlobalContext();
    let devicesize = globalState.deviceSize;

    return (
        <>
            {devicesize === 'mobile' ? (
                <>
                    {content.map((item, id) => (
                        <TipMobile key={item.title} item={item} id={id} />
                    ))}
                </>
            ) : (
                <Container>
                    {content.map((item, id) => (
                        <Tip key={item.title} item={item} id={id} />
                    ))}
                </Container>
            )}
        </>
    );
};

const Container = styled.div`
    width: 100%;
    position: relative;

    :before {
        position: absolute;
        content: ' ';
        width: 0.3rem;
        background: ${(props) => props.theme.colors.lightGreyBackground};
        height: calc(100% - 4rem);
        top: 2.5rem;
        left: 2.6rem;
    }
`;

export default Content;
