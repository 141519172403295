import React, { useState } from 'react';
import styled from '@emotion/styled';
import ResetPasswordForm from '../components/ResetPassword/ResetPasswordForm';
import ResetPasswordSuccess from '../components/ResetPassword/ResetPasswordSuccess';

const ResetPasswordPage = () => {
    const [errorMessage, setErrorMessage] = useState('');
    const [resetSuccess, setResetSuccess] = useState(false);

    if (resetSuccess) {
        return (
            <PageContainer>
                <ResetPasswordSuccess />
            </PageContainer>
        );
    } else {
        return (
            <PageContainer className="flex-column-centered">
                <ResetPasswordForm
                    setErrorMessage={setErrorMessage}
                    setResetSuccess={setResetSuccess}
                    errorMessage={errorMessage}
                />
            </PageContainer>
        );
    }
};

const PageContainer = styled.div`
    background: ${(props) => props.theme.colors.tertiaryGradient};
    height: 100vh;
    padding-top: calc(23rem + 9rem);
`;

export default ResetPasswordPage;
