const sortMyCourses = (courses) => {
    let coursesArray = Object.values(courses);
    let mapped = coursesArray.map(function (el) {
        return { index: parseInt(el.slug.slice(6)), value: el };
    });

    let tmp = mapped.sort((a, b) => a.index - b.index);

    let sortedCourses = tmp.map(function (el) {
        return el.value;
    });

    return sortedCourses;
};
export default sortMyCourses;
