import React, { useState, useRef } from 'react';
import styled from '@emotion/styled';
import Container from 'assets/styledComponents/Container';
import P from 'assets/styledComponents/fonts/P';
import { ActivityHeader } from '../../SharedComponentsInteractive';
import StyledImage from 'assets/styledComponents/StyledImage';
import one from 'assets/images/interactive-course5-2/one.svg';
import five from 'assets/images/interactive-course5-2/five.svg';
import grain from 'assets/images/interactive-course5-2/grain.svg';
import CalorieCalculator from './CalorieCalculator';
import UseDesktopBanner from 'assets/styledComponents/UseDesktopBanner';
import CalorieCalcContent from './CalorieCalcContent';
import ImageWithDescription from './ImageWithDescription';

const CalorieCalcActivity = ({ devicesize }) => {
    const [showHint, setShowHint] = useState(false);
    const hintRef = useRef(null);

    const scrollUp = () => {
        return hintRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    };

    const onHintClick = (e) => {
        e.preventDefault();
        setShowHint(true);
        scrollUp();
        setTimeout(() => {
            setShowHint(false);
        }, 3000);
    };

    return (
        <Container>
            <P
                marginTop={devicesize === 'mobile' ? '4rem' : '8rem'}
                marginBottom={devicesize === 'mobile' ? '6.4rem' : '7.2rem'}
                className={devicesize === 'mobile' ? 'body-2' : 'body-4'}
            >
                For all the foods with portions listed, the serving size equals
                approximately 15 grams of available carbohydrate. Remember, most
                protein foods, vegetables and dietary fats have very little to
                no carbohydrate, so you do not need to count these.
            </P>
            {devicesize === 'mobile' && (
                <UseDesktopBanner marginBottom="4rem" />
            )}
            <ActivityHeader
                instructions="Let's go through an example together."
                marginBottom="4rem"
            />

            <Container
                marginLeft={devicesize === 'mobile' && '-2.5rem'}
                width={devicesize === 'mobile' && '100vw'}
            >
                <StyledImage
                    src={one}
                    alt="an illustration of rice, chicken, milk, and peas"
                    width="100%"
                />
            </Container>

            <BorderedContainer devicesize={devicesize}>
                <P
                    margin={
                        devicesize === 'mobile'
                            ? '1.6rem 0 3.2rem'
                            : '2.4rem 0 4rem'
                    }
                    className={devicesize === 'mobile' ? 'body-2' : 'body-4'}
                >
                    Using the resources, find out the carbohydrate count for
                    this meal: <br /> 1 cup long grain rice, 1 chicken breast +
                    1 cup milk and 1 cup peas.
                </P>

                {CalorieCalcContent.map((item) => (
                    <ImageWithDescription
                        key={item.alt}
                        desctiption={item.desctiption}
                        src={item.src}
                        alt={item.alt}
                        devicesize={devicesize}
                    />
                ))}
            </BorderedContainer>

            <ImageWithDescription
                desctiption="Remember, it is just the carbohydrate containing foods we need
                to count, so we do not need to count the chicken."
                src={five}
                alt="an illustration of rice, chicken, milk, and peas adding up to 75grams of total carbohydrates"
                devicesize={devicesize}
            />

            <P
                margin={devicesize === 'mobile' ? '4rem 0 4rem' : '4rem 0 8rem'}
                className={devicesize === 'mobile' ? 'body-2' : 'body-4'}
                ref={hintRef}
            >
                Total carbs for the example meal: 75g
            </P>
            {devicesize === 'mobile' && (
                <UseDesktopBanner marginBottom="4rem" />
            )}

            <Container width="100%">
                <ActivityHeader instructions="Solve the question below." />
            </Container>

            <P
                margin="3.2rem 0 4rem"
                className={devicesize === 'mobile' ? 'body-2' : 'body-4'}
            >
                Now it&apos;s your turn. How many grams of carbohydrates are in
                2.5 cups of pasta?
            </P>

            <Container
                className="flex-column-all-centered"
                backgroundColor="tertiary"
                marginBottom={
                    devicesize === 'mobile' || devicesize === 'tablet'
                        ? '0rem'
                        : '2.4rem'
                }
                marginLeft={devicesize === 'mobile' && '-2.5rem'}
                width={devicesize === 'mobile' && '100vw'}
            >
                <P
                    className={devicesize === 'mobile' ? 'body-2' : 'body-1'}
                    paddingTop="2.2rem"
                    marginBottom="2.9rem"
                    color="primary"
                    width="100%"
                    textAlign="center"
                >
                    1/2 cup = approx <Span showHint={showHint}>15 gram</Span>{' '}
                    <br />
                    <Span showHint={showHint}>5 servings</Span> of 1/2 cup is
                    2.5 cups
                </P>
            </Container>

            <Container
                marginLeft={devicesize === 'mobile' && '-2.5rem'}
                width={devicesize === 'mobile' && '100vw'}
            >
                <StyledImage
                    src={grain}
                    alt="a table showing the quantity of grains per serving, including that half a cup of cooked pasta equals one serving of carbohydrates."
                    marginBottom={devicesize === 'mobile' ? '0.8rem' : '2.4rem'}
                    borderRadius={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? '0rem'
                            : '2rem'
                    }
                    width="100%"
                />
            </Container>
            <CalorieCalculator onHintClick={onHintClick} />
        </Container>
    );
};

const BorderedContainer = styled.div`
    padding-left: ${(props) =>
        props.devicesize === 'mobile' || props.devicesize === 'tablet'
            ? '0rem'
            : '3.7rem'};
    border-left: ${(props) =>
        props.devicesize === 'mobile' || props.devicesize === 'tablet'
            ? 'none'
            : `0.3rem solid ${props.theme.colors.tertiary}`};
`;

const Span = styled.span`
    color: ${(props) =>
        props.showHint
            ? props.theme.colors.secondary
            : props.theme.colors.primary};
`;

export default CalorieCalcActivity;
