import React from 'react';
import Container from 'assets/styledComponents/Container';
import StyledImage from 'assets/styledComponents/StyledImage';
import glucometerAndKetoneSticks from 'assets/images/interactive-course8-5/glucometerAndKetoneSticks.svg';
import H3 from 'assets/styledComponents/fonts/H3';

const Step4 = () => {
    return (
        <Container className="flex-column-centered" marginTop="5rem">
            <H3 className="header-3">Good Job!</H3>
            <StyledImage
                src={glucometerAndKetoneSticks}
                alt="TODO write alt text"
            />
        </Container>
    );
};

export default Step4;
