const calculateProgress = (course) => {
    const lessonsCompleted = Number(
        Object.keys(course.lessonsCompleted).length
    );
    const progress =
        lessonsCompleted === 0
            ? '0'
            : Math.floor((lessonsCompleted / course.totalLessons) * 100) + '';
    return progress;
};

export default calculateProgress;
