import React from 'react';
import H2 from 'assets/styledComponents/fonts/H2';
import styled from '@emotion/styled';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';

const MixedFontSectionHeader = ({
    title,
    inView,
    landingPageCarouselSection,
    whyLD,
    whatIsLD,
}) => {
    let titleArr = title.split(' ');
    let firstWord = titleArr.shift();
    let remainingWords = titleArr.join(' ');

    let whatIsLDTitleArray = title.split(' ');
    let firstTwoWords = whatIsLDTitleArray.slice(0, 2).join(' ');
    let whatIsLDRemainingWords = whatIsLDTitleArray.slice(2).join(' ');

    let [globalState] = useGlobalContext();

    return (
        <TextContainer
            className={
                inView && globalState.deviceSize === 'mobile'
                    ? 'animate-text flex-column'
                    : inView
                    ? 'animate-text flex-all-centered'
                    : 'flex-all-centered'
            }
            landingPageCarouselSection={landingPageCarouselSection}
            whyLD={whyLD}
            whatIsLD={whatIsLD}
            devicesize={globalState.deviceSize}
        >
            <H2
                color="primaryDarkest"
                className={
                    globalState.deviceSize === 'mobile'
                        ? 'header-3-serif'
                        : 'header-2-serif'
                }
                marginRight="1.6rem"
                marginTop="-1rem"
            >
                {whatIsLD ? firstTwoWords : firstWord}{' '}
            </H2>
            <H2
                color="primaryDarkest"
                className={
                    globalState.deviceSize === 'mobile'
                        ? 'header-3'
                        : 'header-2'
                }
            >
                {whatIsLD ? whatIsLDRemainingWords : remainingWords}
            </H2>
        </TextContainer>
    );
};

const TextContainer = styled.div`
    width: 100%;
    margin-bottom: ${(props) =>
        props.devicesize === 'mobile'
            ? '3.2rem'
            : props.landingPageCarouselSection && props.devicesize === 'tablet'
            ? '5.6rem'
            : props.whyLD && props.devicesize === 'tablet'
            ? '6.4rem'
            : props.landingPageCarouselSection
            ? '4.8rem'
            : props.whyLD && props.devicesize === 'desktopSm'
            ? '10rem'
            : props.whatIsLD
            ? '2.4rem'
            : '14rem'};

    /* @media screen and (max-width: ${(props) =>
        props.theme.breakPoints.desktopMd}) {
        margin-bottom: 10rem;
    } */

    opacity: 0;
    transition: opacity 300ms ease-in;

    &.animate-text {
        opacity: 1;
    }
`;

export default MixedFontSectionHeader;
