import React from 'react';
import styled from '@emotion/styled';
import emailSent from '../../assets/icons/emailSent.svg';
import Header3 from '../../assets/styledComponents/fonts/Header3';
import Body2 from '../../assets/styledComponents/fonts/Body2';
import ModalOverlay from '../../assets/styledComponents/ModalOverlay';
import CloseModalButton from '../../utils/helpers/CloseModalButton';

const ResetPasswordModal = ({
    setShowModal,
    showModal,
    userEmail,
    isMobile,
}) => {
    return (
        <div>
            <ModalOverlay setShowModal={setShowModal} showModal={showModal} />
            <Container
                className="flex-column-centered card-shadow"
                isMobile={isMobile}
            >
                <CloseModalButton
                    setShowModal={setShowModal}
                    top="2rem"
                    right="2rem"
                />
                <SansSerifHeader3 className="flex-center-aligned">
                    Password reset email sent!
                    <img
                        className="paperJet"
                        src={emailSent}
                        alt="Paper jet icon"
                    />
                </SansSerifHeader3>
                <HorizontalLine />
                <Body2 color="darkText">
                    An email has been sent to {userEmail} with instructions on
                    resetting your password.
                </Body2>
            </Container>
        </div>
    );
};

const Container = styled.div`
    width: ${(props) => (props.isMobile ? '90%' : '41rem')};
    height: 36.5rem;
    background-color: ${(props) => props.theme.colors.lightBackground};
    border-radius: 2rem;
    padding: 7rem;
    position: fixed;
    top: ${(props) => (props.isMobile ? '10%' : 'calc(50vh - 18.25rem)')};
    right: ${(props) => (props.isMobile ? '5%' : 'calc(50vw - 20.5rem)')};
    z-index: 10;
`;

const SansSerifHeader3 = styled(Header3)`
    color: ${(props) => props.theme.colors.secondary};
    line-height: 1.25;
    display: inline;
    position: relative;

    img {
        &.paperJet {
            margin-left: 0.8rem;
            display: inline-block;
            margin-top: -0.5rem;
            position: absolute;
        }
    }
`;

const HorizontalLine = styled.hr`
    width: 100%;
    height: 0.4rem;
    background-color: ${(props) => props.theme.colors.secondary};
    border-radius: 3rem;
    margin: 3rem 0 4rem;
`;

export default ResetPasswordModal;
