import { React, useState } from 'react';
import Container from 'assets/styledComponents/Container';
import P from 'assets/styledComponents/fonts/P';
import InteractiveActivityButton from 'assets/styledComponents/buttons/InteractiveActivityButton';
import StyledImage from 'assets/styledComponents/StyledImage';
import redArrowDown from '../../../../../assets/icons/redArrowDown.svg';
import Button from 'assets/styledComponents/buttons/Button';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';

const BolusActivityB = () => {
    const [answer, setAnswer] = useState('');

    const [globalState] = useGlobalContext();
    const { deviceSize } = globalState;

    return (
        <Container marginTop="4.8rem">
            <P
                className={deviceSize === 'mobile' ? 'body-1' : 'header-3'}
                textAlign="center"
                marginBottom="2.2rem"
            >
                Blood glucose will go...
            </P>

            {!answer && (
                <div className="flex-all-centered">
                    <Button
                        onClick={() => setAnswer('correct')}
                        fontSize="1.4rem"
                        height="4rem"
                        borderRadius="3rem"
                        padding="1.2rem 4.8rem"
                        margin="1rem"
                        color="lightText"
                        backgroundColor="tertiaryDark"
                    >
                        Low
                    </Button>
                    <P
                        className={
                            deviceSize === 'mobile' ? 'body-1' : 'header-4'
                        }
                    >
                        or
                    </P>
                    <Button
                        onClick={() => setAnswer('incorrect')}
                        fontSize="1.4rem"
                        height="4rem"
                        borderRadius="3rem"
                        padding="1.2rem 4.8rem"
                        margin="1rem"
                        color="lightText"
                        backgroundColor="tertiaryDark"
                    >
                        High
                    </Button>
                </div>
            )}

            {answer === 'correct' ? (
                <Container
                    display="flex"
                    flexDirection="column"
                    className="flex-all-centered"
                    width="100%"
                >
                    <InteractiveActivityButton
                        isCorrect={true}
                        onClick={() => setAnswer()}
                        className="secondary"
                    />

                    <P
                        className="body-1"
                        color="success"
                        textAlign="center"
                        marginTop="2.6rem"
                        marginBottom="2.5rem"
                    >
                        Your blood glucose will likely go low.
                    </P>
                </Container>
            ) : answer === 'incorrect' ? (
                <Container
                    display="flex"
                    flexDirection="column"
                    className="flex-all-centered"
                    width="100%"
                >
                    <InteractiveActivityButton
                        isCorrect={false}
                        onClick={() => setAnswer()}
                        className={'incorrect'}
                        hasError={true}
                    />
                    <Container
                        display="flex"
                        flexDirection="column"
                        className="flex-all-centered"
                        marginTop="1rem"
                    >
                        <StyledImage
                            src={redArrowDown}
                            alt="Red arrow down"
                            marginRight="1rem"
                            marginBottom="1rem"
                        />
                        <P
                            className="body-1"
                            color="alertRed"
                            textAlign="center"
                            marginBottom="2.5rem"
                        >
                            Take a look at the next graph to find out why
                        </P>
                    </Container>
                </Container>
            ) : (
                ''
            )}
        </Container>
    );
};

export default BolusActivityB;
