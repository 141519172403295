import React from 'react';
import StyledImage from 'assets/styledComponents/StyledImage';
import step1Img from 'assets/images/interactiveBook-course2/step1Img.svg';
import P from 'assets/styledComponents/fonts/P';
import H3 from 'assets/styledComponents/fonts/H3';

const Step1Mobile = () => {
    return (
        <>
            <H3
                className="header-3"
                textAlign="left"
                width="100%"
                marginTop="4.8rem"
            >
                Step 1
            </H3>
            <StyledImage src={step1Img} />
            <P
                className="body-2"
                marginTop="2.4rem"
                color="success"
                width="100%"
            >
                Wash your hands with soap and warm water.
            </P>
            <P className="body-2">
                Any sugar from a snack or meal that is sitting on your finger
                can give a false reading. Dry your hands well as water can
                dilute the blood sample and give an inaccurate reading. You
                don’t need to use alcohol swabs to clean your finger.
            </P>
        </>
    );
};

export default Step1Mobile;
