import { React, useState } from 'react';
import { ActivityHeader } from '../SharedComponentsInteractive';
import ShowPage from './ShowPage';
import styled from '@emotion/styled';
import PageNumberTabs from './PageNumberTabs';
import course2BookPages from 'types/course2BookPages';

const BloodGlucoseCheckDesktop = ({ devicesize }) => {
    const [activePage, setActivePage] = useState(course2BookPages.INTRO);

    const onPageTabClick = (num) => {
        setActivePage(num);
    };
    return (
        <>
            <ActivityHeader
                instructions="Click through the tabs to go through the steps."
                marginBottom="4rem"
            />
            <BookBackground role="presentation">
                <DoublePage className="body-2 flex-all-centered">
                    <PageFold role="presentation" />
                    <PageNumberTabs
                        activePage={activePage}
                        onPageTabClick={onPageTabClick}
                    />
                    <ShowPage
                        activePage={activePage}
                        setActivePage={setActivePage}
                        devicesize={devicesize}
                    />
                </DoublePage>
            </BookBackground>
        </>
    );
};

const BookBackground = styled.div`
    max-width: 98rem;
    height: 58rem;
    background: ${(props) => props.theme.colors.tertiaryDark};
    position: relative;
    overflow: hidden;
    border-radius: 1rem;
    padding: 2rem 5.5rem;
    color: ${(props) => props.theme.colors.primaryDarkest};
    z-index: 0;
    &:before {
        content: '';
        position: absolute;
        width: 350%;
        height: 100%;
        border-radius: 50%;
        background: ${(props) => props.theme.colors.lightBackground};
        left: -125%;
        top: -99%;
    }

    &:after {
        content: '';
        position: absolute;
        width: 350%;
        height: 100%;
        border-radius: 50%;
        background: ${(props) => props.theme.colors.lightBackground};
        left: -125%;
        bottom: -99%;
    }
`;

const DoublePage = styled.div`
    background: ${(props) => props.theme.colors.lightBackground};
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 0.1rem;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.2);

    &:after {
        content: '';
        position: absolute;
        height: 99%;
        width: 1%;
        left: -1%;
        background: ${(props) => props.theme.colors.lightBackground};
        box-shadow: -2px 0px 3px rgba(0, 0, 0, 0.1) inset;
    }

    &:before {
        content: '';
        position: absolute;
        height: 99%;
        width: 1%;
        right: -1%;
        background: ${(props) => props.theme.colors.lightBackground};
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    }
`;

const PageFold = styled.div`
    height: 100%;
    width: 0.1rem;
    background: ${(props) => props.theme.colors.grey};
    position: absolute;
    left: 50%;
`;

export default BloodGlucoseCheckDesktop;
