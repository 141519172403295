import React from 'react';
import Container from 'assets/styledComponents/Container';
import StyledImage from 'assets/styledComponents/StyledImage';
import P from 'assets/styledComponents/fonts/P';
import todlerSchoolAge from 'assets/images/interactive-course5-8/todlerSchoolAge.svg';
import Title from './Title';
import ListItem from './ListItem';
import H1 from 'assets/styledComponents/fonts/H1';

const TodlerSchoolAge = ({ devicesize }) => {
    return (
        <Container
            className={
                devicesize === 'mobile' || devicesize === 'tablet'
                    ? 'flex-column-left'
                    : 'flex-start-aligned'
            }
            marginTop={
                devicesize === 'mobile' || devicesize === 'tablet'
                    ? '4.8rem'
                    : ''
            }
        >
            {devicesize === 'mobile' || devicesize === 'tablet' ? (
                <H1 className="header-4">Toddler-School Age</H1>
            ) : (
                ''
            )}
            <center>
                {' '}
                <StyledImage
                    src={todlerSchoolAge}
                    alt="Todler - School Age girl"
                    marginTop={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? ''
                            : '3.8rem'
                    }
                />
            </center>
            <Container
                marginLeft={
                    devicesize === 'mobile' || devicesize === 'tablet'
                        ? ''
                        : '4rem'
                }
            >
                {devicesize !== 'mobile' && devicesize !== 'tablet' ? (
                    <Title title="Toddler-School Age" />
                ) : (
                    ''
                )}

                <P
                    className={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? 'body-2'
                            : 'body-4'
                    }
                >
                    The less pressure you put on your child to eat, the more
                    likely they will eat according to their own body’s needs. At
                    this age, they are learning about boundaries. Respecting
                    their cues can reduce stress and anxiety related to eating,
                    and can help make mealtime more enjoyable and less stressful
                    for everyone!
                </P>
                <P
                    className={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? 'body-2'
                            : 'body-4'
                    }
                >
                    Tips for meal times:
                </P>
                <ul>
                    <ListItem
                        content="Keep to a structured meal and snack-time schedule the best 
                        you are able to. This is important to help your toddler feel
                        secure, knowing there is always another meal or snack in a
                        few hours."
                    />
                    <ListItem
                        content="Make mealtimes no longer than 30 minutes. Setting a time
                        limit will help establish a regular insulin bolus dosing
                        rhythm and better match insulin action. Do not pressure your child to eat, this will increase their
                        resistance. They will eat when they are hungry."
                    />
                </ul>
                <P
                    className={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? 'body-2'
                            : 'body-4'
                    }
                    marginTop={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? '2.4rem'
                            : '3.2rem'
                    }
                >
                    It is expected your toddler may eat different amounts at
                    meals and snacks, and sometimes refuse to eat altogether. At
                    times, this can make it challenging for insulin dosing.
                </P>
                <P
                    className={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? 'body-2'
                            : 'body-4'
                    }
                    marginTop={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? '2.4rem'
                            : '3.2rem'
                    }
                >
                    If you have given a pre-meal insulin bolus and they refuse a
                    meal:
                </P>
                <ul>
                    <ListItem content="Try offering the same food again in 15 minutes." />
                    <ListItem content="Offer a carbohydrate substitute of your choosing (not the child’s)." />
                    <ListItem
                        content="Avoid giving in to your child’s requests as this can set a
                        the habit of allowing them to choose the what of eating, which is the
                        caregiver’s role."
                    />
                    <ListItem content="Discuss with your care team alternative insulin dosing strategies." />
                </ul>
                <P
                    className={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? 'body-2'
                            : 'body-4'
                    }
                    marginTop={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? '2.4rem'
                            : '3.2rem'
                    }
                >
                    Plan ahead to support insulin dosing:
                </P>
                <ul>
                    <ListItem
                        content="Label food with carbohydrate counts for school assistants to
                                help insulin dosing for your child."
                    />
                    <ListItem
                        content="Discuss possible situations with your child where food may be
                offered without caregivers present. Make a plan ahead of time
                together so your child does not miss out on regular activities
                and socialization during childhood."
                    />
                </ul>
            </Container>
        </Container>
    );
};

export default TodlerSchoolAge;
