import styled from '@emotion/styled';

const ScrollContainer = styled.div`
    height: 100%;
    overflow-y: scroll;
    ::-webkit-scrollbar {
        width: 0;
        background: transparent;
    }
    width: 100%;
`;

export default ScrollContainer;
