import styled from '@emotion/styled';
import { React } from 'react';
import PageContentContainer from 'assets/styledComponents/PageMainContainer';
import ShowAllCourses from 'components/Courses/ShowAllCourses';
import Layout from 'types/layout';

const AllCoursePageNotAuthContent = ({ view, filterValue, allCourses }) => {
    return (
        <ContentContainer view={view}>
            <ShowAllCourses
                allCourses={allCourses}
                searchValue={filterValue}
                progress={'0'}
                view={view}
            />
        </ContentContainer>
    );
};

const ContentContainer = styled(PageContentContainer)`
    display: ${(props) => (props.view == Layout.CARD_VIEW ? 'flex' : '')};
    margin-top: ${(props) => props.theme.spaces.md};
    margin-bottom: ${(props) => props.theme.spaces.md};
    column-gap: ${(props) => (props.view == Layout.CARD_VIEW ? '4rem' : '')};
    flex-wrap: ${(props) => (props.view == Layout.CARD_VIEW ? 'wrap' : '')};
`;

export default AllCoursePageNotAuthContent;
