import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import { useQuery } from '@apollo/client';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';
import { GET_COURSE } from 'graphql/queries';
import chevronDownMobileNav from 'assets/icons/chevronDownMobileNav.svg';
import LessonMobileNavbarContent from './LessonMobileNavbarContent';
import ScrollContainer from 'assets/styledComponents/ScrollContainer';
import backPrimaryDarkest from 'assets/icons/backPrimaryDarkest.svg';

const LessonMobileNavbar = ({
    expandMobileNavbar,
    setExpandMobileNavbar,
    slug,
    hasCurrentLessonEnded,
    currentLesson,
    isNextLessonAvaliable,
}) => {
    const [globalState] = useGlobalContext();
    const { data: course } = useQuery(GET_COURSE, {
        variables: { slug },
    });
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(globalState.auth);
    const currentCourse = course.courses[0];

    useEffect(() => {
        setIsUserLoggedIn(globalState.auth);
        return () => {
            setIsUserLoggedIn(null);
        };
    }, [globalState]);

    const getLessons = (course) => {
        return course.mediaZone.filter((mediaZone) => mediaZone.endPage);
    };

    const lessons = getLessons(currentCourse);
    const numOfLessons = lessons.length;

    const getIsLessonCompleted = (lesson) => {
        const isLessonCompleted =
            globalState.user &&
            globalState.user.trackingLog.courseTracker[course.courses[0].id] &&
            globalState.user.trackingLog.courseTracker[course.courses[0].id]
                .lessonsCompleted[lesson.id]
                ? true
                : false;
        return isLessonCompleted;
    };

    const getIsCurrentLesson = (lesson) => {
        return (
            lesson.lessonNumber ===
            currentLesson.filter((el) => el.lessonNumber)[0].lessonNumber
        );
    };

    const currentLessonIndex = lessons.findIndex((lesson) =>
        getIsCurrentLesson(lesson)
    );

    const history = useHistory();

    return (
        <MobileNavbarOuterContainer className="flex-center-aligned flex-space-between mobile-navbar-shadow petit-title-2">
            L{currentLessonIndex + 1}.&nbsp; {currentLesson[0]?.lessonTitle}
            <button onClick={() => setExpandMobileNavbar(!expandMobileNavbar)}>
                <img src={chevronDownMobileNav} alt="chevron pointing down" />
            </button>
            <ContentListContainer
                display={expandMobileNavbar ? 'flex' : 'none'}
                numOfLessons={numOfLessons}
                onClick={() => setExpandMobileNavbar(!expandMobileNavbar)}
            >
                <div role="presentation" className="topPaddingEffect">
                    <BackButton
                        className="flex-all-centered petit-title-2"
                        onClick={() => history.push(`/modules/${slug}/0`)}
                    >
                        <img
                            src={backPrimaryDarkest}
                            alt="chevron pointing left"
                        />
                        Go back to syllabus
                    </BackButton>
                </div>

                <ScrollContainer role="presentation">
                    {lessons.map((lesson, indexToRender) => (
                        <LessonMobileNavbarContent
                            slug={slug}
                            key={lesson.id}
                            lessonTitle={lesson.lessonTitle}
                            isLessonCompleted={getIsLessonCompleted(lesson)}
                            lessonNumber={lessons.indexOf(lesson) + 1}
                            isCurrentLesson={getIsCurrentLesson(lesson)}
                            isNextLessonAvaliable={
                                indexToRender === currentLessonIndex + 1 &&
                                hasCurrentLessonEnded &&
                                isNextLessonAvaliable
                            }
                            isUserLoggedIn={isUserLoggedIn}
                            setExpandMobileNavbar={setExpandMobileNavbar}
                        />
                    ))}
                    <div role="presentation" className="bottomPaddingEffect" />
                </ScrollContainer>
            </ContentListContainer>
        </MobileNavbarOuterContainer>
    );
};

const MobileNavbarOuterContainer = styled.div`
    min-height: 5.8rem;
    width: 100%;
    background: ${(props) => props.theme.colors.tertiary};
    z-index: 2;
    padding-left: 2.4rem;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;

    button {
        background: inherit;
        /* padding to increase touch surface for usability */
        padding: 1rem 2.4rem;
    }
`;

const ContentListContainer = styled.ul`
    /* overlay effect  */
    background: rgba(0, 0, 0, 0.4);
    z-index: 2;
    display: ${(props) => props.display};
    position: absolute;
    top: 5.8rem;
    left: 0;
    right: 0;
    flex-direction: column;
    /* deduct mobile navbar and lesson navbar height for scrolling to work correctly with lesson lists exceeding 100vh */
    height: calc(100vh - 5.8rem - 6.4rem);

    div.topPaddingEffect {
        background: ${(props) => props.theme.colors.tertiary};
        padding: 3.2rem 2.8rem 1.4rem 2.4rem;
        width: 100%;
        margin-bottom: -0.2rem;
        box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.1) inset;
    }

    div.bottomPaddingEffect {
        background: ${(props) => props.theme.colors.tertiary};
        height: 2.7rem;
        margin-top: -0.1rem;
        width: 100%;
    }
`;

const BackButton = styled.button`
    background: inherit;
    border: 0.1rem solid ${(props) => props.theme.colors.primaryDarkest};
    border-radius: 3rem;
    color: ${(props) => props.theme.colors.primaryDarkest};
    text-transform: uppercase;
    width: 100%;
    :hover {
        cursor: pointer;
        opacity: 0.7;
    }
    img {
        margin-right: 0.3rem;
    }
`;

export default LessonMobileNavbar;
