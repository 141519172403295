import React from 'react';
import styled from '@emotion/styled';
import ShowProfileAvatar from '../../utils/helpers/ShowProfileAvatar';
import Header3 from '../../assets/styledComponents/fonts/Header3';
import Body1 from '../../assets/styledComponents/fonts/Body1';
import StyledImage from '../../assets/styledComponents/StyledImage';

const DashboardHomeHeader = ({ data }) => {
    return (
        <Container className="flex-center-aligned">
            <StyledImage
                width="13rem"
                height="13rem"
                borderRadius="50%"
                marginRight="2.5rem"
                objectFit="cover"
                src={ShowProfileAvatar(data)}
                alt="Avatar"
            />
            <div className="flex-column">
                <Header3 marginBottom="0.6rem">
                    Hello, {data.firstName}!{' '}
                </Header3>
                <Body1 color="greyText">
                    Welcome back aboard! We are excited to see where your
                    learning journey will take you today.
                </Body1>
            </div>
        </Container>
    );
};

const Container = styled.header`
    padding-bottom: 3rem;
`;

export default DashboardHomeHeader;
