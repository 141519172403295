import { React, useState, useRef } from 'react';
import styled from '@emotion/styled';
import Step3Calc from './Step3Calc';

import Container from 'assets/styledComponents/Container';
import P from 'assets/styledComponents/fonts/P';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';
import UseDesktopBanner from 'assets/styledComponents/UseDesktopBanner';
import table3 from 'assets/images/interactive-course8-5/table3.svg';
import table2Hint from 'assets/images/interactive-course8-5/table3Hint.svg';
import { activity2Data } from './activity2Data';
import TableActivityMobile from './TableActivityMobile';
import Step3TableActivity from './Step3TableActivity';

const Step3Activity = () => {
    const [showHint3a, setShowHint3a] = useState(false);
    const [showHint3b, setShowHint3b] = useState(false);
    const hintRef = useRef(null);
    const hintRefA = useRef(null);

    const scrollUp = () => {
        return hintRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    };

    const scrollUpA = () => {
        return hintRefA.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    };

    const handleHintClickA = (e) => {
        e.preventDefault();
        setShowHint3a(true);
        scrollUpA();
        setTimeout(() => {
            setShowHint3a(false);
        }, 3000);
    };

    const handleHintClickB = () => {
        setShowHint3b(true);
        scrollUp();
        setTimeout(() => {
            setShowHint3b(false);
        }, 3000);
    };

    // responsive vars
    let [globalState] = useGlobalContext();
    let devicesize = globalState.deviceSize;

    return (
        <Container
            marginTop={
                devicesize === 'desktopSm' || devicesize === 'desktopMd'
                    ? '5.6rem'
                    : devicesize === 'tablet'
                    ? '2.4rem'
                    : '1.6rem'
            }
            marginLeft={
                devicesize === 'desktopSm' || devicesize === 'desktopMd'
                    ? '6rem'
                    : ''
            }
        >
            {devicesize === 'mobile' || devicesize === 'tablet' ? (
                <UseDesktopBanner marginBottom="3.2rem" />
            ) : (
                ''
            )}
            <P
                ref={hintRefA}
                className={
                    devicesize === 'desktopSm' || devicesize === 'desktopMd'
                        ? 'body-4'
                        : 'body-2'
                }
                marginBottom="1.6rem"
            >
                First, try calculating by hand: Go ahead and calculate{' '}
                <StyledSpan className={showHint3a ? 'showHint3a' : ''}>
                    15%
                </StyledSpan>{' '}
                of{' '}
                <StyledSpan className={showHint3a ? 'showHint3a' : ''}>
                    22 units
                </StyledSpan>{' '}
                . Based on the calculation, what would be the calculated illness
                dose?
            </P>

            <Step3Calc handleHintClickA={handleHintClickA} />

            <P
                className={
                    devicesize === 'desktopSm' || devicesize === 'desktopMd'
                        ? 'body-4'
                        : 'body-2'
                }
                marginBottom="1.6rem"
            >
                Now, try using the table to figure out the calculated illness
                dose. What dose does it tell you to give?
            </P>

            {devicesize === 'mobile' || devicesize === 'tablet' ? (
                <TableActivityMobile
                    showHint={showHint3b}
                    devicesize={devicesize}
                    correctAnswer="31"
                    table={table3}
                    tableHint={table2Hint}
                    activityData={activity2Data}
                    hint="Correct. It says to give 3 units, which is more conservative than doing the full calculation."
                    gameId="3"
                    hintRef={hintRef}
                    handleHintClickB={handleHintClickB}
                />
            ) : (
                <Step3TableActivity
                    devicesize={devicesize}
                    showHint3b={showHint3b}
                    handleHintClickB={handleHintClickB}
                    hintRef={hintRef}
                />
            )}
        </Container>
    );
};

const StyledSpan = styled.span`
    &.showHint3a {
        color: ${(props) => props.theme.colors.success};
    }
`;

export default Step3Activity;
