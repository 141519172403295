import React from 'react';
import Container from 'assets/styledComponents/Container';
import StyledImage from 'assets/styledComponents/StyledImage';
import H3 from 'assets/styledComponents/fonts/H3';
import P from 'assets/styledComponents/fonts/P';
import step2Img2 from 'assets/images/interactiveBook-course2/step2Img2.svg';
import prevChevron from 'assets/images/interactiveBook-course2/prevChevron.svg';
import nextChevron from 'assets/images/interactiveBook-course2/nextChevron.svg';
import NextOrPrevPageButton from './NextOrPrevPageButton';

const Step2B = ({ setPageToShow, devicesize, setActivePage }) => {
    return (
        <>
            <Container className="flex-column-centered" width="50%">
                <H3 className="header-3">Step 2</H3>
                <H3 className="petit-title-2" color="tertiaryDarkest">
                    CONTINUED
                </H3>
            </Container>
            <Container
                className="flex-all-centered"
                height="100%"
                marginTop="-5rem"
            >
                <Container
                    width="50%"
                    padding={devicesize === 'tablet' ? '0 1.8rem' : '0 4rem'}
                >
                    <P marginBottom="1.5rem">
                        Your lancing device has different settings that allow
                        you to change for depth of the finger test the depth of
                        the lancet. The larger the number, the deeper the lancet
                        will puncture the skin.
                    </P>
                    <P>
                        Discuss with your diabetes team what setting to use. Set
                        the lancing device aside. It is recommended to change
                        your lancet after each finger check
                    </P>
                </Container>

                <StyledImage
                    src={step2Img2}
                    width="50%"
                    padding="2rem 4rem 0 3rem"
                />
                <NextOrPrevPageButton
                    className={'flex-all-centered next'}
                    image={nextChevron}
                    alt={'orange chevron pointing left'}
                    setActivePage={setActivePage}
                    setActivePageNum={3}
                    title={'NEXT'}
                />
            </Container>

            <NextOrPrevPageButton
                className="flex-all-centered prev"
                image={prevChevron}
                alt={'orange chevron pointing left'}
                setPageToShow={setPageToShow}
                setPageToShowNum={1}
                title={'PREV'}
            />
        </>
    );
};

export default Step2B;
