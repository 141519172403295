import styled from '@emotion/styled';
import React, { Fragment, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { client } from 'apolloClient';
import SignInButton from 'assets/styledComponents/buttons/SignInButton';
import { clearUser } from 'GlobalContext/actionVariables';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';
import useAuth from 'hooks/useAuth';
import Dropdown from './Dropdown';
import isPropValid from '@emotion/is-prop-valid';
import ModalOverlay from 'assets/styledComponents/ModalOverlay';
import ScrollToTop from 'utils/helpers/ScrollToTop';
import SignInFormWithModal from '../SignInAndSignUp/SignInFormWithModal';
import { authNavOptions, nonAuthNavOptions } from './NavItemData';
import useUpdateDeviceSize from '../../hooks/useUpdateDeviceSize';
import P from 'assets/styledComponents/fonts/P';

const DesktopNavigationItems = ({
    isLandingPage,
    isFocusOnSignIn,
    setIsFocusOnSignIn,
    showModal,
    setShowModal,
}) => {
    useAuth();
    useUpdateDeviceSize();
    const history = useHistory();
    const [state, dispatch] = useGlobalContext();
    const [showSignInForm, setShowSignInForm] = useState(true);
    const [isDropdownOpened, setIsDropdownOpened] = useState(false);

    const handleLogOut = async (e) => {
        e.preventDefault();
        history.push('/');
        localStorage.removeItem('token');
        client.resetStore();
        setIsDropdownOpened(false);
        dispatch({
            type: clearUser,
        });
    };

    const handleSignIn = () => {
        if (isLandingPage) {
            ScrollToTop();
            setIsFocusOnSignIn(true);
        } else {
            setShowModal(true);
        }
    };

    return state.auth ? (
        <Fragment>
            {/* logged in users  */}
            {authNavOptions.map((option, idx) => (
                <StyledLink
                    isLandingPage={isLandingPage}
                    to={option.href}
                    key={idx}
                    className="body-1"
                >
                    {option.title}
                </StyledLink>
            ))}
            <Dropdown
                isOpened={isDropdownOpened}
                setIsOpened={setIsDropdownOpened}
            >
                <DropdownLink
                    onClick={() => setIsDropdownOpened(false)}
                    to="/profile"
                >
                    <P className="body-1" color="greyText">
                        Profile / Settings
                    </P>
                </DropdownLink>
                <Divider />
                <DropdownLink onClick={handleLogOut} to="/">
                    <P className="body-1" color="greyText">
                        Logout
                    </P>
                </DropdownLink>
            </Dropdown>
        </Fragment>
    ) : (
        <div>
            {isLandingPage && isFocusOnSignIn ? (
                <ModalOverlay
                    isLandingPage={isLandingPage}
                    isFocusOnSignIn={isFocusOnSignIn}
                    setIsFocusOnSignIn={setIsFocusOnSignIn}
                />
            ) : (
                !isLandingPage &&
                showModal && (
                    <SignInFormWithModal
                        showModal={showModal}
                        setShowModal={setShowModal}
                        showSignInForm={showSignInForm}
                        setShowSignInForm={setShowSignInForm}
                    />
                )
            )}

            {/* Logged out users */}

            {nonAuthNavOptions.map((option, idx) => (
                <StyledLink
                    isLandingPage={isLandingPage}
                    to={option.href}
                    key={idx}
                    className="body-1"
                >
                    {option.title}
                </StyledLink>
            ))}

            <SignInButton isLandingPage={isLandingPage} onClick={handleSignIn}>
                Sign in
            </SignInButton>
        </div>
    );
};

const StyledLink = styled(NavLink, {
    shouldForwardProp: (prop) => isPropValid(prop),
})`
    margin-left: 3.5rem;
    text-decoration: none;
    background: inherit;
    color: inherit;
    :hover {
        opacity: 0.4;
    }

    :last-of-type {
        margin-left: 2.4rem;
    }
    &.active {
        color: ${(props) => props.theme.colors.primary};
    }
`;

const DropdownLink = styled(NavLink)`
    text-decoration: none;
    :hover {
        cursor: pointer;
        color: ${(props) => props.theme.colors.lightGrey};
    }
`;

const Divider = styled.hr`
    width: 16.5rem;
    border-top: 0.01em solid ${(props) => props.theme.colors.tertiary};
    margin-top: 1.2rem;
    margin-bottom: 1rem;
`;

export default DesktopNavigationItems;
