import React from 'react';
import FluidAmountTable from './FluidAmountTable';
import P from 'assets/styledComponents/fonts/P';

const FluidAmountTip = ({ description }) => {
    return (
        <>
            <P className="body-4">{description}</P>
            <FluidAmountTable />
        </>
    );
};

export default FluidAmountTip;
