import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_LANDING_PAGE_CTA_SECTION } from 'graphql/queries';
import styled from '@emotion/styled';
import H2 from 'assets/styledComponents/fonts/H2';
import FilledButtonMd from 'assets/styledComponents/buttons/FilledButtonMd';
import P from 'assets/styledComponents/fonts/P';
import ScrollToTop from 'utils/helpers/ScrollToTop';
import { useInView } from 'react-intersection-observer';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';

const CTASection = () => {
    const {
        loading: ctaLoading,
        data: ctaData,
        error: ctaError,
    } = useQuery(GET_LANDING_PAGE_CTA_SECTION);

    const [ref, inView] = useInView({
        threshold: 0.5,
        triggerOnce: true,
    });

    const [globalState] = useGlobalContext();
    let { deviceSize } = globalState;

    const isMobile = Boolean(deviceSize === 'mobile');
    const isDesktop = Boolean(
        deviceSize === 'desktopSm' || deviceSize === 'desktopMd'
    );

    if (ctaLoading) return <p>Loading cta...</p>;
    if (ctaError) return <p>cta error</p>;

    const imageURL =
        ctaData?.landingPage?.finalCTASection?.ctaSectionImage?.image[0]?.url;

    return (
        <MainContainer
            imageURL={imageURL}
            className="flex-end-aligned"
            isMobile={isMobile}
            isDesktop={isDesktop}
        >
            <ContentContainer
                ref={ref}
                inView={inView}
                className={inView ? 'animate-content' : ''}
                isMobile={isMobile}
            >
                <H2
                    className={isMobile ? 'header-3' : 'header-2'}
                    color="lightText"
                    width={isMobile ? '100%' : '42rem'}
                    marginBottom="2.8rem"
                >
                    {ctaData?.landingPage?.finalCTASection?.sectionTitle}
                </H2>
                <StyledButton
                    width="25rem"
                    marginBottom="1.6rem"
                    onClick={() => ScrollToTop()}
                >
                    {ctaData?.landingPage?.finalCTASection?.buttonText}
                </StyledButton>
                <div className="flex-center-aligned" role="presentation">
                    <P className="petit-title-2" color="lightText">
                        {ctaData?.landingPage?.finalCTASection?.questionText}
                    </P>
                    <SignIn
                        onClick={() => ScrollToTop()}
                        className="petit-title-2"
                    >
                        &nbsp; SIGN IN
                    </SignIn>
                </div>
            </ContentContainer>
        </MainContainer>
    );
};

const MainContainer = styled.section`
    width: 100%;
    height: ${(props) => (props.isDesktop ? '76rem' : '65rem')};
    margin: auto;
    background-image: url(${(props) => props.imageURL});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 39.2rem;
    padding-bottom: ${(props) => (props.isMobile ? '5.6rem' : '12.4rem')};
    overflow: hidden;
`;

const ContentContainer = styled.div`
    max-width: ${(props) => (props.isMobile ? '24rem' : '116rem')};
    margin: ${(props) => (props.isMobile ? '0 auto' : '0 14rem')};

    opacity: 0;
    transition: opacity 500ms ease-in;

    &.animate-content {
        opacity: 1;
    }
`;

const SignIn = styled.button`
    color: ${(props) => props.theme.colors.secondary};
    background: transparent;
    :hover {
        cursor: pointer;
        opacity: 0.7;
    }
`;

// exception on hover as image behind the button
const StyledButton = styled(FilledButtonMd)`
    :hover {
        opacity: 1;
        background: ${(props) => props.theme.colors.secondaryDark};
    }
`;

export default CTASection;
