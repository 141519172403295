import React from 'react';
import Container from 'assets/styledComponents/Container';
import StyledImage from 'assets/styledComponents/StyledImage';
import P from 'assets/styledComponents/fonts/P';
import allAges from 'assets/images/interactive-course5-8/allAges.svg';
import Title from './Title';
import ListItem from './ListItem';
import H1 from 'assets/styledComponents/fonts/H1';

const AllAges = ({ devicesize }) => {
    return (
        <Container
            className={
                devicesize === 'mobile' || devicesize === 'tablet'
                    ? 'flex-column-left'
                    : 'flex-start-aligned'
            }
            marginTop={
                devicesize === 'mobile' || devicesize === 'tablet'
                    ? '4.8rem'
                    : ''
            }
        >
            {devicesize === 'mobile' || devicesize === 'tablet' ? (
                <H1 className="header-4">
                    All ages recommendations and reminders:
                </H1>
            ) : (
                ''
            )}
            <center>
                <StyledImage
                    src={allAges}
                    alt="all ages"
                    marginTop={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? ''
                            : '3.8rem'
                    }
                />
            </center>
            <Container
                marginLeft={
                    devicesize === 'mobile' || devicesize === 'tablet'
                        ? ''
                        : '4rem'
                }
            >
                {devicesize !== 'mobile' && devicesize !== 'tablet' ? (
                    <Title title="All ages recommendations and reminders:" />
                ) : (
                    ''
                )}

                <P
                    className={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? 'body-2'
                            : 'body-4'
                    }
                >
                    Children with diabetes do not need different foods compared
                    to children without diabetes. There is no &quot;diabetes
                    diet&quot;.
                </P>
                <P
                    className={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? 'body-2'
                            : 'body-4'
                    }
                    marginTop={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? '2.4rem'
                            : '3.2rem'
                    }
                >
                    It is normal for the appetite to increase after a diagnosis
                    of diabetes.
                </P>
                <P
                    className={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? 'body-2'
                            : 'body-4'
                    }
                    marginTop={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? '2.4rem'
                            : '3.2rem'
                    }
                >
                    Use the division of responsibility approach to support your
                    child in eating well and developing a positive relationship
                    with food. This includes thinking about your child’s role
                    and your role at mealtimes:
                </P>
                <ul>
                    <ListItem
                        content="Your child is responsible to know if they eat, and how much to
                        eat."
                    />
                    <ListItem
                        content="You, as a parent or caregiver, are responsible for the where,
                        when, and what of feeding."
                    />
                </ul>
                <P
                    className={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? 'body-2'
                            : 'body-4'
                    }
                    marginTop={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? '2.4rem'
                            : '3.2rem'
                    }
                >
                    Tips for creating a positive relationship with food:
                </P>
                <ul>
                    <ListItem content='Avoid labelling foods as "good" or "bad", focus on the joys of eating.' />
                    <ListItem content="Serve the same foods as the rest of the family." />
                    <ListItem
                        content="Eat together when possible. When you demonstrate good
                    behaviour and enjoyment at mealtimes, your child picks up on
                    it. Adult role - modelling is the number one influence on a
                    child’s eating behaviour."
                    />
                </ul>
            </Container>
        </Container>
    );
};

export default AllAges;
