import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useMutation } from '@apollo/client';
import { UPDATE_GOALS_TRACKER } from '../graphql/mutations';
import { useGlobalContext } from '../GlobalContext/GlobalProvider';
import { saveUser } from '../GlobalContext/actionVariables';
import {
    differenceInCalendarDays,
    differenceInCalendarWeeks,
    parseISO,
    format,
} from 'date-fns';

const useIsNewDayVisited = () => {
    let location = useLocation();

    const [globalState, dispatch] = useGlobalContext();

    const [updateGoalsTracker] = useMutation(UPDATE_GOALS_TRACKER, {
        onError: (error) => console.log('An error occured ', error),
    });

    // Excluding homepage visits from adding to site interactions. Only one week's information is stored at a time. If interaction happens in a new week, current day will be saved as the first item in the daysVisited array.

    useEffect(() => {
        const lessonPath = new RegExp('modules/module.+/[1-9]+');

        let lastDateVisited =
            globalState.user &&
            globalState.user.trackingLog.goalTracker.lastDateVisited;

        let totalVisits =
            globalState.user &&
            globalState.user.trackingLog.goalTracker.totalVisitsForWeek;

        let daysVisitedBefore =
            globalState.user &&
            globalState.user.trackingLog.goalTracker.daysVisited;

        let today = new Date().toISOString();
        let todayName = format(new Date(), 'EEE');

        let daysSinceLastVisit = differenceInCalendarDays(
            parseISO(today),
            parseISO(lastDateVisited)
        );

        let diffInWeeks = differenceInCalendarWeeks(
            parseISO(today),
            parseISO(lastDateVisited),
            {
                weekStartsOn: 1,
            }
        );

        if (
            globalState.user &&
            lessonPath.test(location.pathname) &&
            daysSinceLastVisit >= 0
        ) {
            const updateGoalsData = {
                ...globalState.user.trackingLog.goalTracker,
                lastDateVisited: today,
                totalVisitsForWeek:
                    diffInWeeks > 0 || daysSinceLastVisit > 0
                        ? totalVisits + 1
                        : 1,
                daysVisited:
                    diffInWeeks > 0 || daysSinceLastVisit > 0
                        ? [...daysVisitedBefore, todayName]
                        : [todayName],
            };

            updateGoalsTracker({
                variables: {
                    id: globalState.user.trackingLogId,
                    data: {
                        goalTracker: updateGoalsData,
                    },
                },
            });

            dispatch({
                type: saveUser,
                payload: {
                    ...globalState.user,
                    trackingLog: {
                        ...globalState.user.trackingLog,
                        goalTracker: updateGoalsData,
                    },
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalState.auth, location.pathname]);

    return;
};

export default useIsNewDayVisited;
