export const Table2Data = [
    {
        id: '1',
        items: [
            'negative(-)',
            'less than 0.6 mmol/L',
            'no extra insulin',
            'give 5% of TDD',
            'give 10% of TDD',
        ],
    },
    {
        id: '2',
        items: [
            'trace (+)',
            '0.6 - 0.9 mmol/L',
            'give 5% of TDD',
            'give 5% of TDD',
            'give 10% of TDD',
        ],
    },
    {
        id: '3',
        items: [
            'small (+)',
            '1.0 - 1.4 mmol/L',
            'give 5% of TDD',
            'give 10% of TDD',
            'give 15% of TDD',
        ],
    },
    {
        id: '4',
        items: [
            'moderate (++)',
            '1.5 - 2.9 mmol/L',
            'give 10% of TDD',
            'give 15% of TDD',
            'give 20% of TDD',
        ],
    },
    {
        id: '5',
        items: [
            'large (+++/++++)',
            '3.0 mmol/L or more',
            'give 15% of TDD',
            'give 20% of TDD',
            'give 20% of TDD',
        ],
    },
];
