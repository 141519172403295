import { React, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useGlobalContext } from '../../../../../GlobalContext/GlobalProvider';
import InteractiveActivityButton from 'assets/styledComponents/buttons/InteractiveActivityButton';
import { Divider } from '../SharedComponents';
import { StyledForm } from '../../SharedComponentsInteractive';
import InputItemLabelPosition from 'types/inputItemLabelPosition';
import CalcInputItem from 'assets/styledComponents/CalcInputItem';
import CalcResult from 'assets/styledComponents/CalcResult';
import equalsPrimaryDarkest from 'assets/icons/math/equalsPrimaryDarkest.svg';
import StyledImage from 'assets/styledComponents/StyledImage';
import Container from 'assets/styledComponents/Container';
import { StyledHintButton } from '../../SharedComponentsInteractive';

const BolusCalcPreventInsulinStacking = ({ handleHintClick }) => {
    let initialFormfields = {
        carbMeal: '',
        carbRatio: '',
        result: '',
    };

    let initialErrors = {
        carbMeal: false,
        carbRatio: false,
        result: false,
    };

    const [formFields, setFormFields] = useState({
        ...initialFormfields,
    });

    const [error, setError] = useState({
        ...initialErrors,
    });

    const [carbMeal, setCarbMeal] = useState('');
    const [carbRatio, setCarbRatio] = useState('');
    const [calcResult, setCalcRes] = useState('?');
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const onChange = (event) => {
        setFormFields({
            ...formFields,
            [event.target.name]: event.target.value,
        });

        if (event.target.name === 'carbMeal') {
            setCarbMeal(event.target.value);
        } else if (event.target.name === 'carbRatio') {
            setCarbRatio(event.target.value);
        }
    };

    const resetValues = () => {
        setCarbMeal('');
        setCarbRatio('');
        setCalcRes('?');
        return;
    };

    const calculateResult = async (e) => {
        e.preventDefault();
        setHasSubmitted(true);
        let { carbMeal, carbRatio } = formFields;
        let result = (carbMeal / carbRatio).toFixed(2);
        setCalcRes(result + 'g');

        if (!allCorrect) {
            setError({
                ...initialErrors,
            });
            setFormFields({
                ...initialFormfields,
            });
            // clear input and result fields
            document
                .getElementById('prevent-insulin-stacking-calculator')
                .reset();
            resetValues();
        } else {
            setError({
                ...error,
                carbMeal: carbMeal != 26 ? true : false,
                carbRatio: carbRatio != 15 ? true : false,
                result:
                    result != 1.73 || carbMeal != 26 || carbRatio != 15
                        ? true
                        : false,
            });
        }
    };

    const handleTryAgainAfterAllCorrect = (e) => {
        e.preventDefault();
        setFormFields({
            ...initialFormfields,
        });
        // clear input and result fields
        document.getElementById('prevent-insulin-stacking-calculator').reset();
        resetValues();
    };

    // remove error message if user clears an input field
    useEffect(() => {
        if (!formFields.carbMeal.length || !formFields.carbRatio.length) {
            // for input box styling
            setHasSubmitted(false);
            setError({
                ...initialErrors,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formFields]);

    // responsive vars
    const [globalState] = useGlobalContext();
    const devicesize = globalState.deviceSize;
    const isMobile = Boolean(devicesize === 'mobile');
    const isTablet = Boolean(devicesize === 'tablet');
    const isDesktop = Boolean(!isTablet && !isMobile);

    const inputFieldWidth = isMobile ? '6.8rem' : isTablet ? '10rem' : '12rem';

    const operatorMargin = isMobile ? '1rem' : isTablet ? '1.5rem' : '4rem';
    const operatorWidth = isMobile ? '1.6rem' : isTablet ? '2rem' : '3.2rem';

    let dividerWidth = isDesktop ? '30.4rem' : '15.6rem';

    let allCorrect = !error.carbMeal && !error.carbRatio && !error.result;

    return (
        <>
            <StyledForm
                id="prevent-insulin-stacking-calculator"
                devicesize={devicesize}
            >
                <CalculatorContainer
                    className="flex-all-centered"
                    isDesktop={isDesktop}
                    isMobile={isMobile}
                >
                    <div className="flex-center-aligned">
                        <Container className="flex-column-centered">
                            <CalcInputItem
                                label="Carbs in meal"
                                labelPosition={InputItemLabelPosition.ABOVE}
                                name="carbMeal"
                                value={carbMeal}
                                inputFieldWidth={inputFieldWidth}
                                inputFieldHeight={inputFieldWidth}
                                marginBottom="2.4rem"
                                onChange={onChange}
                                hasSubmitted={hasSubmitted}
                                error={error}
                                placeholder="0g"
                                unit="g"
                            />
                            <Divider width={dividerWidth} />
                            <CalcInputItem
                                label="Carbs ratio"
                                labelPosition={InputItemLabelPosition.BELOW}
                                name="carbRatio"
                                value={carbRatio}
                                onChange={onChange}
                                error={error}
                                inputFieldWidth={inputFieldWidth}
                                inputFieldHeight={inputFieldWidth}
                                hasSubmitted={hasSubmitted}
                                placeholder="0g"
                                unit="g"
                            />
                        </Container>
                    </div>

                    <StyledImage
                        alt="equals symbol"
                        width={operatorWidth}
                        marginLeft={operatorMargin}
                        marginRight={operatorMargin}
                        src={equalsPrimaryDarkest}
                    />
                    <Container
                        className="flex-column-centered"
                        marginBottom="-3rem"
                    >
                        <CalcResult
                            label="Units"
                            name="result"
                            value={calcResult}
                            error={error}
                            hasSubmitted={hasSubmitted}
                            readOnly
                            inputFieldWidth={inputFieldWidth}
                            inputFieldHeight={inputFieldWidth}
                            background="tertiaryDark"
                            borderColor="tertiaryDark"
                            devicesize={devicesize}
                        />
                    </Container>
                </CalculatorContainer>

                {/* Buttons  */}
                <Container className="flex-all-centered">
                    {allCorrect && hasSubmitted ? (
                        <InteractiveActivityButton
                            isCorrect={true}
                            onClick={handleTryAgainAfterAllCorrect}
                            className="secondary"
                        />
                    ) : (
                        <InteractiveActivityButton
                            isCorrect={false}
                            onClick={calculateResult}
                            className={
                                !formFields.carbMeal.length ||
                                !formFields.carbRatio.length
                                    ? 'disabledBtn'
                                    : !allCorrect
                                    ? 'incorrect'
                                    : 'secondary'
                            }
                            hasError={!allCorrect}
                        />
                    )}
                    <StyledHintButton
                        padding="1.2rem 2rem"
                        onClick={(e) => handleHintClick(e)}
                    >
                        Hint
                    </StyledHintButton>
                </Container>
            </StyledForm>
        </>
    );
};

let CalculatorContainer = styled.div`
    background: ${(props) => props.theme.colors.tertiary};
    border-radius: ${({ isMobile }) => (!isMobile ? `2rem` : `none`)};
    padding: 3.4rem;
    margin-bottom: ${({ isDesktop }) => (isDesktop ? `4rem` : `2.4rem`)};
`;

export default BolusCalcPreventInsulinStacking;
