import styled from '@emotion/styled';
import React, { useState, useEffect } from 'react';
import Header2 from '../../assets/styledComponents/fonts/Header2';
import LayoutViewIcons from '../../assets/styledComponents/LayoutViewIcons';
import PageContentContainer from '../../assets/styledComponents/PageContentContainer';
import Layout from '../../types/layout';
import TracksPageCourses from './TracksPageCourses';

const ShowTrackCourses = ({ data, isDesktopMd }) => {
    const [view, setView] = useState(Layout.CARD_VIEW);

    let track = data.learningTracks[0];

    const sortArr = [...track.courses];

    let sorted = sortArr?.sort((a, b) => {
        return parseInt(a.slug.slice(-1)) - parseInt(b.slug.slice(-1));
    });

    useEffect(() => {
        !isDesktopMd && setView(Layout.CARD_VIEW);
    }, [isDesktopMd]);
    return (
        <ShowTrackCoursesContainer className="flex-column-centered">
            <PageContentContainer marginLeft="8.8rem" marginRight="8.8rem">
                <header>
                    <Header2 color="secondary" textAlign="center">
                        MODULES
                    </Header2>
                    {isDesktopMd && (
                        <LayoutViewIconsContainer>
                            <LayoutViewIcons setView={setView} view={view} />
                        </LayoutViewIconsContainer>
                    )}
                </header>
                <TracksPageCourses trackCourses={sorted} view={view} />
            </PageContentContainer>
        </ShowTrackCoursesContainer>
    );
};

const ShowTrackCoursesContainer = styled.div`
    padding-top: 6rem;
    padding-bottom: 10rem;
    background-color: ${(props) => props.theme.colors.tertiary};
`;

const LayoutViewIconsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin: 1.3rem 0 1.5rem;
`;

export default ShowTrackCourses;
