const handleSubmitSignIn = (
    event,
    formFields,
    updateAuth,
    dispatch,
    loadUserData,
    setErrorMessage
) => {
    event.preventDefault();
    const signInURL = `${process.env.REACT_APP_API_URL}/auth/local`;

    const data = {
        identifier: formFields.email,
        password: formFields.password,
    };

    const signIn = async (url, data) => {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        return response.json();
    };

    signIn(signInURL, data).then((res) => {
        if (!res.statusCode) {
            const token = res.jwt;
            localStorage.setItem('token', token);
            dispatch({ type: updateAuth, payload: true });

            window.dataLayer.push({
                event: 'signin',
                userId: res.user.id,
            });

            loadUserData(res.user.id);
        } else {
            console.log(res.message[0].messages[0].message);
            setErrorMessage('Email or password is incorrect');
        }
    });
};

export default handleSubmitSignIn;
