import React from 'react';
import Container from 'assets/styledComponents/Container';
import StyledImage from 'assets/styledComponents/StyledImage';
import P from 'assets/styledComponents/fonts/P';
import months12_24 from 'assets/images/interactive-course5-8/months12_24.svg';
import Title from './Title';
import H1 from 'assets/styledComponents/fonts/H1';

const Months12_24 = ({ devicesize }) => {
    return (
        <Container
            className={
                devicesize === 'mobile' || devicesize === 'tablet'
                    ? 'flex-column-left'
                    : 'flex-start-aligned'
            }
            marginTop={
                devicesize === 'mobile' || devicesize === 'tablet'
                    ? '4.8rem'
                    : ''
            }
        >
            {devicesize === 'mobile' || devicesize === 'tablet' ? (
                <H1 className="header-4">12-24 months old</H1>
            ) : (
                ''
            )}
            <center>
                {' '}
                <StyledImage
                    src={months12_24}
                    alt="12-24 months boy"
                    marginTop={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? ''
                            : '3.8rem'
                    }
                />
            </center>
            <Container
                marginLeft={
                    devicesize === 'mobile' || devicesize === 'tablet'
                        ? ''
                        : '4rem'
                }
            >
                {devicesize !== 'mobile' && devicesize !== 'tablet' ? (
                    <Title title="12-24 months old" />
                ) : (
                    ''
                )}

                <P
                    className={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? 'body-2'
                            : 'body-4'
                    }
                >
                    Having structured meal and snack times, rather than grazing
                    on snacks all throughout the day, can help with your
                    toddler’s blood glucose and insulin management.
                </P>
                <P
                    className={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? 'body-2'
                            : 'body-4'
                    }
                    marginTop={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? '2.4rem'
                            : '3.2rem'
                    }
                >
                    At this age, pre-meal food insulin boluses may be
                    challenging to give, as your child may eat different amounts
                    at each meal or each day, making it hard to predict how much
                    they will eat. Discuss with your care team if this becomes
                    challenging.
                </P>
                <P
                    className={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? 'body-2'
                            : 'body-4'
                    }
                    marginTop={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? '2.4rem'
                            : '3.2rem'
                    }
                >
                    If your child does not eat, or does not eat the
                    carbohydrates planned, but you have already given pre-meal
                    insulin, try the food again 15 minutes later. If that is
                    unsuccessful, try a substitute carbohydrate. If this happens
                    consistently, discuss options with your care team.
                </P>
            </Container>
        </Container>
    );
};

export default Months12_24;
