import React from 'react';
import styled from '@emotion/styled';
import H1 from 'assets/styledComponents/fonts/H1';

const NamedSectionContainer = ({
    title,
    gridAreaName,
    height,
    minHeight,
    width,
    isOnDashboardHomePage,
    children,
    padding,
    devicesize,
    headerPadding,
}) => {
    return (
        <SectionContainer
            className="card-shadow"
            gridAreaName={gridAreaName}
            minHeight={minHeight}
            height={height}
            width={width}
            isOnDashboardHomePage={isOnDashboardHomePage}
            padding={padding}
            devicesize={devicesize}
        >
            <H1
                className="petit-title-1"
                textTransform="uppercase"
                isOnDashboardHomePage={isOnDashboardHomePage}
                padding={headerPadding}
            >
                {title}
            </H1>

            <Content
                isOnDashboardHomePage={isOnDashboardHomePage}
                className={gridAreaName}
                devicesize={devicesize}
            >
                {children}
            </Content>
        </SectionContainer>
    );
};

const SectionContainer = styled.div`
    width: ${(props) => props.width};
    min-height: ${(props) => props.minHeight};
    height: ${(props) => props.height};
    border-radius: 2rem;
    background-color: ${(props) => props.theme.colors.lightBackground};
    padding: ${(props) => props.padding};
`;

const Content = styled.div`
    height: calc(100% - 5rem);
    &.thisWeek {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: ${(props) =>
            props.isOnDashboardHomePage && props.devicesize !== 'mobile'
                ? '4rem 3rem 3.4rem 3rem'
                : props.devicesize === 'mobile'
                ? '4.2rem 0rem'
                : '4.6rem 0rem 5rem 0rem'};
    }

    &.lastAcquiredBadge {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        paddin-bottom: 4rem;
    }

    &.myBadges {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-align: center;
        padding: ${(props) =>
            props.devicesize === 'mobile' ? '2rem' : '3rem 5.5rem 5rem 5.5rem'};
        flex-wrap: wrap;
        row-gap: 2rem;
        column-gap: 0.8rem;
        max-width: 105rem;
        margin: 0 auto;
    }
`;

export default NamedSectionContainer;
