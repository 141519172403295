import React from 'react';
import styled from '@emotion/styled';
import H3 from 'assets/styledComponents/fonts/H3';
import StyledImage from 'assets/styledComponents/StyledImage';
import step1Img from 'assets/images/interactiveBook-course2/step1Img.svg';
import P from 'assets/styledComponents/fonts/P';
import Container from 'assets/styledComponents/Container';
import { PageContainer } from './SharedComponents';
import nextChevron from 'assets/images/interactiveBook-course2/nextChevron.svg';
import prevChevron from 'assets/images/interactiveBook-course2/prevChevron.svg';
import NextOrPrevPageButton from './NextOrPrevPageButton';

const Step1 = ({ devicesize, setActivePage }) => {
    return (
        <PageContainer>
            <H3 className="header-3" textAlign="center" width="50%">
                Step 1
            </H3>
            <Container className="flex-all-centered" height="100%">
                <StyledImage
                    src={step1Img}
                    width="50%"
                    padding="0 3.5rem 0 6rem"
                />
                <NextOrPrevPageButton
                    className="flex-all-centered prev"
                    image={prevChevron}
                    alt={'orange chevron pointing left'}
                    setActivePage={setActivePage}
                    setActivePageNum={0}
                    title={'PREV'}
                />

                <Container
                    width="50%"
                    padding={devicesize === 'tablet' ? '0 2rem' : '0 4rem'}
                >
                    <P>
                        <Span>Wash your hands with soap and warm water.</Span>{' '}
                        Any sugar from a snack or meal that is sitting on your
                        finger can give a false reading. Dry your hands well as
                        water can dilute the blood sample and give an inaccurate
                        reading. You don’t need to use alcohol swabs to clean
                        your finger.
                    </P>
                </Container>
            </Container>
            <NextOrPrevPageButton
                className={'flex-all-centered next'}
                image={nextChevron}
                alt={'orange chevron pointing right'}
                setActivePage={setActivePage}
                setActivePageNum={2}
                title={'NEXT'}
            />
        </PageContainer>
    );
};

const Span = styled.span`
    color: ${(props) => props.theme.colors.success};
`;

export default Step1;
