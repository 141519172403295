const course2BookPages = Object.freeze({
    INTRO: 0,
    ONE: 1,
    TWO: 2,
    THREE: 3,
    FOUR: 4,
    FIVE: 5,
});

export default course2BookPages;
