import React from 'react';
import styled from '@emotion/styled';
import ResourceActivity from './ResourceActivity';
import H2 from 'assets/styledComponents/fonts/H2';
import P from 'assets/styledComponents/fonts/P';
import NutritionApps from './NutritionApps';
import CalorieKingActivity from './CalorieKingActivity';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';
import UseDesktopBanner from 'assets/styledComponents/UseDesktopBanner';

const CarbAppsAndResources = () => {
    let [globalState] = useGlobalContext();
    let devicesize = globalState.deviceSize;
    return (
        <div>
            <H2
                className={devicesize === 'mobile' ? 'header-4' : 'header-3'}
                color="darkText"
                marginBottom={devicesize === 'mobile' ? '2.4rem' : '3.2rem'}
                marginTop={devicesize === 'mobile' ? '4.2rem' : '4.8rem'}
            >
                BC Children’s Carbohydrate Counting Resource:
            </H2>
            <P
                className="body-4"
                marginBottom={devicesize === 'mobile' ? '2.4rem' : '4.8rem'}
            >
                One tool that can be helpful to start carbohydrate counting is
                the{' '}
                <AnchorTag
                    href="http://www.bcchildrens.ca/endocrinology-diabetes-site/documents/carbresource.pdf"
                    target="_blank"
                >
                    BC Children’s Hospital Carbohydrate Counting Resource.
                </AnchorTag>{' '}
                Take some time to read through the instructions for using the
                poster.
            </P>
            {devicesize === 'mobile' && (
                <UseDesktopBanner marginBottom="3.2rem" />
            )}
            <ResourceActivity devicesize={devicesize} />
            <NutritionApps devicesize={devicesize} />
            <CalorieKingActivity devicesize={devicesize} />
        </div>
    );
};

const AnchorTag = styled.a`
    color: ${(props) => props.theme.colors.primary};
    text-decoration: none;

    :hover {
        cursor: pointer;
    }
`;

export default CarbAppsAndResources;
