import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import P from 'assets/styledComponents/fonts/P';
import {
    CalculatorContainer,
    StyledForm,
    StyledHintButton,
} from '../../SharedComponentsInteractive';
import StyledImage from 'assets/styledComponents/StyledImage';
import berries from 'assets/images/interactive-course5-2/berries.svg';
import equalsPrimaryDarkest from 'assets/icons/math/equalsPrimaryDarkest.svg';
import minusPrimaryDarkest from 'assets/icons/math/minusPrimaryDarkest.svg';
import Container from 'assets/styledComponents/Container';
import InteractiveActivityButton from 'assets/styledComponents/buttons/InteractiveActivityButton';
import CalcInputItem from 'assets/styledComponents/CalcInputItem';
import InputItemLabelPosition from 'types/inputItemLabelPosition';
import CalcResult from 'assets/styledComponents/CalcResult';
import hint2A from 'assets/images/interactive-course5-2/hint2A.svg';
import hint2B from 'assets/images/interactive-course5-2/hint2B.svg';
import HintModal from '../../HintModal';

const ActivityCalc = ({ devicesize }) => {
    let initialFormfields = {
        carbs: '',
        fibre: '',
        result: '',
    };

    let initialErrors = {
        carbs: false,
        fibre: false,
        result: false,
    };

    const [formFields, setFormFields] = useState({
        ...initialFormfields,
    });

    const [error, setError] = useState({
        ...initialErrors,
    });

    const [carbVal, setCarbVal] = useState('');
    const [fibreVal, setFibreVal] = useState('');
    const [calcResult, setCalcRes] = useState('?');
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const onChange = (event) => {
        setFormFields({
            ...formFields,
            [event.target.name]: event.target.value,
        });

        if (event.target.name === 'carbs') {
            setCarbVal(event.target.value);
        } else if (event.target.name === 'fibre') {
            setFibreVal(event.target.value);
        }
    };

    const calculateResult = async (e) => {
        e.preventDefault();
        setHasSubmitted(true);
        let { carbs, fibre } = formFields;
        let result = (+carbs - +fibre).toFixed(2);
        setCalcRes(result + 'g');

        if (error.carbs || error.fibre || error.result) {
            setError({
                ...initialErrors,
            });
            setFormFields({
                ...initialFormfields,
            });
            // clear input and result fields
            document.getElementById('calorie-king-calculator-2').reset();
            setCarbVal('');
            setFibreVal('');
            setCalcRes('?');
        } else {
            setError({
                ...error,
                carbs: carbs != 4.5 ? true : false,
                fibre: fibre != 2.5 ? true : false,
                // both inputs and the result have to be correct for the result to be correct
                result:
                    result != 2 || carbs != 4.5 || fibre != 2.5 ? true : false,
            });
        }
    };

    const handleTryAgainAfterAllCorrect = (e) => {
        e.preventDefault();
        setFormFields({
            ...initialFormfields,
        });
        // clear input and result fields
        document.getElementById('calorie-king-calculator-2').reset();
        setCarbVal('');
        setFibreVal('');
        setCalcRes('?');
    };

    // remove error message if user clears an input field
    useEffect(() => {
        if (!formFields.carbs.length || !formFields.fibre.length) {
            setHasSubmitted(false);
            setError({
                ...error,
                carbs: false,
                fibre: false,
                result: false,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formFields]);

    const [showModal, setShowModal] = useState(false);

    // Prevent background content scrolling when modal open
    if (showModal) {
        document.body.style.overflow = 'hidden';
    } else if (!showModal) {
        document.body.style.overflow = 'unset';
    }

    let inputFieldWidth =
        devicesize === 'mobile'
            ? '6.8rem'
            : devicesize === 'tablet'
            ? '10rem'
            : '12rem';

    let inputFieldHeight =
        devicesize === 'mobile'
            ? '6.8rem'
            : devicesize === 'tablet'
            ? '10rem'
            : '12rem';

    let operatorMargin =
        devicesize === 'mobile'
            ? '1rem'
            : devicesize === 'tablet'
            ? '1.5rem'
            : '4rem';
    let operatorWidth =
        devicesize === 'mobile'
            ? '1.6rem'
            : devicesize === 'tablet'
            ? '2rem'
            : '3.2rem';

    let allCorrect = !error.carbs && !error.fibre && !error.result;

    const onHintClick = (e) => {
        e.preventDefault();
        setShowModal(true);
    };

    return (
        <>
            {showModal && (
                <HintModal
                    setShowModal={setShowModal}
                    showModal={showModal}
                    imgA={hint2A}
                    altA="a screenshot of search results for raspberries with a green circle around the 'raspberries, raw' search result"
                    maxHeightA="21rem"
                    imgB={hint2B}
                    altB="a screenshot of search results for raspberries with green circles around the '20 berry serving size' and '4.5g carbs and 2.5g fiber' fields"
                    maxHeightB="25rem"
                />
            )}

            <InstructionsContainer
                className="flex-column-all-centered body-1"
                devicesize={devicesize}
            >
                <StepNumber
                    className="flex-all-centered"
                    devicesize={devicesize}
                >
                    4
                </StepNumber>
                <P
                    className={devicesize === 'mobile' ? 'body-4' : 'body-1'}
                    marginRight="4rem"
                    marginBottom="2rem"
                >
                    Now let’s change the serving to <span>per berry.</span>{' '}
                    <br />
                    And <span>enter 20 berries</span> as the quantity.
                </P>
            </InstructionsContainer>

            <StyledForm id="calorie-king-calculator-2" devicesize={devicesize}>
                <CalculatorContainer
                    className="flex-all-centered"
                    devicesize={devicesize}
                    background="tertiary"
                >
                    {devicesize === 'mobile' ? (
                        ''
                    ) : (
                        <Container
                            className="flex-column-centered"
                            marginRight={
                                devicesize === 'tablet' ? '2rem' : '6.4rem'
                            }
                        >
                            <StyledImage
                                src={berries}
                                alt="An illustration of a 20 berries"
                                height={
                                    devicesize === 'tablet' ? '8rem' : '10.4rem'
                                }
                                marginBottom={
                                    devicesize === 'tablet'
                                        ? '3.6rem'
                                        : '1.6rem'
                                }
                            />
                            <P className="body-1" color="primaryDarkest">
                                20 berries
                            </P>
                        </Container>
                    )}

                    <div className="flex-column-centered">
                        <CalcInputItem
                            label="Carbs"
                            labelPosition={InputItemLabelPosition.BELOW}
                            name="carbs"
                            value={carbVal}
                            onChange={onChange}
                            error={error}
                            hasSubmitted={hasSubmitted}
                            inputFieldWidth={inputFieldWidth}
                            inputFieldHeight={inputFieldHeight}
                            placeholder="0g"
                            unit="g"
                        />
                    </div>
                    <StyledImage
                        alt="minussymbol"
                        width={operatorWidth}
                        marginTop="-3rem"
                        marginLeft={operatorMargin}
                        marginRight={operatorMargin}
                        src={minusPrimaryDarkest}
                    />
                    <div className="flex-column-centered">
                        <CalcInputItem
                            label="Fibre"
                            labelPosition={InputItemLabelPosition.BELOW}
                            name="fibre"
                            value={fibreVal}
                            onChange={onChange}
                            error={error}
                            hasSubmitted={hasSubmitted}
                            inputFieldWidth={inputFieldWidth}
                            inputFieldHeight={inputFieldHeight}
                            placeholder="0g"
                            unit="g"
                        />
                    </div>

                    <StyledImage
                        alt="equals symbol"
                        width={operatorWidth}
                        marginTop="-3rem"
                        marginLeft={operatorMargin}
                        marginRight={operatorMargin}
                        src={equalsPrimaryDarkest}
                    />
                    <div className="flex-column-centered">
                        <CalcResult
                            label="Carbs"
                            name="result"
                            value={calcResult}
                            error={error}
                            hasSubmitted={hasSubmitted}
                            inputFieldWidth={inputFieldWidth}
                            inputFieldHeight={inputFieldHeight}
                            borderColor="tertiaryDark"
                            background="tertiaryDark"
                            devicesize={devicesize}
                        />
                    </div>
                </CalculatorContainer>

                {/* Buttons */}
                <Container className="flex-all-centered">
                    {allCorrect && hasSubmitted ? (
                        <InteractiveActivityButton
                            isCorrect={true}
                            onClick={handleTryAgainAfterAllCorrect}
                            className="secondary"
                        />
                    ) : (
                        <InteractiveActivityButton
                            isCorrect={false}
                            onClick={calculateResult}
                            className={
                                !formFields.carbs.length ||
                                !formFields.fibre.length
                                    ? 'disabledBtn'
                                    : error.carbs || error.fibre || error.result
                                    ? 'incorrect'
                                    : 'secondary'
                            }
                            hasError={
                                error.carbs || error.fibre || error.result
                            }
                        />
                    )}
                    <StyledHintButton
                        onClick={(e) => onHintClick(e)}
                    ></StyledHintButton>
                </Container>
            </StyledForm>
        </>
    );
};

const InstructionsContainer = styled.div`
    margin-bottom: -3rem;
    text-align: center;
    margin-top: 4rem;
    border-top: ${(props) =>
        props.devicesize === 'mobile'
            ? `0.3rem solid ${props.theme.colors.tertiary}`
            : ''};

    p {
        span {
            color: ${(props) => props.theme.colors.secondary};
        }
    }
`;

const StepNumber = styled.div`
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background: ${(props) => props.theme.colors.secondary};
    color: ${(props) => props.theme.colors.lightText};
    margin-top: ${(props) =>
        props.devicesize === 'mobile' ? '4rem' : '0.8rem'};
    margin-bottom: 1.6rem;
`;

export default ActivityCalc;
