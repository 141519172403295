import React from 'react';
import { ActivityHeader } from '../../SharedComponentsInteractive';
import Container from 'assets/styledComponents/Container';

const ActivityHeaderAndHint = ({ instructions, link, devicesize }) => {
    return (
        <Container
            className="flex-space-between"
            marginBottom={devicesize === 'mobile' ? '1.6rem' : '3rem'}
        >
            <ActivityHeader instructions={instructions} link={link} />
        </Container>
    );
};

export default ActivityHeaderAndHint;
