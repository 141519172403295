import { React, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import InteractiveActivityButton from '../../../../../assets/styledComponents/buttons/InteractiveActivityButton';
import {
    StyledForm,
    StyledHintButton,
} from '../../SharedComponentsInteractive';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';
import StyledImage from 'assets/styledComponents/StyledImage';
import minusPrimaryDarkest from 'assets/icons/math/minusPrimaryDarkest.svg';
import equalsPrimaryDarkest from 'assets/icons/math/equalsPrimaryDarkest.svg';
import openBracket from 'assets/icons/math/openBracket.svg';
import closeBracket from 'assets/icons/math/closeBracket.svg';
// import openBracketNonDesktop from 'assets/icons/math/openBracketNonDesktop.svg';
// import closeBracketNonDesktop from 'assets/icons/math/closeBracketNonDesktop.svg';
import Container from 'assets/styledComponents/Container';
import { Divider, StepTitle } from '../SharedComponents';
import CalcInputItem from 'assets/styledComponents/CalcInputItem';
import InputItemLabelPosition from 'types/inputItemLabelPosition';
import CalcResult from 'assets/styledComponents/CalcResult';

const BolusCalcStep1 = ({ setShowHint1, scrollUp }) => {
    let initialFormfields = {
        currentBG: '',
        targetBG: '',
        isf: '',
        result: '',
    };

    let initialErrors = {
        currentBG: false,
        targetBG: false,
        isf: false,
        result: false,
    };

    const [formFields, setFormFields] = useState({
        ...initialFormfields,
    });

    const [error, setError] = useState({
        ...initialErrors,
    });

    const [currentBG, setCurrentBG] = useState('');
    const [targetBG, setTargetBG] = useState('');
    const [isf, setIsf] = useState('');
    const [calcResult, setCalcRes] = useState('?');
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const onChange = (e) => {
        setFormFields({
            ...formFields,
            [e.target.name]: e.target.value,
        });
        if (e.target.name === 'currentBG') {
            setCurrentBG(e.target.value);
        } else if (e.target.name === 'targetBG') {
            setTargetBG(e.target.value);
        } else if (e.target.name === 'isf') {
            setIsf(e.target.value);
        }
    };

    const calculateResult = (e) => {
        e.preventDefault();
        setHasSubmitted(true);
        let { currentBG, targetBG, isf } = formFields;

        let result = ((currentBG - targetBG) / isf).toFixed(2);
        setCalcRes(result);
        if (error.currentBG || error.targetBG || error.isf || error.result) {
            setError({
                ...initialErrors,
            });
            setFormFields({
                ...initialFormfields,
            });
            // clear input and result fields
            setCurrentBG('');
            setTargetBG('');
            setIsf('');
            setCalcRes('?');
        } else {
            setError({
                ...error,
                currentBG: currentBG != 12.1 ? true : false,
                targetBG: targetBG != 6 ? true : false,
                isf: isf != 3 ? true : false,
                // all inputs and the result have to be correct for the result to be correct
                result:
                    result != 2.03 ||
                    currentBG != 12.1 ||
                    isf != 3 ||
                    targetBG != 6
                        ? true
                        : false,
            });
        }
    };

    const handleTryAgainAfterAllCorrect = (e) => {
        e.preventDefault();
        setFormFields({
            ...initialFormfields,
        });
        // clear input and result fields
        setCurrentBG('');
        setTargetBG('');
        setIsf('');
        setCalcRes('?');
    };

    // remove error message if user clears an input field
    useEffect(() => {
        if (
            !formFields.currentBG.length ||
            !formFields.targetBG.length ||
            !formFields.isf.length
        ) {
            setHasSubmitted(false);

            // for submit button text
            setError({
                ...error,
                currentBG: false,
                targetBG: false,
                isf: false,
                result: false,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formFields]);

    const handleHintClick = (e) => {
        e.preventDefault();
        setShowHint1(true);
        scrollUp();
        setTimeout(() => {
            setShowHint1(false);
        }, 3000);
    };

    // responsive vars
    const [globalState] = useGlobalContext();
    const devicesize = globalState.deviceSize;
    const isTablet = Boolean(devicesize === 'tablet');
    const isMobile = Boolean(devicesize === 'mobile');
    const isDesktop = Boolean(!isTablet && !isMobile);

    const inputFieldWidth = isMobile ? '6.8rem' : isTablet ? '10rem' : '12rem';
    const operatorMargin = isMobile ? '1rem' : isTablet ? '1.5rem' : '4rem';
    const operatorWidth = isMobile ? '1.6rem' : isTablet ? '2rem' : '3.2rem';
    const bracketWidth = isMobile ? '1.4rem' : '3.2rem';

    let allCorrect =
        !error.currentBG && !error.targetBG && !error.isf && !error.result;

    return (
        <>
            <Container
                width="100%"
                className="flex-space-between"
                marginBottom="4rem"
            >
                <StepTitle
                    isMobile={isMobile}
                    isDesktop={isDesktop}
                    stepNumber="1"
                    instruction="Insulin to correct high blood glucose (correction bolus)"
                />
            </Container>

            <StyledForm>
                <CalculatorContainer
                    className="flex-all-centered"
                    isMobile={isMobile}
                >
                    <div className="flex-column-centered">
                        <div className="flex-center-aligned">
                            <StyledImage
                                alt="open bracket"
                                width={bracketWidth}
                                marginTop={'2rem'}
                                marginBottom={isMobile ? '0' : '1.6rem'}
                                src={openBracket}
                            />
                            <div className="flex-column-centered">
                                <CalcInputItem
                                    label="Current BG"
                                    labelPosition={InputItemLabelPosition.ABOVE}
                                    name="currentBG"
                                    value={currentBG}
                                    onChange={onChange}
                                    error={error}
                                    hasSubmitted={hasSubmitted}
                                    inputFieldWidth={inputFieldWidth}
                                    inputFieldHeight={inputFieldWidth}
                                    placeholder="0"
                                    unit=""
                                />
                            </div>
                            <StyledImage
                                alt="subtraction symbol"
                                width={operatorWidth}
                                marginTop={isDesktop ? '2rem' : '2.6rem'}
                                marginLeft={operatorMargin}
                                marginRight={operatorMargin}
                                src={minusPrimaryDarkest}
                            />
                            <div className="flex-column-centered">
                                <CalcInputItem
                                    label="Target BG"
                                    labelPosition={InputItemLabelPosition.ABOVE}
                                    name="targetBG"
                                    value={targetBG}
                                    onChange={onChange}
                                    error={error}
                                    hasSubmitted={hasSubmitted}
                                    inputFieldWidth={inputFieldWidth}
                                    inputFieldHeight={inputFieldWidth}
                                    placeholder="0"
                                    unit=""
                                />
                            </div>
                            <StyledImage
                                alt="close bracket"
                                width={bracketWidth}
                                marginTop={'2rem'}
                                marginBottom={isMobile ? '0' : '1.6rem'}
                                src={closeBracket}
                            />
                        </div>

                        <Divider
                            marginBottom={isDesktop ? '2.4rem' : '1.2rem'}
                        />

                        <div className="flex-column-centered">
                            <CalcInputItem
                                label="ISF"
                                labelPosition={InputItemLabelPosition.BELOW}
                                name="isf"
                                value={isf}
                                onChange={onChange}
                                error={error}
                                hasSubmitted={hasSubmitted}
                                inputFieldWidth={inputFieldWidth}
                                inputFieldHeight={inputFieldWidth}
                                placeholder="0"
                                unit=""
                            />
                        </div>
                    </div>
                    <StyledImage
                        alt="equals symbol"
                        width={operatorWidth}
                        marginTop={isDesktop ? '-1rem' : '0rem'}
                        marginLeft={operatorMargin}
                        marginRight={operatorMargin}
                        src={equalsPrimaryDarkest}
                    />

                    <div className="flex-column-centered">
                        <CalcResult
                            label="Units"
                            name="result"
                            value={calcResult}
                            error={error}
                            hasSubmitted={hasSubmitted}
                            inputFieldWidth={inputFieldWidth}
                            inputFieldHeight={inputFieldWidth}
                            marginTop={isDesktop ? '2.4rem' : '3.6rem'}
                            borderColor="pinkDark"
                            background="pinkDark"
                            devicesize={devicesize}
                        />
                    </div>
                </CalculatorContainer>

                <Container marginBottom="4.8rem" className="flex-all-centered">
                    {allCorrect && hasSubmitted ? (
                        <InteractiveActivityButton
                            isCorrect={true}
                            onClick={handleTryAgainAfterAllCorrect}
                            className="secondary"
                        />
                    ) : (
                        <InteractiveActivityButton
                            isCorrect={false}
                            onClick={calculateResult}
                            className={
                                !formFields.currentBG.length ||
                                !formFields.targetBG.length ||
                                !formFields.isf.length
                                    ? 'disabledBtn'
                                    : !allCorrect
                                    ? 'incorrect'
                                    : 'secondary'
                            }
                            hasError={!allCorrect}
                        />
                    )}
                    <StyledHintButton onClick={(e) => handleHintClick(e)} />
                </Container>
            </StyledForm>
        </>
    );
};

let CalculatorContainer = styled.div`
    background: ${(props) => props.theme.colors.pink};
    padding: ${({ isMobile }) =>
        isMobile ? `2.4rem 0` : `2rem 3.5rem 2rem 3.9rem`};
    border-radius: ${({ isMobile }) => (isMobile ? `none` : `2rem`)};
    width: ${({ isMobile }) => (isMobile ? `100vw` : `100%`)};
    margin-left: ${({ isMobile }) => (isMobile ? `-2.5rem` : `0`)};
    margin-bottom: 4rem;
`;

export default BolusCalcStep1;
