import React from 'react';
import FilledButtonLg from '../../assets/styledComponents/buttons/FilledButtonLg';
import LinkWrappingButton from '../../assets/styledComponents/buttons/LinkWrappingButton';

const HomeButton = () => {
    return (
        <LinkWrappingButton to="/">
            <FilledButtonLg width="22rem" marginTop="5rem">
                Go Home
            </FilledButtonLg>
        </LinkWrappingButton>
    );
};

export default HomeButton;
