import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import ScrollToTop from 'utils/helpers/ScrollToTop';
import logoWithText from 'assets/images/navbar/logoWithText.svg';
import logo from 'assets/images/navbar/logo.svg';
import DesktopNavigationItems from './DesktopNavItems';
import HamburgerMenu from './HamburgerMenu';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';

const NavBar = () => {
    const [isLandingPage, setIsLandingPage] = useState(false);
    const [isFocusOnSignIn, setIsFocusOnSignIn] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isMobileNavbarOpen, setIsMobileNavbarOpen] = useState(false);
    const [showMobileSignInForm, setShowMobileSignInForm] = useState(false);
    const [showMobileSignUpForm, setShowMobileSignUpForm] = useState(false);

    let location = useLocation();

    let [globalState] = useGlobalContext();

    useEffect(() => {
        setIsLandingPage(location.pathname === '/');
    }, [location]);

    // Prevent background content scrolling when modal open or landing page signin is focused
    if (
        isFocusOnSignIn ||
        showModal ||
        isMobileNavbarOpen ||
        showMobileSignInForm ||
        showMobileSignUpForm
    ) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = 'unset';
    }

    return (
        <MainContainer
            isLandingPage={isLandingPage}
            isFocusOnSignIn={isFocusOnSignIn}
            devicesize={globalState.deviceSize}
            className={
                globalState.deviceSize === 'mobile' ||
                globalState.deviceSize === 'tablet'
                    ? 'mobile-navbar-shadow'
                    : ''
            }
        >
            <NavBarContainer
                devicesize={globalState.deviceSize}
                className="flex-space-between"
            >
                <NavLink to="/" onClick={ScrollToTop}>
                    <Logo
                        src={
                            globalState.deviceSize === 'mobile'
                                ? logo
                                : logoWithText
                        }
                        alt="Digital Lab logo"
                        devicesize={globalState.deviceSize}
                    />
                </NavLink>
                {globalState.deviceSize === 'mobile' ||
                globalState.deviceSize === 'tablet' ? (
                    <HamburgerMenu
                        isMobileNavbarOpen={isMobileNavbarOpen}
                        setIsMobileNavbarOpen={setIsMobileNavbarOpen}
                        showMobileSignInForm={showMobileSignInForm}
                        setShowMobileSignInForm={setShowMobileSignInForm}
                        showMobileSignUpForm={showMobileSignUpForm}
                        setShowMobileSignUpForm={setShowMobileSignUpForm}
                    />
                ) : (
                    <DesktopNavItemsContainer className="flex-center-aligned">
                        <DesktopNavigationItems
                            isLandingPage={isLandingPage}
                            isFocusOnSignIn={isFocusOnSignIn}
                            setIsFocusOnSignIn={setIsFocusOnSignIn}
                            showModal={showModal}
                            setShowModal={setShowModal}
                        />
                    </DesktopNavItemsContainer>
                )}
            </NavBarContainer>
        </MainContainer>
    );
};

const MainContainer = styled.div`
    z-index: ${(props) => (props.isFocusOnSignIn ? 1 : 4)};
    top: 0;
    width: 100%;
    position: sticky;

    height: ${(props) =>
        props.devicesize === 'mobile'
            ? '12.8rem'
            : props.devicesize === 'tablet'
            ? '7.2rem'
            : '9rem'};
    background-color: ${(props) => props.theme.colors.lightBackground};
    margin-bottom: -${(props) => (props.devicesize === 'mobile' ? '12.8rem' : props.devicesize === 'tablet' ? '7.2rem' : '9rem')};
`;

const NavBarContainer = styled.div`
    padding-left: 2.4rem;
    padding-right: ${(props) =>
        props.devicesize === 'mobile'
            ? '1.6rem'
            : props.devicesize === 'tablet'
            ? '2.4rem'
            : '4rem'};
    height: ${(props) =>
        props.devicesize === 'mobile'
            ? '6.4rem'
            : props.devicesize === 'tablet'
            ? '7.2rem'
            : '9rem'};
    color: ${(props) => props.theme.colors.greyText};
`;

const Logo = styled.img`
    width: ${(props) =>
        props.devicesize === 'mobile'
            ? '3.5rem'
            : props.devicesize === 'tablet'
            ? '26rem'
            : '30rem'};
`;

const DesktopNavItemsContainer = styled.nav`
    height: 4.3rem;
`;

export default NavBar;
