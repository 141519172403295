import React from 'react';
import styled from '@emotion/styled';
import H2 from 'assets/styledComponents/fonts/H2';
import P from 'assets/styledComponents/fonts/P';
import Container from 'assets/styledComponents/Container';
import calorieKingLogo from 'assets/images/interactive-course5-2/calorieKingLogo.svg';
import carbsAndCalsLogo from 'assets/images/interactive-course5-2/carbsAndCalsLogo.svg';
import myFitnessPalLogo from 'assets/images/interactive-course5-2/myFitnessPalLogo.svg';
import nutrionixLogo from 'assets/images/interactive-course5-2/nutrionixLogo.svg';
import StyledImage from 'assets/styledComponents/StyledImage';
import { Link } from '../../SharedComponentsInteractive';

const NutritionApps = ({ devicesize }) => {
    const AppList = [
        {
            id: 1,
            image: calorieKingLogo,
            imageAltText: 'Calorie King logo',
            appLink: 'https://www.calorieking.com',
            linkTitle: 'Calorie King',
            content:
                'is a free food database app that is also a website, so it can be opened in a web browser without needing to download the app. It allows you to search any food, and provides a nutrition facts table for foods that match what you searched. Note: It is an American program; some of the brands may be slightly different than what we have here in Canada.',
        },
        {
            id: 2,
            image: carbsAndCalsLogo,
            imageAltText: 'Carbs and Cals logo',
            appLink: 'https://carbsandcals.com/',
            linkTitle: 'Carbs and Cals',
            content:
                'is a food database app that uses pictures to help determine carb amounts. It is a paid, subscription-based app. This app can be helpful if you are unsure about the exact portion size.',
        },
        {
            id: 3,
            image: myFitnessPalLogo,
            imageAltText: 'My Fitness Pal logo',
            appLink: 'https://www.myfitnesspal.com/',
            linkTitle: 'My Fitness Pal',
            content:
                ' is a food database website and app. Its limitation is that it may not be the most accurate. It is similar to Wikipedia, meaning that anyone can enter information. It can be helpful for foods that you may not find in the previously mentioned apps.',
        },
        {
            id: 4,
            image: nutrionixLogo,
            imageAltText: 'Nutrionix logo',
            appLink: 'https://www.nutritionix.com/',
            linkTitle: 'Nutritionix',
            content:
                ' is another food database which is both a website and app that allows you to search foods and get access to nutrition information.',
        },
    ];

    const ShowAppList = () => {
        return AppList.map((app) => {
            return (
                <Figure
                    key={app.id}
                    devicesize={devicesize}
                    className={
                        devicesize === 'mobile'
                            ? 'flex-column-centered'
                            : 'flex-center-aligned'
                    }
                >
                    <StyledImage
                        src={app.image}
                        alt={app.imageAltText}
                        marginBottom="3.2rem"
                        marginRight={devicesize === 'mobile' ? '' : '4rem'}
                        width="12.8rem"
                        height="12.8rem"
                    />
                    <figcaption className="body-4">
                        {app.appLink && (
                            <Link
                                href={app.appLink}
                                title={app.linkTitle}
                                className="body-4"
                            />
                        )}{' '}
                        {app.content}
                    </figcaption>
                </Figure>
            );
        });
    };

    return (
        <Container color="darkText" marginTop="7.2rem">
            <H2
                className={devicesize === 'mobile' ? 'header-4' : 'header-3'}
                marginTop="3.8rem"
                marginBottom="2.4rem"
            >
                Nutrition Apps
            </H2>
            <P className="body-4" marginBottom="4rem">
                Some foods do not have a label or are not listed on carbohydrate
                counting handouts, such as fast food, restaurant meals or other
                specific foods you enjoy. Apps and websites can be helpful tools
                in determining carbohydrate amounts for these foods.
                <br />
                <br />
                We’ll introduce 4 apps that many families find helpful.
            </P>
            <HR marginBottom="5.4rem" />
            <ShowAppList />
            <HR marginBottom="5.4rem" />
        </Container>
    );
};

const Figure = styled.figure`
    margin-bottom: 5.4rem;
    margin-left: ${(props) => (props.devicesize === 'mobile' ? '' : '3.2rem')};
`;

const HR = styled.hr`
    height: 0.3rem;
    width: 100%;
    background: ${(props) => props.theme.colors.tertiary};
    margin-bottom: ${(props) => props.marginBottom};
`;

export default NutritionApps;
