import styled from '@emotion/styled';
import React from 'react';
import ButtonSection from './ButtonSection';
import CardInfoSection from './CardInfoSection';
import CardTopSection from './CardTopSection';

const CardContent = ({
    hovered,
    view,
    progress,
    slug,
    lastPage,
    title,
    description,
    lastLesson,
    imageURL,
    totalLessons,
    handleDelete,
    isDashboard,
    imageHeight,
    margin,
    progressBarWidth,
    isMobile,
}) => {
    return (
        <>
            <CardTopSection
                imageURL={imageURL}
                title={title}
                progress={progress}
                hovered={hovered}
                handleDelete={handleDelete}
                isDashboard={isDashboard}
                imageHeight={imageHeight}
            />
            {hovered ? <CardLinearGradientBackground /> : ''}
            {!isMobile &&
                (hovered ? (
                    <ButtonSection
                        view={view}
                        progress={progress}
                        slug={slug}
                        lastPage={lastPage}
                    />
                ) : (
                    <CardInfoSection
                        progress={progress}
                        title={title}
                        description={description}
                        lastLesson={lastLesson}
                        slug={slug}
                        totalLessons={totalLessons}
                        lastPage={lastPage}
                        margin={margin}
                        isMobile={isMobile}
                        progressBarWidth={progressBarWidth}
                    />
                ))}
            {isMobile && (
                <>
                    <CardInfoSection
                        progress={progress}
                        title={title}
                        description={description}
                        lastLesson={lastLesson}
                        slug={slug}
                        totalLessons={totalLessons}
                        lastPage={lastPage}
                        margin={margin}
                        progressBarWidth={progressBarWidth}
                        isMobile={isMobile}
                    />
                    <ButtonSection
                        view={view}
                        progress={progress}
                        slug={slug}
                        lastPage={lastPage}
                        isMobile={isMobile}
                    />
                </>
            )}
        </>
    );
};

const CardLinearGradientBackground = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 2rem;
    background: linear-gradient(
        179.89deg,
        rgba(255, 255, 255, 0) 11.51%,
        rgba(255, 255, 255, 0.443182) 31.49%,
        #ffffff 55.57%
    );
`;

export default CardContent;
