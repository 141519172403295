import styled from '@emotion/styled';

const Container = styled.div`
    margin: ${(props) => props.margin};
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
    width: ${(props) => props.width};
    min-width: ${(props) => props.minWidth};
    max-width: ${(props) => props.maxWidth};
    height: ${(props) => props.height};
    min-height: ${(props) => props.minHeight};
    max-height: ${(props) => props.maxHeight};
    padding: ${(props) => props.padding};
    padding-top: ${(props) => props.paddingTop};
    padding-bottom: ${(props) => props.paddingBottom};
    padding-left: ${(props) => props.paddingLeft};
    padding-right: ${(props) => props.paddingRight};
    text-align: ${(props) => props.textAlign};
    color: ${(props) => props.color && props.theme.colors[props.color]};
    background-color: ${(props) =>
        props.backgroundColor && props.theme.colors[props.backgroundColor]};
    background: ${(props) =>
        props.background && props.theme.colors[props.background]};
    position: ${(props) => props.position};
    top: ${(props) => props.top};
    bottom: ${(props) => props.bottom};
    left: ${(props) => props.left};
    right: ${(props) => props.right};
    border-radius: ${(props) => props.borderRadius};
    border: ${(props) => props.border};
    overflow: ${(props) => props.overflow};
    display: ${(props) => props.display};
    column-gap: ${(props) => props.colGap};
    row-gap: ${(props) => props.rowGap};
    flex-direction: ${(props) => props.flexDirection};
`;

export default Container;
