import { React } from 'react';
import Accordion from './Accordion';
import P from 'assets/styledComponents/fonts/P';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';
import ContentMobile from './ContentMobile';

const FiveTenFifteenTwentyActivity = () => {
    let [globalState] = useGlobalContext();
    let devicesize = globalState.deviceSize;
    return (
        <>
            <P
                className={
                    devicesize === 'mobile' || devicesize === 'tablet'
                        ? 'body-2'
                        : 'body-4'
                }
            >
                Let’s put all the steps together and work through an example.
                Let’s pretend your blood glucose at breakfast is 17.5 mmol/L.
                You check for ketones and discover that you have moderate
                ketones. What now?
            </P>
            {devicesize === 'mobile' || devicesize === 'tablet' ? (
                <ContentMobile devicesize={devicesize} />
            ) : (
                <Accordion />
            )}
        </>
    );
};

export default FiveTenFifteenTwentyActivity;
