import React, { useCallback, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import H4 from 'assets/styledComponents/fonts/H4';
import H3 from 'assets/styledComponents/fonts/H3';
import { GET_COURSE } from 'graphql/queries';
import CourseCardDetails from './CourseCardDetails';

const CardInfoSection = ({
    progress,
    title,
    description,
    lastLesson,
    slug,
    totalLessons,
    lastPage,
    small,
}) => {
    const [isNotInProgress] = useState(progress === '0' || progress === '100');

    const { data: course } = useQuery(GET_COURSE, {
        variables: { slug },
    });

    const [headerLinesCount, headerRef] = useElementLinesCount();
    const [descriptionLinesCount, setDescriptionLinesCount] =
        useState(headerLinesCount);

    useEffect(() => {
        setDescriptionLinesCount(calculateDescriptionLines(headerLinesCount));
        return () => setDescriptionLinesCount(0);
    }, [headerLinesCount]);

    function useElementLinesCount() {
        const [count, setCount] = useState(null);
        const ref = useCallback((node) => {
            if (node !== null) {
                setCount(countLinesInElement(node));
            }
        }, []);
        return [count, ref];
    }

    const countLinesInElement = (el) => {
        let divHeight = el.offsetHeight;
        let lineHeight = 26; // line height for course header
        return Math.round(divHeight / lineHeight);
    };

    const calculateDescriptionLines = (headerLinesCount) => {
        if (headerLinesCount === 1) {
            return 2;
        }
        return 1;
    };

    return (
        <Info small={small}>
            <H3
                className={
                    small
                        ? 'maxTwoLineText petit-title-2'
                        : 'maxTwoLineText header-4'
                }
                innerRef={headerRef}
                marginBottom={headerLinesCount === 1 ? `1.6rem` : `0.8rem`}
            >
                {title}
            </H3>
            {(!isNotInProgress && !small) || isNotInProgress ? (
                <Description
                    className="body-2"
                    color="greyText"
                    lines={
                        isNotInProgress
                            ? descriptionLinesCount + 1
                            : descriptionLinesCount
                    }
                >
                    {description}
                </Description>
            ) : (
                ''
            )}

            <CourseCardDetails
                course={course}
                slug={slug}
                progress={progress}
                lastLesson={lastLesson}
                totalLessons={totalLessons}
                lastPage={lastPage}
                small={small}
            />
        </Info>
    );
};

const Info = styled.div`
    margin: ${(props) =>
        props.small ? '1rem 1.6rem 1.4rem' : '2rem 3rem 2.5rem'};
    /* height needed to keep icons fixed at one position */
    height: ${(props) => (props.small ? '12rem' : '18rem')};
    position: relative;
`;

const Description = styled(H4)`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: ${(props) => props.lines};
    -webkit-box-orient: vertical;
    text-decoration: none;
    color: ${(props) => props.theme.colors.greyText};
`;

export default CardInfoSection;
