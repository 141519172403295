import step1Img from 'assets/images/interactiveBook-course7/step1Img.svg';
import step2Img from 'assets/images/interactiveBook-course7/step2Img.svg';
import step3Img from 'assets/images/interactiveBook-course7/step3Img.svg';
import step4Img from 'assets/images/interactiveBook-course7/step4Img.svg';

const steps = {
    Step1: {
        highlightContent: `Eat an “activity snack” before being active.`,
        content: ` An activity snack is a snack that you do not take insulin for and should contain long lasting carbohydrates, such as a granola bar. Most people need 10-15 g of extra carbohydrates  for every hour of activity. This could vary depending on the age of the person and how hard they are working during the activity.`,
        image: step1Img,
    },
    Step2: {
        highlightContent: `Have a fast-acting sugar and a snack with you.`,
        content: `Physical activity can cause low blood glucose to happen quickly.`,
        image: step2Img,
    },
    Step3: {
        highlightContent: `If you are going to be active after dinner or have been active all day, have a bigger snack at bedtime to prevent low blood glucose overnight.`,
        content: `Physical activity can cause low blood glucose many hours after the activity is completed. Your bed time snack should include some fat and protein.`,
        image: step3Img,
    },
    Step4: {
        highlightContent: `Adjust your insulin depending on timing, length and type of activity you are doing.`,
        content: `Speak with your diabetes team for advice with insulin adjustments. With practice, you will learn to adjust these on your own.`,
        image: step4Img,
    },
};

export default steps;
