import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import chevronDownMobileNav from 'assets/icons/chevronDownMobileNav.svg';
import chevronUpMobileNav from 'assets/icons/chevronUpMobileNav.svg';
import ScrollContainer from 'assets/styledComponents/ScrollContainer';
import DashboardMobileNavbarContent from './DashboardMobileNavbarContent';
import { withRouter } from 'react-router';
import P from 'assets/styledComponents/fonts/P';

const DashboardMobileNavbar = ({ dashboardNavItems, location }) => {
    const [expandMobileNavbar, setExpandMobileNavbar] = useState(false);
    const [activeItem, setActiveItem] = useState('');

    useEffect(() => {
        const active = dashboardNavItems.find(
            (value) => value.route == location.pathname
        );
        if (active) {
            setActiveItem(active);
        }
    }, [location, dashboardNavItems]);

    const isItemActive = (location, itemPath) => {
        if (location.pathname === itemPath) {
            return true;
        }
        return false;
    };

    return (
        <MobileNavbarOuterContainer className="flex-center-aligned flex-space-between mobile-navbar-shadow petit-title-1">
            <Title>
                <StyledIcon src={activeItem.icon} />
                <P
                    marginTop=".7rem"
                    color="primaryDarkest"
                    className="petit-title-1"
                >
                    {activeItem.text}
                </P>
            </Title>
            <button onClick={() => setExpandMobileNavbar(!expandMobileNavbar)}>
                <img
                    src={
                        expandMobileNavbar
                            ? chevronUpMobileNav
                            : chevronDownMobileNav
                    }
                    alt="chevron pointing down"
                />
            </button>
            <ContentListContainer
                display={expandMobileNavbar ? 'flex' : 'none'}
                onClick={() => setExpandMobileNavbar(!expandMobileNavbar)}
            >
                <div role="presentation" className="topPaddingEffect" />
                <ScrollContainer role="presentation">
                    {dashboardNavItems.map((item) => (
                        <DashboardMobileNavbarContent
                            key={item.text}
                            item={item}
                            isActive={isItemActive(
                                location,
                                item.route,
                                item.text
                            )}
                        />
                    ))}
                    <div role="presentation" className="bottomPaddingEffect" />
                </ScrollContainer>
            </ContentListContainer>
        </MobileNavbarOuterContainer>
    );
};

const MobileNavbarOuterContainer = styled.div`
    min-height: 5.8rem;
    width: 100%;
    background: ${(props) => props.theme.colors.tertiary};
    z-index: 1;
    padding-left: 2.4rem;
    padding-right: 1.9rem;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;

    button {
        background: inherit;
        /* padding to increase touch surface for usability */
        padding: 1rem 2.4rem;
    }
`;

const Title = styled.div`
    display: flex;
    text-decoration: none;
    width: 100%;
`;

const StyledIcon = styled.img`
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 1.2rem;
    margin-top: 0.8rem;
`;

const ContentListContainer = styled.ul`
    /* overlay effect  */
    background: rgba(0, 0, 0, 0.4);
    z-index: 1;
    display: ${(props) => props.display};
    position: absolute;
    top: 5.8rem;
    left: 0;
    right: 0;
    flex-direction: column;
    /* deduct mobile navbar and lesson navbar height for scrolling to work correctly with lesson lists exceeding 100vh */
    height: calc(100vh - 5.8rem - 6.4rem);

    div.topPaddingEffect {
        background: ${(props) => props.theme.colors.tertiary};
        height: 2.8rem;
        width: 100%;
        margin-bottom: -0.2rem;
        box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.1) inset;
    }

    div.bottomPaddingEffect {
        background: ${(props) => props.theme.colors.tertiary};
        height: 4rem;
        margin-top: -0.1rem;
        width: 100%;
    }
`;

export default withRouter(DashboardMobileNavbar);
