import React from 'react';
import styled from '@emotion/styled';

const Step3DataCell = ({
    cellTextContent,
    cellNumericValue,
    isAnswer3Correct,
    setIsAnswer3Correct,
    rowNumber,
    colNumber,
    hasSubmitted3,
    setValSelected,
    valSelected,
}) => {
    const handleT3Click = () => {
        if (rowNumber === '4' && colNumber === 4 && !isAnswer3Correct) {
            setValSelected(cellNumericValue);
            setIsAnswer3Correct(true);
        } else {
            setValSelected(cellNumericValue);
            setIsAnswer3Correct(false);
        }
    };

    return (
        <>
            {colNumber === 1 ? (
                <TH scope="row">{cellTextContent}</TH>
            ) : (
                <TD>
                    <CellLabel
                        htmlFor={'T3' + rowNumber + colNumber}
                        id={
                            rowNumber + colNumber === '44' &&
                            isAnswer3Correct &&
                            hasSubmitted3
                                ? 'correct'
                                : ''
                        }
                        className={
                            isAnswer3Correct && hasSubmitted3
                                ? 'correct flex-all-centered'
                                : 'flex-all-centered'
                        }
                    >
                        {cellTextContent}
                        <CellInput
                            type="radio"
                            name="table3"
                            id={'T3' + rowNumber + colNumber}
                            value={rowNumber + colNumber}
                            checked={valSelected === cellNumericValue}
                            onChange={handleT3Click}
                        />
                    </CellLabel>
                </TD>
            )}
        </>
    );
};

// Color vars not shared in app theme
const tableDataBackgroundOdd = '#DADDDE';
const tableDataBackgroundEven = '#E1EDF0';
const tableHeaderLeft = '#FCE4D8';
const selectedBorder = '#2962FF';

const TH = styled.th`
    text-align: center;
    font-family: 'MontserratBold', sans-serif;
    background: ${tableHeaderLeft};
`;

const TD = styled.td`
    text-align: center;
    font-family: 'MontserratMedium', sans-serif;

    :nth-of-type(odd) {
        background: ${tableDataBackgroundOdd};
    }

    :nth-of-type(even) {
        background: ${tableDataBackgroundEven};
    }
`;

const CellLabel = styled.label`
    width: 100%;
    height: 100%;

    :hover {
        cursor: pointer;
    }

    padding: 0.6rem 0.4rem;
    :focus-within {
        border: 0.4rem solid ${selectedBorder};
        padding: 0.2rem 0;
    }

    &#correct {
        transition: background 5s ease-out;
        background: ${(props) => props.theme.colors.successLight};
        border: 0.4rem solid ${(props) => props.theme.colors.success};
        padding: 0.2rem 0;
    }

    &#incorrect {
        background: red;
    }
`;

const CellInput = styled.input`
    /* hide the radio button from view. Have to use z-index for focus:within in parent to work */
    z-index: -1;
`;

export default Step3DataCell;
