import Container from 'assets/styledComponents/Container';
import React from 'react';
import checkCircle from '../../../assets/icons/checkCircle.svg';
import secondaryDot from '../../../assets/icons/secondaryDot.svg';
import StyledIcon from '../../../assets/styledComponents/StyledImage';

const LessonCardCompletionMarker = ({
    lastLessonCompleted,
    lessonTitle,
    isCourseCompleted,
    isLessonCompleted,
    iconSize,
}) => {
    return (
        <Container className="flex-center-aligned" marginLeft="3rem">
            {lastLessonCompleted === lessonTitle && !isCourseCompleted ? (
                <StyledIcon
                    height={iconSize}
                    width={iconSize}
                    src={secondaryDot}
                    alt="Orange Circle Indicating Lesson Last Visited"
                />
            ) : isLessonCompleted ? (
                <StyledIcon
                    height={iconSize}
                    width={iconSize}
                    src={checkCircle}
                    alt="Orange Checkmark Indicating Lesson Complete"
                />
            ) : (
                ''
            )}
        </Container>
    );
};

export default LessonCardCompletionMarker;
