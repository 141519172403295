import styled from '@emotion/styled';
import React from 'react';
import { useHistory } from 'react-router-dom';
import CardButtonMd from '../../assets/styledComponents/buttons/CardButtonMd';
import DeleteButton from '../../assets/styledComponents/buttons/DeleteButton';
import { useGlobalContext } from '../../GlobalContext/GlobalProvider';
import Layout from '../../types/layout';

const ButtonSection = ({
    view,
    progress,
    slug,
    lastPage,
    handleDelete,
    isDashboard,
    isMobile,
}) => {
    const history = useHistory();
    const [globalState] = useGlobalContext();
    return (
        <ButtonContainer view={view} isMobile={isMobile}>
            {view === Layout.LIST_VIEW && globalState.user && isDashboard ? (
                <DeleteButtonContainer>
                    <DeleteButton handleDelete={handleDelete} />
                </DeleteButtonContainer>
            ) : (
                ''
            )}
            <CourseCardButtonStyled
                view={view}
                width="100%"
                onClick={() => history.push(`/modules/${slug}/0`)}
            >
                View Syllabus
            </CourseCardButtonStyled>
            {progress === '0' ? (
                <CourseCardButtonStyled
                    view={view}
                    width="100%"
                    onClick={() => history.push(`/modules/${slug}/1`)}
                >
                    Start Module
                </CourseCardButtonStyled>
            ) : progress === '100' ? (
                <CourseCardButtonStyled
                    width="100%"
                    view={view}
                    onClick={() => history.push(`/modules/${slug}/${lastPage}`)}
                >
                    View Module
                </CourseCardButtonStyled>
            ) : (
                <CourseCardButtonStyled
                    width="100%"
                    view={view}
                    onClick={() => history.push(`/modules/${slug}/${lastPage}`)}
                >
                    Resume Module
                </CourseCardButtonStyled>
            )}
        </ButtonContainer>
    );
};

const ButtonContainer = styled.div`
    ${(props) =>
        props.view === Layout.LIST_VIEW
            ? `display: flex;
        width: 100%;
        height: 100%;
        padding-right: 5rem;
        justify-content: flex-end;
        position: absolute;`
            : `padding: 3.5rem 5rem 5rem;
        `}
    z-index: 1;

    ${(props) =>
        props.isMobile === false
            ? `background: linear-gradient(
        82.22deg,
        rgba(255, 255, 255, 0) 27.04%,
        rgba(255, 255, 255, 0.443182) 43.08%,
        #ffffff 62.41%
    );`
            : ``}

    ${(props) =>
        props.isMobile === true
            ? `
            padding: 0.5rem 5rem 5rem`
            : ``}
`;

const DeleteButtonContainer = styled.div`
    margin-top: 3.2rem;
`;

const CourseCardButtonStyled = styled(CardButtonMd)`
    ${(props) =>
        props.view === Layout.LIST_VIEW
            ? ` margin-left: 1rem;
                margin-top: 3.4rem;
                width: 22rem;`
            : `
                :first-of-type {
                    margin-bottom: 1.5rem;
                    }`}
`;

export default ButtonSection;
