import React from 'react';
import styled from '@emotion/styled';
import Layout from '../../types/layout';
import cardViewActive from '../../assets/icons/cardViewActive.svg';
import cardViewInactive from '../../assets/icons/cardViewInactive.svg';
import listViewActive from '../../assets/icons/listViewActive.svg';
import listViewInactive from '../../assets/icons/listViewInactive.svg';
import Container from './Container';

const LayoutViewIcons = ({ view, setView }) => {
    return (
        <Container
            className="flex"
            width="6.4rem"
            height="3.2rem"
            borderRadius="0.5rem"
            backgroundColor="lightBackground"
            marginLeft="1rem"
        >
            <Icon
                src={
                    view === Layout.CARD_VIEW
                        ? cardViewActive
                        : cardViewInactive
                }
                onClick={() => setView(Layout.CARD_VIEW)}
                alt="Icon representing card view"
            />
            <IconDividerLine />
            <Icon
                src={
                    view === Layout.LIST_VIEW
                        ? listViewActive
                        : listViewInactive
                }
                onClick={() => setView(Layout.LIST_VIEW)}
                alt="Icon representing list view"
            />
        </Container>
    );
};

const IconDividerLine = styled.div`
    border-right: 0.1rem solid ${(props) => props.theme.colors.tertiary};
    margin: 0;
`;

const Icon = styled.img`
    :hover {
        cursor: pointer;
        opacity: 0.5;
        transition: all 0.2s ease-in-out;
    }
`;

export default LayoutViewIcons;
