import React from 'react';
import styled from '@emotion/styled';
import P from 'assets/styledComponents/fonts/P';
import CircularNumber from 'assets/styledComponents/CircularNumber';

// TODO DELETE all EXCEPT Divider when replaced with items found in SharedComponentsInteractive.js

//color vars
const step1Result = `#E8A7F2`;
const step2Result = `#BFDEE4`;
const step3Result = `#FFFFFF`;

// Hint button
export const StyledHintButton = styled.button`
    :hover {
        cursor: pointer;
    }
    padding: 2rem;
    border-radius: 2rem;
    border: 0.2rem gray solid;
    background: white;
`;

export const StepTitle = ({ isDesktop, isMobile, stepNumber, instruction }) => {
    return (
        <div className="flex-space-between">
            <div className="flex-center-aligned">
                {!isMobile ? <CircularNumber number={stepNumber} /> : null}
                <P className={isDesktop ? 'body-1' : 'body-2'}>
                    {isMobile ? `${stepNumber}. ` : null}
                    {instruction}
                </P>
            </div>
        </div>
    );
};

// Input Field

export const InputField = styled.input`
    outline: none;
    border-radius: 2rem;
    width: 8rem;
    height: 8rem;
    text-align: center;

    font-size: 4rem;
    -moz-appearance: textfield;

    &.underline {
        text-decoration: underline;
    }

    /* TODO ask design for error and success colors in styleguide */

    &.error {
        border: 1px solid ${(props) => props.theme.colors.alertRed};
    }

    &.correct {
        border: 1px solid ${(props) => props.theme.colors.success};
    }

    &.step1Result {
        background: ${step1Result};
    }

    &.step2Result {
        background: ${step2Result};
    }

    &.step3Result {
        background: ${step3Result};
    }
`;

// StyledForm

export const StyledForm = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0.8rem;
`;

// Calculator divider for division

export const Divider = styled.hr`
    width: ${(props) => props.width ?? `100%`};
    min-height: 0.4rem;
    border-radius: 1rem;
    background-color: ${(props) => props.theme.colors.primaryDarkest};
    margin-bottom: ${(props) => props.marginBottom ?? `0`};
`;
