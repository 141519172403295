import styled from '@emotion/styled';

const H2 = styled.h2`
    /* customizable */
    margin: ${(props) => props.margin};
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};

    padding: ${(props) => props.padding};
    padding-top: ${(props) => props.paddingTop};
    padding-bottom: ${(props) => props.paddingBottom};
    padding-left: ${(props) => props.paddingLeft};
    padding-right: ${(props) => props.paddingRight};

    width: ${(props) => props.width};
    min-width: ${(props) => props.minWidth};
    max-width: ${(props) => props.maxWidth};
    height: ${(props) => props.height};
    color: ${(props) => props.color && props.theme.colors[props.color]};
    text-align: ${(props) => props.textAlign};
    text-transform: ${(props) => props.textTransform};

    position: ${(props) => props.position};
    top: ${(props) => props.top};
    bottom: ${(props) => props.bottom};
    left: ${(props) => props.left};
    right: ${(props) => props.right};
    display: ${(props) => props.display};
    background: ${(props) =>
        props.background && props.theme.colors[props.color]};
`;

export default H2;
