import Container from 'assets/styledComponents/Container';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';
import React, { useState } from 'react';
import badgeStatus from '../../types/badgeStatus';
import convertDate from '../../utils/helpers/ConvertDate';
import BadgeModal from './BadgeModal';
import BadgesSection from './BadgesSection';
import StudyPlanSection from './StudyPlanSection';

const StudyPlanAndBadgesContainer = ({ isDesktop, isDesktopSm }) => {
    let [globalState] = useGlobalContext();
    let devicesize = globalState.deviceSize;
    const [showModal, setShowModal] = useState(false);
    const [badgeToShow, setBadgeToShow] = useState();

    // Prevent background content scrolling when badge modal open
    if (showModal) {
        document.body.style.overflow = 'hidden';
    } else if (!showModal) {
        document.body.style.overflow = 'unset';
    }

    const handleShowBadge = (badge) => {
        setBadgeToShow(badge);
        setShowModal(true);
    };

    return (
        <div className="flex-column">
            {showModal && (
                <BadgeModal
                    setShowModal={setShowModal}
                    showModal={showModal}
                    status={badgeStatus.EXISTING}
                    title={badgeToShow.title}
                    description={badgeToShow.description}
                    image={badgeToShow.badgeImage.image.url}
                    achievementDate={
                        badgeToShow.achievementDate
                            ? convertDate(badgeToShow.achievementDate)
                            : null
                    }
                    devicesize={devicesize}
                    badgeOutline={
                        badgeToShow.badgeImagePlaceholder.imagePlaceholder.url
                    }
                />
            )}
            <Container
                marginBottom="2rem"
                isDesktop={isDesktop}
                className={isDesktop ? 'flex' : 'flex-column'}
                colGap="4rem"
            >
                <StudyPlanSection
                    isDesktop={isDesktop}
                    isDesktopSm={isDesktopSm}
                    devicesize={devicesize}
                />
                <BadgesSection
                    handleShowBadge={handleShowBadge}
                    devicesize={devicesize}
                />
            </Container>
        </div>
    );
};

export default StudyPlanAndBadgesContainer;
