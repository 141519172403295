import React, { useEffect, useState } from 'react';
import CardContent from './CardContent';
import StyledCourseCard from 'assets/styledComponents/Container';

const CarouselCourseCard = ({
    course,
    imageURL,
    title,
    description,
    slug,
    progress,
    lastLesson,
    lastPage,
    totalLessons,
    handleDelete,
    isDashboard,
    devicesize,
}) => {
    const [hovered, setHovered] = useState(false);

    let small = devicesize === 'mobile' || devicesize === 'tablet';

    useEffect(() => {
        setHovered(false);
    }, []);

    return (
        <StyledCourseCard
            className="card-shadow flex-column-flex-start"
            borderRadius="2rem"
            width={small ? '16rem' : '36rem'}
            height={small ? '25rem' : '38rem'}
            position="relative"
            background="lightBackground"
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <CardContent
                course={course}
                hovered={hovered}
                progress={progress}
                slug={slug}
                lastPage={lastPage}
                title={title}
                description={description}
                lastLesson={lastLesson}
                imageURL={imageURL}
                totalLessons={totalLessons}
                handleDelete={handleDelete}
                isDashboard={isDashboard}
                small={small}
            />
        </StyledCourseCard>
    );
};

export default CarouselCourseCard;
