import React from 'react';
import styled from '@emotion/styled';
import P from './fonts/P';
import Container from './Container';

const IconAndInfo = ({ icon, text, marginBottom }) => {
    return (
        <Container className="flex-center-aligned" marginBottom={marginBottom}>
            <StyledIcon src={icon} />
            <P color="darkText" marginRight="2rem" className="petit-title-2">
                {text}
            </P>
        </Container>
    );
};

const StyledIcon = styled.img`
    height: 2.4rem;
    width: 2.4rem;
    margin-right: 0.5rem;
`;

export default IconAndInfo;
