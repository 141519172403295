import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import removeStyleAttributes from 'components/SignInAndSignUp/utils/removeStyleAttributes';

const Description = ({ data, isMobile }) => {
    // function to order lists and insert them in the correct dom elements
    const fixQuillRichTextOrderedList = (selector) => {
        for (const targetElement of selector) {
            // logic for lists with only 1 list item

            if (targetElement.tagName !== 'LI') {
                return;
            }

            if (targetElement.previousSibling) {
                if (
                    targetElement.previousSibling.classList[0] === 'ql-indent-2'
                ) {
                    setTimeout(() => {
                        targetElement.parentNode.previousSibling.appendChild(
                            targetElement
                        );
                    }, 0);
                    clearTimeout();
                }
            }

            if (targetElement.previousSibling) {
                if (
                    targetElement.previousSibling.childNodes[0].nodeName !==
                    'LI'
                ) {
                    // resets first layer list naming back to above if inputted below another OL

                    {
                        const parent = targetElement.parentNode;
                        const wrapper = document.createElement('ol');

                        parent.replaceChild(wrapper, targetElement);
                        wrapper.appendChild(targetElement);
                    }
                }
            }
            // logic for 2nd level nested lists
            else if (selector[0].classList[0] == 'ql-indent-2') {
                const prevOl = targetElement.previousSibling;

                const wrapper = document.createElement('ol');

                // if 2nd level nested list is first item create ol and append
                if (
                    targetElement.previousSibling.childNodes[
                        targetElement.previousSibling.childNodes.length - 1
                    ].childNodes[
                        targetElement.previousSibling.childNodes[
                            targetElement.previousSibling.childNodes.length - 1
                        ].childNodes.length - 1
                    ].nodeName !== 'OL'
                ) {
                    wrapper.appendChild(targetElement);
                    prevOl.childNodes[
                        prevOl.childNodes.length - 1
                    ].insertBefore(
                        wrapper,
                        prevOl.childNodes[prevOl.childNodes.length]
                    );
                }
                // if 2nd level nested list item is not first item, append to previously created ol
                else {
                    targetElement.previousSibling.childNodes[
                        targetElement.previousSibling.childNodes.length - 1
                    ].childNodes[
                        targetElement.previousSibling.childNodes[
                            targetElement.previousSibling.childNodes.length - 1
                        ].childNodes.length - 1
                    ].appendChild(targetElement);
                }
            }
            // logic to ensure first layer list with more than 1 item get inserted correctly
            else {
                targetElement.previousSibling.insertBefore(
                    targetElement,
                    targetElement.previousSibling.childNodes[
                        targetElement.previousSibling.childNodes.length - 1
                    ].nextSibling
                );
            }
        }
    };

    // function using queries to select appropriate nodes and calls fixing function to fix DOM
    const queryAndFixQuill = () => {
        const targetElements1 = document.querySelectorAll('.ql-indent-1');
        const targetElements2 = document.querySelectorAll('.ql-indent-2');

        if (targetElements1[0]) {
            if (targetElements1[0].parentNode.tagName === 'UL') {
                return;
            }
        }

        fixQuillRichTextOrderedList(targetElements1);
        fixQuillRichTextOrderedList(targetElements2);
    };

    useEffect(() => {
        queryAndFixQuill();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Paragraph
            isMobile={isMobile}
            dangerouslySetInnerHTML={{
                __html: removeStyleAttributes(data.description),
            }}
        ></Paragraph>
    );
};

const Paragraph = styled.p`
    font-family: 'DMSans', sans-serif;
    font-size: ${(props) => (props.isMobile ? '1.4rem' : '1.7rem')};
    line-height: ${(props) => (props.isMobile ? '1.8rem' : '2.4rem')};
    letter-spacing: 0.01em;

    blockquote {
        border-left: 0.25rem solid #ccc;
        margin: 0.3125rem 0;
        padding-left: 1rem;
    }

    .ql-size-huge {
        font-size: 2.5rem;
    }

    .ql-size-large {
        font-size: 2rem;
    }

    .ql-size-small {
        font-size: 0.75rem;
    }

    ul {
        list-style-type: disc;
        margin-left: 2em;
    }

    ol {
        list-style-type: auto;
        margin-left: 2em;

        .ql-indent-1 {
            list-style: lower-alpha;
        }

        .ql-indent-2 {
            list-style: lower-roman;
        }
    }
`;

export default Description;
