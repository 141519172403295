import React from 'react';
import styled from '@emotion/styled';
import { NavLink, useHistory } from 'react-router-dom';
import { client } from 'apolloClient';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';
import { clearUser } from 'GlobalContext/actionVariables';
import isPropValid from '@emotion/is-prop-valid';
import { mobileAuthNavOptions, nonAuthNavOptions } from './NavItemData';

const LoggedInOptions = ({
    isLandingPage,
    handleLogOut,
    devicesize,
    setIsMobileNavbarOpen,
}) => {
    return (
        <>
            {mobileAuthNavOptions.map((option, idx) => {
                return (
                    <StyledLink
                        isLandingPage={isLandingPage}
                        to={option.href}
                        key={idx}
                        className="petit-title-1"
                        devicesize={devicesize}
                        onClick={() => setIsMobileNavbarOpen(false)}
                    >
                        {option.title}
                    </StyledLink>
                );
            })}
            <Button
                isLandingPage={isLandingPage}
                onClick={handleLogOut}
                devicesize={devicesize}
                className="petit-title-1"
            >
                Log Out
            </Button>
        </>
    );
};

const PublicOptions = ({
    isLandingPage,
    handleSignIn,
    devicesize,
    setIsMobileNavbarOpen,
}) => {
    return (
        <>
            {nonAuthNavOptions.map((option, idx) => {
                return (
                    <StyledLink
                        isLandingPage={isLandingPage}
                        to={option.href}
                        key={idx}
                        className="petit-title-1"
                        devicesize={devicesize}
                        onClick={() => setIsMobileNavbarOpen(false)}
                    >
                        {option.title}
                    </StyledLink>
                );
            })}
            <Button
                isLandingPage={isLandingPage}
                onClick={handleSignIn}
                devicesize={devicesize}
                className="petit-title-1"
            >
                Sign in
            </Button>
        </>
    );
};

const MobileNavItems = ({
    setShowMobileSignInForm,

    setIsMobileNavbarOpen,
}) => {
    const history = useHistory();
    const [globalState, dispatch] = useGlobalContext();

    const handleLogOut = async (e) => {
        e.preventDefault();
        history.push('/');
        localStorage.removeItem('token');
        client.resetStore();
        dispatch({
            type: clearUser,
        });

        setIsMobileNavbarOpen(false);
    };

    const handleSignIn = () => {
        setShowMobileSignInForm(true);
        setIsMobileNavbarOpen(false);
    };

    return (
        <>
            <MobileDropdownContainer
                className="flex-column-flex-start petit-title-1"
                devicesize={globalState.deviceSize}
                onClick={() => setIsMobileNavbarOpen(false)}
            >
                <TopPaddingEffect
                    role="presentation"
                    devicesize={globalState.deviceSize}
                />

                {globalState.auth ? (
                    <LoggedInOptions
                        handleSignIn={handleSignIn}
                        handleLogOut={handleLogOut}
                        devicesize={globalState.deviceSize}
                        setIsMobileNavbarOpen={setIsMobileNavbarOpen}
                    />
                ) : (
                    <PublicOptions
                        handleSignIn={handleSignIn}
                        handleLogOut={handleLogOut}
                        devicesize={globalState.deviceSize}
                        setIsMobileNavbarOpen={setIsMobileNavbarOpen}
                    />
                )}
            </MobileDropdownContainer>
        </>
    );
};

const TopPaddingEffect = styled.div`
    height: ${(props) => (props.devicesize === 'mobile' ? '2rem' : '2.8rem')};
    margin-top: -0.2rem;
    background: ${(props) => props.theme.colors.lightBackground};
    width: 100%;
`;

const Button = styled.button`
    padding: ${(props) =>
        props.devicesize === 'mobile'
            ? '1.5rem 2.4rem 4rem'
            : '1.5rem 4.8rem 4rem'};
    margin-top: -0.2rem;
    background: ${(props) => props.theme.colors.lightBackground} !important;
    width: 100%;
    text-align: left;
    text-transform: uppercase;

    :hover {
        color: ${(props) => props.theme.colors.secondary};
    }
`;

const MobileDropdownContainer = styled.div`
    /* overlay effect  */
    background: rgba(0, 0, 0, 0.4);
    z-index: 1;
    position: absolute;
    top: ${(props) => (props.devicesize === 'mobile' ? '12.8rem' : '7.2rem')};
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    align-items: left;
    color: ${(props) => props.theme.colors.primaryDarkest};
    overflow: hidden;
    text-transform: uppercase;
    cursor: pointer;
`;

const StyledLink = styled(NavLink, {
    shouldForwardProp: (prop) => isPropValid(prop),
})`
    text-decoration: none;
    background: ${(props) => props.theme.colors.lightBackground};
    color: inherit;
    padding: ${(props) =>
        props.devicesize === 'mobile' ? '1.4rem 2.4rem' : '1.4rem 4.8rem'};
    :hover {
        color: ${(props) => props.theme.colors.secondary};
    }

    &.active {
        color: ${(props) => props.theme.colors.secondary};
    }
`;

export default MobileNavItems;
