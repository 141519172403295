import styled from '@emotion/styled';
import React from 'react';
import P from 'assets/styledComponents/fonts/P';
import { ActivityHeader } from '../SharedComponentsInteractive';
import BehaviouralAdvice from './BehaviouralAdvice';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';
import { ListItem } from './SharedComponents';
import BehaviouralAdviceMobile from './BehaviouralAdviceMobile';

const NeedleFear = () => {
    const resources = [
        {
            id: 1,
            title: 'It Doesn’t Have to Hurt Website ',
            link: 'https://itdoesnthavetohurt.ca/resources/',
        },
        {
            id: 2,
            title: 'Anxiety Canada Website – My Anxiety Plan for Specific Phobias in Children',
            link: 'https://anxietycanada.com/disorders/specific-phobia-2/',
        },
        {
            id: 3,
            title: 'Needle Fears',
            link: 'http://www.bcchildrens.ca/endocrinology-diabetes-site/documents/needlefears.pdf',
        },
        {
            id: 4,
            title: 'Needle fears and phobia: Making a difference in the lives of children, youth and families',
            link: 'https://www.cheo.on.ca/en/resources-and-support/resources/P5018E.pdf',
        },
        {
            id: 5,
            title: 'Free downloadable resources',
            link: 'https://www.anxietycanada.com/free-downloadable-pdf-resources/',
        },
    ];

    const [globalState] = useGlobalContext();
    let devicesize = globalState.deviceSize;

    return (
        <div>
            <P className="body-4">
                A common question: How can I support my child or teenager to
                adapt to needle use associated with diabetes care, and overcome
                fears or phobias they may experience?
            </P>
            <P className="body-4" marginTop="2rem">
                It is common for some children and teenagers to be upset or
                distressed regarding needle use when checking blood sugars and
                administrating insulin, particularly at the time of diagnosis.
                There are many ways for parents and caregivers to support and
                implement coping strategies for their children and teenagers to
                adapt and develop the skills required to overcome any
                challenges, fears and/or phobias associated with needle use and
                diabetes management.
            </P>

            {devicesize === 'tablet' ||
                (devicesize === 'mobile' ? (
                    ''
                ) : (
                    <ActivityHeader
                        instructions="Click through the tabs to view helpful and unhelpful behaviours."
                        marginTop="5.6rem"
                        marginBottom="4rem"
                    />
                ))}

            {devicesize === 'desktopSm' || devicesize === 'desktopMd' ? (
                <BehaviouralAdvice devicesize={devicesize} />
            ) : (
                <BehaviouralAdviceMobile devicesize={devicesize} />
            )}

            <P
                className="body-4"
                marginTop={
                    devicesize === 'desktopSm' || devicesize === 'desktopMd'
                        ? '6.4rem'
                        : '4rem'
                }
                marginBottom={
                    devicesize === 'desktopSm' || devicesize === 'desktopMd'
                        ? '4rem'
                        : '3.2rem'
                }
            >
                For more information about overcoming needle fear and phobias
                often associated with type 1 diabetes, please refer to the below
                handouts on this topic:
            </P>
            <ul>
                {resources.map((resource) => (
                    <ListItem key={resource.id} className="list-with-link">
                        <P className="body-4">{resource.title}</P>
                        <Link
                            href={resource.link}
                            target="_blank"
                            className="body-2"
                        >
                            {resource.link}
                        </Link>
                    </ListItem>
                ))}
            </ul>
            <P
                className="body-4"
                marginTop={
                    devicesize === 'desktopSm' || devicesize === 'desktopMd'
                        ? '4rem'
                        : '2.4rem'
                }
            >
                Some children and teenagers may experience ongoing acute and
                distressing needle fears and phobias. If these
                concerns/fears/phobias are impacting your child’s ability to
                adjust to a new diagnosis and/or interfering with your child’s
                daily functioning the diabetes team may discuss with you, a
                referral to the Medical Psychology Department at BC Children’s
                Hospital for additional support. The Outpatient Medical
                Psychology team supports children, youth and their families to
                overcome challenges sometimes associated with a new diagnosis
                and develop skills and coping strategies to overcome needle
                fears/phobias.
            </P>
        </div>
    );
};

const Link = styled.a`
    margin-top: 0.8rem;
    text-decoration: none;
    color: ${(props) => props.theme.colors.primary};
`;

export default NeedleFear;
