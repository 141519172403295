import { React, useState } from 'react';
import NamedSectionContainer from './NamedSectionContainer';
import Body2 from '../../assets/styledComponents/fonts/Body2';
import convertDate from '../../utils/helpers/ConvertDate';
import PetitTitle1 from '../../assets/styledComponents/fonts/PetitTitle1';
import StyledImage from '../../assets/styledComponents/StyledImage';
import BadgeModal from '../DashboardHome/BadgeModal';

const LastAcquiredBadgeSection = ({ globalState, allBadges, devicesize }) => {
    const [showModal, setShowModal] = useState(false);

    let myBadges = globalState?.user?.trackingLog?.badgeTracker;
    let lastAcquiredBadge = null;

    let lastBadgeAchievedId = myBadges?.lastBadgeAchieved;
    let achievementDate = myBadges?.dateOfLastBadgeAchieved;

    if (achievementDate) {
        achievementDate = convertDate(achievementDate);
    }

    allBadges?.forEach((item) => {
        if (lastBadgeAchievedId === item.id) {
            lastAcquiredBadge = item;
        }
    });

    return (
        <NamedSectionContainer
            title="Last Acquired Badge "
            gridAreaName="lastAcquiredBadge"
            height="41rem"
            width="46rem"
            headerPadding="3rem 0 0 3rem"
        >
            {showModal && (
                <BadgeModal
                    setShowModal={setShowModal}
                    showModal={showModal}
                    title={lastAcquiredBadge.title}
                    description={lastAcquiredBadge.description}
                    image={lastAcquiredBadge.badgeImage.image.url}
                    achievementDate={achievementDate}
                    devicesize={devicesize}
                    badgeOutline={
                        lastAcquiredBadge.badgeImagePlaceholder.imagePlaceholder
                            .url
                    }
                />
            )}
            <StyledImage
                src={lastAcquiredBadge?.badgeImage?.image?.url}
                onClick={() => setShowModal(!showModal)}
                style={{ cursor: 'pointer' }}
            />
            <PetitTitle1>{lastAcquiredBadge?.title}</PetitTitle1>
            <Body2
                className="maxTwoLineText"
                width="25rem"
                color="darkText"
                margin="1rem 0 1.4rem"
            >
                {lastAcquiredBadge?.description}
            </Body2>
            <Body2 color="greyText">
                {achievementDate ? `Unlocked ${achievementDate}` : ''}
            </Body2>
        </NamedSectionContainer>
    );
};

export default LastAcquiredBadgeSection;
