import React from 'react';
import styled from '@emotion/styled';
import { Table } from './SharedComponents';
import Container from 'assets/styledComponents/Container';
import highBloodSugarStep3 from 'assets/images/highBloodSugarStep3.svg';
import { Caption } from './SharedComponents';
import { Table3Data } from './Table3Data';
import Step3TableRow from './Step3TableRow';

const Step3Table = ({
    hasSubmitted3,
    isAnswer3Correct,
    setIsAnswer3Correct,
    valSelected,
    setValSelected,
    showHint3b,
    hintRef,
}) => {
    return (
        <Container ref={hintRef} position="relative" width="fit-content">
            <CorrectColBorder
                role="presentation"
                className={
                    (hasSubmitted3 && isAnswer3Correct) || showHint3b
                        ? 'correct-border-flash'
                        : ''
                }
            />
            <CorrectRowBorder
                role="presentation"
                className={
                    (hasSubmitted3 && isAnswer3Correct) || showHint3b
                        ? 'correct-border-flash'
                        : ''
                }
            />
            <Table>
                <Caption>
                    <StyledImage src={highBloodSugarStep3} />
                    5-10-15-20 Rules of High Blood Glucose
                </Caption>

                <tbody>
                    <tr>
                        <TH scope="col">Total Daily Dose (TDD)</TH>
                        <TH scope="col">5% of TDD</TH>
                        <TH scope="col">10% of TDD</TH>
                        <TH scope="col">15% of TDD</TH>
                        <TH scope="col">20% of TDD</TH>
                    </tr>
                    {Table3Data.map((item) => {
                        return (
                            <Step3TableRow
                                key={item.id}
                                data={item.items}
                                rowNumber={item.id}
                                isAnswer3Correct={isAnswer3Correct}
                                setIsAnswer3Correct={setIsAnswer3Correct}
                                hasSubmitted3={hasSubmitted3}
                                valSelected={valSelected}
                                setValSelected={setValSelected}
                            />
                        );
                    })}
                </tbody>
            </Table>
        </Container>
    );
};

const tableHeaderBackground = '#FF9FC2';

const TH = styled.th`
    background: ${tableHeaderBackground};
    font-family: 'MontserratBold', sans-serif;
    font-size: 1.4rem;
`;

const StyledImage = styled.img`
    height: 10rem;
`;

const CorrectColBorder = styled.div`
    width: 15.7rem;
    border: 0.4rem solid ${(props) => props.theme.colors.success};
    height: 54.5rem;
    position: absolute;
    bottom: 0.5rem;
    right: 14rem;
    z-index: -1;

    &.correct-border-flash {
        animation-name: flash;
        animation-duration: 5s;
        @keyframes flash {
            0% {
                border: 0.4rem solid ${(props) => props.theme.colors.success};
                z-index: 5;
            }

            100% {
                border: none;
                z-index: -1;
            }
        }
    }
`;

const CorrectRowBorder = styled.div`
    width: calc(100% - 1.2rem);
    height: 4rem;
    position: absolute;
    top: 30.8rem;
    right: 0.6rem;
    z-index: -1;
    &.correct-border-flash {
        animation-name: flash;
        animation-duration: 5s;
        @keyframes flash {
            0% {
                border: 0.4rem solid ${(props) => props.theme.colors.success};
                z-index: 5;
            }

            100% {
                border: none;
                z-index: -1;
            }
        }
    }
`;

export default Step3Table;
