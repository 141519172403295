import styled from '@emotion/styled';

// See style guide, card button hover styling is different from regular filled / outlined buttons
const CardButtonMd = styled.button`
    font-family: 'MontserratSemiBold', sans-serif;
    font-size: 1.8rem;
    height: 4rem;
    border-radius: 4rem;

    background-color: ${(props) => props.theme.colors.lightBackground};
    color: ${(props) => props.theme.colors.secondary};
    border: 0.1rem solid ${(props) => props.theme.colors.secondary};

    display: flex;
    align-items: center;
    justify-content: center;

    /* customizable */
    margin: ${(props) => props.margin};
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
    width: ${(props) => props.width};
    min-width: ${(props) => props.minWidth};
    max-width: ${(props) => props.maxWidth};
    padding: ${(props) => props.padding};

    :hover {
        background-color: ${(props) => props.theme.colors.secondary};
        color: ${(props) => props.theme.colors.lightText};
        cursor: pointer;
    }
    :active {
        opacity: 0.4;
    }
`;

export default CardButtonMd;
