export const Table3Data = [
    {
        id: '1',
        items: [
            'less than 4 units',
            ['-', 0],
            ['-', 0],
            ['1/2 unit', 0.5],
            ['1/2 unit', 0.5],
        ],
    },
    {
        id: '2',
        items: [
            '4-6 units',
            ['-', 0],
            ['1/2 unit', 0.5],
            ['1 unit', 1],
            ['1 unit', 1],
        ],
    },
    {
        id: '3',
        items: [
            '5-15 units',
            ['1/2 unit', 0.5],
            ['1 unit', 1],
            ['1 1/2 units', 1.5],
            ['2 units', 2],
        ],
    },
    {
        id: '4',
        items: [
            '16-25 units',
            ['1 unit', 1],
            ['2 units', 2],
            ['3 units', 3],
            ['4 units', 4],
        ],
    },
    {
        id: '5',
        items: [
            '26-35 units',
            ['1 1/2 units', 1.5],
            ['3 units', 3],
            ['4 1/2 units', 4.5],
            ['6 units', 6],
        ],
    },
    {
        id: '6',
        items: [
            '36-45 units',
            ['2 units', 2],
            ['4 units', 4],
            ['6 units', 6],
            ['8 units', 8],
        ],
    },
    {
        id: '7',
        items: [
            '46-55 units',
            ['2 1/2 units', 2.5],
            ['5 units', 5],
            ['7 1/2 units', 7.5],
            ['10 units', 10],
        ],
    },
    {
        id: '8',
        items: [
            '56-65 units',
            ['3 units', 3],
            ['6 units', 6],
            ['9 units', 9],
            ['12 units', 12],
        ],
    },
    {
        id: '9',
        items: [
            '66-75 units',
            ['3 1/2 units', 3.5],
            ['7 units', 7],
            ['10 1/2 units', 10.5],
            ['14 units', 14],
        ],
    },
    {
        id: '10',
        items: [
            '76-85 units',
            ['4 units', 4],
            ['8 units', 8],
            ['12 units', 12],
            ['16 units', 16],
        ],
    },
    {
        id: '11',
        items: [
            '86-95 units',
            ['4 1/2 units', 4.5],
            ['9 units', 9],
            ['13 1/2 units', 13.5],
            ['18 units', 18],
        ],
    },
    {
        id: '12',
        items: [
            '96-105 units',
            ['5 units', 5],
            ['10 units', 10],
            ['15 units', 15],
            ['20 units', 20],
        ],
    },
];
