import { React, useState, useRef } from 'react';
import styled from '@emotion/styled';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';
import insulinActionGraph1 from 'assets/images/interactive-course6-4/insulinActionGraph1.png';
import insulinActionGraph2 from 'assets/images/interactive-course6-4/insulinActionGraph2.png';
import insulinActionGraph1NoBorder from 'assets/images/interactive-course6-4/insulinActionGraph1NoBorder.png';
import insulinActionGraph2NoBorder from 'assets/images/interactive-course6-4/insulinActionGraph2NoBorder.png';
import doctor from 'assets/images/interactive-course6-4/doctor.svg';
import Container from 'assets/styledComponents/Container';
import InfoTable from './InfoTable';
import BolusCalcStep1 from './BolusCalcStep1';
import BolusCalcStep2 from './BolusCalcStep2';
import BolusCalcStep3 from './BolusCalcStep3';
import BolusActivityB from './BolusActivityB';
import P from 'assets/styledComponents/fonts/P';
import { ActivityHeader } from '../../SharedComponentsInteractive';
import H2 from 'assets/styledComponents/fonts/H2';
import PictureFullSize from 'components/Courses/PictureFullSize';
import UseDesktopBanner from '../../../../../assets/styledComponents/UseDesktopBanner';
import StyledImage from '../../../../../assets/styledComponents/StyledImage';

const InsulinStackingActivity = () => {
    const [showHint1, setShowHint1] = useState(false);
    const [showHint2, setShowHint2] = useState(false);
    const [showHint3, setShowHint3] = useState(false);

    const [globalState] = useGlobalContext();
    const { deviceSize } = globalState;
    const isTablet = Boolean(deviceSize === 'tablet');
    const isMobile = Boolean(deviceSize === 'mobile');
    const isDesktop = Boolean(!isTablet && !isMobile);

    const hintRef = useRef(null);
    const scrollUp = () => {
        return hintRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    };

    return (
        <Container position="relative">
            <Paragraph
                marginBottom={isDesktop ? '2.4rem' : '1.6rem'}
                margin={'2.4rem 0'}
            >
                Sometimes you may want a second serving or a snack not too long
                after a meal. Let’s go over how to calculate your bolus insulin
                for those carbohydrates.
            </Paragraph>
            {isDesktop || isTablet ? <div ref={hintRef}></div> : ''}
            <Paragraph
                className={!isMobile ? 'body-4' : 'body-2'}
                marginBottom={isDesktop ? '2.4rem' : '1.6rem'}
            >
                If correction boluses are given too close to previous bolus
                insulin injections, the action of all the insulin injections add
                up and can cause a low blood glucose to occur later on. This is
                called insulin stacking.
            </Paragraph>
            {isMobile ? <div ref={hintRef}></div> : ''}
            <Paragraph
                className={!isMobile ? 'body-4' : 'body-2'}
                marginBottom={isDesktop ? '2.4rem' : '1.6rem'}
            >
                Let’s think back to the insulin action time of bolus insulin.
                Rapid acting bolus insulin is active in the body for 3 to 4
                hours.
            </Paragraph>
            {/* Activity A  */}
            <Paragraph
                className={!isMobile ? 'body-4' : 'body-2'}
                marginBottom={isDesktop ? '4rem' : '3.2rem'}
            >
                Let’s practice! The blood glucose level before breakfast{' '}
                <StyledSpan className={showHint1 ? 'showHint1' : ''}>
                    12.1
                </StyledSpan>{' '}
                and you plan to eat{' '}
                <StyledSpan className={showHint2 ? 'showHint2' : ''}>
                    30 grams
                </StyledSpan>{' '}
                of carbohydrates. The ISF is{' '}
                <StyledSpan className={showHint1 ? 'showHint1' : ''}>
                    3
                </StyledSpan>{' '}
                and the carbohydrate ratio is 15.
                <br />
                <br />
                How would you complete this bolus calculation to figure out how
                much bolus insulin is needed for breakfast?
            </Paragraph>
            <InfoTable
                showHint1={showHint1}
                showHint2={showHint2}
                isDesktop={isDesktop}
                isTablet={isTablet}
                isMobile={isMobile}
            />

            {deviceSize === 'mobile' && <UseDesktopBanner marginTop="3.2rem" />}
            <ActivityHeader
                instructions="Read the example above to fill in the values in
                the worksheet below."
                marginTop={isDesktop ? '7.2rem' : '4.8rem'}
                marginBottom="4rem"
            />
            {/* Worksheet  */}
            <Container
                className="flex-column-centered"
                borderRadius="2rem"
                border={isDesktop ? '4px solid #E1EDF0' : 'none'}
                padding={isDesktop ? '6.4rem 8rem' : '0rem'}
                marginBottom={isDesktop ? '7.2rem' : '4.8rem'}
            >
                <H2
                    className={isMobile ? 'body-1' : 'header-3'}
                    marginBottom={isDesktop ? '4.8rem' : '4rem'}
                >
                    Bolus Calculation Worksheet
                </H2>
                {/* Three calculators  */}
                <BolusCalcStep1
                    setShowHint1={setShowHint1}
                    scrollUp={scrollUp}
                />
                <BolusCalcStep2
                    setShowHint2={setShowHint2}
                    scrollUp={scrollUp}
                />
                <BolusCalcStep3
                    showHint3={showHint3}
                    setShowHint3={setShowHint3}
                />
            </Container>

            {/* Activity B  */}

            <Paragraph
                className={!isMobile ? 'body-4' : 'body-2'}
                marginBottom={isDesktop ? '2.4rem' : '1.8rem'}
            >
                This graph shows how the action of the correction and food
                boluses line up with the absorption of the food.
            </Paragraph>

            <Paragraph
                className={!isMobile ? 'body-4' : 'body-2'}
                marginBottom={isDesktop ? '2.4rem' : '1.8rem'}
            >
                It’s been one hour since breakfast and you want to have a snack.
                Your blood glucose is now 15.3. What might happen if you give
                your food bolus for the carbohydrates in your snack plus another
                correction bolus?
            </Paragraph>

            <Paragraph
                className={!isMobile ? 'body-4' : 'body-2'}
                marginBottom={isDesktop ? '4rem' : '3.2rem'}
            >
                Now you check your blood glucose following your morning snack
                and find it is low.
            </Paragraph>

            {isDesktop ? (
                <PictureFullSize
                    src={insulinActionGraph1}
                    alt="Blood glucose readings after eating carbohydrates will be higher than before eating because the insulin is still working!"
                    marginTop="4rem"
                />
            ) : (
                <StyledImage
                    marginTop="4rem"
                    width="100vw"
                    marginLeft={isMobile ? '-2.5rem' : '-4.8rem'}
                    src={insulinActionGraph1NoBorder}
                    alt="Length of time rapid acting insulin stays in body"
                    borderRadius="0rem"
                />
            )}

            <BolusActivityB />

            <Paragraph
                marginTop={isDesktop ? '4rem' : '4.8rem'}
                className="body-4"
                marginBottom={isDesktop ? '4.2rem' : '4.8rem'}
            >
                Notice on the graph how the insulin actions of both corrections
                have been added together. This happens because the first
                correction was still working when the second correction was
                given. This is insulin stacking. Please note that insulin
                stacking only occurs with correction boluses and does not apply
                to food boluses.
            </Paragraph>

            <Container
                className={
                    isMobile ? 'flex-column-centered' : 'flex-all-centered'
                }
                marginBottom={isDesktop ? '3.2rem' : '0'}
                marginTop={isMobile ? '4.8rem' : '0'}
            >
                <ImageBesideBubble
                    src={doctor}
                    alt="doctor"
                    isMobile={isMobile}
                />
                {isMobile ? null : <Arrow />}
                <P
                    className={!isMobile ? 'body-4' : 'body-2'}
                    marginRight={isDesktop ? '4rem' : '0'}
                    background="tertiary"
                    padding={isMobile ? '2rem 1.6rem' : '3.2rem 4.8rem'}
                    borderRadius="2rem"
                    marginBottom={isDesktop ? '2.4rem' : '0'}
                >
                    Remember blood glucose readings after eating carbohydrates
                    will always be higher than before eating because the insulin
                    is still working.
                </P>
            </Container>

            {isDesktop ? (
                <PictureFullSize
                    marginTop="7rem"
                    src={insulinActionGraph2}
                    alt="Length of time rapid acting insulin stays in body"
                />
            ) : (
                <StyledImage
                    marginTop={isMobile ? '4.8rem' : '6.4rem'}
                    marginLeft={isTablet ? '-4.8rem' : '-2.5rem'}
                    width="100vw"
                    src={insulinActionGraph2NoBorder}
                    alt="Length of time rapid acting insulin stays in body"
                />
            )}
        </Container>
    );
};

const ImageBesideBubble = styled.img`
    height: 14.5rem;
    width: 15.4rem;
    margin-bottom: ${({ isMobile }) => (isMobile ? `1.6rem` : `0`)};
`;

const StyledSpan = styled.span`
    &.showHint1 {
        color: ${(props) => props.theme.colors.success};
    }

    &.showHint2 {
        color: ${(props) => props.theme.colors.success};
    }
`;

const Arrow = styled.div`
    width: 0;
    height: 0;
    border-top: 1.6rem solid transparent;
    border-bottom: 1.6rem solid transparent;
    border-right: 3.6rem solid ${(props) => props.theme.colors.tertiary};
    margin-left: 2rem;
`;

const Paragraph = styled.p`
    font-family: 'DMSans', sans-serif;
    font-size: ${(props) => (props.isMobile ? '1.4rem' : '1.7rem')};
    line-height: ${(props) => (props.isMobile ? '1.8rem' : '2.4rem')};
    letter-spacing: 0.01em;
    margin-bottom: ${(props) => props.marginBottom};
    margin: ${(props) => props.margin};
`;

export default InsulinStackingActivity;
