import React from 'react';
import styled from '@emotion/styled';
import Figure from './Figure';
import Image1 from 'assets/images/interactive-course5-6/calorieWebsites.svg';
import Image2 from 'assets/images/interactive-course5-6/calorieApps.svg';
import Image3 from 'assets/images/interactive-course5-6/hospitalHandout.svg';
import ActivityCalc from './ActivityCalc';
import H2 from 'assets/styledComponents/fonts/H2';
import P from 'assets/styledComponents/fonts/P';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';
import UseDesktopBanner from 'assets/styledComponents/UseDesktopBanner';

const Activity2 = () => {
    let [globalState] = useGlobalContext();
    let devicesize = globalState.deviceSize;

    let figureData = [
        {
            id: 1,
            src: `${Image1}`,
            altText: `Calorie King and Happy Forks logos`,
            captionIntro: `Websites \nsuch as`,
            captionExample: `Calorie King`,
            urlExample: 'https://www.calorieking.com/',
            captionExample2: `Happy Forks`,
            urlExample2: 'https://happyforks.com/',
        },
        {
            id: 2,
            src: `${Image2}`,
            altText: `Calorie King and Nutritionix logos`,
            captionIntro: `Mobile apps\nsuch as`,
            captionExample: `Calorie King`,
            urlExample: 'https://www.calorieking.com/',
            captionExample2: `Nutritionix`,
            urlExample2: 'https://www.nutritionix.com/',
        },
        {
            id: 3,
            src: `${Image3}`,
            altText: `BC Children's Hospital logo`,
            captionIntro: `BC Children's Hospital\nHandout`,
            captionExample: `Common Baking Ingredients and\ntheir Nutrient Contents`,
            urlExample:
                'https://learndiabetesstorage.azureedge.net/learn-diabetes-prod/assets/carbabking_1cefb97d21.pdf',
            captionExample2: ``,
            urlExample2: '',
        },
    ];

    return (
        <>
            <H2
                className={devicesize === 'mobile' ? 'header-4' : 'header-3'}
                marginBottom={devicesize === 'mobile' ? '2.4rem' : '3.2rem'}
            >
                Tools for Calculating Carbohydrate Amounts
            </H2>

            <P
                className={devicesize === 'mobile' ? 'body-2' : 'body-1'}
                marginBottom={devicesize === 'mobile' ? '2.4rem' : '4rem'}
            >
                Now that you know which ingredients to count, the next step is
                to calculate the carbohydrate amount for the portions in the
                recipe.
            </P>

            <ToolsContainer devicesize={devicesize}>
                {devicesize === 'desktopMd' || devicesize === 'desktopSm' ? (
                    <P
                        color="primaryDarkest"
                        className="body-1"
                        marginBottom="1.2rem"
                    >
                        This can be done using different tools
                    </P>
                ) : (
                    ''
                )}

                <div
                    className={
                        devicesize === 'mobile'
                            ? 'flex-column'
                            : 'flex-space-around'
                    }
                >
                    {figureData.map((fig) => (
                        <Figure
                            key={fig.id}
                            src={fig.src}
                            altText={fig.altText}
                            captionIntro={fig.captionIntro}
                            captionExample={fig.captionExample}
                            urlExample={fig.urlExample}
                            captionExample2={fig.captionExample2}
                            urlExample2={fig.urlExample2}
                            devicesize={devicesize}
                        />
                    ))}
                </div>
            </ToolsContainer>

            <H2
                className={devicesize === 'mobile' ? 'header-4' : 'header-3'}
                marginBottom={devicesize === 'mobile' ? '2.4rem' : '3.2rem'}
            >
                Calculate the Carb Amounts in the Recipe
            </H2>

            <P
                className={devicesize === 'mobile' ? 'body-2' : 'body-1'}
                marginBottom={devicesize === 'mobile' ? '2.4rem' : '4rem'}
            >
                To calculate the carbohydrate amounts, you can simply search the
                food for the portion listed in the recipe.
            </P>
            {devicesize === 'mobile' && (
                <UseDesktopBanner marginBottom="3.2rem" />
            )}

            <ActivityCalc />

            <P
                className={devicesize === 'mobile' ? 'body-2' : 'body-1'}
                marginTop={devicesize === 'mobile' ? '2.4rem' : '4rem'}
            >
                If you want to do it by hand, you can use the BC Children’s
                Hospital Handout: Common Baking Ingredients and their Nutrient
                Contents. This will be shown in the next module.
            </P>
        </>
    );
};

const ToolsContainer = styled.section`
    border: ${(props) =>
        props.devicesize === 'mobile' || props.devicesize === 'tablet'
            ? ''
            : `0.3rem solid ${props.theme.colors.tertiary}`};
    border-radius: 2rem;
    padding: ${(props) =>
        props.devicesize === 'mobile' || props.devicesize === 'tablet'
            ? ''
            : '4rem 6rem 5.8rem'};
    margin-bottom: ${(props) =>
        props.devicesize === 'mobile' || props.devicesize === 'tablet'
            ? '6.4rem'
            : '7.2rem'};
    text-align: center;

    div {
        flex-wrap: wrap;
    }
`;
export default Activity2;
