import MyStudyCoursesCard from 'components/DashboardHome/MyStudyCoursesCard';
import SectionHeader from 'components/DashboardHome/SectionHeader';
import SectionSubHeader from 'components/DashboardHome/SectionSubHeader';
import ShowTracksCards from 'components/Tracks/ShowTracksCards';
import React from 'react';

const MyStudySectionMobile = () => {
    return (
        <>
            <SectionHeader title="My study" />
            <SectionSubHeader
                title="My modules"
                linkTitle="see all"
                path={`/dashboard/mymodules`}
                marginTop="1.6rem"
                paddingBottom="1.6rem"
            />
            <MyStudyCoursesCard isMobile={true} />
            <SectionSubHeader
                title="My pathways"
                linkTitle="see all"
                path={`dashboard/mypathways`}
                marginTop="2.7rem"
                paddingBottom="1.6rem"
            />
            <ShowTracksCards isDashboard={true} isMobile={true} />
        </>
    );
};

export default MyStudySectionMobile;
