import H1 from 'assets/styledComponents/fonts/H1';
import React from 'react';

const SectionHeader = ({ title, marginTop, marginBottom }) => {
    return (
        <H1
            className="header-4"
            color="darkText"
            textTransform="uppercase"
            marginTop={marginTop}
            marginBottom={marginBottom}
        >
            {title}
        </H1>
    );
};

export default SectionHeader;
