import { React, useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { activity1Data } from './activity1Data';
import InteractiveActivityButton from 'assets/styledComponents/buttons/InteractiveActivityButton';
import H3 from 'assets/styledComponents/fonts/H3';
import P from 'assets/styledComponents/fonts/P';
import checked from 'assets/icons/checked.svg';
import Container from 'assets/styledComponents/Container';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';
import UseDesktopBanner from 'assets/styledComponents/UseDesktopBanner';
import {
    ActivityHeader,
    StyledHintButton,
} from '../../SharedComponentsInteractive';

const Activity1 = () => {
    let [globalState] = useGlobalContext();
    let devicesize = globalState.deviceSize;

    const initialState = {
        ingredient1: false,
        ingredient2: false,
        ingredient3: false,
        ingredient4: false,
        ingredient5: false,
        ingredient6: false,
        ingredient7: false,
        ingredient8: false,
        ingredient9: false,
        ingredient10: false,
    };

    const [checkedItems, setCheckedItems] = useState({
        ...initialState,
    });

    // to keep answer container items styled when trying again when some items were incorrect
    const [correctlySubmittedBefore, setCorrectlySubmittedBefore] = useState(
        {}
    );

    const correctItems = {
        ingredient2: true,
        ingredient3: true,
        ingredient6: true,
        ingredient10: true,
    };

    const incorrectItems = {
        ingredient1: false,
        ingredient4: false,
        ingredient5: false,
        ingredient7: false,
        ingredient8: false,
        ingredient9: false,
    };

    const [hasSubmitted, setHasSubmitted] = useState(false);
    let [score, setScore] = useState(0);
    let [numOfSelections, setNumOfSelections] = useState(0);

    useEffect(() => {
        if (!hasSubmitted) {
            return;
        }
        let correctKeys = Object.keys(correctItems);
        setScore(
            correctKeys.map((item) => checkedItems[item]).filter((item) => item)
                .length
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkedItems, hasSubmitted]);

    const handleCheckAnswers = () => {
        setHasSubmitted(true);

        if (hasSubmitted && score !== 4) {
            setHasSubmitted(false);
            setCheckedItems({
                ...checkedItems,
                ...incorrectItems,
            });
            setCorrectlySubmittedBefore({
                ...checkedItems,
                ...incorrectItems,
            });
        }
    };

    const handleTryAgainAfterAllCorrect = () => {
        setHasSubmitted(false);
        setCheckedItems({
            ...initialState,
        });
        setCorrectlySubmittedBefore({});
    };

    const onChange = (event) => {
        // limit num of selections to 6 and allow changes before submission
        if (numOfSelections === 4 && event.target.checked && !hasSubmitted)
            return false;

        if (numOfSelections > 3 && hasSubmitted) return false;
        else {
            setCheckedItems({
                ...checkedItems,
                [event.target.name]: event.target.checked,
            });
            if (
                !event.target.checked &&
                correctlySubmittedBefore[event.target.name]
            ) {
                setCorrectlySubmittedBefore({
                    ...correctlySubmittedBefore,
                    [event.target.name]: event.target.checked,
                });
            }
        }
    };

    useEffect(
        () => {
            setNumOfSelections(
                Object.values(checkedItems).reduce(
                    (acc, val) => (acc += !!val),
                    0
                )
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [checkedItems]
    );

    const [showHint1, setShowHint1] = useState(false);
    const hintRef = useRef(null);

    const scrollUp = () => {
        return hintRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    };

    const handleHint1 = () => {
        setShowHint1(true);
        scrollUp();

        setTimeout(() => {
            setShowHint1(false);
        }, 3000);
    };

    return (
        <>
            {devicesize === 'mobile' && (
                <UseDesktopBanner marginBottom="3.2rem" />
            )}
            <Container width="100%" className="flex-space-between">
                <ActivityHeader
                    instructions="Identifying Which Foods You Need To Count"
                    marginBottom={devicesize === 'mobile' ? '1.6rem' : '3.6rem'}
                />
            </Container>

            <P
                className={devicesize === 'mobile' ? 'body-2' : 'body-1'}
                marginBottom={devicesize === 'mobile' ? '2.4rem' : '4rem'}
            >
                Looking at this recipe for chocolate chip cookies, start out by
                identifying which foods you would need to count. Which
                ingredients would raise blood glucose?
            </P>

            <div className="flex" ref={hintRef}>
                <Container
                    className={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? 'flex-all-centered'
                            : ''
                    }
                    width={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? '100vw'
                            : ''
                    }
                    marginLeft={devicesize === 'mobile' ? '-2.5rem' : ''}
                    marginRight={devicesize === 'mobile' ? '-2.5rem' : ''}
                >
                    {(hasSubmitted && devicesize === 'mobile') ||
                    (hasSubmitted && devicesize === 'tablet') ? (
                        <AnswerContainer devicesize={devicesize}>
                            {activity1Data.map((item) => {
                                let itemName = 'ingredient' + item.id;

                                return (
                                    <AnswerItem
                                        htmlFor={itemName}
                                        className={
                                            correctItems[itemName] &&
                                            hasSubmitted &&
                                            checkedItems[itemName]
                                                ? 'showInAnswerContainer correct'
                                                : correctlySubmittedBefore[
                                                      itemName
                                                  ]
                                                ? 'showInAnswerContainer correct'
                                                : !correctItems[itemName] &&
                                                  hasSubmitted &&
                                                  checkedItems[itemName]
                                                ? 'showInAnswerContainer incorrect'
                                                : !hasSubmitted &&
                                                  checkedItems[itemName]
                                                ? 'showInAnswerContainer'
                                                : 'visible'
                                        }
                                        key={item.id}
                                    >
                                        <P
                                            className={
                                                devicesize === 'mobile'
                                                    ? 'body-2'
                                                    : 'body-1'
                                            }
                                            minHeight="2.4rem"
                                        >
                                            {item.label}
                                        </P>
                                    </AnswerItem>
                                );
                            })}
                            <P className="petit-title-serif score">
                                {score}/7 Correct
                            </P>
                        </AnswerContainer>
                    ) : (
                        <Form devicesize={devicesize}>
                            {devicesize !== 'mobile' &&
                            devicesize !== 'tablet' ? (
                                <H3
                                    className="body-1"
                                    textAlign="center"
                                    marginBottom="2.8rem"
                                >
                                    Chocolate Chip Cookies
                                </H3>
                            ) : (
                                ''
                            )}
                            {activity1Data.map((item) => {
                                let itemName = 'ingredient' + item.id;

                                return (
                                    <Label
                                        htmlFor={itemName}
                                        className={
                                            showHint1 && !correctItems[itemName]
                                                ? 'hint-style flex-center-aligned'
                                                : 'flex-center-aligned'
                                        }
                                        key={item.id}
                                    >
                                        <Input
                                            type="checkbox"
                                            className="checkbox flex-all-centered"
                                            id={itemName}
                                            checked={
                                                checkedItems[itemName] === true
                                            }
                                            onChange={onChange}
                                            name={itemName}
                                        />
                                        <P
                                            className={
                                                devicesize === 'mobile'
                                                    ? 'body-2'
                                                    : 'body-1'
                                            }
                                        >
                                            {item.label}
                                        </P>
                                    </Label>
                                );
                            })}
                            {devicesize === 'mobile' ||
                            devicesize === 'tablet' ? (
                                <P
                                    className="petit-title-serif selectedAmount"
                                    marginTop="1.6rem"
                                >
                                    {numOfSelections}/3
                                </P>
                            ) : (
                                ''
                            )}
                        </Form>
                    )}
                </Container>

                {devicesize === 'mobile' || devicesize === 'tablet' ? (
                    ''
                ) : (
                    <AnswerContainer>
                        <H3
                            className="body-1"
                            textAlign="center"
                            marginBottom="2.8rem"
                        >
                            Chocolate Chip Cookies
                        </H3>

                        {activity1Data.map((item) => {
                            let itemName = 'ingredient' + item.id;

                            return (
                                <AnswerItem
                                    htmlFor={itemName}
                                    className={
                                        correctItems[itemName] &&
                                        hasSubmitted &&
                                        checkedItems[itemName]
                                            ? 'showInAnswerContainer correct'
                                            : correctlySubmittedBefore[itemName]
                                            ? 'showInAnswerContainer correct'
                                            : !correctItems[itemName] &&
                                              hasSubmitted &&
                                              checkedItems[itemName]
                                            ? 'showInAnswerContainer incorrect'
                                            : !hasSubmitted &&
                                              checkedItems[itemName]
                                            ? 'showInAnswerContainer'
                                            : ''
                                    }
                                    key={item.id}
                                >
                                    <p className="body-1">{item.label}</p>
                                </AnswerItem>
                            );
                        })}
                        <P className="petit-title-serif score">
                            {score}/4 Correct
                        </P>
                    </AnswerContainer>
                )}
            </div>

            {/* Buttons  */}
            <Container
                marginTop="4rem"
                marginBottom="7.2rem"
                className="flex-all-centered"
            >
                {score === 4 && hasSubmitted ? (
                    <InteractiveActivityButton
                        isCorrect={true}
                        onClick={handleTryAgainAfterAllCorrect}
                        className="secondary"
                    />
                ) : (
                    <InteractiveActivityButton
                        isCorrect={false}
                        onClick={handleCheckAnswers}
                        className={
                            numOfSelections !== 4
                                ? 'disabledBtn'
                                : hasSubmitted && score !== 4
                                ? 'incorrect'
                                : 'secondary'
                        }
                        hasError={hasSubmitted && score !== 4}
                    />
                )}
                <StyledHintButton onClick={() => handleHint1()} />
            </Container>
        </>
    );
};

const Form = styled.form`
    width: ${(props) => (props.devicesize === 'mobile' ? '100vw' : '')};
    background-color: ${(props) => props.theme.colors.tertiary};
    border-radius: ${(props) => (props.devicesize === 'mobile' ? '' : '2rem')};
    padding: 2rem 3.2rem 4rem;
    position: relative;

    p.selectedAmount {
        position: absolute;
        text-align: center;
        bottom: 1.1rem;
        width: calc(100% - 6.4rem);
    }
`;

const AnswerContainer = styled.div`
    width: ${(props) => (props.devicesize === 'mobile' ? '100vw' : '')};
    background-color: ${(props) => props.theme.colors.tertiary};
    border-radius: ${(props) => (props.devicesize === 'mobile' ? '' : '2rem')};
    padding: 2rem 3.2rem 4rem;
    margin-left: ${(props) =>
        props.devicesize === 'mobile' || props.devicesize === 'tablet'
            ? '0rem'
            : '2.8rem'};
    position: relative;

    p.score {
        position: absolute;
        text-align: center;
        bottom: 1.1rem;
        width: calc(100% - 6.4rem);
    }
`;

const AnswerItem = styled.div`
    background: ${(props) => props.theme.colors.lightBackground};
    border: 0.3rem solid ${(props) => props.theme.colors.lightBackground};
    margin-bottom: 0.8rem;
    padding: 0.5rem 4.5rem;

    @media screen and (max-width: ${(props) =>
            props.theme.breakPoints.desktopSm}) {
        padding: 0.5rem 2rem 0.5rem 4.5rem;
    }
    border-radius: 5rem;

    :last-of-type {
        margin-bottom: 0;
    }

    visibility: hidden;

    &.showInAnswerContainer {
        visibility: visible;
    }

    &.correct {
        border: 0.3rem solid ${(props) => props.theme.colors.success};
    }

    &.incorrect {
        border: 0.3rem solid ${(props) => props.theme.colors.alertRed};
    }

    &.visible {
        visibility: visible;
    }
`;

const Input = styled.input`
    display: inline-block;

    &.checkbox {
        margin-right: 1.6rem;
        min-width: 2.4rem;
        min-height: 2.4rem;

        -moz-appearance: none;
        -webkit-appearance: none;
        -o-appearance: none;
        outline: 1px solid ${(props) => props.theme.colors.greyText};
        border-radius: 0.5rem;
    }

    :checked {
        background-image: url(${checked});
        background-repeat: no-repeat;
        background-position: center;
    }

    :hover {
        cursor: pointer;
    }
`;

const Label = styled.label`
    background: ${(props) => props.theme.colors.lightBackground};
    border: 0.3rem solid ${(props) => props.theme.colors.lightBackground};
    margin-bottom: 0.8rem;
    padding: 0.5rem 4.8rem 0.5rem 0.5rem;

    &.hint-style {
        opacity: 0.2;
    }
    @media screen and (max-width: ${(props) =>
            props.theme.breakPoints.desktopSm}) {
        padding: 0.5rem 2rem 0.5rem 0.5rem;
    }

    border-radius: 5rem;
    :last-of-type {
        margin-bottom: 0;
    }
`;
export default Activity1;
