import React, { useEffect, useState } from 'react';
import CardContent from './CardContent';
import StyledCourseCard from 'assets/styledComponents/Container';

const CourseCard = ({
    course,
    view,
    imageURL,
    title,
    description,
    slug,
    progress,
    lastLesson,
    lastPage,
    totalLessons,
    handleDelete,
    isDashboard,
    width,
    height,
    imageHeight,
    margin,
    progressBarWidth,
    marginBottom,
    isMobile,
}) => {
    const [hovered, setHovered] = useState(false);

    useEffect(() => {
        setHovered(false);
    }, []);

    return (
        <StyledCourseCard
            className="card-shadow flex-column-flex-start"
            borderRadius="2rem"
            width={width}
            height={height}
            marginBottom={marginBottom}
            position="relative"
            background="lightBackground"
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            isMobile={isMobile}
        >
            <CardContent
                course={course}
                view={view}
                hovered={hovered}
                progress={progress}
                slug={slug}
                lastPage={lastPage}
                title={title}
                description={description}
                lastLesson={lastLesson}
                imageURL={imageURL}
                totalLessons={totalLessons}
                handleDelete={handleDelete}
                isDashboard={isDashboard}
                imageHeight={imageHeight}
                margin={margin}
                progressBarWidth={progressBarWidth}
                isMobile={isMobile}
            />
        </StyledCourseCard>
    );
};

export default CourseCard;
