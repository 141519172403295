import React from 'react';
import styled from '@emotion/styled';
import CourseSlider from './Carousel/CourseSlider';
import MixedFontSectionHeader from './MixedFontSectionHeader';
import { useInView } from 'react-intersection-observer';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';

const CarouselSection = () => {
    const [ref, inView] = useInView({
        threshold: 0,
        triggerOnce: true,
    });

    const [globalState] = useGlobalContext();

    return (
        <SectionContainer ref={ref} devicesize={globalState.deviceSize}>
            <MixedFontSectionHeader
                inView={inView}
                innerRef={ref}
                title="Explore Our Modules"
                landingPageCarouselSection={true}
            />
            <CourseSlider
                devicesize={globalState.deviceSize}
                authUser={globalState.auth}
                globalState={globalState}
            />
        </SectionContainer>
    );
};

const SectionContainer = styled.section`
    width: 100%;
    padding: ${(props) =>
        props.devicesize === 'mobile'
            ? '4.8rem 2.4rem 7.2rem 2.4rem'
            : props.devicesize === 'tablet'
            ? '7.2rem 4.8rem 10.4rem'
            : '9.6rem 10rem 10.4rem'};
    background-color: ${(props) => props.theme.colors.tertiary};
`;

export default CarouselSection;
