import React from 'react';
import Container from 'assets/styledComponents/Container';
import StyledImage from 'assets/styledComponents/StyledImage';
import P from 'assets/styledComponents/fonts/P';
import adolescent from 'assets/images/interactive-course5-8/adolescent.svg';
import Title from './Title';
import ListItem from './ListItem';
import H1 from 'assets/styledComponents/fonts/H1';

const Adolescent = ({ devicesize }) => {
    return (
        <Container
            className={
                devicesize === 'mobile' || devicesize === 'tablet'
                    ? 'flex-column-left'
                    : 'flex-start-aligned'
            }
            marginTop={
                devicesize === 'mobile' || devicesize === 'tablet'
                    ? '4.8rem'
                    : ''
            }
        >
            {devicesize === 'mobile' || devicesize === 'tablet' ? (
                <H1 className="header-4">Adolescents</H1>
            ) : (
                ''
            )}
            <center>
                <StyledImage
                    src={adolescent}
                    alt="adolescent boy"
                    marginTop={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? ''
                            : '3.8rem'
                    }
                />
            </center>
            <Container
                marginLeft={
                    devicesize === 'mobile' || devicesize === 'tablet'
                        ? ''
                        : '4rem'
                }
            >
                {devicesize !== 'mobile' && devicesize !== 'tablet' ? (
                    <Title title="Adolescents" />
                ) : (
                    ''
                )}

                <P
                    className={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? 'body-2'
                            : 'body-4'
                    }
                >
                    Allow the space for your child to make some independent
                    choices around eating, but maintain structure to help them
                    learn consistency and balance.
                </P>
                <P
                    className={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? 'body-2'
                            : 'body-4'
                    }
                    marginTop={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? '2.4rem'
                            : '3.2rem'
                    }
                >
                    Resistance to diabetes care related to meals, snacks,
                    exercise, blood glucose checks, and insulin dosing is common
                    during this stage. Validate your adolescent’s feelings, and
                    reassure them with consistent and caring messaging.
                </P>
                <P
                    className={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? 'body-2'
                            : 'body-4'
                    }
                    marginTop={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? '2.4rem'
                            : '3.2rem'
                    }
                >
                    It is not realistic to expect someone this age to be
                    completely independent with their diabetes care. Adolescents
                    still need support from caregivers for diabetes care needs.
                </P>
                <P
                    className={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? 'body-2'
                            : 'body-4'
                    }
                    marginTop={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? '2.4rem'
                            : '3.2rem'
                    }
                >
                    Tips for supporting independent diabetes care:
                </P>
                <ul>
                    <ListItem
                        content="Remind your child about diabetes care-related activities,
                        when needed. If they have been living with diabetes for some
                        time, it is still normal and expected that they will forget
                        activities like blood glucose checks and insulin dosing). Be kind,
                        consistent, and assertive with your reminders."
                    />
                    <ListItem
                        content="Discuss your child’s skill and confidence level for dosing
                    insulin at school with your school’s nursing support
                    services coordinator and/or diabetes care team as needed.
                    With guidance, your child will be able to identify carbohydrate
                    containing foods, perform basic carb counting math, and/or
                    memorize carb content in their favourite or common foods."
                    />
                    <ListItem
                        content="Start conversations with your child about how they feel about
                    their body and their diabetes care, and the various
                    influences in society. A discussion around the social media
                    they consume is especially important at this age."
                    />
                </ul>
                <P
                    className={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? 'body-2'
                            : 'body-4'
                    }
                    marginTop={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? '2.4rem'
                            : '3.2rem'
                    }
                >
                    Encourage independent decision-making, but provide support
                    as needed. The goal is for them to be involved in regular
                    activities and socialization.
                </P>
            </Container>
        </Container>
    );
};

export default Adolescent;
