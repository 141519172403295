import styled from '@emotion/styled';
import React from 'react';

const CourseCardPlaceholder = () => {
    return <Card />;
};

const Card = styled.div`
    border: 0.3rem dashed ${(props) => props.theme.colors.lightBackground};
    border-radius: 2rem;
    width: 16rem;
    height: 25rem;
`;

export default CourseCardPlaceholder;
