import { useGlobalContext } from 'GlobalContext/GlobalProvider';
import React from 'react';
import CardSize from '../../types/cardSize';
import FormatTracksViewCount from '../../utils/helpers/FormatTracksViewCount';
import CardAddTrack from '../DashboardHome/CardAddTrack';
import MyStudyTracksCardPlaceholder from '../DashboardHome/MyStudyTracksCardPlaceholder';
import TrackCardSmall from '../DashboardHome/TrackCardSmall';
import BadgesAchievement from '../../assets/styledComponents/BadgesAchievement';
import badgesType from '../../types/badgesType';

const ShowDashboardHomeTracks = ({ tracksWithProgress, isMobile }) => {
    const [globalState] = useGlobalContext();
    const isDesktop =
        globalState.deviceSize === 'desktopSm' ||
        globalState.deviceSize === 'desktopMd';
    const myTracksMaxLength = isDesktop ? 2 : 1;
    const cardsToDisplay = [];
    let trackCompleted = false;

    for (let i = 0; i < myTracksMaxLength; ++i) {
        if (tracksWithProgress && tracksWithProgress.length > i) {
            const track = tracksWithProgress[i];
            if (track.progress === 100) {
                trackCompleted = true;
            }
            // use Strapi thumbnail optimized image size if available,
            // else use original uploaded image

            let thumbnailImage =
                track.trackImage.image.formats &&
                track.trackImage.image.formats.thumbnail &&
                track.trackImage.image.formats.thumbnail.url;
            let nonOptimizedImage = track.trackImage.image.url;

            cardsToDisplay.push(
                <TrackCardSmall
                    key={`track-${track.id}`}
                    numOfCourses={track.courses.length}
                    estimatedTime={track.estimatedTimeInMinutes}
                    numOfViews={FormatTracksViewCount(track.courses)}
                    slug={track.slug}
                    trackTitle={track.trackTitle}
                    trackSubtitle={track.subtitle}
                    trackImage={
                        thumbnailImage ? thumbnailImage : nonOptimizedImage
                    }
                    trackImageAlternativeText={track.trackImage.alternativeText}
                    progress={track.progress}
                    isMobile={isMobile}
                />
            );
        } else if (tracksWithProgress && tracksWithProgress.length === i) {
            cardsToDisplay.push(<CardAddTrack key={i} size={CardSize.SMALL} />);
        } else {
            cardsToDisplay.push(<MyStudyTracksCardPlaceholder key={i} />);
        }
    }
    return (
        <div>
            {trackCompleted ? (
                <BadgesAchievement id={badgesType.TRACK_STAR} />
            ) : (
                ''
            )}
            {cardsToDisplay}
        </div>
    );
};

export default ShowDashboardHomeTracks;
