import { React, Fragment, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { cx } from '@emotion/css';
import StyledInput from '../SignInAndSignUp/StyledInput';
import Body1 from '../../assets/styledComponents/fonts/Body1';
import Body2 from '../../assets/styledComponents/fonts/Body2';
import PetitTitle1 from '../../assets/styledComponents/fonts/PetitTitle1';
import Header3 from '../../assets/styledComponents/fonts/Header3';
import FilledButtonMd from '../../assets/styledComponents/buttons/FilledButtonMd';
import noEntry from '../../assets/icons/noEntry.svg';
import resetPasswordFlyingLock from '../../assets/images/resetPasswordFlyingLock.svg';

const ResetPasswordForm = ({
    setResetSuccess,
    setErrorMessage,
    errorMessage,
}) => {
    const [formFields, setFormFields] = useState({
        password: '',
        confirmPassword: '',
    });

    const onChange = (event) => {
        setFormFields({
            ...formFields,
            [event.target.name]: event.target.value,
        });
    };

    // remove error message if user clears a pw field
    useEffect(() => {
        if (!formFields.password.length || !formFields.confirmPassword.length) {
            setErrorMessage('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formFields]);

    const handleResetPassword = (event) => {
        event.preventDefault();

        const resetPasswordUrl = `${process.env.REACT_APP_API_URL}/auth/reset-password`;

        // Get code in url sent by Strapi
        const queryParams = window.location.search;
        const params = new URLSearchParams(queryParams);
        const code = params.get('code');

        const data = {
            code: code,
            password: formFields.password,
            passwordConfirmation: formFields.confirmPassword,
        };

        const resetPW = async (url, data) => {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
            return response.json();
        };

        if (formFields.password.length < 8) {
            setErrorMessage('Password should have 8 or more characters.');
        } else if (formFields.confirmPassword.length === 0) {
            setErrorMessage('Passwords do not match.');
        } else {
            resetPW(resetPasswordUrl, data).then((res) => {
                if (!res.statusCode) {
                    setResetSuccess(true);
                } else {
                    res.message[0].messages[0].message ===
                    'Incorrect code provided.'
                        ? setErrorMessage(
                              'This password reset link is no longer valid.'
                          )
                        : setErrorMessage(res.message[0].messages[0].message);
                }
            });
        }
    };

    return (
        <Fragment>
            <StyledForm action="">
                <StyledImage src={resetPasswordFlyingLock} />
                <HeaderContainer>
                    <Header3 marginBottom="0.5rem">Reset Your Password</Header3>
                    <Body1 color="greyText">
                        Password should be more than 8 characters long.
                    </Body1>
                </HeaderContainer>
                <ErrorContainer>
                    <ErrorMessage className="flex-center-aligned">
                        {errorMessage.length ? (
                            <Fragment>
                                <img src={noEntry} alt="Error, no entry icon" />
                                {errorMessage}
                            </Fragment>
                        ) : (
                            ''
                        )}
                    </ErrorMessage>
                </ErrorContainer>
                <div className="flex-space-between">
                    {/* Hidden username field for accessibility */}
                    <Input
                        hidden
                        type="text"
                        id="username"
                        name="username"
                        autoComplete="username"
                        required
                    />
                    <PetitTitle1>
                        {' '}
                        <label htmlFor="Password">New Password</label>
                    </PetitTitle1>
                    <Input
                        type="password"
                        id="password"
                        name="password"
                        placeholder="New Password"
                        onChange={onChange}
                        autoComplete="new-password"
                        required
                        className={cx(
                            { ['error']: errorMessage.length },
                            { [undefined]: !errorMessage.length }
                        )}
                    />
                </div>
                <div className="flex-space-between">
                    <PetitTitle1>
                        <label htmlFor="Confirm Password">
                            Confirm New Password
                        </label>
                    </PetitTitle1>
                    <Input
                        type="password"
                        id="confirmPassword"
                        name="confirmPassword"
                        placeholder="Confirm New Password"
                        onChange={onChange}
                        autoComplete="new-password"
                        className={cx(
                            { ['error']: errorMessage.length },
                            { [undefined]: !errorMessage.length }
                        )}
                    />
                </div>
                <FilledButtonMd
                    width="26rem"
                    margin="6rem auto"
                    onClick={handleResetPassword}
                >
                    Save
                </FilledButtonMd>
            </StyledForm>
        </Fragment>
    );
};

const StyledForm = styled.form`
    width: 64.2rem;
    position: relative;
`;

const HeaderContainer = styled.header`
    margin-bottom: 3rem;
`;

const ErrorContainer = styled.div`
    height: 2rem;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    right: 0;
`;

const ErrorMessage = styled(Body2)`
    color: ${(props) => props.theme.colors.primary};
    width: 40rem;

    img {
        margin-right: 0.8rem;
    }
`;

const StyledImage = styled.img`
    position: absolute;
    right: -5.6rem;
    top: -10.3rem;
`;

const Input = styled(StyledInput)`
    width: 40rem;
    padding: 0.5rem 1rem;
    margin: 1rem 0;
`;

export default ResetPasswordForm;
