import React from 'react';
import styled from '@emotion/styled';
import step4Img1 from 'assets/images/interactiveBook-course2/step4Img1.svg';
import nextChevron from 'assets/images/interactiveBook-course2/nextChevron.svg';
import H3 from 'assets/styledComponents/fonts/H3';
import H4 from 'assets/styledComponents/fonts/H4';
import Container from 'assets/styledComponents/Container';
import { Step4ListItems } from './Step4ListItems';
import prevChevron from 'assets/images/interactiveBook-course2/prevChevron.svg';
import NextOrPrevPageButton from './NextOrPrevPageButton';

const Step4A = ({ setPageToShow, devicesize, setActivePage }) => {
    return (
        <>
            <Header>
                <H3
                    className="header-3"
                    marginBottom={devicesize === 'tablet' ? 0 : '2.5rem'}
                >
                    Step 4
                </H3>
                <H4 className="body-2" color="success" marginBottom="1.6rem">
                    Check your blood glucose
                </H4>
            </Header>
            <Container
                className="flex-all-centered"
                height="100%"
                marginTop="-8rem"
            >
                <Container
                    width="50%"
                    padding={
                        devicesize === 'tablet'
                            ? '1rem 1.5rem'
                            : '2rem 4rem 1.8rem 2.8rem'
                    }
                >
                    <UL devicesize={devicesize}>
                        {Step4ListItems.map((item) => {
                            return (
                                <LI key={item.id} devicesize={devicesize}>
                                    <figure
                                        className={
                                            devicesize !== 'tablet'
                                                ? 'flex-center-aligned'
                                                : 'tablet'
                                        }
                                    >
                                        <img
                                            src={item.img}
                                            alt={item.imgAltText}
                                        />
                                        <figcaption>{item.text}</figcaption>
                                    </figure>
                                </LI>
                            );
                        })}
                    </UL>
                    <NextOrPrevPageButton
                        className="flex-all-centered prev"
                        image={prevChevron}
                        alt={'orange chevron pointing left'}
                        setActivePage={setActivePage}
                        setActivePageNum={3}
                        title={'PREV'}
                    />
                </Container>
                <StyledFigure className="flex-column-centered">
                    <img
                        src={step4Img1}
                        alt="An illustration of an arrow pointing to a button on a lancing device"
                    />
                    <figcaption className="body-2">
                        Once positioned, press the button on your lancing device
                        to puncture the skin.
                    </figcaption>
                </StyledFigure>
            </Container>
            <NextOrPrevPageButton
                className={'flex-all-centered next'}
                image={nextChevron}
                alt={'orange chevron pointing left'}
                setPageToShow={setPageToShow}
                setPageToShowNum={4}
                title={'NEXT'}
            />
        </>
    );
};

const StyledFigure = styled.figure`
    width: 50%;
    padding: 0 8rem 0 6rem;
    margin-top: -5rem;

    img {
        margin-bottom: 1.5rem;
        width: 23rem;
        min-width: 15rem;
        max-width: 80%;
    }

    figcaption {
        text-align: center;
        width: 23rem;
        min-width: 15rem;
        max-width: 80%;
    }
`;

const UL = styled.ul`
    height: 100%;
    padding-bottom: ${(props) =>
        props.devicesize === 'tablet' ? 0 : '1.8rem'};
`;

const LI = styled.li`
    figure {
        img {
            margin-right: ${(props) =>
                props.devicesize === 'desktopMd' ? '3rem' : '1.5rem'};
            margin-bottom: 2rem;
            width: ${(props) =>
                props.devicesize === 'tablet'
                    ? '6rem'
                    : props.devicesize === 'desktopSm'
                    ? '7.5rem'
                    : '8.5rem'};
        }

        figcaption {
            text-align: center;
        }

        &.tablet {
            overflow: auto;
            margin-bottom: 1rem;

            img {
                float: left;
                margin-right: 1rem;
                margin-bottom: 1rem;
            }

            figcaption {
                text-align: left;
            }
        }
    }
`;

const Header = styled.header`
    width: calc(50% - 6.8rem);
    text-align: center;
    border-bottom: 0.1rem solid ${(props) => props.theme.colors.tertiary};
    margin-left: 2.8rem;
    margin-bottom: 2.5rem;
`;

export default Step4A;
