import React from 'react';
import styled from '@emotion/styled';
import P from 'assets/styledComponents/fonts/P';
import { Link } from '../../SharedComponentsInteractive';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';

const ListItem = ({ content, href, title }) => {
    const [globalState] = useGlobalContext();
    const devicesize = globalState.deviceSize;
    const isDesktop = devicesize !== 'mobile' && devicesize !== 'tablet';

    return (
        <>
            {devicesize === 'mobile' || devicesize === 'tablet' ? (
                <>
                    <Item isDesktop={isDesktop}>
                        <P
                            className="body-2"
                            marginRight="2.3rem"
                            marginTop="2.6rem"
                        >
                            {content}
                        </P>
                        {href && (
                            <P className="body-2" marginTop="2.2rem">
                                <Link
                                    marginLeft="4.8rem"
                                    marginRight="4.6rem"
                                    href={href}
                                    title={title}
                                />
                            </P>
                        )}
                    </Item>{' '}
                </>
            ) : (
                <Item className="list-with-link" isDesktop={isDesktop}>
                    <P className="body-4" marginTop="3.2rem">
                        {content}
                    </P>
                    {href && (
                        <P className="body-2" marginTop="0.8rem">
                            <Link href={href} title={title} />
                        </P>
                    )}
                </Item>
            )}
        </>
    );
};

const Item = styled.li`
    position: relative;
    margin-left: 2.3rem;
    margin-bottom: 4rem;
    margin-right: 1.5rem;

    :last-of-type {
        margin-bottom: 0;
    }

    :before {
        position: absolute;
        content: ' ';
        border-radius: 50%;
        width: ${(props) => (props.isDesktop ? '0.8rem' : '0.6rem')};
        height: ${(props) => (props.isDesktop ? '0.8rem' : '0.6rem')};
        background: ${(props) =>
            props.isDesktop ? props.theme.colors.primary : '#000000'};
        color: ${(props) => props.theme.colors.darkText};
        bottom: calc(50% - 0.3rem);
        top: ${(props) => (props.isDesktop ? '0.9rem' : '0.6rem')};
        left: -2.4rem;
    }

    &.list-with-link {
        margin-bottom: 2.4rem;

        :before {
            bottom: calc(60%);
        }
        :last-of-type {
            margin-bottom: 0;
        }
    }
`;

export default ListItem;
