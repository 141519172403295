import React from 'react';
import carbresource from 'assets/documents/carbresource.pdf';
import PDFCarousel from '../../../PDFCarousel';
import FoodsToCount from './FoodsToCount';
import CalorieCalcActivity from './CalorieCalcActivity';

const ResourceActivity = ({ devicesize }) => {
    return (
        <div>
            <PDFCarousel pdf={carbresource} devicesize={devicesize} />
            <FoodsToCount devicesize={devicesize} />
            <CalorieCalcActivity devicesize={devicesize} />
        </div>
    );
};

export default ResourceActivity;
