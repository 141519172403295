import React, { useState } from 'react';
import ageGroups from 'types/ageGroups';
import Content from './Content';
import Navigation from './Navigations';

const ToolsForCalculatingCarbohydrateAmounts = () => {
    const [activeTab, setActiveTab] = useState(ageGroups.LESS_6_MONTHS);

    const onTabClick = (value) => {
        setActiveTab(value);
    };

    return (
        <>
            <Navigation activeTab={activeTab} onTabClick={onTabClick} />
            <Content activeTab={activeTab} />
        </>
    );
};

export default ToolsForCalculatingCarbohydrateAmounts;
