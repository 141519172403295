import React from 'react';
import { HeaderContainer } from './SharedComponentsDashboard';
import SectionHeader from './SectionHeader';
import LinkButton from '../../assets/styledComponents/buttons/LinkButton';
import WeekProgressSection from '../DashboardStudyPlan/WeekProgressSection';
import { useGlobalContext } from '../../GlobalContext/GlobalProvider';
import { useQuery } from '@apollo/client';
import { GET_GOALS_TRACKER } from '../../graphql/queries';
import Container from 'assets/styledComponents/Container';

const StudyPlanSection = ({ isDesktop, isDesktopSm, devicesize }) => {
    const [globalState, dispatch] = useGlobalContext();

    const { data: goalData } = useQuery(GET_GOALS_TRACKER, {
        variables: {
            id: globalState.user ? globalState.user.trackingLogId : '',
        },
        skip: globalState.user ? false : true,
    });

    let goals = goalData ? goalData.trackingLog.goalTracker : '';

    return (
        <Container marginTop={devicesize === 'mobile' ? '2.6rem' : '3.3rem'}>
            {devicesize === 'mobile' ? (
                <SectionHeader title="study plan" marginBottom="1.4rem" />
            ) : (
                <HeaderContainer>
                    <SectionHeader title="study plan" />
                    <LinkButton
                        linkTitle="go to the page"
                        path={`dashboard/studyplan`}
                    />
                </HeaderContainer>
            )}

            <WeekProgressSection
                isOnDashboardHomePage={true}
                goals={goals}
                globalState={globalState}
                dispatch={dispatch}
                height={
                    isDesktop
                        ? '28rem'
                        : devicesize === 'mobile'
                        ? 'auto'
                        : '32rem'
                }
                width={
                    devicesize === 'desktopMd'
                        ? '56rem'
                        : devicesize === 'desktopSm'
                        ? '50rem'
                        : '100%'
                }
                isDesktopSm={isDesktopSm}
                padding={devicesize === 'mobile' ? '0 0.8rem' : '0 3.2rem'}
                devicesize={devicesize}
            />
        </Container>
    );
};

export default StudyPlanSection;
