import H2 from 'assets/styledComponents/fonts/H2';
import React from 'react';

const SubTitle = ({ data, isMobile }) => {
    return (
        <H2
            className={isMobile ? 'header-4' : 'header-3'}
            dangerouslySetInnerHTML={{ __html: data.subTitle }}
            marginBottom="2.4rem"
        ></H2>
    );
};

export default SubTitle;
