import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { useGlobalContext } from '../../GlobalContext/GlobalProvider';
import CardSize from '../../types/cardSize';
import CardAddCourse from './CardAddCourse';
import CourseCardPlaceholder from './CourseCardPlaceholder';
import CourseCardSmall from './CourseCardSmall';
import CourseCardSmallHorizontal from './CourseCardSmallHorizontal';
import backpackSm from '../../assets/images/backpackSm.svg';
import CardAddStudyHorizontal from './CardAddStudyHorizontal';

const MyStudyCoursesCard = ({ isMobile, isTablet, isDesktopSm }) => {
    const [globalState] = useGlobalContext();
    const [myCourses, setMyCourses] = useState(
        globalState.user ? globalState.user.trackingLog.courseTracker : null
    );
    const myCoursesMaxLength = isDesktopSm ? 2 : 3;

    useEffect(() => {
        setMyCourses(
            globalState.user ? globalState.user.trackingLog.courseTracker : null
        );
        return () => setMyCourses(null);
    }, [globalState]);

    const items = Object.values(myCourses);

    const cardsToDisplay = [];
    for (let i = 0; i < myCoursesMaxLength; ++i) {
        if (items.length > i) {
            const course = items[i];
            isMobile
                ? cardsToDisplay.push(
                      <CourseCardSmallHorizontal
                          key={course.id}
                          course={course}
                      />
                  )
                : cardsToDisplay.push(
                      <CourseCardSmall key={course.id} course={course} />
                  );
        } else if (items.length === i) {
            isMobile
                ? cardsToDisplay.push(
                      <CardAddStudyHorizontal
                          key={i}
                          image={backpackSm}
                          title="Ready to learn?"
                          activity="Add a module"
                          link="/dashboard/mymodules"
                      />
                  )
                : cardsToDisplay.push(
                      <CardAddCourse key={i} size={CardSize.SMALL} />
                  );
        } else {
            isMobile
                ? cardsToDisplay.push()
                : cardsToDisplay.push(<CourseCardPlaceholder key={i} />);
        }
    }

    return (
        <MyStudyCoursesCardContainer
            className={isMobile ? '' : ' flex-row-flex-start'}
            isMobile={isMobile}
            isTablet={isTablet}
        >
            {cardsToDisplay}
        </MyStudyCoursesCardContainer>
    );
};

const MyStudyCoursesCardContainer = styled.div`
    height: ${(props) => (props.isMobile ? 'auto' : ' 25rem')};
    gap: 2.5rem;
`;

export default MyStudyCoursesCard;
