import styled from '@emotion/styled';
import React from 'react';
import CourseCardsNavigation from './CoursesCardsNavigation';
import DashboardPagesHeader from 'assets/styledComponents/DashboardPagesHeader';
import tracksMd from 'assets/icons/dbMyCourses.svg';
import PageContentContainer from 'assets/styledComponents/PageContentContainer';

const MyCoursesDashboardHeader = ({
    view,
    setView,
    coursesToShow,
    setCoursesToShow,
    numOfCourses,
    onSearchChange,
    isDesktop,
}) => {
    return (
        <HeaderContainer>
            <PageContentContainer
                marginTop="4rem"
                marginRight="7rem"
                marginLeft="4.5rem"
            ></PageContentContainer>
            <DashboardPagesHeader icon={tracksMd} pageTitle="MY MODULES" />
            <CourseCardsNavigation
                view={view}
                setView={setView}
                coursesToShow={coursesToShow}
                setCoursesToShow={setCoursesToShow}
                numOfCourses={numOfCourses}
                onSearchChange={onSearchChange}
                isDashboard="true"
                isDesktop={isDesktop}
            />
        </HeaderContainer>
    );
};

const HeaderContainer = styled.header`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    border-bottom: 0.4rem solid ${(props) => props.theme.colors.lightGrey};
    padding-bottom: 0.6rem;
`;

export default MyCoursesDashboardHeader;
