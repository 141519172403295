import React from 'react';
import styled from '@emotion/styled';
import nextChevron from 'assets/images/interactiveBook-course2/nextChevron.svg';
import step2Img1 from 'assets/images/interactiveBook-course2/step2Img1.svg';
import Container from 'assets/styledComponents/Container';
import StyledImage from 'assets/styledComponents/StyledImage';
import H3 from 'assets/styledComponents/fonts/H3';
import { Step2ListItems } from './Step2ListItems';
import prevChevron from 'assets/images/interactiveBook-course2/prevChevron.svg';
import NextOrPrevPageButton from './NextOrPrevPageButton';

const Step2A = ({ setPageToShow, devicesize, setActivePage }) => {
    return (
        <>
            <H3 className="header-3" textAlign="center" width="50%">
                Step 2
            </H3>
            <Container className="flex-all-centered" height="100%">
                <StyledFigure className="flex-column-centered">
                    <StyledImage src={step2Img1} />
                    <figcaption className="body-2">
                        Set up your lancing device
                    </figcaption>
                </StyledFigure>
                <NextOrPrevPageButton
                    className="flex-all-centered prev"
                    image={prevChevron}
                    alt={'orange chevron pointing left'}
                    setActivePage={setActivePage}
                    setActivePageNum={1}
                    title={'PREV'}
                />

                <Container
                    width="50%"
                    height="calc(100% + 7rem)"
                    padding={
                        devicesize === 'tablet'
                            ? '0 2rem 1.8rem'
                            : '0 4rem 1.8rem 2rem'
                    }
                    marginTop={devicesize === 'tablet' && '-5rem'}
                >
                    <UL>
                        {Step2ListItems.map((item) => {
                            return (
                                <LI
                                    key={item.id}
                                    className="flex-center-aligned"
                                    devicesize={devicesize}
                                >
                                    <div className="flex-end-justified">
                                        <img
                                            src={item.img}
                                            alt={item.imgAltText}
                                        />
                                    </div>
                                    {item.text}
                                </LI>
                            );
                        })}
                    </UL>
                </Container>
            </Container>
            <NextOrPrevPageButton
                className={'flex-all-centered next'}
                image={nextChevron}
                alt={'orange chevron pointing left'}
                setPageToShow={setPageToShow}
                setPageToShowNum={3}
                title={'NEXT'}
            />
        </>
    );
};

const StyledFigure = styled.figure`
    width: 50%;
    margin-top: -5rem;

    img {
        max-width: 80%;
    }

    figcaption {
        margin-top: 2rem;
    }
`;

const UL = styled.ul`
    height: 100%;
    padding-bottom: 1.8rem;
`;

const LI = styled.li`
    height: 25%;

    border-bottom: 0.1rem solid ${(props) => props.theme.colors.tertiary};

    div {
        min-width: 4.5rem;
        margin-right: ${(props) =>
            props.devicesize === 'tablet' ? '1rem' : '5rem'};
    }
`;

export default Step2A;
