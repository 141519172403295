import React from 'react';
import Header3 from '../../assets/styledComponents/fonts/Header3';
import Body1 from '../../assets/styledComponents/fonts/Body1';
import HomeButton from './HomeButton';
import designTwo404 from '../../assets/images/designTwo404.svg';
import Container from '../../assets/styledComponents/Container';
import StyledImage from '../../assets/styledComponents/StyledImage';

const DesignTwo = () => {
    return (
        <Container
            className="flex-column-all-centered"
            padding="16rem 0 4rem"
            height="100vh"
        >
            <StyledImage
                width="100vw"
                src={designTwo404}
                alt="404 text between an illustration of an unplugged cord"
            />
            <Header3 margin="10rem 0 2rem" color="darkText">
                Oops! Something&apos;s wrong.
            </Header3>

            <Body1 color="greyText">
                This page is missing or you may have typed the address
                incorrectly.
            </Body1>

            <HomeButton />
        </Container>
    );
};

export default DesignTwo;
