import React from 'react';
import course2BookPages from 'types/course2BookPages';
import IntroPage from './IntroPage';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';

const ShowPage = ({ activePage, setActivePage, devicesize }) => {
    const setPage = () => {
        switch (activePage) {
            case course2BookPages.INTRO:
                return (
                    <IntroPage
                        devicesize={devicesize}
                        setActivePage={setActivePage}
                    />
                );
            case course2BookPages.ONE:
                return (
                    <Step1
                        devicesize={devicesize}
                        setActivePage={setActivePage}
                    />
                );
            case course2BookPages.TWO:
                return (
                    <Step2
                        devicesize={devicesize}
                        setActivePage={setActivePage}
                    />
                );
            case course2BookPages.THREE:
                return (
                    <Step3
                        devicesize={devicesize}
                        setActivePage={setActivePage}
                    />
                );
            case course2BookPages.FOUR:
                return (
                    <Step4
                        devicesize={devicesize}
                        setActivePage={setActivePage}
                    />
                );
            case course2BookPages.FIVE:
                return (
                    <Step5
                        devicesize={devicesize}
                        setActivePage={setActivePage}
                    />
                );

            default:
                return (
                    <IntroPage
                        devicesize={devicesize}
                        setActivePage={setActivePage}
                    />
                );
        }
    };
    return <>{setPage()}</>;
};

export default ShowPage;
