import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { React, useEffect, useState } from 'react';
import PageContentContainer from '../assets/styledComponents/PageContentContainer';
import PageMainContainer from '../assets/styledComponents/PageMainContainer';
import { useGlobalContext } from '../GlobalContext/GlobalProvider';
import { GET_ALL_COURSES } from '../graphql/queries';
import CoursesToShow from '../types/coursesToShow';
import Layout from '../types/layout';
import AllCoursesPageContentMobile from './AllCoursesPageContentMobile';
import AllCoursesPageContent from './AllCoursesPageContent';
import filterCoursesBySearchValue from 'utils/helpers/filterCoursesBySearchValue';
import sortCourses from 'utils/helpers/SortCourses';
import sortMyCourses from 'utils/helpers/SortMyCourses';

const AllCoursesPage = () => {
    const [globalState] = useGlobalContext();
    const getCourses = useQuery(GET_ALL_COURSES);
    const allCourses = getCourses && getCourses.data && getCourses.data.courses;
    const [filterValue, setFilterValue] = useState('');
    const [view, setView] = useState(Layout.CARD_VIEW);
    const [coursesToShow, setCoursesToShow] = useState(CoursesToShow.ALL);
    const authUser = globalState.auth;

    const [myCourses, setMyCourses] = useState(
        globalState.user ? globalState.user.trackingLog.courseTracker : null
    );

    const isDesktop =
        globalState.deviceSize === 'desktopSm' ||
        globalState.deviceSize === 'desktopMd';
    const isMobile = globalState.deviceSize === 'mobile';

    //Introducing this effect to avoid memory leak warning for this component
    // TODO update as warning appears for logged out users
    useEffect(() => {
        setMyCourses(
            globalState.user ? globalState.user.trackingLog.courseTracker : null
        );
        return () => setMyCourses(null);
    }, [globalState]);

    const [numOfCourses, setNumOfCourses] = useState({
        all: allCourses && allCourses.length,
        inProgress: 0,
        completed: 0,
    });

    const [allFilteredItemsLength, setAllFilteredItemsLength] = useState(
        allCourses && allCourses.length
    );

    let [numOfCoursesCompleted, setNumOfCoursesCompleted] = useState(0);

    let [numOfCoursesInProgress, setNumOfCoursesInProgress] = useState(0);

    const onSearchChange = (value) => {
        setFilterValue(value);

        setAllFilteredItemsLength(
            allCourses && filterCoursesBySearchValue(allCourses, value).length
        );

        if (authUser) {
            setNumOfCoursesInProgress(
                filterCoursesBySearchValue(
                    Object.values(myCourses),
                    value
                ).filter((c) => !c.courseCompleted).length
            );

            setNumOfCoursesCompleted(
                filterCoursesBySearchValue(
                    Object.values(myCourses),
                    value
                ).filter((c) => c.courseCompleted).length
            );
        }
    };

    useEffect(() => {
        setNumOfCourses({
            all: allFilteredItemsLength,
            inProgress: numOfCoursesInProgress,
            completed: numOfCoursesCompleted,
        });
    }, [allFilteredItemsLength, numOfCoursesInProgress, numOfCoursesCompleted]);

    useEffect(() => {
        setView(Layout.CARD_VIEW);
    }, [isDesktop]);

    return (
        <PageMainContainer
            className="below-navbar flex-column-centered"
            background="lightGreyBackground"
        >
            <AllCoursesPageContainer marginLeft="8.8rem" marginRight="8.8rem">
                {isMobile ? (
                    <AllCoursesPageContentMobile
                        authUser={authUser}
                        onSearchChange={onSearchChange}
                        filterValue={filterValue}
                        view={view}
                        allCourses={allCourses && sortCourses(allCourses)}
                        myCourses={myCourses && sortMyCourses(myCourses)}
                        coursesToShow={coursesToShow}
                        setCoursesToShow={setCoursesToShow}
                    />
                ) : (
                    <AllCoursesPageContent
                        authUser={authUser}
                        onSearchChange={onSearchChange}
                        filterValue={filterValue}
                        view={view}
                        setView={setView}
                        allCourses={allCourses && sortCourses(allCourses)}
                        myCourses={myCourses && sortMyCourses(myCourses)}
                        coursesToShow={coursesToShow}
                        setCoursesToShow={setCoursesToShow}
                        numOfCourses={numOfCourses}
                        isDesktop={isDesktop}
                    />
                )}
            </AllCoursesPageContainer>
        </PageMainContainer>
    );
};

const AllCoursesPageContainer = styled(PageContentContainer)`
    min-height: calc(
        100vh - 24rem
    ); // 24rem = 9rem(top navbar) + 15rem(padding for PageMainContainer)
`;

export default AllCoursesPage;
