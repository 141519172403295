import { React, useState, useRef } from 'react';
import styled from '@emotion/styled';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';
import UseDesktopBanner from 'assets/styledComponents/UseDesktopBanner';
import Container from 'assets/styledComponents/Container';
import StyledImage from 'assets/styledComponents/StyledImage';
import insulinActionGraph3 from 'assets/images/interactive-course6-5/insulinActionGraph3.svg';
import insulinActionGraphNoText from 'assets/images/interactive-course6-5/insulinActionGraph3-no-text.svg';
import insulinActionGraphText from 'assets/images/interactive-course6-5/insulinActionGraph3-text.svg';
import BolusCalcPreventInsulinStacking from './BolusCalcPreventInsulinStacking';
import ActivityHeaderWithInstructions from './ActivityHeaderWithInstructions';

const PreventInsulinStackingActivity = () => {
    const [showHint, setShowHint] = useState(false);
    const [globalState] = useGlobalContext();
    const { deviceSize } = globalState;

    const isMobile = Boolean(deviceSize === 'mobile');
    const isTablet = Boolean(deviceSize === 'tablet');
    const isDesktop = Boolean(!isMobile && !isTablet);
    const responsivePClass = isMobile ? 'body-2' : 'body-4';

    const hintRef = useRef(null);

    const scrollUp = () => {
        return hintRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    };

    const handleHintClick = (e) => {
        e.preventDefault();
        setShowHint(true);
        scrollUp();
        setTimeout(() => {
            setShowHint(false);
        }, 3000);
    };

    const ResponsiveGraph = () => {
        if (isDesktop) {
            return (
                <StyledImage
                    src={insulinActionGraph3}
                    alt="A graph showing insulin action over time."
                    width="100%"
                />
            );
        }
        return (
            <Container width="100%">
                <StyledImage
                    src={insulinActionGraphNoText}
                    alt="A graph showing insulin action over time."
                    width="100%"
                    marginBottom="4rem"
                />
                <StyledImage
                    src={insulinActionGraphText}
                    alt="A graph showing insulin action over time."
                />
            </Container>
        );
    };

    return (
        <>
            {isDesktop ? (
                <>
                    <Paragraph marginBottom={'3rem'}>
                        How do can you give insulin for your snack without
                        insulin stacking?
                    </Paragraph>
                    <Paragraph marginBottom={'3rem'}>
                        Insulin stacking only occurs with correction boluses. It
                        is important not to give correction boluses too close
                        together, but you can always give a food bolus for
                        additional carbohydrates. Your diabetes team will
                        discuss this with you
                    </Paragraph>
                </>
            ) : null}
            {deviceSize === 'mobile' ? (
                <UseDesktopBanner marginTop="0.6rem" marginBottom="2.4rem" />
            ) : (
                ''
            )}
            <ActivityHeaderWithInstructions />
            <Container ref={hintRef}>
                <Paragraph
                    className={responsivePClass}
                    margin={
                        isDesktop
                            ? '3rem 0 4rem'
                            : isMobile
                            ? '-1.8rem 0 3.2rem'
                            : '-0.6rem 0 3.2rem'
                    }
                >
                    It’s time for morning snack and it’s about an hour after
                    giving a bolus of 5.5 units for breakfast. You check your
                    blood glucose and it is 15.3 mmol/L and you plan to have a
                    snack containing{' '}
                    <StyledSpan className={showHint ? 'showHint' : ''}>
                        26 g carbs. Your carb ratio is 15.
                    </StyledSpan>{' '}
                    Calculate how much insulin to give for just the food bolus.
                </Paragraph>

                <BolusCalcPreventInsulinStacking
                    handleHintClick={handleHintClick}
                />

                <Paragraph
                    className={responsivePClass}
                    color="darkText"
                    margin={isDesktop ? '7.2rem 0 4rem' : '4rem 0 4rem'}
                >
                    Let’s take a look at the graph after only giving a food
                    bolus. You can see that the blood glucose did not go low
                    since a correction bolus was not given. To prevent insulin
                    stacking, remember not to give a correction bolus within 3
                    hours of a previous insulin injection. If you will be having
                    a snack requiring a bolus within 3 hours, make sure to only
                    give a food bolus.
                </Paragraph>

                <ResponsiveGraph />
            </Container>
        </>
    );
};

const StyledSpan = styled.span`
    &.showHint {
        color: ${(props) => props.theme.colors.success};
    }
`;

const Paragraph = styled.p`
    font-family: 'DMSans', sans-serif;
    font-size: ${(props) => (props.isMobile ? '1.4rem' : '1.7rem')};
    line-height: ${(props) => (props.isMobile ? '1.8rem' : '2.4rem')};
    letter-spacing: 0.01em;
    margin-bottom: ${(props) => props.marginBottom};
    margin: ${(props) => props.margin};
`;

export default PreventInsulinStackingActivity;
