import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

const StyledLink = styled(Link)`
    text-decoration: none;
    color: inherit;

    :hover {
        cursor: pointer;
    }
`;

export default StyledLink;
