import React from 'react';
import H3 from 'assets/styledComponents/fonts/H3';
import P from 'assets/styledComponents/fonts/P';
import Container from 'assets/styledComponents/Container';
import StyledImage from 'assets/styledComponents/StyledImage';
import step4Img2 from 'assets/images/interactiveBook-course2/step4Img2.svg';
import step4Img3 from 'assets/images/interactiveBook-course2/step4Img3.svg';
import prevChevron from 'assets/images/interactiveBook-course2/prevChevron.svg';
import nextChevron from 'assets/images/interactiveBook-course2/nextChevron.svg';
import NextOrPrevPageButton from './NextOrPrevPageButton';

const Step4B = ({ setPageToShow, devicesize, setActivePage }) => {
    return (
        <>
            <Container className="flex-column-centered" width="50%">
                <H3 className="header-3">Step 4</H3>
                <H3 className="petit-title-2" color="tertiaryDarkest">
                    CONTINUED
                </H3>
            </Container>
            <Container
                className="flex-all-centered"
                height="100%"
                paddingBottom="2.8rem"
            >
                <Container
                    width="50%"
                    padding={
                        devicesize === 'tablet' ? '0 2rem' : '4.5rem 4rem 0'
                    }
                >
                    <P marginBottom="2rem">
                        Bring your finger below the level of your heart and
                        squeeze from the base of your finger up to the tip to
                        get a large enough sample. If you cannot get a good
                        sample, you can reset your lancing device to do another
                        finger check. Your diabetes team can show you how to do
                        this.
                    </P>
                    <StyledImage
                        src={step4Img2}
                        float="right"
                        height={devicesize === 'tablet' ? '20rem' : '25rem'}
                    />
                </Container>

                <Container
                    width="50%"
                    className="flex-column-centered"
                    marginTop={devicesize === 'tablet' && '-3rem'}
                >
                    <StyledImage src={step4Img3} />
                    <P
                        margin={
                            devicesize === 'tablet'
                                ? '1.8rem'
                                : '4rem 4rem 5rem'
                        }
                    >
                        When you have your sample, take your meter and use the
                        test strip to collect the sample. The meter will count
                        down when the sample is collected and display your blood
                        glucose level once the test is complete. This takes
                        about 5 seconds. If the sample is too small, an error
                        message will appear on the screen. Replace the test
                        strip with a new one and repeat the check.
                    </P>
                </Container>
            </Container>

            <NextOrPrevPageButton
                className="flex-all-centered prev"
                image={prevChevron}
                alt={'orange chevron pointing left'}
                setPageToShow={setPageToShow}
                setPageToShowNum={1}
                title={'PREV'}
            />
            <NextOrPrevPageButton
                className={'flex-all-centered next'}
                image={nextChevron}
                alt={'orange chevron pointing left'}
                setActivePage={setActivePage}
                setActivePageNum={5}
                title={'NEXT'}
            />
        </>
    );
};

export default Step4B;
