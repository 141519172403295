import { React } from 'react';
import calendarMd from '../assets/icons/calendarMd.svg';
import PageContentContainer from '../assets/styledComponents/PageContentContainer';
import ScrollContainer from '../assets/styledComponents/ScrollContainer';
import DashboardPagesHeader from '../assets/styledComponents/DashboardPagesHeader';
import WeekProgressSection from '../components/DashboardStudyPlan/WeekProgressSection';
import LastAcquiredBadgeSection from '../components/DashboardStudyPlan/LastAcquiredBadgeSection';
import MyBadgesSection from '../components/DashboardStudyPlan/MyBadgesSection';
import { useQuery } from '@apollo/client';
import { useGlobalContext } from '../GlobalContext/GlobalProvider';
import { GET_GOALS_TRACKER, GET_ALL_BADGES } from '../graphql/queries';
import BadgesAchievement from '../assets/styledComponents/BadgesAchievement';
import badgesType from '../types/badgesType';
import Container from 'assets/styledComponents/Container';

const DashboardStudyPlanPage = () => {
    const [globalState, dispatch] = useGlobalContext();
    let devicesize = globalState.deviceSize;

    const getBadges = useQuery(GET_ALL_BADGES);
    const allBadges = getBadges?.data?.badges;

    const { data: goalData } = useQuery(GET_GOALS_TRACKER, {
        variables: {
            id: globalState.user ? globalState.user.trackingLogId : '',
        },
        skip: globalState.user ? false : true,
    });

    let goals = goalData ? goalData.trackingLog.goalTracker : '';

    const isDesktopMd = globalState.deviceSize === 'desktopMd';

    return (
        <ScrollContainer>
            {goals?.daysPerWeekGoal > 0 && (
                <BadgesAchievement id={badgesType.STRATEGIC_LEARNER} />
            )}
            <PageContentContainer
                marginTop="4rem"
                marginRight="7rem"
                marginLeft="4.5rem"
            >
                {devicesize !== 'mobile' && (
                    <DashboardPagesHeader
                        icon={calendarMd}
                        pageTitle="STUDY PLAN & PROGRESS"
                    />
                )}
                <Container
                    className="flex-justify-space-between"
                    colGap="4rem"
                    marginBottom={devicesize === 'mobile' ? '1.6rem' : '4rem'}
                >
                    <WeekProgressSection
                        goals={goals}
                        globalState={globalState}
                        dispatch={dispatch}
                        height={devicesize === 'mobile' ? 'auto' : '41rem'}
                        width={isDesktopMd ? '66rem' : '100%'}
                        padding={
                            devicesize === 'mobile' ? '0 0.8rem' : '0 3.2rem'
                        }
                        devicesize={devicesize}
                    />

                    {isDesktopMd && (
                        <LastAcquiredBadgeSection
                            globalState={globalState}
                            allBadges={allBadges}
                        />
                    )}
                </Container>
                <MyBadgesSection
                    globalState={globalState}
                    allBadges={allBadges}
                    devicesize={devicesize}
                />
            </PageContentContainer>
        </ScrollContainer>
    );
};

export default DashboardStudyPlanPage;
