import React from 'react';
import styled from '@emotion/styled';
import { useQuery } from '@apollo/client';
import { GET_LANDING_PAGE_CREATED_BY_SECTION } from 'graphql/queries';
import H3 from 'assets/styledComponents/fonts/H3';
import { useInView } from 'react-intersection-observer';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';
import MixedFontSectionHeader from './MixedFontSectionHeader';

const CreatedBy = () => {
    const {
        loading: createdByLoading,
        data: createdByData,
        error: createdByError,
    } = useQuery(GET_LANDING_PAGE_CREATED_BY_SECTION);

    const [ref, inView] = useInView({
        threshold: 0.5,
        triggerOnce: true,
    });

    const [globalState] = useGlobalContext();
    let { deviceSize } = globalState;

    const isMobile = Boolean(deviceSize === 'mobile');
    const isTablet = Boolean(deviceSize === 'tablet');
    const isDesktopSm = Boolean(deviceSize === 'desktopSm');
    const isDesktopMd = Boolean(deviceSize === 'desktopMd');

    if (createdByLoading) return <p>Loading section...</p>;
    if (createdByError) return <p>Section error</p>;

    return (
        <>
            <BackgroundUnderBorderRadius2 role="presentation"></BackgroundUnderBorderRadius2>
            <MainContainer
                className="flex-all-centered"
                isMobile={isMobile}
                isTablet={isTablet}
                isDesktopSm={isDesktopSm}
                isDesktopMd={isDesktopMd}
            >
                <SectionContainer
                    ref={ref}
                    inView={inView}
                    className={
                        inView
                            ? 'animate-content flex-column-centered'
                            : 'flex-column-centered'
                    }
                    isMobile={isMobile}
                >
                    <MixedFontSectionHeader
                        inView={inView}
                        innerRef={ref}
                        title={
                            createdByData?.landingPage?.createdBySection
                                ?.cursiveText
                        }
                        whatIsLD={true}
                    />
                    <H3
                        className="body-4"
                        width={isMobile ? '100%' : '69rem'}
                        margin={isMobile ? '0 3.6rem' : ''}
                        padding={isTablet ? '0 3.6rem' : ''}
                        color="primaryDarkest"
                    >
                        {
                            createdByData?.landingPage?.createdBySection
                                ?.mainTitleText
                        }
                    </H3>
                    <Image
                        src={
                            createdByData?.landingPage?.createdBySection?.image
                                ?.url
                        }
                        alt={
                            createdByData?.landingPage?.createdBySection?.image
                                ?.imageAlternativeText
                        }
                    />
                </SectionContainer>
            </MainContainer>
        </>
    );
};

const MainContainer = styled.div`
    background: ${(props) =>
        props.isMobile ? 'white' : props.theme.colors.tertiary};

    padding: ${(props) =>
        props.isMobile
            ? '0rem'
            : props.isTablet
            ? '0 1.6rem 12rem 1.6rem'
            : props.isDesktopSm
            ? '0 7rem 14.4rem'
            : props.isDesktopMd && '0 7rem 12rem'};

    padding-bottom: 10rem;
`;

const SectionContainer = styled.section`
    background: ${(props) => (props.isMobile ? 'white' : '#F2F4F8')};
    padding-top: ${(props) => (props.isMobile ? '' : '10rem')};
    border-radius: ${(props) => (props.isMobile ? '' : '50rem')};

    margin-top: 10rem;
    width: 100%;
    max-width: 124.4rem;
    height: 70.5rem;
    text-align: center;
    position: relative;
    overflow: hidden;
    padding-left: 3.5rem;
    padding-right: 3.5rem;

    @media screen and (max-width: ${(props) =>
            props.theme.breakPoints.isMobile}) {
        height: 69rem;
    }

    opacity: 0;
    transition: opacity 500ms ease-in;

    &.animate-content {
        opacity: 1;
    }
`;

const Image = styled.img`
    height: 37rem;
    position: absolute;
    bottom: 0;
    @media screen and (max-width: ${(props) =>
            props.theme.breakPoints.desktopMd}) {
        height: 27.5rem;
    }
`;

const BackgroundUnderBorderRadius2 = styled.div`
    height: 100rem;
    position: absolute;
    background: white;
    border-radius: none;
    z-index: 0;
    width: 100%;

    border-top-right-radius: ${(props) =>
        props.coloredBackground && !props.isMobile && `15rem`};
    border-top-left-radius: ${(props) =>
        !props.coloredBackground && !props.isMobile && `15rem`};
`;

export default CreatedBy;
