import styled from '@emotion/styled';
import React from 'react';
import finishedCourse from 'assets/icons/finishedCourse.svg';

const CompletedBadgeContainer = () => {
    return (
        <CompletedBadge>
            <img
                src={finishedCourse}
                alt="orange badge indicating that course has been completed"
            />
        </CompletedBadge>
    );
};

const CompletedBadge = styled.div`
    position: absolute;
    top: 0;
    left: 10%;
`;

export default CompletedBadgeContainer;
