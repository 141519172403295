import React from 'react';
import styled from '@emotion/styled';
import Body1 from './fonts/Body1';

const ToastNotification = ({ message, success, failure, top }) => {
    return (
        <Container success={success} failure={failure} top={top}>
            <Body1>{message}</Body1>
        </Container>
    );
};

const Container = styled.div`
    background-color: ${(props) =>
        props.success ? props.theme.colors.tertiary : props.failure && 'red'};
    padding: ${(props) => props.theme.spaces.xxs};
    border-radius: 1rem;
    width: fit-content;
    /* set the parent to relative */
    position: absolute;
    top: ${(props) => (props.top ? props.top : 0)};

    animation: showNotification ease-in-out 3s forwards;

    @keyframes showNotification {
        0% {
            opacity: 0;
            visibility: none;
        }
        20% {
            opacity: 1;
            visibility: visible;
        }
        100% {
            opacity: 0;
            visibility: none;
        }
    }
`;

export default ToastNotification;
