import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import React from 'react';
import { useParams } from 'react-router-dom';
import PageContentContainer from '../assets/styledComponents/PageContentContainer';
import PageMainContainer from '../assets/styledComponents/PageMainContainer';
import ShowTrackCourses from '../components/Tracks/ShowTrackCourses';
import IndividualTracksPageHeader from '../components/Tracks/IndividualTracksPageHeader';
import { useGlobalContext } from '../GlobalContext/GlobalProvider';
import { GET_ALL_COURSES_IN_TRACK } from '../graphql/queries';

const IndividualTrackPage = () => {
    const [globalState] = useGlobalContext();
    const { trackSlug } = useParams();
    const { data, loading, error } = useQuery(GET_ALL_COURSES_IN_TRACK, {
        variables: { slug: trackSlug },
    });
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error</p>;
    const isDesktop =
        globalState.deviceSize === 'desktopSm' ||
        globalState.deviceSize === 'desktopMd';

    const isMobile = globalState.deviceSize === 'mobile';

    const isDesktopMd = globalState.deviceSize === 'desktopMd';

    return (
        <StyledMainContainer className="below-navbar">
            <div
                className={
                    isDesktopMd
                        ? 'flex-column-centered'
                        : 'flex-column-flex-start'
                }
            >
                <PageContentContainer
                    marginLeft={isDesktop ? '8.8rem' : ''}
                    marginRight={isDesktop ? '8.8rem' : ''}
                    width={isDesktopMd ? '116rem' : ''}
                >
                    <IndividualTracksPageHeader
                        isMobile={isMobile}
                        data={data}
                    />
                </PageContentContainer>
            </div>

            <StyledMainContainer>
                <ShowTrackCourses
                    data={data}
                    globalState={globalState}
                    isDesktopMd={isDesktopMd}
                />
            </StyledMainContainer>
        </StyledMainContainer>
    );
};

const StyledMainContainer = styled(PageMainContainer)`
    background: ${(props) => props.theme.colors.lightGreyGradient};
    padding-bottom: 0;
`;

export default IndividualTrackPage;
