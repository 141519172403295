import { React, Fragment } from 'react';
import styled from '@emotion/styled';
import DeleteButton from '../../../../assets/styledComponents/buttons/DeleteButton';
import lessonSmGrey from '../../../../assets/icons/lessonSmGrey.svg';
import courseSmGrey from '../../../../assets/icons/courseSmGrey.svg';
import Body1 from '../../../../assets/styledComponents/fonts/Body1';
import CardButtonMd from '../../../../assets/styledComponents/buttons/CardButtonMd';
import LinkWrappingButton from '../../../../assets/styledComponents/buttons/LinkWrappingButton';
import CardContainer from '../../../../assets/styledComponents/Container';
import { UPDATE_SAVED_LESSONS_TRACKER } from '../../../../graphql/mutations';
import { useMutation } from '@apollo/client';
import { saveUser } from '../../../../GlobalContext/actionVariables';
import _ from 'lodash';
import Container from '../../../../assets/styledComponents/Container';

const SavedLessonCard = ({
    lessonName,
    lessonID,
    lessonNum,
    url,
    courseName,
    savedLessons,
    globalState,
    dispatch,
}) => {
    const isMobile = Boolean(globalState.deviceSize === 'mobile');

    const [updateSavedLessonsTracker] = useMutation(
        UPDATE_SAVED_LESSONS_TRACKER
    );

    const handleDelete = () => {
        // waiting on designs for 'are you sure you want to delete this lesson'
        const updatedData = _.omit(savedLessons, lessonID);

        updateSavedLessonsTracker({
            variables: {
                id: globalState.user.trackingLogId,
                data: {
                    savedLessonsTracker: updatedData,
                },
            },
        });

        dispatch({
            type: saveUser,
            payload: {
                ...globalState.user,
                trackingLog: {
                    ...globalState.user.trackingLog,
                    savedLessonsTracker: updatedData,
                },
            },
        });
    };

    const content = [
        {
            icon: courseSmGrey,
            alt: 'course icon',
            iconTitle: 'Module',
            title: courseName,
        },
        {
            icon: lessonSmGrey,
            alt: 'lesson icon',
            iconTitle: `Lesson ${lessonNum}`,
            title: lessonName,
        },
    ];

    return (
        <CardContainer
            className={`${isMobile ? '' : 'flex-space-between'} card-shadow `}
            borderRadius="2rem"
            paddingRight="3rem"
            backgroundColor="lightBackground"
            marginBottom="2rem"
            position="relative"
        >
            <LessonInfoSection className="flex-column" isMobile={isMobile}>
                <HoverOverlay className="nextLessonButton flex-space-between">
                    <LinkWrappingButton to={url}>
                        {' '}
                        <CardButtonMd width="33rem">View Lesson</CardButtonMd>
                    </LinkWrappingButton>
                </HoverOverlay>

                <Fragment>
                    {content.map((item) => (
                        <IconAndDetail key={item.alt} isMobile={isMobile}>
                            <ItemAndTitle isMobile={isMobile}>
                                <Item>
                                    <img
                                        src={item.icon}
                                        alt={item.alt}
                                        style={{ marginTop: '0.2rem' }}
                                    />
                                </Item>
                                <Item>
                                    <Body1
                                        color="greyText"
                                        className="maxOneLineText"
                                        grey
                                    >
                                        {item.iconTitle}
                                    </Body1>
                                </Item>
                            </ItemAndTitle>
                            <Item>
                                <Body1
                                    className="maxOneLineText"
                                    color="darkText"
                                >
                                    {item.title}
                                </Body1>
                            </Item>
                        </IconAndDetail>
                    ))}
                </Fragment>
            </LessonInfoSection>
            <Container
                className={isMobile ? 'flex-center-justified' : ''}
                paddingBottom={isMobile ? '1.6rem' : ''}
            >
                <DeleteButton handleDelete={handleDelete} />
            </Container>
        </CardContainer>
    );
};

const HoverOverlay = styled.div`
    max-width: 106rem;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.colors.lightBackground};
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 2rem;
    display: none;
    padding: 2.5rem 3rem;
`;

const LessonInfoSection = styled.section`
    height: 100%;
    padding: ${(props) =>
        props.isMobile ? '2.4rem 2.4rem 0rem' : '2.5rem 3rem'};
    :hover .nextLessonButton {
        display: flex;
    }
`;

const Item = styled.div`
    display: flex;
    align-items: center;
`;

const IconAndDetail = styled.div`
    display: ${(props) => (props.isMobile ? '' : 'flex')};

    justify-content: start;
    margin-bottom: ${(props) => (props.isMobile ? '1.6rem' : '')};
`;

const ItemAndTitle = styled.div`
    display: flex;
    column-gap: 1.5rem;
    width: 16rem !important;
    margin-bottom: ${(props) => (props.isMobile ? '0.8rem' : '')};
`;

export default SavedLessonCard;
