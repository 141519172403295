import styled from '@emotion/styled';
import React, { Suspense } from 'react';
const DragDropContainer = React.lazy(() => import('./DragDropContainer'));

/*
    FoodOption -- a thing that can be draged into a Box
*/

const FoodOption = ({
    targetKey,
    label,
    image,
    customDragElement,
    isCorrect,
    bounce,
}) => {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            {/* each food item on the table is styled in index.css */}
            <DragDropContainer
                targetKey={targetKey}
                dragData={{ label: label, img: image }}
                customDragElement={customDragElement}
            >
                <Image
                    src={image}
                    alt={label}
                    isCorrect={isCorrect}
                    bounce={bounce}
                />
            </DragDropContainer>
        </Suspense>
    );
};

const Image = styled.img`
    ${(props) =>
        props.bounce && props.isCorrect && `animation: bounce 2s ease 2;`}
    @keyframes bounce {
        0%,
        20%,
        50%,
        80%,
        100% {
            transform: translateY(0);
        }
        40% {
            transform: translateY(-30px);
        }
        60% {
            transform: translateY(-15px);
        }
    }
`;

export default FoodOption;
