import React from 'react';
import styled from '@emotion/styled';
import H2 from 'assets/styledComponents/fonts/H2';
import hintLightbulb from 'assets/icons/hintLightbulb.svg';

// Input Field

export const InputField = styled.input`
    border: 0.4rem solid
        ${(props) =>
            props.borderColor
                ? props.theme.colors[props.borderColor]
                : props.theme.colors.lightBackground};
    border-radius: 2rem;
    background: ${(props) => props.theme.colors[props.background]};
    width: ${(props) => (props.width ? props.width : '8rem')};
    height: ${(props) => (props.height ? props.height : '8rem')};
    box-shadow: ${(props) => props.boxShadow};
    text-align: center;
    -moz-appearance: textfield;
    /* use padding right to move input text to center when 'g' unit is present */
    padding-right: ${(props) => props.paddingRight};

    margin-bottom: ${(props) =>
        props.marginBottom ? props.marginBottom : '1.6rem'};

    margin-top: ${(props) => props.marginTop};
    margin-left: ${(props) => props.marginLeft};
    color: ${(props) => props.theme.colors.primaryDarkest};

    font-family: ${(props) =>
        props.devicesize === 'mobile'
            ? `DMSansMedium, sans-serif`
            : `MontserratBold, sans-serif`};
    font-size: ${(props) =>
        props.devicesize === 'mobile' ? '1.7rem' : '2.8rem'};

    ::placeholder,
    :-ms-input-placeholder,
    ::-ms-input-placeholder {
        color: ${(props) => props.theme.colors.lightGrey};
        text-decoration: underline;
    }

    &.underline {
        text-decoration: underline;
    }

    &.error {
        border: 0.4rem solid ${(props) => props.theme.colors.alertRed};
    }
    &.correct {
        border: 0.4rem solid ${(props) => props.theme.colors.success};
    }

    &.tertiaryDark {
        background: ${(props) => props.theme.colors.tertiaryDark};
        border: 0.4rem solid ${(props) => props.theme.colors.tertiaryDark};
    }
`;

// Input Field Wrapper - used for inputs with units inside e.g. grams or millitres
export const InputWrapper = styled.div`
    position: relative;
    font-family: MontserratBold, sans-serif;
    font-size: ${(props) =>
        props.devicesize === 'mobile' ? '1.7rem' : '2.8rem'};

    /* variable styles */
    color: ${(props) => props.color && props.theme.colors[props.color]};
    width: ${(props) => props.width};
    margin-top: ${(props) => props.marginTop};
`;

// unit wrapper used inside input wrapper
export const UnitWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 1.2rem;
    right: 0;
    text-align: center;
    pointer-events: none;
    overflow: hidden;
    width: 100%;
    font-family: ${(props) =>
        props.devicesize === 'mobile'
            ? `DMSansMedium, sans-serif`
            : `MontserratBold, sans-serif`};
    font-size: ${(props) =>
        props.devicesize === 'mobile' ? '1.7rem' : '2.8rem'};
    /* variable styles */
    height: ${(props) => props.height};
    margin-top: ${(props) => props.marginTop};

    /* hide while placeholder is visible */
    &.invisible {
        visibility: hidden;
    }

    /* always hide invisible value */
    span.invisible-value {
        visibility: hidden;
    }

    span.unit {
        /* use padding right to move input text to center when 'g' unit is present */
        padding-right: ${(props) =>
            props.devicesize === 'mobile' ? '2.4rem' : '1.6rem'};
    }
`;

// Calculator Form

export const CalculatorContainer = styled.div`
    background: ${(props) => props.theme.colors[props.background]};
    border-radius: ${(props) =>
        props.devicesize === 'mobile' ? 'none' : '2rem'};
    padding: ${(props) =>
        props.devicesize === 'mobile'
            ? '2.4rem 2rem 2.4rem'
            : '4.8rem 2rem 3.2rem'};
    margin-bottom: ${(props) =>
        props.devicesize === 'mobile' ? '2.4rem' : '4rem'};
    margin-top: 1.6rem;
`;

// StyledForm
export const StyledForm = styled.form`
    /* giving the form full screen width for mobile devices */
    width: ${(props) => (props.devicesize === 'mobile' ? '100vw' : '100%')};
    margin-left: ${(props) => (props.devicesize === 'mobile' ? '-2.5rem' : '')};
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: ${(props) => props.marginBottom};
    &.tertiary {
        background: ${(props) => props.theme.colors.tertiary};
    }
`;

// Hint button
const HintButton = styled.button`
    :hover {
        cursor: pointer;
    }
    height: 3.2rem;
    min-width: ${(props) => props.width};
    margin-left: 1rem;
    border-radius: 3rem;
    background: ${(props) => props.theme.colors.lightBackground};
    color: ${(props) => props.theme.colors.secondary};
    float: ${(props) => props.float};
    margin-bottom: ${(props) => props.marginBottom};

    img {
        margin-left: ${(props) => (props.isDesktop ? '-1rem' : '-0.4rem')};
        margin-right: ${(props) => (props.isDesktop ? '0.3rem' : 0)};
        margin-top: -0.2rem;
        height: 2.2rem;
    }
`;

import { useGlobalContext } from 'GlobalContext/GlobalProvider';

export const StyledHintButton = ({
    float,
    onClick,
    position,
    marginBottom,
}) => {
    const [globalStyle] = useGlobalContext();
    let isDesktop =
        globalStyle.deviceSize === 'desktopMd' ||
        globalStyle.deviceSize === 'desktopSm';
    return (
        <HintButton
            float={float}
            width={isDesktop ? '10rem' : '4.8rem'}
            onClick={onClick}
            className="flex-all-centered petit-title-1 card-shadow"
            isDesktop={isDesktop}
            position={position}
            marginBottom={marginBottom}
        >
            <img
                src={hintLightbulb}
                alt="an illustration of an orange lightbulb outline"
            />
            {isDesktop ? 'HINT' : ''}
        </HintButton>
    );
};

// Activity Header - color secondary

const ActivityIndicator = styled.div`
    color: ${(props) => props.theme.colors.lightText};
    background: ${(props) => props.theme.colors.secondary};
    margin-right: 1.6rem;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    display: inline-block;
    padding: 0.8rem 1.5rem;
    text-align: center;
`;

export const ActivityHeader = ({
    instructions,
    link,
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    margin,
}) => {
    let optionalLink = link ? link : '';

    let [globalState] = useGlobalContext();
    let devicesize = globalState.deviceSize;

    return (
        <H2
            className={
                devicesize === 'mobile'
                    ? 'body-4 flex-center-aligned flex-wrap'
                    : 'body-1 flex-center-aligned flex-wrap'
            }
            color="secondary"
            marginTop={marginTop}
            marginBottom={marginBottom}
            marginLeft={marginLeft}
            marginRight={marginRight}
            margin={margin}
            // inline-block needed for responsive reflow with longer instructions
            display="inline-block"
        >
            <ActivityIndicator className="petit-title-2 flex-all-centered">
                Activity
            </ActivityIndicator>

            <Span className="text">
                {instructions}
                {optionalLink}
            </Span>
        </H2>
    );
};

const Span = styled.span`
    /* all inline-blocks needed for content to reflow as expected on smaller screens */
    a {
        text-decoration: none;
        color: ${(props) => props.theme.colors.primary};
        display: inline-block;
    }

    &.text {
        display: inline-block;
    }
`;

export const Link = ({ href, title, className }) => {
    return (
        <StyledLink
            href={href}
            target="_blank"
            className={className ? className : 'body-2'}
        >
            {title}
        </StyledLink>
    );
};

const StyledLink = styled.a`
    text-decoration: none;
    color: ${(props) => props.theme.colors.primary};
`;
