// sign up
const signUp = async (url, data) => {
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });
    return response.json();
};

// handle sign up
const handleSubmitSignUp = async (
    event,
    formFields,
    setPwLengthError,
    setErrorMessage,
    dispatcher,
    updateAuth,
    loadUserData,
    history
) => {
    event.preventDefault();

    const signUpURL = `${process.env.REACT_APP_API_URL}/auth/local/register`;
    const data = {
        //keeping username for easier ref in Strapi
        username: formFields.email,
        firstName: formFields.firstName,
        lastName: formFields.lastName,
        email: formFields.email,
        password: formFields.password,
        group: formFields.group,
    };

    if (formFields.password.length < 8) {
        setPwLengthError(true);
        setErrorMessage('Password should have 8 or more characters.');
        setPwLengthError(true);
    } else if (formFields.confirmPassword === formFields.password) {
        signUp(signUpURL, data).then((res) => {
            if (!res.statusCode) {
                const token = res.jwt;
                localStorage.setItem('token', token);
                dispatcher({ type: updateAuth, payload: true });
                window.dataLayer.push({
                    event: 'signup',
                    userId: res.user.id,
                });
                loadUserData(res.user.id);
                history.push(`/dashboard`);
            } else {
                console.log(res.message[0].messages[0].message);
                setPwLengthError(false);
                setErrorMessage('Email invalid or already in use.');
            }
        });
    } else {
        setPwLengthError(false);
        setErrorMessage('Passwords do not match.');
    }
};

export default handleSubmitSignUp;
