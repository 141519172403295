import React from 'react';
import P from 'assets/styledComponents/fonts/P';
import Title from './Title';
import less6Months from 'assets/images/interactive-course5-8/less6Months.svg';
import StyledImage from 'assets/styledComponents/StyledImage';
import Container from 'assets/styledComponents/Container';
import ListItem from './ListItem';
import H1 from 'assets/styledComponents/fonts/H1';

const Less6Months = ({ devicesize }) => {
    return (
        <Container
            className={
                devicesize === 'mobile' || devicesize === 'tablet'
                    ? 'flex-column-left'
                    : 'flex-start-aligned'
            }
            marginTop={
                devicesize === 'mobile' || devicesize === 'tablet'
                    ? '4.8rem'
                    : ''
            }
        >
            {devicesize === 'mobile' || devicesize === 'tablet' ? (
                <H1 className="header-4">Less than 6 months old</H1>
            ) : (
                ''
            )}
            <center>
                <StyledImage
                    src={less6Months}
                    alt="mom is feeding the child"
                    marginTop={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? ''
                            : '3.8rem'
                    }
                />
            </center>
            <Container
                marginLeft={
                    devicesize === 'mobile' || devicesize === 'tablet'
                        ? ''
                        : '4rem'
                }
            >
                {devicesize !== 'mobile' && devicesize !== 'tablet' ? (
                    <Title title="Less than 6 months old" />
                ) : (
                    ''
                )}
                <P
                    className={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? 'body-2'
                            : 'body-4'
                    }
                >
                    Breastfeeding when possible provides your baby with
                    important nutrients and antibodies.
                </P>
                <P
                    className={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? 'body-2'
                            : 'body-4'
                    }
                >
                    Your care team will discuss how to dose insulin for your
                    breastfed or formula-fed infant. Breast milk or formula is a
                    source of carbohydrates, and the timing and amounts may need
                    to be considered. Insulin is important for your infant to be
                    able to use the nutrition from breastmilk or formula for
                    growth and development.
                </P>
                <P
                    className={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? 'body-2'
                            : 'body-4'
                    }
                    marginTop={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? '2.4rem'
                            : '3.2rem'
                    }
                >
                    Your infant may feed more frequently soon after being
                    diagnosed with diabetes, to make up for any growth that was
                    paused when their body wasn’t making enough insulin.
                </P>
                <P
                    className={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? 'body-2'
                            : 'body-4'
                    }
                    marginTop={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? '2.4rem'
                            : '3.2rem'
                    }
                >
                    Infants with diabetes are expected to grow the same as any
                    other infant. Discuss with your care team if your baby is
                    not growing as expected.
                </P>
                <ul>
                    <ListItem
                        content="See the BCCH handout for details on counting carbohydrates for
            infant food and drinks:"
                        href="http://www.bcchildrens.ca/endocrinology-diabetes-site/documents/carbinfant.pdf"
                        title="http://www.bcchildrens.ca/endocrinology-diabetes-site/documents/carbinfant.pdf"
                    />
                </ul>
            </Container>
        </Container>
    );
};

export default Less6Months;
