import styled from '@emotion/styled';
import React, { useState } from 'react';
import filledDeleteIcon from '../../../assets/icons/filledDeleteIcon.svg';
import outlinedDeleteIcon from '../../../assets/icons/outlinedDeleteIcon.svg';

const DeleteButton = ({ handleDelete }) => {
    const [hovered, setHovered] = useState(false);
    return (
        <DeleteBtn
            type="button"
            className="flex-all-centered"
            onClick={handleDelete}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            {hovered ? (
                <img src={filledDeleteIcon} alt="delete icon" />
            ) : (
                <img src={outlinedDeleteIcon} alt="delete icon" />
            )}
        </DeleteBtn>
    );
};

const DeleteBtn = styled.button`
    border-radius: 50%;
    :hover {
        cursor: pointer;
    }
    :active {
        opacity: 0.4;
    }
`;

export default DeleteButton;
