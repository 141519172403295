import styled from '@emotion/styled';
import { Fragment, React } from 'react';
import CoursesToShow from '../../types/coursesToShow';
import Layout from '../../types/layout';
import calculateTotalLessons from '../../utils/helpers/CalculateTotalLessons';
import filterCoursesBySearchValue from '../../utils/helpers/filterCoursesBySearchValue';
import CourseCard from '../CourseCard/CourseCard';
import CourseCardListView from '../CourseCard/CourseCardListView';
import CardAddCourse from '../DashboardHome/CardAddCourse';
import CourseSearchNoResult from 'assets/styledComponents/CourseSearchNoResult';

const ShowCoursesLoggedInUser = ({
    myCourses,
    allCourses,
    coursesToShow,
    view,
    searchValue,
    isDashboard,
    isDesktop,
}) => {
    const getCourseDescription = (course) => {
        let fullCourse =
            allCourses !== undefined &&
            allCourses.find((allCourses) => allCourses.id === course.id);
        // TODO fix IDs as SQL DB ids aren't working for this check. only a problem for dashboard cards
        return fullCourse?.description && fullCourse.description;
    };

    const calculateProgress = (lessonsCompleted, course) => {
        const progress =
            lessonsCompleted === 0
                ? '0'
                : Math.floor((lessonsCompleted / course.totalLessons) * 100) +
                  '';
        return progress;
    };

    const handleDelete = () => {
        console.log('On Delete Button Clicked');
    };

    const filteredAllCourses =
        allCourses && filterCoursesBySearchValue(allCourses, searchValue);

    const ShowAllCourses = () => {
        let filteredItems =
            allCourses &&
            filterCoursesBySearchValue(allCourses, searchValue).map(
                (c, index) => {
                    const currentCourse = myCourses.map((c2) => {
                        if (c2.id == c.id) {
                            return c2;
                        } else return;
                    });

                    const currentCourseObject = currentCourse.filter(
                        (element) => {
                            if (element != undefined) return element;
                        }
                    );

                    const courseObject = currentCourseObject[0];

                    const completedLessons =
                        myCourses && courseObject
                            ? Object.keys(courseObject.lessonsCompleted).length
                            : null;

                    const myCourse = courseObject;
                    const progress =
                        myCourse && completedLessons === 0
                            ? '0'
                            : Math.floor(
                                  (completedLessons !== null
                                      ? completedLessons /
                                        courseObject.totalLessons
                                      : 0) * 100
                              ) + '';

                    // use Strapi small optimized image size if available,
                    // else use original uploaded image

                    let smallImage =
                        c.courseImage.image.formats &&
                        c.courseImage.image.formats.small &&
                        c.courseImage.image.formats.small.url;
                    let nonOptimizedImage = c.courseImage.image.url;

                    return view === Layout.CARD_VIEW ? (
                        <CourseCard
                            course={c}
                            key={c.id}
                            imageURL={
                                smallImage ? smallImage : nonOptimizedImage
                            }
                            title={c.title}
                            description={c.description}
                            intro={null}
                            courseURL={`/`}
                            progress={progress}
                            id={c.id}
                            slug={c.slug}
                            lastLesson={myCourse && myCourse.lastLesson}
                            lastPage={myCourse && myCourse.lastPageIndex}
                            totalLessons={calculateTotalLessons(c)}
                            handleDelete={handleDelete}
                            width="36rem"
                            height="38rem"
                            imageHeight="20rem"
                            margin="2rem 3rem 2.5rem"
                            progressBarWidth="27rem"
                            marginBottom={isDesktop ? '4rem' : '1rem'}
                        />
                    ) : (
                        <CourseCardListView
                            course={c}
                            key={c.id}
                            view={Layout.LIST_VIEW}
                            title={c.title}
                            description={c.description}
                            slug={c.slug}
                            progress={progress}
                            lastLesson={myCourse && myCourse.lastLesson}
                            lastPage={myCourse && myCourse.lastPageIndex}
                            totalLessons={calculateTotalLessons(c)}
                            id={c.id}
                            first={index === 0}
                            last={index === filteredAllCourses.length - 1}
                            handleDelete={handleDelete}
                        />
                    );
                }
            );
        return filteredItems && filteredItems.length === 0 ? (
            <CourseSearchNoResult searchvalue={searchValue} />
        ) : (
            filteredItems
        );
    };

    const ShowInProgressCourses = () => {
        const coursesToShow = filterCoursesBySearchValue(
            Object.values(myCourses),
            searchValue
        ).filter((c) => !c.courseCompleted);
        if (isDashboard && view === Layout.CARD_VIEW) {
            coursesToShow.push('ADD_COURSE_CARD');
        }

        let filteredItems =
            myCourses &&
            coursesToShow.map((c, index, coursesInProgress) => {
                if (c === 'ADD_COURSE_CARD') {
                    return <CardAddCourse key="add a course card" />;
                }

                const lessonsCompleted = Number(
                    Object.keys(c.lessonsCompleted).length
                );

                return view === Layout.CARD_VIEW ? (
                    <CourseCard
                        course={c}
                        key={c.id}
                        imageURL={
                            c.smallImageUrl
                                ? c.smallImageUrl
                                : c.nonOptimizedImageUrl
                        }
                        title={c.title}
                        description={getCourseDescription(c)}
                        intro={null}
                        courseURL={`/`}
                        progress={calculateProgress(lessonsCompleted, c)}
                        id={c.id}
                        slug={c.slug}
                        lastLesson={c.lastLesson}
                        lastPage={c.lastPageIndex}
                        totalLessons={c.totalLessons}
                        isDashboard={isDashboard}
                        width="36rem"
                        height="38rem"
                        imageHeight="20rem"
                        margin="2rem 3rem 2.5rem"
                        progressBarWidth="27rem"
                        marginBottom={isDesktop ? '4rem' : '1rem'}
                    />
                ) : (
                    <CourseCardListView
                        course={c}
                        key={c.id}
                        view={Layout.LIST_VIEW}
                        title={c.title}
                        description={getCourseDescription(c)}
                        slug={c.slug}
                        progress={calculateProgress(lessonsCompleted, c)}
                        lastLesson={c.lastLesson}
                        lastPage={c.lastPageIndex}
                        totalLessons={c.totalLessons}
                        id={c.id}
                        first={index === 0}
                        last={index === coursesInProgress.length - 1}
                        handleDelete={handleDelete}
                        isDashboard={isDashboard}
                    />
                );
            });
        return filteredItems && filteredItems.length === 0 ? (
            <CourseSearchNoResult searchvalue={searchValue} />
        ) : (
            filteredItems
        );
    };

    const ShowCompletedCourses = () => {
        let filteredItems =
            myCourses &&
            filterCoursesBySearchValue(Object.values(myCourses), searchValue)
                .filter((c) => c.courseCompleted)
                .map((c, index, coursesCompleted) => {
                    const lessonsCompleted = Number(
                        Object.keys(c.lessonsCompleted).length
                    );

                    if (c.courseCompleted) {
                        return view === Layout.CARD_VIEW ? (
                            <CourseCard
                                key={c.id}
                                imageURL={
                                    c.smallImageUrl
                                        ? c.smallImageUrl
                                        : c.nonOptimizedImageUrl
                                }
                                title={c.title}
                                description={getCourseDescription(c)}
                                intro={null}
                                courseURL={`/`}
                                progress={calculateProgress(
                                    lessonsCompleted,
                                    c
                                )}
                                id={c.id}
                                slug={c.slug}
                                lastLesson={c.lastLesson}
                                lastPage={c.lastPageIndex}
                                totalLessons={c.totalLessons}
                                isDashboard={isDashboard}
                                width="36rem"
                                height="38rem"
                                imageHeight="20rem"
                                margin="2rem 3rem 2.5rem"
                                progressBarWidth="27rem"
                                marginBottom={isDesktop ? '4rem' : '1rem'}
                            />
                        ) : (
                            <CourseCardListView
                                course={c}
                                key={c.id}
                                view={Layout.LIST_VIEW}
                                title={c.title}
                                description={getCourseDescription(c)}
                                slug={c.slug}
                                progress={calculateProgress(
                                    lessonsCompleted,
                                    c
                                )}
                                lastLesson={c.lastLesson}
                                lastPage={c.lastPageIndex}
                                totalLessons={c.totalLessons}
                                id={c.id}
                                first={index === 0}
                                last={index === coursesCompleted.length - 1}
                                handleDelete={handleDelete}
                                isDashboard={isDashboard}
                            />
                        );
                    }
                });
        return filteredItems && filteredItems.length === 0 ? (
            <CourseSearchNoResult searchvalue={searchValue} />
        ) : (
            filteredItems
        );
    };

    const ShowSelectedCourses = () => {
        if (coursesToShow === CoursesToShow.ALL) {
            return ShowAllCourses();
        }
        if (coursesToShow === CoursesToShow.IN_PROGRESS) {
            return ShowInProgressCourses();
        }
        if (coursesToShow === CoursesToShow.COMPLETED) {
            return ShowCompletedCourses();
        }
    };

    return (
        <Fragment>
            <ContentContainer view={view}>
                {ShowSelectedCourses()}
            </ContentContainer>
        </Fragment>
    );
};

const ContentContainer = styled.div`
    width: 100%;
    background-color: inherit;
    display: ${(props) => (props.view == Layout.CARD_VIEW ? 'flex' : '')};
    margin-top: ${(props) =>
        props.theme.breakPoints.mobile ? '1.5rem' : props.theme.spaces.md};
    margin-bottom: ${(props) => props.theme.spaces.md};
    column-gap: ${(props) => (props.view == Layout.CARD_VIEW ? '4rem' : '')};
    flex-wrap: ${(props) => (props.view == Layout.CARD_VIEW ? 'wrap' : '')};
`;

export default ShowCoursesLoggedInUser;
