import { React, useState, useEffect, useRef } from 'react';
import Step2Table from './Step2Table';
import Container from 'assets/styledComponents/Container';
import P from 'assets/styledComponents/fonts/P';
import {
    ActivityHeader,
    StyledHintButton,
} from '../../SharedComponentsInteractive';
import InteractiveActivityButton from 'assets/styledComponents/buttons/InteractiveActivityButton';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';
import UseDesktopBanner from 'assets/styledComponents/UseDesktopBanner';
import table2 from 'assets/images/interactive-course8-5/table2.svg';
import table2Hint from 'assets/images/interactive-course8-5/table2Hint.svg';
import { activity1Data } from './activity1Data';
import TableActivityMobile from './TableActivityMobile';

const Step2Activity = () => {
    let [globalState] = useGlobalContext();
    let devicesize = globalState.deviceSize;

    const [isAnswer2Correct, setIsAnswer2Correct] = useState(false);
    const [hasSubmitted2, setHasSubmitted2] = useState(false);
    const [valSelected, setValSelected] = useState(false);
    const [showHint2, setShowHint2] = useState(false);
    const hintRef = useRef(null);

    const scrollUp = () => {
        return hintRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    };

    const handleSubmitActivity2 = () => {
        if (!hasSubmitted2) {
            setHasSubmitted2(true);
            return;
        }

        setHasSubmitted2(false);
        setIsAnswer2Correct(false);
        setValSelected(false);
    };

    useEffect(() => {
        setHasSubmitted2(false);
    }, [isAnswer2Correct]);

    const handleHintClick = () => {
        setShowHint2(true);
        scrollUp();
        setTimeout(() => {
            setShowHint2(false);
        }, 3000);
    };

    const handleHintClickB = () => {
        setShowHint2(true);
        scrollUp();
        setTimeout(() => {
            setShowHint2(false);
        }, 3000);
    };

    const handleTryAgainAfterAllCorrect = (e) => {
        e.preventDefault();
        setValSelected(false);
        setIsAnswer2Correct(false);
        setHasSubmitted2(false);
    };

    return (
        <Container
            marginTop={
                devicesize === 'desktopSm' || devicesize === 'desktopMd'
                    ? '5.6rem'
                    : devicesize === 'tablet'
                    ? '2.4rem'
                    : '1.6rem'
            }
            marginLeft={
                devicesize === 'desktopSm' || devicesize === 'desktopMd'
                    ? '6rem'
                    : ''
            }
        >
            <P
                className={
                    devicesize === 'desktopSm' || devicesize === 'desktopMd'
                        ? 'body-4'
                        : 'body-2'
                }
                marginBottom={
                    devicesize === 'desktopSm' || devicesize === 'desktopMd'
                        ? '3.2rem'
                        : '2.4rem'
                }
            >
                Based on your blood glucose of 17.5 mmol/L and having moderate
                ketones, what percentage of insulin is needed?
            </P>

            {devicesize === 'mobile' || devicesize === 'tablet' ? (
                <UseDesktopBanner marginBottom="3.2rem" />
            ) : (
                ''
            )}

            <Container width="100%">
                <ActivityHeader
                    marginRight="5rem"
                    instructions={
                        devicesize === 'desktopSm' || devicesize === 'desktopMd'
                            ? 'Click the percentage in the table you think is the correct answer, then click Check My Answer to find out!'
                            : 'See the percentage in the table you think is the correct answer, then click one of the answer to find out!'
                    }
                />
            </Container>

            {devicesize === 'desktopSm' || devicesize === 'desktopMd' ? (
                <>
                    <Step2Table
                        hintRef={hintRef}
                        isAnswer2Correct={isAnswer2Correct}
                        setIsAnswer2Correct={setIsAnswer2Correct}
                        hasSubmitted2={hasSubmitted2}
                        setHasSubmitted2={setHasSubmitted2}
                        setValSelected={setValSelected}
                        showHint2={showHint2}
                        valSelected={valSelected}
                    />
                    <Container
                        marginTop="4rem"
                        marginBottom="5.6rem"
                        className="flex-all-centered"
                    >
                        {hasSubmitted2 && isAnswer2Correct ? (
                            <InteractiveActivityButton
                                isCorrect={true}
                                onClick={handleTryAgainAfterAllCorrect}
                                className="secondary"
                            />
                        ) : (
                            <InteractiveActivityButton
                                isCorrect={false}
                                onClick={handleSubmitActivity2}
                                className={
                                    !valSelected
                                        ? 'disabledBtn'
                                        : hasSubmitted2
                                        ? 'incorrect'
                                        : 'secondary'
                                }
                                hasError={hasSubmitted2 && !isAnswer2Correct}
                            />
                        )}
                        <StyledHintButton
                            padding="1.2rem 2rem"
                            onClick={() => handleHintClick()}
                        />
                    </Container>
                </>
            ) : (
                <TableActivityMobile
                    showHint={showHint2}
                    devicesize={devicesize}
                    correctAnswer="22"
                    table={table2}
                    tableHint={table2Hint}
                    activityData={activity1Data}
                    gameId="2"
                    hintRef={hintRef}
                    handleHintClickB={handleHintClickB}
                />
            )}
        </Container>
    );
};

export default Step2Activity;
