import styled from '@emotion/styled';
import React from 'react';
import { useHistory } from 'react-router-dom';
import backpackMd from '../../assets/images/backpackMd.svg';
import backpackSm from '../../assets/images/backpackSm.svg';
import FilledButtonSm from '../../assets/styledComponents/buttons/FilledButtonSm';
import CardSize from '../../types/cardSize';
import CourseImage from '../../assets/styledComponents/StyledImage';
import P from 'assets/styledComponents/fonts/P';

const CardAddCourse = ({ size }) => {
    const history = useHistory();
    return (
        <Card className="card-shadow" size={size}>
            <CourseImage
                width="100%"
                height={size === CardSize.SMALL ? '13rem' : '20rem'}
                borderRadius="2rem 2rem 0 0"
                objectFit="cover"
                src={size === CardSize.SMALL ? backpackSm : backpackMd}
                alt="backpack"
            />
            <CardContent className="flex-column-centered" size={size}>
                {size === CardSize.SMALL ? (
                    <P
                        className="petit-title-2"
                        margin="0rem 1.5rem 1.3rem 1.5rem"
                        textAlign="center"
                        textTransform="uppercase"
                    >
                        Ready to learn?
                    </P>
                ) : (
                    <CardTitleContainer>
                        <P className="header-4">Ready to learn?</P>
                        <P className="body-2" color="greyText" marginTop="1rem">
                            Let’s add a module to start learning!
                        </P>
                    </CardTitleContainer>
                )}

                <FilledButtonSm
                    onClick={() => history.push(`/modules`)}
                    width={size === CardSize.SMALL ? '12rem' : '15rem'}
                >
                    {size === CardSize.SMALL
                        ? 'Add a module'
                        : '+ Add a module'}
                </FilledButtonSm>
            </CardContent>
        </Card>
    );
};

const Card = styled.div`
    border-radius: 2rem;
    background-color: ${(props) => props.theme.colors.lightBackground};
    width: ${(props) => (props.size === CardSize.SMALL ? '16rem' : '36rem')};
    height: ${(props) => (props.size === CardSize.SMALL ? '25rem' : '38rem')};
`;

const CardContent = styled.div`
    height: ${(props) => props.size === CardSize.SMALL && '12rem'};
    margin: ${(props) =>
        props.size === CardSize.REGULAR && '2.7rem 4rem 4rem 4rem'};
    padding: ${(props) =>
        props.size === CardSize.SMALL
            ? '1.3rem 1.5rem 3.4rem 1.5rem'
            : '2.4rem 1.5rem 4rem 1.5rem'}; ;
`;

const CardTitleContainer = styled.div`
    margin-bottom: 3rem;
    text-align: center;
`;

export default CardAddCourse;
