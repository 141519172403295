import React from 'react';
import styled from '@emotion/styled';
import CardSubtitle from '../../../assets/styledComponents/fonts/PetitTitleSerif';
import CardButtonMd from '../../../assets/styledComponents/buttons/CardButtonMd';
import LinkWrappingButton from '../../../assets/styledComponents/buttons/LinkWrappingButton';
import InfoText from '../../../assets/styledComponents/fonts/PetitTitle2';
import Container from '../../../assets/styledComponents/Container';
import Title from '../../../assets/styledComponents/fonts/Header4';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';
import LessonCardCompletionMarker from './LessonCardCompletionMarker';

const LessonCard = ({
    lessonNumber,
    lessonTitle,
    isLessonCompleted,
    url,
    lastLessonCompleted,
    isCourseCompleted,
    isUserLoggedIn,
    estimatedLessonTimeInMinutes,
}) => {
    const [globalState] = useGlobalContext();
    let { deviceSize } = globalState;

    const isMobile = Boolean(deviceSize === 'mobile');

    return (
        <CardContainer
            className={
                isLessonCompleted || !isUserLoggedIn
                    ? 'card-shadow'
                    : 'card-shadow disabled'
            }
        >
            <div className="flex-column">
                <CardSubtitle color="greyText">
                    lesson {lessonNumber}
                </CardSubtitle>

                <Title className="maxTwoLineText" color="darkText">
                    {lessonTitle}
                </Title>
                <Container className="flex" position="absolute" bottom="2.6rem">
                    <InfoText marginRight="1rem" color="greyText">
                        {estimatedLessonTimeInMinutes &&
                            `${estimatedLessonTimeInMinutes}  mins`}
                    </InfoText>
                    {isMobile && (
                        <LessonCardCompletionMarker
                            lastLessonCompleted={lastLessonCompleted}
                            lessonTitle={lessonTitle}
                            isCourseCompleted={isCourseCompleted}
                            isLessonCompleted={isLessonCompleted}
                            iconSize="1.6rem"
                        />
                    )}
                </Container>
            </div>

            {!isMobile && (
                <LessonCardCompletionMarker
                    lastLessonCompleted={lastLessonCompleted}
                    lessonTitle={lessonTitle}
                    isCourseCompleted={isCourseCompleted}
                    isLessonCompleted={isLessonCompleted}
                    iconSize="3.2rem"
                />
            )}
            <ButtonContainer className="btn-container">
                <LinkWrappingButton to={url}>
                    <CardButtonMd width="fit-content" padding="0 2.5rem">
                        {' '}
                        {lastLessonCompleted === lessonTitle &&
                        !isCourseCompleted
                            ? 'Resume Lesson'
                            : 'View Lesson'}
                    </CardButtonMd>
                </LinkWrappingButton>
            </ButtonContainer>
        </CardContainer>
    );
};

const CardContainer = styled.div`
    display: flex;
    justify-content: space-between;
    background-color: ${(props) => props.theme.colors.lightText};
    border-radius: 2rem;
    margin-bottom: 3rem;
    width: 100%;
    height: auto;
    padding: 2.7rem 3rem 7.6rem;
    position: relative;

    :hover .btn-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &.disabled {
        pointer-events: none;
        opacity: 0.4;
    }
`;

// overlay that displays the button when card is hovered on
const ButtonContainer = styled.div`
    width: 100%;
    background: linear-gradient(
        87.38deg,
        rgba(255, 255, 255, 0) 35.06%,
        rgba(255, 255, 255, 0.443182) 45.22%,
        #ffffff 75.84%
    );
    height: 100%;
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    display: none;
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
    padding: ${(props) => props.theme.spaces.md};
`;

export default LessonCard;
