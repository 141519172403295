import React from 'react';
import Container from 'assets/styledComponents/Container';
import StyledIcon from 'assets/styledComponents/StyledImage';
import P from 'assets/styledComponents/fonts/P';
import IconLayout from 'types/iconLayout';

const CourseInfoIcons = ({ infoIcons, iconLayout }) => {
    return (
        <>
            {infoIcons.map((item, index) => (
                <Container className="flex-center-aligned" key={item.icon}>
                    <StyledIcon
                        width="1.3rem"
                        height="1.3rem"
                        marginRight="0.5rem"
                        marginLeft={
                            index !== 0 && iconLayout === IconLayout.HORISONTAL
                                ? '1rem'
                                : ''
                        }
                        src={item.icon}
                        alt={item.title}
                    />
                    <P
                        className="petit-title-2"
                        color="greyText"
                        textTransform="uppercase"
                    >
                        {item.title}
                    </P>
                </Container>
            ))}
        </>
    );
};

export default CourseInfoIcons;
