import styled from '@emotion/styled';
import StyledImage from 'assets/styledComponents/StyledImage';
import React from 'react';
import ageGroups from 'types/ageGroups';
import less6Months_icon from 'assets/images/interactive-course5-8/less6Months_icon.svg';
import months6_9_icon from 'assets/images/interactive-course5-8/months6_9_icon.svg';
import months9_12_icon from 'assets/images/interactive-course5-8/months9_12_icon.svg';
import months12_24_icon from 'assets/images/interactive-course5-8/months12_24_icon.svg';
import todlerSchoolAge_icon from 'assets/images/interactive-course5-8/todlerSchoolAge_icon.svg';
import adolescent_icon from 'assets/images/interactive-course5-8/adolescent_icon.svg';
import allAges_icon from 'assets/images/interactive-course5-8/allAges_icon.svg';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';

const Navigation = ({ activeTab, onTabClick }) => {
    const getNavIcon = (value) => {
        switch (value) {
            case ageGroups.LESS_6_MONTHS:
                return less6Months_icon;
            case ageGroups.MONTHS_6_9:
                return months6_9_icon;
            case ageGroups.MONTHS_9_12:
                return months9_12_icon;
            case ageGroups.MONTHS_12_24:
                return months12_24_icon;
            case ageGroups.TODLER_SCHOOL:
                return todlerSchoolAge_icon;
            case ageGroups.ADOLESCENT:
                return adolescent_icon;
            case ageGroups.ALL:
                return allAges_icon;
            default:
                less6Months_icon;
        }
    };

    let [globalState] = useGlobalContext();
    let devicesize = globalState.deviceSize;

    let isDesktopMd = devicesize === 'isDesktoMd';
    return (
        <>
            {devicesize === 'mobile' || devicesize === 'tablet' ? (
                ''
            ) : (
                <Container>
                    {Object.values(ageGroups).map((value) => (
                        <NavItem
                            key={value}
                            isActive={activeTab === value ? true : false}
                            onClick={() => onTabClick(value)}
                            className="flex-all-centered"
                            isDesktopMd={isDesktopMd}
                        >
                            <StyledImage src={getNavIcon(value)} alt={value} />
                        </NavItem>
                    ))}
                </Container>
            )}
        </>
    );
};

const Container = styled.div`
    margin-top: ${(props) => props.theme.spaces.md};
    display: flex;
`;

const NavItem = styled.div`
    padding: ${(props) =>
        props.isDesktopMd ? '2rem 3rem 1.6rem 3rem' : '1rem '};
    text-decoration: none;
    border-radius: 50% 50% 0 0;
    cursor: pointer;
    text-align: center;
    width: 14rem;
    ${(props) =>
        props.isActive
            ? `background-color: ${props.theme.colors.tertiary};`
            : `background: ${props.theme.colors.tertiaryGradient};`}
`;

export default Navigation;
