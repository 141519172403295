import React from 'react';
import styled from '@emotion/styled';
import PetitTitle2 from '../../../assets/styledComponents/fonts/PetitTitle2';
import chevronRightSm from '../../../assets/icons/chevronRightSm.svg';
import { useHistory } from 'react-router-dom';

const LinkButton = ({ linkTitle, path }) => {
    const history = useHistory();
    return (
        <Container onClick={() => history.push(`${path}`)}>
            <LinkButtonText>{linkTitle}</LinkButtonText>
            <img src={chevronRightSm} alt="Right pointing chevron" />
        </Container>
    );
};

const Container = styled.button`
    display: flex;
    align-items: center;
    background-color: inherit;
    height: 1.7rem;
    :hover {
        opacity: 0.35;
        cursor: pointer;
    }
`;

const LinkButtonText = styled(PetitTitle2)`
    color: ${(props) => props.theme.colors.primary};
`;

export default LinkButton;
