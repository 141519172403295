import styled from '@emotion/styled';
import React from 'react';
import ButtonSection from './ButtonSection';
import CardInfoSection from './CardInfoSection';
import CardTopSection from './CardTopSection';

const CardContent = ({
    hovered,
    progress,
    slug,
    lastPage,
    title,
    description,
    lastLesson,
    imageURL,
    totalLessons,
    handleDelete,
    isDashboard,
    small,
}) => {
    return (
        <>
            <CardTopSection
                imageURL={imageURL}
                title={title}
                progress={progress}
                hovered={hovered}
                handleDelete={handleDelete}
                isDashboard={isDashboard}
                small={small}
            />
            {hovered ? <CardLinearGradientBackground /> : ''}
            {hovered ? (
                <ButtonSection
                    progress={progress}
                    slug={slug}
                    lastPage={lastPage}
                    small={small}
                />
            ) : (
                <CardInfoSection
                    progress={progress}
                    title={title}
                    description={description}
                    lastLesson={lastLesson}
                    slug={slug}
                    totalLessons={totalLessons}
                    lastPage={lastPage}
                    small={small}
                />
            )}
        </>
    );
};

const CardLinearGradientBackground = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 2rem;
    background: linear-gradient(
        179.89deg,
        rgba(255, 255, 255, 0) 11.51%,
        rgba(255, 255, 255, 0.443182) 31.49%,
        #ffffff 55.57%
    );
`;

export default CardContent;
