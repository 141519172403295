import React from 'react';
import H3 from 'assets/styledComponents/fonts/H3';
import StyledImage from 'assets/styledComponents/StyledImage';
import page1Img from 'assets/images/interactiveBook-course2/page1Img.svg';
import P from 'assets/styledComponents/fonts/P';
import Container from 'assets/styledComponents/Container';
import { PageContainer } from './SharedComponents';
import nextChevron from 'assets/images/interactiveBook-course2/nextChevron.svg';
import NextOrPrevPageButton from './NextOrPrevPageButton';

const IntroPage = ({ devicesize, setActivePage }) => {
    return (
        <PageContainer>
            <H3 className="header-3" textAlign="center">
                Completing A Blood Glucose Check
            </H3>

            <div className="flex-all-centered">
                <Container
                    width="50%"
                    padding={devicesize === 'tablet' ? '0 2rem' : '0 4rem'}
                >
                    <P marginBottom="1.5rem">
                        This is a glucose meter, it is used to check your blood
                        glucose. Included in your kit is a meter, test strips, a
                        lancing device, lancets and an instruction manual.
                    </P>
                    <P>
                        Keep in mind that each glucometer may be slightly
                        different, but the following general steps explain how
                        to check a blood glucose level:
                    </P>
                </Container>
                <StyledImage
                    src={page1Img}
                    width="50%"
                    padding={devicesize === 'tablet' ? '3rem' : '6rem'}
                />
            </div>
            <NextOrPrevPageButton
                className={'flex-all-centered next'}
                image={nextChevron}
                alt={'orange chevron pointing right'}
                setActivePage={setActivePage}
                setActivePageNum={1}
                title={'NEXT'}
            />
        </PageContainer>
    );
};

export default IntroPage;
