import { React } from 'react';
import styled from '@emotion/styled';

const Step2DataCell = ({
    cellContent,
    isAnswer2Correct,
    setIsAnswer2Correct,
    rowNumber,
    colNumber,
    hasSubmitted2,
    setValSelected,
    valSelected,
}) => {
    const onChange = () => {
        setValSelected(rowNumber + colNumber);

        if (rowNumber === '4' && colNumber === 4 && !isAnswer2Correct) {
            setIsAnswer2Correct(true);
        } else {
            setIsAnswer2Correct(false);
        }
    };

    return (
        <>
            {colNumber === 1 || colNumber === 2 ? (
                <TH scope="row">{cellContent}</TH>
            ) : (
                <TD>
                    <CellLabel
                        htmlFor={'T2' + rowNumber + colNumber}
                        id={
                            rowNumber + colNumber === '44' &&
                            isAnswer2Correct &&
                            hasSubmitted2
                                ? 'correct'
                                : ''
                        }
                        className={
                            isAnswer2Correct && hasSubmitted2
                                ? 'correct flex-all-centered'
                                : 'flex-all-centered'
                        }
                    >
                        {cellContent}
                        <CellInput
                            type="radio"
                            name="table2"
                            id={'T2' + rowNumber + colNumber}
                            value={rowNumber + colNumber}
                            checked={valSelected === rowNumber + colNumber}
                            onChange={onChange}
                        />
                    </CellLabel>
                </TD>
            )}
        </>
    );
};

// Color vars not shared in app theme
const tableDataBackgroundOdd = '#DADDDE';
const tableDataBackgroundEven = '#E1EDF0';
const selectedBorder = '#2962FF';

const TH = styled.th`
    text-align: center;
    font-family: 'MontserratBold', sans-serif;
    background: lightblue;
`;

const TD = styled.td`
    text-align: center;
    font-family: 'MontserratMedium', sans-serif;

    :nth-of-type(odd) {
        background: ${tableDataBackgroundOdd};
    }

    :nth-of-type(even) {
        background: ${tableDataBackgroundEven};
    }
`;

const CellLabel = styled.label`
    width: 100%;
    height: 100%;

    :hover {
        cursor: pointer;
    }

    padding: 1.4rem 0.4rem;
    :focus-within {
        border: 0.4rem solid ${selectedBorder};
        padding: 1rem 0;
    }
    &#correct {
        border: 0.4rem solid ${(props) => props.theme.colors.success};
        padding: 1rem 0;
    }

    &#incorrect {
        background: red;
    }
`;

const CellInput = styled.input`
    /* hide the radio button from view. Have to use z-index for focus:within in parent to work */
    z-index: -1;
`;

export default Step2DataCell;
