import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import LandingPage from '../pages/LandingPage';
import ProfilePage from '../pages/ProfilePage';
import DashboardContainerPage from '../pages/DashboardContainerPage';
import AllCoursesPage from '../pages/AllCoursesPage';
import CoursePage from '../pages/CoursePage';
import IndividualTrackPage from '../pages/IndividualTrackPage';
import ResetPasswordPage from '../pages/ResetPasswordPage';
import TracksPage from '../pages/TracksPage';
import PageNotFound from '../pages/PageNotFound';
import NavBar from '../components/NavBar/NavBar';
import { ThemeProvider } from '@emotion/react';
import { useGlobalContext } from '../GlobalContext/GlobalProvider';
import useUserData from '../hooks/useUserData';
import jwtDecode from 'jwt-decode';
import useUpdateDeviceSize from '../hooks/useUpdateDeviceSize';
import ScrollPageToTop from 'utils/helpers/ScrollPageToTop';
import useIsNewDayVisited from 'hooks/useIsNewDayVisited';

const Routes = () => {
    const [state] = useGlobalContext();
    const token = localStorage.getItem('token');
    const id = token ? jwtDecode(token)['id'] : null;
    const { loadUserData } = useUserData(id, false);

    // update device size in global state
    useUpdateDeviceSize();

    // hook for updating goals tracking log
    useIsNewDayVisited();

    useEffect(() => {
        if (id) {
            loadUserData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
        ((id && state.user && state.user.id) || !id) && (
            <ThemeProvider theme={state.theme}>
                <ScrollPageToTop>
                    <NavBar />
                    <Switch>
                        <Route exact path="/" component={LandingPage} />
                        <Route
                            exact
                            path="/reset-password"
                            component={ResetPasswordPage}
                        />
                        <ProtectedRoute
                            exact
                            path="/profile/"
                            component={ProfilePage}
                        />
                        <ProtectedRoute
                            path="/dashboard"
                            component={DashboardContainerPage}
                        />
                        <Route
                            exact
                            path="/pathway/:trackSlug"
                            component={IndividualTrackPage}
                        />
                        <Route exact path="/pathways" component={TracksPage} />
                        <Route
                            exact
                            path="/modules"
                            component={AllCoursesPage}
                        />
                        <Route
                            exact
                            path="/modules/:slug/:page"
                            component={CoursePage}
                        />
                        <Route component={PageNotFound} />
                    </Switch>
                </ScrollPageToTop>
            </ThemeProvider>
        )
    );
};

export default Routes;
