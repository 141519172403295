import React from 'react';
import Container from 'assets/styledComponents/Container';
import StyledImage from 'assets/styledComponents/StyledImage';
import P from 'assets/styledComponents/fonts/P';
import months9_12 from 'assets/images/interactive-course5-8/months9_12.svg';
import Title from './Title';
import { Link } from '../../SharedComponentsInteractive';
import H1 from 'assets/styledComponents/fonts/H1';

const Months9_12 = ({ devicesize }) => {
    return (
        <Container
            className={
                devicesize === 'mobile' || devicesize === 'tablet'
                    ? 'flex-column-left'
                    : 'flex-start-aligned'
            }
            marginTop={
                devicesize === 'mobile' || devicesize === 'tablet'
                    ? '4.8rem'
                    : ''
            }
        >
            {devicesize === 'mobile' || devicesize === 'tablet' ? (
                <H1 className="header-4">9-12 months old</H1>
            ) : (
                ''
            )}

            <center>
                {' '}
                <StyledImage
                    src={months9_12}
                    alt="baby girl"
                    marginTop={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? ''
                            : '3.8rem'
                    }
                />
            </center>
            <Container
                marginLeft={
                    devicesize === 'mobile' || devicesize === 'tablet'
                        ? ''
                        : '4rem'
                }
            >
                {devicesize !== 'mobile' && devicesize !== 'tablet' ? (
                    <Title title="9-12 months old" />
                ) : (
                    ''
                )}
                <P
                    className={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? 'body-2'
                            : 'body-4'
                    }
                >
                    During this time, when babies are trying new foods and
                    exploring their hunger and fullness, try not to control
                    portions of food in order to manage blood sugars. Allow your
                    baby to explore and learn their hunger and fullness. Refer
                    to{' '}
                    <Link
                        href="https://www.ellynsatterinstitute.org/"
                        title="this website"
                        className={
                            devicesize === 'mobile' || devicesize === 'tablet'
                                ? 'body-2'
                                : 'body-4'
                        }
                    />{' '}
                    for more information on hunger and fullness cues.
                </P>
                <P
                    className={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? 'body-2'
                            : 'body-4'
                    }
                    marginTop={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? '2.4rem'
                            : '3.2rem'
                    }
                >
                    At this age, your baby may eat quantities large enough to
                    need an insulin food bolus. Discuss with your care team for
                    further guidance.
                </P>
            </Container>
        </Container>
    );
};

export default Months9_12;
