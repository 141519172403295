import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import searchIconInactive from '../../assets/icons/searchIconInactive.svg';
import searchIconActive from '../../assets/icons/searchIconActive.svg';
import clearSearch from '../../assets/icons/clearSearch.svg';
import StyledIcon from '../../assets/styledComponents/StyledImage';

const SearchBar = ({ onSearchChange, isMobile, marginBottom }) => {
    const [searchTerm, setSearchTerm] = useState('');
    useEffect(() => onSearchChange(searchTerm), [searchTerm, onSearchChange]);

    const onChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleClear = (e) => {
        e.preventDefault();
        setSearchTerm('');
    };

    const handleSubmit = (event) => {
        event.preventDefault(); // prevent page refresh
    };

    return (
        <SearchWrapper
            role="search"
            className="search-shadow flex-center-aligned"
            searchTerm={searchTerm}
            isMobile={isMobile}
            marginBottom={marginBottom}
            onSubmit={handleSubmit}
        >
            <AccessibilityLabel htmlFor="search" />

            <StyledIcon
                src={searchTerm.length ? searchIconActive : searchIconInactive}
                padding="0 1rem"
                alt="search icon"
                className="searchIcon"
            />

            <SearchInput
                type="text"
                name="search"
                placeholder="Search"
                onChange={onChange}
                value={searchTerm}
                aria-label="Search"
                isMobile={isMobile}
            />
            <InputButton
                type="button"
                onClick={handleClear}
                name="search button"
                className="clearSearchIcon flex-all-centered"
            >
                {searchTerm.length ? (
                    <StyledIcon
                        src={clearSearch}
                        alt="clear search text icon"
                    />
                ) : (
                    ''
                )}
            </InputButton>
        </SearchWrapper>
    );
};

// searchbar needs form and label to meet accessibility requirements
const SearchWrapper = styled.form`
    height: ${(props) => (props.isMobile ? '3.4rem' : '3rem')};
    border-radius: 2rem;
    border: ${(props) =>
        props.searchTerm.length > 0
            ? `0.1rem solid ${props.theme.colors.secondary}`
            : `0.1rem solid ${props.theme.colors.lightBackground}`};
    background-color: ${(props) => props.theme.colors.lightBackground};
    margin-bottom: ${(props) => props.marginBottom};
`;

const AccessibilityLabel = styled.label`
    visibility: hidden;
`;

const SearchInput = styled.input`
    font-size: 1.6rem;
    font-family: 'MontserratSemiBold';
    height: 100%;
    width: ${(props) => (props.isMobile ? '90%' : '17rem')};
    color: ${(props) => props.theme.colors.darkText};

    ::placeholder {
        color: ${(props) => props.theme.colors.lightGrey};
    }

    :-ms-input-placeholder {
        color: ${(props) => props.theme.colors.lightGrey};
    }

    ::-ms-input-placeholder {
        color: ${(props) => props.theme.colors.lightGrey};
    }
`;
const InputButton = styled.button`
    width: 3.5rem;
    height: 100%;
    background-color: ${(props) => props.theme.colors.lightBackground};

    &.clearSearchIcon {
        border-top-right-radius: 2rem;
        border-bottom-right-radius: 2rem;
    }

    :hover {
        cursor: pointer;
    }
`;

export default SearchBar;
