import styled from '@emotion/styled';

const StyledInput = styled.input`
    width: 100%;
    padding: 1rem;
    /* body-2 font */
    font-family: 'DMSansMedium', sans-serif;
    font-size: 1.4rem;
    line-height: 1.8rem;
    letter-spacing: 0.01em;
    border: 0.1rem solid ${(props) => props.theme.colors.lightGrey};
    border-radius: 0.5rem;
    height: ${(props) => props.theme.spaces.md};
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => (props.marginLeft ? props.marginLeft : 0)};
    margin-right: ${(props) => (props.marginRight ? props.marginRight : 0)};

    ::placeholder {
        color: ${(props) => props.theme.colors.lightGrey};
    }

    :-ms-input-placeholder {
        color: ${(props) => props.theme.colors.lightGrey};
    }

    ::-ms-input-placeholder {
        color: ${(props) => props.theme.colors.lightGrey};
    }

    &.error {
        border: 0.1rem solid ${(props) => props.theme.colors.primary};
        color: ${(props) => props.theme.colors.primary};
    }

    :focus {
        border: 0.1rem solid ${(props) => props.theme.colors.darkText};
    }
`;

export default StyledInput;
