import Container from 'assets/styledComponents/Container';
import React from 'react';
import IntroPageMobile from './IntroPageMobile';
import Step1Mobile from './Step1Mobile';
import Step2Mobile from './Step2Mobile';
import Step3Mobile from './Step3Mobile';
import Step4Mobile from './Step4Mobile';
import Step5Mobile from './Step5Mobile';

const BloodGlucoseCheckMobile = () => {
    return (
        <Container className="flex-column-all-centered" marginTop="2.6rem">
            <IntroPageMobile />
            <Step1Mobile />
            <Step2Mobile />
            <Step3Mobile />
            <Step4Mobile />
            <Step5Mobile />
        </Container>
    );
};
export default BloodGlucoseCheckMobile;
