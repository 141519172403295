import React from 'react';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';
import { InputField } from 'components/Courses/InteractiveModules/SharedComponentsInteractive';
import P from './fonts/P';

const CalcResult = ({
    label,
    name,
    value,
    error,
    hasSubmitted,
    inputFieldWidth,
    inputFieldHeight,
    background,
    borderColor,
    marginTop,
    maxWidth,
    marginLeft,
}) => {
    let [globalState] = useGlobalContext();
    let devicesize = globalState.deviceSize;

    return (
        <>
            <InputField
                type="text"
                id="result"
                name={name}
                placeholder="?"
                readOnly
                value={value}
                className={
                    error.result && hasSubmitted
                        ? 'error'
                        : !error.result && hasSubmitted
                        ? 'correct'
                        : ''
                }
                width={inputFieldWidth}
                height={inputFieldHeight}
                marginTop={marginTop}
                marginLeft={marginLeft}
                background={background}
                borderColor={borderColor}
                devicesize={devicesize}
            />
            <P
                className={devicesize === 'mobile' ? 'body-2' : 'body-1'}
                textAlign="center"
                maxWidth={maxWidth}
                color="primaryDarkest"
            >
                {label}
            </P>
        </>
    );
};

export default CalcResult;
