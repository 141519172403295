import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import BadgesAchievement from 'assets/styledComponents/BadgesAchievement';
import badgesType from 'types/badgesType';
import LessonHeader from './LessonHeader';
import { saveUser } from 'GlobalContext/actionVariables';
import { UPDATE_SAVED_LESSONS_TRACKER } from 'graphql/mutations';
import { GET_SAVED_LESSONS_TRACKER } from 'graphql/queries';
import { useMutation, useQuery } from '@apollo/client';
// Navbars
import LessonMobileNavbar from './LessonMobileNavbar';
import LessonSideNavBar from './LessonSideNavBar';
import ModalOverlay from 'assets/styledComponents/ModalOverlay';
import LessonBottomSection from './LessonBottomSection';
import SaveButton from 'assets/styledComponents/buttons/SaveButton';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';
import LessonMainSection from './LessonMainSection';
import _ from 'lodash';

const Lesson = ({
    lesson,
    last,
    globalState,
    currentCourse,
    isNextLessonAvaliable,
    setIsNextLessonAvaliable,
}) => {
    const { slug, page } = useParams();
    let isUserLoggedIn = globalState.auth;
    const devicesize = globalState.deviceSize;
    const lgDesktopSize = 1440;
    const isDesktop = devicesize === 'desktopSm' || devicesize === 'desktopMd';
    const isMobile = devicesize === 'mobile';
    const isTablet = devicesize === 'tablet';

    const [expandNavbar, setExpandNavbar] = useState(
        window.innerWidth > lgDesktopSize ? true : false
    );

    const [expandMobileNavbar, setExpandMobileNavbar] = useState(false);

    const [showOverlay, setShowOverlay] = useState(false);
    const [saveBadge, setSaveBadge] = useState(false);

    useEffect(() => {
        function handleResize() {
            // auto open / close side navbar based on window width
            if (window.innerWidth > lgDesktopSize && !expandNavbar) {
                setExpandNavbar(true);
            } else if (window.innerWidth < lgDesktopSize && expandNavbar) {
                setExpandNavbar(false);
            }
        }
        window.addEventListener('resize', handleResize);
    });

    useEffect(() => {
        // add overlay over content when side navbar is opened on smaller screens
        if (window.innerWidth < lgDesktopSize && !expandNavbar) {
            setShowOverlay(false);
        } else if (window.innerWidth < lgDesktopSize && expandNavbar) {
            setShowOverlay(true);
        }
    }, [expandNavbar]);

    const [hasCurrentLessonEnded, setHasCurrentLessonEnded] = useState(false);

    const handleScroll = (event) => {
        let element = event.target;
        setSaveBadge(true);
        if (element.scrollHeight - element.scrollTop === element.clientHeight) {
            setHasCurrentLessonEnded(true);
        }
        if (element.scrollTop < 10) {
            setSaveBadge(false);
        }
    };

    const endPage = lesson[lesson.length - 1];

    const [, dispatch] = useGlobalContext();
    const { data: savedLessonLogData } = useQuery(GET_SAVED_LESSONS_TRACKER, {
        variables: {
            id: globalState.user ? globalState.user.trackingLogId : '',
        },
        skip: globalState.user ? false : true,
    });
    let savedLessons = savedLessonLogData
        ? savedLessonLogData.trackingLog.savedLessonsTracker
        : '';

    const [updateSavedLessonsTracker] = useMutation(
        UPDATE_SAVED_LESSONS_TRACKER,
        { onError: (error) => console.log('An error occured ', error) }
    );

    const handleSave = () => {
        const updateData = savedLessons[endPage.id]
            ? _.omit(savedLessons, endPage.id)
            : {
                  ...savedLessons,
                  [endPage.id]: {
                      lessonTitle: endPage.lessonTitle,
                      lessonID: endPage.id,
                      lessonNum: endPage.lessonNumber,
                      url: `/modules/${slug}/${Number(page)}`,
                      courseID: currentCourse.id,
                      courseName: currentCourse.title,
                  },
              };

        updateSavedLessonsTracker({
            variables: {
                id: globalState.user.trackingLogId,
                data: {
                    savedLessonsTracker: updateData,
                },
            },
        });

        dispatch({
            type: saveUser,
            payload: {
                ...globalState.user,
                trackingLog: {
                    ...globalState.user.trackingLog,
                    savedLessonsTracker: updateData,
                },
            },
        });
    };

    useEffect(() => {
        setIsNextLessonAvaliable(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    const anchor = useRef(null);
    useEffect(() => {
        anchor.current.scrollIntoView({
            behavior: 'auto',
            block: 'start',
        });
    }, [page]);

    return (
        <>
            <BadgesAchievement id={badgesType.OFF_THE_GROUND} />
            {page == last ? (
                <BadgesAchievement id={badgesType.FIRST_OF_MANY} />
            ) : (
                ''
            )}
            <LessonPageContainer
                className={
                    isMobile ? 'flex-column-flex-start' : 'flex-row-flex-start'
                }
                isDesktop={isDesktop}
                isMobile={isMobile}
            >
                {/* Lesson Navbar  */}
                {isMobile ? (
                    <LessonMobileNavbar
                        slug={slug}
                        currentLesson={lesson}
                        hasCurrentLessonEnded={hasCurrentLessonEnded}
                        expandMobileNavbar={expandMobileNavbar}
                        setExpandMobileNavbar={setExpandMobileNavbar}
                        isNextLessonAvaliable={isNextLessonAvaliable}
                    />
                ) : isTablet || isDesktop ? (
                    <LessonSideNavBar
                        slug={slug}
                        currentLesson={lesson}
                        hasCurrentLessonEnded={hasCurrentLessonEnded}
                        expandNavbar={expandNavbar}
                        setExpandNavbar={setExpandNavbar}
                        isNextLessonAvaliable={isNextLessonAvaliable}
                    />
                ) : (
                    ''
                )}

                <LessonContent
                    hasCurrentLessonEnded={hasCurrentLessonEnded}
                    isMobile={isMobile}
                    expandNavbar={expandNavbar}
                    onScroll={handleScroll}
                    showOverlay={showOverlay}
                >
                    <ModalOverlay
                        setShowModal={setShowOverlay}
                        showModal={showOverlay}
                        isForLessons={true}
                        setExpandNavbar={setExpandNavbar}
                    />

                    {isUserLoggedIn && (
                        <ButtonContainer
                            saveBadge={saveBadge}
                            isMobile={isMobile}
                            isTablet={isTablet}
                        >
                            <SaveButton
                                handleSave={handleSave}
                                savedLessons={savedLessons}
                                endPage={endPage}
                                isMobile={isMobile}
                                saveBadge={saveBadge}
                            />
                        </ButtonContainer>
                    )}

                    <LessonContentContainer
                        isMobile={isMobile}
                        isTablet={isTablet}
                        ref={anchor}
                    >
                        <LessonHeader
                            globalState={globalState}
                            lesson={lesson}
                            currentCourse={currentCourse}
                            isMobile={isMobile}
                            expandNavbar={expandNavbar}
                            endPage={endPage}
                        />
                        <LessonMainSection
                            lesson={lesson}
                            isMobile={isMobile}
                            devicesize={devicesize}
                        />
                        <LessonBottomSection
                            setHasCurrentLessonEnded={setHasCurrentLessonEnded}
                            page={page}
                            last={last}
                            slug={slug}
                            isMobile={isMobile}
                            isNextLessonAvaliable={isNextLessonAvaliable}
                            isUserLoggedIn={isUserLoggedIn}
                        />
                    </LessonContentContainer>
                </LessonContent>
            </LessonPageContainer>
        </>
    );
};

const LessonPageContainer = styled.div`
    width: 100vw;
    /* regular height needed for sticky mobile navbar to work */
    height: ${(props) =>
        props.isMobile
            ? 'calc(100vh - 12.8rem)'
            : 'calc(100vh - 9rem)'}; //9 rem - height of the top Navigation component for desktop, 7.2 rem - height of the top Navigation component for tablet
    max-height: initial;
    overflow-x: hidden;
`;

const LessonContent = styled.section`
    background-color: #fcfcb6;
    padding-top: 1rem;
    margin-left: ${(props) =>
        props.isMobile
            ? '0rem'
            : props.expandNavbar && !props.showOverlay
            ? '24rem'
            : '5.6rem'};
    width: 100%;
    align-items: center;
    overflow-y: scroll;
    overflow-x: hidden;
    background: linear-gradient(
        180deg,
        rgba(232, 245, 248, 0.5) 0%,
        #ffffff 100%
    );
    ::-webkit-scrollbar {
        width: 0;
        background: transparent;
    }
`;

const LessonContentContainer = styled.section`
    height: ${(props) => (props.isMobile ? '80vh' : '100%')};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-top: ${(props) =>
        props.isMobile ? '0rem' : props.isTablet ? '8rem' : '6rem'};
    margin: ${(props) =>
        props.isMobile
            ? '0 2.5rem 0rem 2.5rem'
            : props.isTablet
            ? '0 4.8rem 0rem 4.8rem'
            : '0 12rem 0 8rem'};
`;

const ButtonContainer = styled.div`
    z-index: 1;
    position: absolute;
    right: ${(props) => (props.isMobile ? '2.5rem' : '12.4rem')};
    top: ${(props) =>
        props.isMobile
            ? '19.9rem'
            : props.isTablet && props.saveBadge
            ? '16.5rem'
            : props.saveBadge
            ? '16.5rem'
            : '16.5rem'};
    /* z-index needed to keep position relative lesson content behind the button */
    z-index: 1;
`;

export default Lesson;
