import styled from '@emotion/styled';

export const BackgroundUnderBorderRadius = styled.div`
    height: 30rem;
    position: absolute;
    background: ${(props) =>
        props.coloredBackground
            ? props.theme.colors.lightBackground
            : props.theme.colors.tertiary};
    border-radius: none;
    z-index: -1;
    width: 100%;
    top: ${(props) => !props.bottom && 0};
    bottom: ${(props) => props.bottom && 0};
`;
