import React from 'react';
import styled from '@emotion/styled';
import back from '../../assets/icons/back.svg';

const BackButton = ({ setForgotPassword }) => {
    return (
        <StyledButton onClick={() => setForgotPassword(false)}>
            <img src={back} alt="chevron pointing left" />
            Back
        </StyledButton>
    );
};

const StyledButton = styled.button`
    font-family: 'DMSansMedium';
    font-size: 1.4rem;
    color: ${(props) => props.theme.colors.greyText};
    background-color: inherit;
    position: absolute;
    top: 3.4rem;
    left: 3rem;
    display: flex;
    align-items: center;

    img {
        margin-right: 0.5rem;
    }

    :hover {
        cursor: pointer;
        opacity: 0.4;
    }
`;

export default BackButton;
