import Container from 'assets/styledComponents/Container';
import React, { useState, useEffect } from 'react';
import strawberriesMacaroniMilk from 'assets/images/interactive-course5-1/strawberriesMacaroniMilk.svg';
import strawberriesWhippedCreamMacaroni from 'assets/images/interactive-course5-1/strawberriesWhippedCreamMacaroni.svg';
import StyledImage from 'assets/styledComponents/StyledImage';
import InteractiveActivityButton from 'assets/styledComponents/buttons/InteractiveActivityButton';
import { StyledHintButton } from '../../SharedComponentsInteractive';

const GameMobile = ({ showHint, hintRef, onHintClick }) => {
    const twoColumnBreakpoint = 580;

    const [isCorrect, setIsCorrect] = useState('');
    const [valSelected, setValSelected] = useState('');
    const [hasSubmitted, setHasSubmitted] = useState(false);

    useEffect(() => {
        setHasSubmitted(false);
    }, [isCorrect, valSelected]);

    const handleClick = (answer) => {
        setValSelected(answer);
        if (answer === '1') {
            setIsCorrect(true);
        } else {
            setIsCorrect(false);
        }
    };

    const handleSubmitActivity = () => {
        if (!hasSubmitted) {
            setHasSubmitted(true);
            return;
        }

        setHasSubmitted(false);
        setIsCorrect(false);
        setValSelected('');
    };

    const handleTryAgain = (e) => {
        e.preventDefault();
        setHasSubmitted(false);
        setValSelected('');
        setIsCorrect(false);
    };

    return (
        <>
            <Container
                className={
                    window.innerWidth >= twoColumnBreakpoint
                        ? 'flex'
                        : 'flex-column'
                }
                marginTop="2.4rem"
            >
                <Container
                    width="100%"
                    borderRadius="2rem"
                    border={
                        showHint || (isCorrect && hasSubmitted)
                            ? '0.3rem solid #21BA8C'
                            : valSelected === '1'
                            ? '0.3rem solid #3F82E8'
                            : ''
                    }
                    backgroundColor="blueGreen"
                    marginRight={
                        window.innerWidth >= twoColumnBreakpoint ? '1.6rem' : ''
                    }
                    ref={hintRef}
                >
                    <StyledImage
                        src={strawberriesMacaroniMilk}
                        alt="set of strawberries macaroni and milk"
                        width="100%"
                        onClick={() => handleClick('1')}
                        cursor="pointer"
                    />
                </Container>
                <Container
                    width="100%"
                    borderRadius="2rem"
                    border={
                        !isCorrect && hasSubmitted
                            ? '0.3rem solid #FF3636'
                            : valSelected === '2'
                            ? '0.3rem solid #3F82E8'
                            : ''
                    }
                    backgroundColor="blueGreen"
                    marginTop={
                        window.innerWidth >= twoColumnBreakpoint ? '' : '1.6rem'
                    }
                >
                    <StyledImage
                        src={strawberriesWhippedCreamMacaroni}
                        alt="set of strawberries sugar free whipped cream and macaroni"
                        width="100%"
                        onClick={() => handleClick('2')}
                        cursor="pointer"
                    />
                </Container>
            </Container>

            <Container
                marginTop="2.4rem"
                marginBottom="4rem"
                className="flex-all-centered"
            >
                {isCorrect && hasSubmitted ? (
                    <InteractiveActivityButton
                        isCorrect={true}
                        onClick={handleTryAgain}
                        className="secondary"
                    />
                ) : (
                    <InteractiveActivityButton
                        isCorrect={false}
                        onClick={handleSubmitActivity}
                        className={
                            !valSelected
                                ? 'disabledBtn'
                                : hasSubmitted
                                ? 'incorrect'
                                : 'secondary'
                        }
                        hasError={hasSubmitted && !isCorrect}
                    />
                )}
                <StyledHintButton
                    padding="1.2rem 2rem"
                    onClick={() => onHintClick()}
                >
                    Hint
                </StyledHintButton>
            </Container>
        </>
    );
};

export default GameMobile;
