import { React, useRef, useState, useEffect, Fragment } from 'react';
import styled from '@emotion/styled';
import edit from '../../assets/icons/edit.svg';
import OutlinedButtonMd from '../../assets/styledComponents/buttons/OutlinedButtonMd';
import FilledButtonMd from '../../assets/styledComponents/buttons/FilledButtonMd';
import ModalOverlay from '../../assets/styledComponents/ModalOverlay';
import ShowProfileAvatar from '../../utils/helpers/ShowProfileAvatar';
import { useMutation } from '@apollo/client';
import { saveUser } from '../../GlobalContext/actionVariables';
import {
    UPDATE_PROFILE_PIC,
    DELETE_PROFILE_PIC,
} from '../../graphql/mutations';
import { GET_FULL_PROFILE } from '../../graphql/queries';
import { useGlobalContext } from '../../GlobalContext/GlobalProvider';
import CloseModalButton from '../../utils/helpers/CloseModalButton';
import H2 from 'assets/styledComponents/fonts/H2';
import Container from 'assets/styledComponents/Container';

const AvatarModal = ({ setShowModal, showModal, data, isMobile }) => {
    const inputRef = useRef();

    const [currentModal, setCurrentModal] = useState('uploadOrDelete');
    const [userInfo] = useState(data);
    const [, dispatch] = useGlobalContext();

    const [newPic, setNewPic] = useState('');
    const [tempPic, setTempPic] = useState('');

    const [uploadProfilePic] = useMutation(UPDATE_PROFILE_PIC);

    // separate mutation for deleting in the upload pic function as not closing the
    // modal or updating the cache or global state to empty string in this case
    const [deleteOldPicWhenUploadingNew] = useMutation(DELETE_PROFILE_PIC);

    // mutation for delete only function
    const [deleteProfilePic] = useMutation(DELETE_PROFILE_PIC, {
        update(cache) {
            const profile = cache.readQuery({
                query: GET_FULL_PROFILE,
                variables: { id: +data.id },
            });

            const newData = {
                user: {
                    ...profile.user,
                    __typename: 'UsersPermissionsUser',
                    registered_user: {
                        ...profile.user.registered_user,
                        __typename: 'RegisteredUser',
                        profilePic: null,
                    },
                },
            };

            cache.writeQuery({
                query: GET_FULL_PROFILE,
                data: newData,
            });
        },
        onCompleted: () => {
            dispatch({
                type: saveUser,
                payload: {
                    ...userInfo,
                    profilePic: '',
                },
            }),
                setCurrentModal('uploadOrDelete');
        },
        onError: (err) => {
            console.log('Error with deleting avatar ', err);
        },
    });

    useEffect(() => {
        if (newPic) {
            uploadPic(newPic, userInfo.profilePicId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newPic]);

    const handleDelete = async (oldpic) => {
        uploadProfilePic({
            variables: {
                registeredId: userInfo.registeredUserID,
                imgId: '',
            },
        });

        await deleteProfilePic({
            variables: {
                oldPicId: oldpic,
            },
        });
    };

    const uploadPic = async (file, oldpic) => {
        let formData = new FormData();

        formData.append('files', file);
        const request = new XMLHttpRequest();

        request.open('POST', `${process.env.REACT_APP_API_URL}/upload`);
        request.onload = () => {
            let response = JSON.parse(request.response);
            uploadProfilePic({
                variables: {
                    registeredId: userInfo.registeredUserID,
                    imgId: response[0] ? response[0].id : '',
                },
            });

            if (oldpic) {
                deleteOldPicWhenUploadingNew({
                    variables: {
                        oldPicId: oldpic,
                    },
                });
            }

            dispatch({
                type: saveUser,
                payload: {
                    ...userInfo,
                    profilePicId: response[0].id,
                    profilePic: response[0].formats,
                },
            });
        };
        request.send(formData);
        if (currentModal === 'uploadOrDelete') {
            setCurrentModal('changeOrSave');
        }
    };

    const changePic = (e) => {
        e.preventDefault();
        if (e.target.files[0]) {
            setNewPic(e.target.files[0]);
            let pic = URL.createObjectURL(e.target.files[0]);
            setTempPic(pic);
        }
    };

    const ShowUploadButton = (buttonText, className) => {
        return (
            <UploadLabel
                htmlFor="avatar"
                className={className}
                isMobile={isMobile}
            >
                {buttonText}
                <HiddenUploadInput
                    id="avatar"
                    ref={inputRef}
                    type="file"
                    name="files"
                    accept="image/png, image/jpg, image/jpeg"
                    onChange={changePic}
                />
            </UploadLabel>
        );
    };

    const ButtonsToDisplay = () => {
        if (currentModal === 'uploadOrDelete') {
            return (
                <Container
                    className={isMobile ? 'flex-column' : 'flex-center-aligned'}
                    width={isMobile ? '100%' : ''}
                >
                    <OutlinedButtonMd
                        minWidth="15rem"
                        margin={isMobile ? '0 0 1rem' : '0 0.5rem'}
                        width={isMobile ? '100%' : ''}
                        onClick={() => setCurrentModal('confirmDelete')}
                        disabled={!Object.keys(data.profilePic).length}
                        className={
                            Object.keys(data.profilePic).length
                                ? ''
                                : 'disabledBtn'
                        }
                    >
                        Delete
                    </OutlinedButtonMd>
                    {ShowUploadButton('Upload')}
                </Container>
            );
        } else if (currentModal === 'changeOrSave') {
            return (
                <Container
                    className={isMobile ? 'flex-column' : 'flex-center-aligned'}
                >
                    {ShowUploadButton('Upload Another', 'outlined')}
                    <FilledButtonMd
                        minWidth="15rem"
                        margin={isMobile ? '0' : '0 0.5rem'}
                        onClick={() => setShowModal(false)}
                    >
                        Done
                    </FilledButtonMd>
                </Container>
            );
        } else if (currentModal === 'confirmDelete') {
            return (
                <Container
                    className={isMobile ? 'flex-column' : 'flex-center-aligned'}
                >
                    <OutlinedButtonMd
                        minWidth="15rem"
                        margin={isMobile ? '0 0 1rem' : '0 0.5rem'}
                        onClick={() => setShowModal(false)}
                    >
                        No
                    </OutlinedButtonMd>
                    <FilledButtonMd
                        minWidth="15rem"
                        margin="0 0.5rem"
                        onClick={() => handleDelete(userInfo.profilePicId)}
                    >
                        Yes
                    </FilledButtonMd>
                </Container>
            );
        }
        return;
    };

    const ModalTitleWithIcon = ({ title }) => {
        return (
            <div className="flex-center-aligned">
                <img src={edit} alt="pencil icon symbolising editing" />
                <H2
                    marginLeft="1rem"
                    className={isMobile ? 'body-2' : 'header-4'}
                >
                    {title}
                </H2>
            </div>
        );
    };

    const ShowModalTitle = () => {
        if (currentModal === 'uploadOrDelete') {
            return <ModalTitleWithIcon title="Upload or delete your photo" />;
        } else if (currentModal === 'confirmDelete') {
            return (
                <H2
                    className={isMobile ? 'body-2' : 'header-4'}
                    textAlign={isMobile && 'center'}
                >
                    Would you like to delete your photo?
                </H2>
            );
        } else if (currentModal === 'changeOrSave') {
            return (
                <H2
                    className={isMobile ? 'body-2' : 'header-4'}
                    textAlign={isMobile && 'center'}
                >
                    Upload complete. <br /> Close pop-up or upload another.
                </H2>
            );
        }
        return;
    };

    return (
        <Fragment>
            <ModalOverlay showModal={showModal} setShowModal={setShowModal} />

            <ModalContainer isMobile={isMobile}>
                <CloseModalButton
                    setShowModal={setShowModal}
                    top="2rem"
                    right="2rem"
                />

                <ShowModalTitle />

                <Avatar src={tempPic ? tempPic : ShowProfileAvatar(data)} />

                <ButtonsToDisplay />
            </ModalContainer>
        </Fragment>
    );
};

const ModalContainer = styled.div`
    background-color: ${(props) => props.theme.colors.lightBackground};
    border-radius: 1.6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5rem 5rem 6.5rem;
    width: ${(props) => (props.isMobile ? '90%' : '64rem')};
    height: ${(props) => (props.isMobile ? '' : '52rem')};
    z-index: 10;
    position: fixed;
    top: ${(props) => (props.isMobile ? '10%' : 'calc(50vh - 26rem)')};
    left: ${(props) => (props.isMobile ? '' : 'calc(50vw - 32rem)')};
`;

const Avatar = styled.img`
    width: 24rem;
    height: 24rem;
    border-radius: 50%;
    margin: ${(props) => props.theme.spaces.md};
    object-fit: cover;
`;

const UploadLabel = styled.label`
    background-color: ${(props) => props.theme.colors.secondary};
    color: ${(props) => props.theme.colors.lightText};
    border: 0.1rem solid ${(props) => props.theme.colors.secondary};
    border-radius: 4rem;
    min-width: ${(props) => (props.isMobile ? '' : '15rem')};
    width: ${(props) => (props.isMobile ? '100%' : 'calc(100% + 4.4rem)')};
    height: 4.2rem;
    font-family: 'MontserratSemiBold';
    padding: 0.5rem 2.2rem;
    font-size: 1.8rem;
    margin: ${(props) => (props.isMobile ? '0 0 1rem' : '0 0.5rem')};
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;

    &.outlined {
        background-color: transparent;
        color: ${(props) => props.theme.colors.secondary};
    }

    :hover {
        cursor: pointer;
        opacity: 0.4;
    }
`;

const HiddenUploadInput = styled.input`
    display: none;
`;

export default AvatarModal;
