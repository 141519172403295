import { React, useState } from 'react';
import styled from '@emotion/styled';
import PageNumberTabs from './PageNumberTabs';
import course7BookPages from 'types/course7BookPages';
import ShowPage from './ShowPage';
import { ActivityHeader } from '../SharedComponentsInteractive';
import StyledImage from 'assets/styledComponents/StyledImage';
import P from 'assets/styledComponents/fonts/P';
import Container from 'assets/styledComponents/Container';
import { Span } from './SharedComponents';
import H2 from 'assets/styledComponents/fonts/H2';
import steps from './StepsContent';

const PreparingForPhysicalActivity = ({ isMobile }) => {
    const [activePage, setActivePage] = useState(course7BookPages.ONE);

    const onPageTabClick = (num) => {
        setActivePage(num);
    };

    const DesktopContent = () => {
        if (isMobile) return null;
        return (
            <>
                <ActivityHeader
                    instructions="Click through this storybook to learn more."
                    marginBottom="4rem"
                />
                <BookBackground role="presentation">
                    <DoublePage className="body-2 flex-all-centered">
                        <PageFold role="presentation" />
                        <PageNumberTabs
                            activePage={activePage}
                            onPageTabClick={onPageTabClick}
                        />
                        <ShowPage
                            activePage={activePage}
                            setActivePage={setActivePage}
                        />
                    </DoublePage>
                </BookBackground>
            </>
        );
    };

    const MobileContent = () => {
        if (!isMobile) return null;
        const StepsArray = Object.entries(steps);

        return StepsArray.map(([stepTitle, stepValue]) => (
            <Container key={stepTitle} marginBottom={`4.8rem`}>
                <H2
                    marginBottom="3.9rem"
                    className="header-4"
                    color="primaryDarkest"
                >
                    {stepTitle.slice(0, 4) + ' ' + stepTitle.slice(-1)}
                </H2>
                <Container
                    className="flex-center-justified"
                    marginBottom={`3.7rem`}
                    width={`100%`}
                >
                    <StyledImage
                        src={stepValue.image}
                        alt={stepTitle}
                        width={`17.7rem`}
                        height="22rem"
                    />
                </Container>
                <P className="body-2" color="primaryDarkest">
                    {stepValue.highlightContent && (
                        <Span>{stepValue.highlightContent}</Span>
                    )}
                    {stepValue.content}
                </P>
            </Container>
        ));
    };

    return (
        <div>
            <P className="body-4" marginBottom="3.2rem">
                To lower your risk of hypoglycemia, there needs to be enough
                glucose in the blood to provide extra energy for the duration of
                the activity.
            </P>

            <DesktopContent />
            <MobileContent />
        </div>
    );
};

const BookBackground = styled.div`
    max-width: 98rem;
    height: 58rem;
    background: ${(props) => props.theme.colors.tertiaryDark};
    position: relative;
    overflow: hidden;
    border-radius: 1rem;
    padding: 2rem 5.5rem;
    color: ${(props) => props.theme.colors.primaryDarkest};

    &:before {
        content: '';
        position: absolute;
        width: 350%;
        height: 100%;
        border-radius: 50%;
        background: ${(props) => props.theme.colors.lightBackground};
        left: -125%;
        top: -99%;
    }

    &:after {
        content: '';
        position: absolute;
        width: 350%;
        height: 100%;
        border-radius: 50%;
        background: ${(props) => props.theme.colors.lightBackground};
        left: -125%;
        bottom: -99%;
    }
`;

const DoublePage = styled.div`
    background: ${(props) => props.theme.colors.lightBackground};
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 0.1rem;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.2);

    &:after {
        content: '';
        position: absolute;
        height: 99%;
        width: 1%;
        left: -1%;
        background: ${(props) => props.theme.colors.lightBackground};
        box-shadow: -2px 0px 3px rgba(0, 0, 0, 0.1) inset;
    }

    &:before {
        content: '';
        position: absolute;
        height: 99%;
        width: 1%;
        right: -1%;
        background: ${(props) => props.theme.colors.lightBackground};
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    }
`;

const PageFold = styled.div`
    height: 100%;
    width: 0.1rem;
    background: ${(props) => props.theme.colors.grey};
    position: absolute;
    left: 50%;
`;

export default PreparingForPhysicalActivity;
