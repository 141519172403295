import styled from '@emotion/styled';
import React from 'react';
import { useHistory } from 'react-router-dom';
import checkCircleSm from 'assets/icons/checkCircleSm.svg';
import secondaryDotSm from 'assets/icons/secondaryDotSm.svg';
import StyledIcon from 'assets/styledComponents/StyledImage';
import P from 'assets/styledComponents/fonts/P';

const LessonSideNavBarLessonInfo = ({
    slug,
    lessonTitle,
    isLessonCompleted,
    lessonNumber,
    isCurrentLesson,
    isNextLessonAvaliable,
    isUserLoggedIn,
    expandNavbar,
}) => {
    const history = useHistory();

    return (
        <LessonListItemContainer
            padding={expandNavbar ? '0 1.8rem 0 3rem' : '0 1.6rem'}
            isCurrentLesson={isCurrentLesson}
            className={
                !isUserLoggedIn ||
                lessonNumber === 1 ||
                isLessonCompleted ||
                isNextLessonAvaliable
                    ? ''
                    : 'disabled'
            }
            onClick={() => history.push(`/modules/${slug}/${lessonNumber}`)}
        >
            <P
                className="maxTwoLineText petit-title-2 flex-space-between"
                textTransform="uppercase"
                width="18rem"
            >
                <span>
                    L{lessonNumber}
                    {expandNavbar ? '.' : ''}
                </span>
                {expandNavbar ? ' ' + lessonTitle : ''}
            </P>

            {isCurrentLesson && expandNavbar ? (
                <StyledIcon
                    height="2.4rem"
                    width="2.4rem"
                    marginLeft="0.8rem"
                    src={secondaryDotSm}
                    alt="Orange Circle Indicating Lesson Last Visited"
                />
            ) : isLessonCompleted && expandNavbar ? (
                <StyledIcon
                    height="2.4rem"
                    width="2.4rem"
                    marginLeft="0.8rem"
                    src={checkCircleSm}
                    alt="Orange Checkmark Indicating Lesson Complete"
                />
            ) : (
                ''
            )}
        </LessonListItemContainer>
    );
};

const LessonListItemContainer = styled.li`
    min-height: 6.4rem;
    display: flex;
    align-items: center;
    padding: ${(props) => props.padding};
    background-color: ${(props) =>
        props.isCurrentLesson
            ? props.theme.colors.lightBackground
            : props.theme.colors.lightGreyBackground};
    color: ${(props) =>
        props.isCurrentLesson
            ? props.theme.colors.secondary
            : props.theme.colors.primaryDarkest};
    :hover {
        cursor: pointer;
        opacity: 0.6;
        background-color: ${(props) => props.theme.colors.lightBackground};
    }
    &.disabled {
        pointer-events: none;
        opacity: 0.4;
    }
`;

export default LessonSideNavBarLessonInfo;
