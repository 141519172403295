import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import P from 'assets/styledComponents/fonts/P';
import React from 'react';
import HeaderSection from '../../assets/styledComponents/Container';
import Header4 from '../../assets/styledComponents/fonts/Header4';
import { GET_COURSE } from '../../graphql/queries';
import ButtonSection from './ButtonSection';
import CompletedBadgeContainer from './CompletedBadgeContainer';
import CourseCardDetails from './CourseCardDetails';

const CourseCardContentListView = ({
    slug,
    view,
    title,
    description,
    progress,
    lastLesson,
    totalLessons,
    lastPage,
    hovered,
    handleDelete,
    isDashboard,
}) => {
    const { data: course } = useQuery(GET_COURSE, {
        variables: { slug },
    });

    return (
        <>
            {hovered ? <CardLinearGradientBackground /> : ''}
            <HeaderSection
                className="flex"
                margin="2.5rem 0rem 2.5rem 3rem"
                height="5.3rem"
            >
                {progress === '100' ? (
                    <CompletedBadgeContainer view={view} />
                ) : (
                    ''
                )}
                <Header4 className="maxTwoLineText" marginTop="-0.2rem">
                    {title}
                </Header4>
            </HeaderSection>
            <P
                color="greyText"
                className="maxThreeLineText body-2"
                height="5.5rem"
                margin="2.5rem 0rem"
            >
                {description}
            </P>
            {hovered ? (
                <ButtonSection
                    view={view}
                    progress={progress}
                    slug={slug}
                    lastPage={lastPage}
                    handleDelete={handleDelete}
                    isDashboard={isDashboard}
                />
            ) : (
                <CourseCardDetails
                    view={view}
                    course={course}
                    slug={slug}
                    progress={progress}
                    lastLesson={lastLesson}
                    lastPage={lastPage}
                    totalLessons={totalLessons}
                />
            )}
        </>
    );
};

const CardLinearGradientBackground = styled.div`
    position: absolute;
    width: 100%;
    height: 10.6rem;
    background-color: linear-gradient(
        82.22deg,
        rgba(255, 255, 255, 0) 27.04%,
        rgba(255, 255, 255, 0.443182) 43.08%,
        #ffffff 57.41%
    );
`;

export default CourseCardContentListView;
