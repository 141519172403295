import { useQuery } from '@apollo/client';
import { React, useState } from 'react';
import { useGlobalContext } from '../../GlobalContext/GlobalProvider';
import { GET_TRACKS } from '../../graphql/queries';
import ShowAllTracks from './ShowAllTracks';
import ShowDashboardHomeTracks from './ShowDashboardHomeTracks';
import ShowDashboardMyTracks from './ShowDashboardMyTracks';

const ShowTracksCards = ({ isDashboard, isMobile }) => {
    const [globalState] = useGlobalContext();
    const myCourses = globalState.user
        ? globalState.user.trackingLog.courseTracker
        : null;

    const {
        loading: tracksLoading,
        data: tracksData,
        error: tracksError,
    } = useQuery(GET_TRACKS);

    const [isDashboardHome] = useState(isDashboard);

    tracksLoading && <p>Tracks loading...</p>;
    tracksError && <p>Track Error</p>;

    // check if user is viewing myTracks in their dashboard
    let isDashboardMyTracks;
    if (window.location.href.indexOf('mytracks') > -1) {
        isDashboardMyTracks = true;
    }

    const data = tracksData && tracksData.learningTracks;

    const getProgress = (track) => {
        let coursesInTrack = track.courses.map((t) => t.title);
        let coursesStarted = [];
        let coursesCompleted = [];

        myCourses &&
            Object.values(myCourses).forEach((c) => {
                if (coursesInTrack.includes(c.title)) {
                    if (!c.courseCompleted) {
                        coursesStarted.push(c.title);
                    } else if (c.courseCompleted) {
                        coursesCompleted.push(c.title);
                    }
                }
            });

        let numOfCourses = track.courses.length;
        let numOfCompletedCourses = coursesCompleted.length;
        let numOfCoursesStarted = coursesStarted.length;

        return Math.floor(
            (numOfCompletedCourses > 0
                ? numOfCompletedCourses / numOfCourses
                : numOfCoursesStarted > 0 && numOfCompletedCourses === 0
                ? numOfCoursesStarted / 100
                : 0) * 100
        );
    };

    const tracksWithProgress =
        data &&
        data
            .filter((track) => getProgress(track) > 0)
            .map((item) => {
                return { ...item, progress: getProgress(item) };
            });

    const filterUnderDevelopment = tracksWithProgress?.filter((track) => {
        if (track?.underDevelopment === false) return true;
    });

    if (isDashboardHome && myCourses) {
        return (
            <ShowDashboardHomeTracks
                tracksWithProgress={filterUnderDevelopment}
                isMobile={isMobile}
            />
        ); // displayed track cards for /dashboard route
    }
    if (isDashboardMyTracks && myCourses) {
        return (
            <ShowDashboardMyTracks
                tracksWithProgress={filterUnderDevelopment}
                myCourses={myCourses}
                isMobile={isMobile}
            /> // displayed track cards for /dashboard/mytracks route
        );
    }
    return (
        <ShowAllTracks
            data={data}
            myCourses={myCourses}
            getProgress={getProgress}
            isMobile={isMobile}
        /> // displayed track cards for /tracks route
    );
};

export default ShowTracksCards;
