import React from 'react';
import { Switch } from 'react-router-dom';
import styled from '@emotion/styled';
import ProtectedRoute from '../routes/ProtectedRoute';
import DashboardHomePage from './DashboardHomePage';
import DashboardMyCoursesPage from './DashboardMyCoursesPage';
import DashboardMyTracksPage from './DashboardMyTracksPage';
import DashboardStudyPlanPage from './DashboardStudyPlanPage';
import DashboardSavedPage from './DashboardSavedPage';
import DashboardNavbar from 'components/DashboardNavbar/DashboardNavbar';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';

const DashboardContainerPage = () => {
    const [globalState] = useGlobalContext();
    const isDesktop =
        globalState.deviceSize === 'desktopSm' ||
        globalState.deviceSize === 'desktopMd';

    const isTablet = globalState.deviceSize === 'tablet';
    return (
        <DashboardPageContainer
            className={
                isDesktop || isTablet
                    ? 'flex-row-flex-start below-navbar'
                    : 'flex-column-flex-start below-navbar'
            }
        >
            <DashboardNavbar isDesktop={isDesktop} isTablet={isTablet} />
            <Switch>
                <ProtectedRoute
                    exact
                    path="/dashboard"
                    component={DashboardHomePage}
                />
                <ProtectedRoute
                    exact
                    path="/dashboard/mymodules"
                    component={DashboardMyCoursesPage}
                />
                <ProtectedRoute
                    exact
                    path="/dashboard/studyplan"
                    component={DashboardStudyPlanPage}
                />
                <ProtectedRoute
                    exact
                    path="/dashboard/saved"
                    component={DashboardSavedPage}
                />
                <ProtectedRoute
                    exact
                    path="/dashboard/mypathways"
                    component={DashboardMyTracksPage}
                />
            </Switch>
        </DashboardPageContainer>
    );
};

const DashboardPageContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    height: 100vh;
    overflow-y: hidden;
    background: ${(props) => props.theme.colors.tertiaryGradient};
`;

export default DashboardContainerPage;
