import styled from '@emotion/styled';
import React from 'react';
import PageHeaderWithSquare from '../../assets/styledComponents/PageHeaderWithSquare';
import CourseCardsNavigation from './CoursesCardsNavigation';

const CoursesHeaderSection = ({
    view,
    setView,
    coursesToShow,
    setCoursesToShow,
    numOfCourses,
    onSearchChange,
    isDesktop,
}) => {
    return (
        <HeaderContainer>
            <PageHeaderWithSquare
                headerTitle="MODULES"
                headerDescription="Start any module that you want to learn more about. Taking the modules in order will help build a strong diabetes foundation that will be expanded upon in the modules that follow."
                isDesktop={isDesktop}
            />
            <CourseCardsNavigation
                view={view}
                setView={setView}
                coursesToShow={coursesToShow}
                setCoursesToShow={setCoursesToShow}
                numOfCourses={numOfCourses}
                onSearchChange={onSearchChange}
                isDesktop={isDesktop}
            />
        </HeaderContainer>
    );
};

const HeaderContainer = styled.header`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-bottom: 0.4rem solid ${(props) => props.theme.colors.lightGrey};
    padding-bottom: 0.6rem;
`;

export default CoursesHeaderSection;
