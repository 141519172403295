import React from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import checkCircleSm from 'assets/icons/checkCircleSm.svg';
import secondaryDotSm from 'assets/icons/secondaryDotSm.svg';
import StyledIcon from 'assets/styledComponents/StyledImage';
import P from 'assets/styledComponents/fonts/P';

const LessonMobileNavbarContent = ({
    slug,
    lessonTitle,
    isLessonCompleted,
    lessonNumber,
    isCurrentLesson,
    isNextLessonAvaliable,
    isUserLoggedIn,
    setExpandMobileNavbar,
}) => {
    const history = useHistory();

    function handleClick() {
        history.push(`/modules/${slug}/${lessonNumber}`);
        setExpandMobileNavbar(false);
    }

    return (
        <LessonListItem
            padding={'0 2.8rem 0 2.4rem'}
            isCurrentLesson={isCurrentLesson}
            className={
                !isUserLoggedIn ||
                lessonNumber === 1 ||
                isLessonCompleted ||
                isNextLessonAvaliable
                    ? 'flex-space-between'
                    : 'disabled flex-space-between'
            }
            onClick={() => handleClick()}
        >
            <P
                className="maxTwoLineText petit-title-2"
                textTransform="uppercase"
            >
                L{lessonNumber}.&nbsp; {lessonTitle}
            </P>

            {isCurrentLesson ? (
                <StyledIcon
                    height="2.4rem"
                    width="2.4rem"
                    marginLeft="1.6rem"
                    src={secondaryDotSm}
                    alt="Orange Circle Indicating Lesson Last Visited"
                />
            ) : isLessonCompleted ? (
                <StyledIcon
                    height="2.4rem"
                    width="2.4rem"
                    marginLeft="1.6rem"
                    src={checkCircleSm}
                    alt="Orange Checkmark Indicating Lesson Complete"
                />
            ) : (
                ''
            )}
        </LessonListItem>
    );
};

const LessonListItem = styled.li`
    height: 5.8rem;
    width: 100%;
    padding: ${(props) => props.padding};
    background-color: ${(props) =>
        props.isCurrentLesson
            ? props.theme.colors.lightBackground
            : props.theme.colors.tertiary};
    color: ${(props) =>
        props.isCurrentLesson
            ? props.theme.colors.secondary
            : props.theme.colors.darkText};

    margin-top: -0.1rem;
    &.disabled {
        p {
            opacity: 0.4;
        }
        pointer-events: none;
    }
`;

export default LessonMobileNavbarContent;
