import CreatePlaceholder from './CreatePlaceHolder';

const ShowProfileAvatar = (data) => {
    let imageToDisplay;

    // uploaded images < 157px height will be null or undefined, displaying initials+ if no pic uploaded or uploaded image is too small
    if (data.id) {
        if (
            data.profilePic === null ||
            data.profilePic.thumbnail === undefined
        ) {
            imageToDisplay = CreatePlaceholder(data.firstName, data.lastName);
        } else {
            imageToDisplay = data.profilePic.thumbnail.url;
        }
    }

    return imageToDisplay;
};

export default ShowProfileAvatar;
