import React from 'react';
import styled from '@emotion/styled';
import PageMainContainer from '../assets/styledComponents/PageMainContainer';
import PageContentContainer from '../assets/styledComponents/PageContentContainer';
import PageHeaderWithSquare from '../assets/styledComponents/PageHeaderWithSquare';
import ShowTracksCards from '../components/Tracks/ShowTracksCards';
import { useQuery } from '@apollo/client';
import { GET_TRACKS_PAGE_HEADER } from '../graphql/queries';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';

const TracksPage = () => {
    const [globalState] = useGlobalContext();
    const isMobile = globalState.deviceSize === 'mobile';
    const isDesktop =
        globalState.deviceSize === 'desktopSm' ||
        globalState.deviceSize === 'desktopMd';
    // Queries
    const {
        loading: tracksHeaderLoading,
        data: tracksHeaderData,
        error: tracksHeaderError,
    } = useQuery(GET_TRACKS_PAGE_HEADER);

    // Loading and error messages
    tracksHeaderLoading && <p>Loading header info...</p>;
    tracksHeaderError && <p>Tracks Header Error</p>;

    // Data
    const HeaderTitle = tracksHeaderData
        ? tracksHeaderData.tracksPage.Header
        : '';

    const HeaderDescription = tracksHeaderData
        ? tracksHeaderData.tracksPage.Description
        : '';

    return (
        <PageMainContainer
            className="below-navbar flex-column-centered"
            backgroundColor="lightGreyBackground"
        >
            <TracksPageContainer
                marginLeft="8.8rem"
                marginRight="8.8rem"
                isDesktop={isDesktop}
            >
                <PageHeaderWithSquare
                    headerTitle={HeaderTitle}
                    headerDescription={isMobile ? '' : HeaderDescription}
                    isOnTracksPage={true}
                    isMobile={isMobile}
                    isDesktop={isDesktop}
                />
                <div className="flex-column-centered">
                    <ShowTracksCards isMobile={isMobile} />
                </div>
            </TracksPageContainer>
        </PageMainContainer>
    );
};

const TracksPageContainer = styled(PageContentContainer)`
    min-height: calc(
        100vh - 24rem
    ); // 24rem = 9rem(top navbar) + 15rem(padding for PageMainContainer)
`;

export default TracksPage;
