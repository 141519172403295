import React from 'react';
import styled from '@emotion/styled';
import Description from '../Description';
import Picture from '../Picture';
import PictureFullSize from '../PictureFullSize';
import Video from '../Video';
import Container from 'assets/styledComponents/Container';

// Interactive lessons
import CompletingBloodGlucoseCheck from '../InteractiveModules/Course2/CompletingBloodGlucoseCheck';
import ToolsForCalculatingCarbohydrateAmounts from '../InteractiveModules/Course5/Lesson5_8/ToolsForCalculatingCarbohydrateAmounts';
import RecipeCalculation from '../InteractiveModules/Course5/Lesson5_6/RecipeCalculation';
import PreparingForPhysicalActivity from '../InteractiveModules/Course7/PreparingForPhysicalActivity';
import NeedleFear from '../InteractiveModules/Course4/NeedleFear';
import FoodChoices from '../InteractiveModules/Course5/Lesson5_1/FoodChoices';
import CarbAppsAndResources from '../InteractiveModules/Course5/Lesson5_2/CarbAppsAndResources';
import PreventInsulinStackingActivity from '../InteractiveModules/Course6/Lesson6_5/PreventInsulinStackingActivity';
import TipsForIllnessManagement from '../InteractiveModules/Course9/TipsForIllnessManagement';
import FiveTenFifteenTwentyActivity from '../InteractiveModules/Course8/Lesson8_5/FiveTenFifteenTwentyActivity';
import InsulinStackingActivity from '../InteractiveModules/Course6/Lesson6_4/InsulinStackingActivity';
import PDFCarousel from '../PDFCarousel';
import SubTitle from '../SubTitle';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';

const LessonMainSection = ({ lesson, isMobile, devicesize }) => {
    const [globalState] = useGlobalContext();
    const { deviceSize } = globalState;
    const isTablet = Boolean(deviceSize === 'tablet');
    const isDesktop = Boolean(!isTablet && !isMobile);

    return (
        <MainSection>
            {lesson.map((l) => {
                switch (l.lessonNumber) {
                    case 2.3:
                        return <CompletingBloodGlucoseCheck key={l.id} />;
                    case 4.6:
                        return <NeedleFear key={l.id} />;
                    case 5.1:
                        return <FoodChoices key={l.id} />;
                    case 5.2:
                        return <CarbAppsAndResources key={l.id} />;
                    case 5.6:
                        return <RecipeCalculation key={l.id} />;
                    case 5.9:
                        return (
                            <ToolsForCalculatingCarbohydrateAmounts
                                key={l.id}
                            />
                        );
                    case 6.4:
                        return <InsulinStackingActivity key={l.id} />;
                    case 6.5:
                        return <PreventInsulinStackingActivity key={l.id} />;
                    case 7.3:
                        return (
                            <PreparingForPhysicalActivity
                                key={l.id}
                                isMobile={isMobile}
                            />
                        );
                    case 8.5:
                        return <FiveTenFifteenTwentyActivity key={l.id} />;
                    case 9.2:
                        return <TipsForIllnessManagement key={l.id} />;
                    default:
                }

                // regular lessons
                if (l.subTitle) {
                    return (
                        <SubTitle
                            key={l.subTitle}
                            data={l}
                            isMobile={isMobile}
                        />
                    );
                }
                if (l.description)
                    return (
                        <Description
                            key={l.description}
                            data={l}
                            isMobile={isMobile}
                        />
                    );

                if (l.image) {
                    switch (l.image.url) {
                        case 'https://learndiabetesstorage.azureedge.net/learn-diabetes-prod/assets/ggg_1f27699f40.jpg':
                            return (
                                <PictureFullSize
                                    key={l.image.url}
                                    src={l.image.url}
                                    alt={l.alternativeText}
                                />
                            );
                        case 'https://learndiabetesstorage.azureedge.net/learn-diabetes-prod/assets/5_6_01_90b4fe6c34.png':
                            return (
                                <PictureFullSize
                                    key={l.image.url}
                                    src={l.image.url}
                                    alt={l.alternativeText}
                                />
                            );
                        case 'https://learndiabetesstorage.azureedge.net/learn-diabetes-prod/assets/Picture11_83cdd3d7b5.png':
                            if (isDesktop) {
                                return (
                                    <Picture
                                        key={l.image.url}
                                        src={l.image.url}
                                        alt={l.alternativeText}
                                    />
                                );
                            } else {
                                return (
                                    <PictureFullSize
                                        key={l.image.url}
                                        src={l.image.url}
                                        alt={l.alternativeText}
                                    />
                                );
                            }
                        case 'https://learndiabetesstorage.azureedge.net/learn-diabetes-prod/assets/Screen_Shot_2023_01_26_at_12_06_51_PM_e7719f7086.png':
                            return (
                                <Container
                                    className="flex-column-centered"
                                    borderRadius="2rem"
                                    border={
                                        isDesktop ? '4px solid #E1EDF0' : 'none'
                                    }
                                    padding={isDesktop ? '6.4rem 8rem' : '0rem'}
                                    marginBottom={
                                        isDesktop ? '7.2rem' : '4.8rem'
                                    }
                                >
                                    <PictureFullSize
                                        key={l.image.url}
                                        src={l.image.url}
                                        alt={l.alternativeText}
                                    />
                                </Container>
                            );
                        case 'https://learndiabetesstorage.azureedge.net/learn-diabetes-prod/assets/Screen_Shot_2023_01_26_at_12_07_04_PM_93ff5bdf61.png':
                            return (
                                <Container
                                    className="flex-column-centered"
                                    borderRadius="2rem"
                                    border={
                                        isDesktop ? '4px solid #E1EDF0' : 'none'
                                    }
                                    padding={isDesktop ? '6.4rem 8rem' : '0rem'}
                                    marginBottom={
                                        isDesktop ? '7.2rem' : '4.8rem'
                                    }
                                >
                                    <PictureFullSize
                                        key={l.image.url}
                                        src={l.image.url}
                                        alt={l.alternativeText}
                                    />
                                </Container>
                            );
                        case 'https://learndiabetesstorage.azureedge.net/learn-diabetes-prod/assets/Screen_Shot_2023_01_26_at_12_07_09_PM_a67df81ac6.png':
                            return (
                                <Container
                                    className="flex-column-centered"
                                    borderRadius="2rem"
                                    border={
                                        isDesktop ? '4px solid #E1EDF0' : 'none'
                                    }
                                    padding={isDesktop ? '6.4rem 8rem' : '0rem'}
                                    marginBottom={
                                        isDesktop ? '7.2rem' : '4.8rem'
                                    }
                                >
                                    <PictureFullSize
                                        key={l.image.url}
                                        src={l.image.url}
                                        alt={l.alternativeText}
                                    />
                                </Container>
                            );

                        default:
                            return (
                                <>
                                    <Picture
                                        key={l.image.url}
                                        src={l.image.url}
                                        alt={l.alternativeText}
                                    />
                                </>
                            );
                    }
                }
                if (l.videoUrl) return <Video key={l.videoUrl} data={l} />;
                if (l.document)
                    return (
                        <PDFCarousel
                            key={l.document.url}
                            pdf={l.document.url}
                            devicesize={devicesize}
                        />
                    );
            })}
        </MainSection>
    );
};

const MainSection = styled.main`
    max-width: 98rem;
    width: 100%;
`;

export default LessonMainSection;
