import React from 'react';
import styled from '@emotion/styled';
import useAuth from 'hooks/useAuth';
import MobileNavItems from './MobileNavItems';
import hamburgerMenu from 'assets/icons/hamburgerMenu.svg';
import closeHamburgerMenu from 'assets/icons/closeHamburger.svg';
import MobileSignUpForm from 'components/SignInAndSignUp/MobileSignUpForm';
import MobileSignInForm from 'components/SignInAndSignUp/MobileSignInForm';

const HamburgerMenu = ({
    isMobileNavbarOpen,
    setIsMobileNavbarOpen,
    showMobileSignInForm,
    setShowMobileSignInForm,
    showMobileSignUpForm,
    setShowMobileSignUpForm,
}) => {
    useAuth();
    return (
        <Wrapper>
            <button
                className="burger-or-close-button"
                onClick={() => setIsMobileNavbarOpen(!isMobileNavbarOpen)}
            >
                <img
                    className="burger-or-close-icon"
                    src={
                        isMobileNavbarOpen ? closeHamburgerMenu : hamburgerMenu
                    }
                    alt=""
                />
            </button>
            {isMobileNavbarOpen ? (
                <MobileNavItems
                    isMobileNavbarOpen={isMobileNavbarOpen}
                    setIsMobileNavbarOpen={setIsMobileNavbarOpen}
                    showMobileSignInForm={showMobileSignInForm}
                    setShowMobileSignInForm={setShowMobileSignInForm}
                    showMobileSignUpForm={showMobileSignUpForm}
                    setShowMobileSignUpForm={setShowMobileSignUpForm}
                />
            ) : showMobileSignInForm ? (
                <MobileSignInForm
                    showMobileSignInForm={showMobileSignInForm}
                    setShowMobileSignInForm={setShowMobileSignInForm}
                    setShowMobileSignUpForm={setShowMobileSignUpForm}
                />
            ) : showMobileSignUpForm ? (
                <MobileSignUpForm
                    showMobileSignUpForm={showMobileSignUpForm}
                    setShowMobileSignUpForm={setShowMobileSignUpForm}
                    setShowMobileSignInForm={setShowMobileSignInForm}
                />
            ) : (
                ''
            )}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    button.burger-or-close-button {
        background: inherit;
        cursor: pointer;

        img.burger-or-close-icon {
            width: 4.8rem;
            height: 4.8rem;
        }
    }
`;

export default HamburgerMenu;
