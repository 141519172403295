import React, { useState, useRef } from 'react';
import { usePdf } from '@mikecousins/react-pdf';
import styled from '@emotion/styled';
import pdfPrev from 'assets/icons/pdfPrev.svg';
import pdfNext from 'assets/icons/pdfNext.svg';
import ShowTabButtons from './InteractiveModules/Course5/Lesson5_2/ShowTabButtons';
import Container from 'assets/styledComponents/Container';
import PdfDescriptionMobile from './InteractiveModules/Course5/Lesson5_2/PdfDescriptionMobile';
import DownloadButton from '../../assets/styledComponents/buttons/DownloadButton';

const PDFCarousel = ({ pdf, isFoodsToCount, devicesize }) => {
    const pdfFile = pdf;
    const [page, setPage] = useState(1);
    const canvasRef = useRef(null);

    const { pdfDocument } = usePdf({
        scale: 1.4,
        file: pdfFile,
        page,
        canvasRef,
    });

    let pagesArray = [];

    if (pdfDocument?.numPages) {
        for (let i = 1; i <= pdfDocument.numPages; i++) {
            pagesArray.push(i);
        }
    }

    return (
        <Container
            className="flex-column-centered"
            marginTop={
                devicesize === 'mobile' || devicesize === 'tablet'
                    ? '3.2rem'
                    : '4.8rem'
            }
        >
            <PDFContainer
                Container
                className={
                    isFoodsToCount
                        ? 'foods-to-count flex-column-centered'
                        : 'carb-counting-resource flex-column-centered'
                }
                devicesize={devicesize}
            >
                {isFoodsToCount ? (
                    devicesize === 'mobile' ? (
                        <PdfDescriptionMobile page={page} />
                    ) : (
                        <ShowTabButtons setPage={setPage} page={page} />
                    )
                ) : (
                    ''
                )}
                <Container width={devicesize === 'mobile' ? '100vw' : '100%'}>
                    {!pdfDocument && <span>Loading...</span>}
                    <canvas ref={canvasRef} />
                </Container>

                {devicesize !== 'mobile' &&
                    Boolean(pdfDocument && pdfDocument.numPages) && (
                        <nav className="nav-side-buttons flex-space-between">
                            <StyledBtn
                                className="previous pdf-button-shadow"
                                disabled={page === 1}
                                onClick={() => setPage(page - 1)}
                            />

                            <StyledBtn
                                className="next pdf-button-shadow"
                                disabled={page === pdfDocument.numPages}
                                onClick={() => setPage(page + 1)}
                            />
                        </nav>
                    )}
            </PDFContainer>
            {Boolean(pdfDocument && pdfDocument.numPages) && (
                <NavDotContainer className="dots" devicesize={devicesize}>
                    <ul className="flex-all-centered">
                        {pagesArray.map((item) => {
                            return (
                                <li key={item}>
                                    {' '}
                                    <DotButton
                                        className={
                                            item === page
                                                ? 'active'
                                                : 'inactive'
                                        }
                                        onClick={() => setPage(item)}
                                    />
                                </li>
                            );
                        })}
                    </ul>
                </NavDotContainer>
            )}
            <DownloadButton
                text="Download PDF"
                marginTop={devicesize === 'mobile' ? '3.2rem' : '4.8rem'}
                className="flex-column-centered"
                pdf={pdf}
            />
        </Container>
    );
};

const NavDotContainer = styled.nav`
    margin-top: ${(props) =>
        props.devicesize === 'mobile'
            ? '2.4rem'
            : props.devicesize === 'tablet'
            ? '3.2rem'
            : '4.8rem'};
    position: relative;
    width: 15.2rem;
    > ul {
        gap: 10%;
    }
`;

const DotButton = styled.button`
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    cursor: pointer;

    &.active {
        background: ${(props) => props.theme.colors.secondary};
    }

    &.inactive {
        background: ${(props) => props.theme.colors.greyText};
    }
`;

const PDFContainer = styled.div`
    position: relative;

    width: auto;

    &.carb-counting-resource {
        border: ${(props) =>
            props.devicesize === 'mobile'
                ? 'none'
                : `0.3rem solid ${props.theme.colors.tertiary}`};
        border-radius: ${(props) =>
            props.devicesize === 'mobile' ? '0rem' : '2rem'};

        canvas {
            border-radius: ${(props) =>
                props.devicesize === 'mobile' ? '0rem' : '2rem'};
        }

        nav.nav-side-buttons {
            width: calc(100% + 3.6rem);
            bottom: calc(50% - 1.8rem);
            position: absolute;
        }
    }

    &.foods-to-count {
        nav.nav-side-buttons {
            width: calc(100% + 4rem);
            bottom: calc(32%);
            position: absolute;
            @media screen and (max-width: ${(props) =>
                    props.theme.breakPoints.desktopSm}) {
                width: calc(100% + 3rem);
                bottom: 23%;
            }
        }

        canvas {
            width: 100% !important;
            height: auto !important;
        }

        width: 100%;
    }
`;

let StyledBtn = styled.button`
    background: ${(props) => props.theme.colors.lightGrey};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 3.6rem;
    height: 3.6rem;
    border-radius: 100%;
    :hover {
        cursor: pointer;
    }

    background-size: cover;

    &.previous {
        background-image: url(${pdfPrev});

        :hover {
            box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.4);
        }
    }

    &.next {
        background-image: url(${pdfNext});

        :hover {
            box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.4);
        }
    }
`;

export default PDFCarousel;
