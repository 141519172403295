import React from 'react';
import { ListItem } from './SharedComponents';

const UnHelpfulBehaviours = ({ devicesize }) => {
    const unhelpfulBehaviours = [
        `Making multiple reoccuring comments i.e. it won't hurt at all, I promise`,
        `Criticizing`,
        `Apologizing or bargaining`,
        `Getting upset`,
        `Getting upset with your child, remain calm`,
        `Talking about previous negative experiences`,
    ];
    return (
        <>
            {unhelpfulBehaviours.map((item) => (
                <ListItem
                    className="body-4 flex-center-aligned"
                    key={item}
                    devicesize={devicesize}
                >
                    {item}
                </ListItem>
            ))}
        </>
    );
};

export default UnHelpfulBehaviours;
