import React from 'react';
import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import P from 'assets/styledComponents/fonts/P';

const DashboardMobileNavbarContent = ({ item, isActive }) => {
    return (
        <LinkAndIconContainer exact to={item.route} key={item.text}>
            <StyledIcon src={isActive ? item.iconActive : item.icon} />
            <P
                className="petit-title-1"
                marginTop="0.9rem"
                color={isActive ? 'secondary' : 'primaryDarkest'}
            >
                {item.text}
            </P>
        </LinkAndIconContainer>
    );
};

const LinkAndIconContainer = styled(NavLink)`
    display: flex;
    text-decoration: none;
    height: 5.7rem;
    width: 100%;
    padding-left: 2.1rem;
    background-color: ${(props) => props.theme.colors.tertiary};
    border: 0.3rem solid ${(props) => props.theme.colors.tertiary};
    color: ${(props) => props.theme.colors.primaryDarkest};
`;

const StyledIcon = styled.img`
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 1.2rem;
    margin-top: 1.1rem;
`;

export default DashboardMobileNavbarContent;
