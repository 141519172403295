import React from 'react';
import styled from '@emotion/styled';

// keep as a div and use the correct hierarchy h1-h6 or p as a child inside this wrapper. It will inherit this style. For improved accessibility.
// role="presentation" is used for an element whose content is completely presentational or an element used as an additional markup "hook" for CSS
const Header4 = ({
    children,
    className,
    margin,
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    width,
    minWidth,
    maxWidth,
    color,
    textAlign,
    textTransform,
    innerRef,
    onClick,
}) => {
    return (
        <Header4El
            role="presentation"
            className={className}
            margin={margin}
            marginTop={marginTop}
            marginBottom={marginBottom}
            marginLeft={marginLeft}
            marginRight={marginRight}
            width={width}
            minWidth={minWidth}
            maxWidth={maxWidth}
            color={color}
            textAlign={textAlign}
            textTransform={textTransform}
            ref={innerRef}
            onClick={onClick}
        >
            {children}
        </Header4El>
    );
};

const Header4El = styled.div`
    font-family: 'MontserratBold', sans-serif;
    font-size: 2.2rem;
    letter-spacing: ${(props) => props.theme.letterSpacing.sm};
    line-height: 118%;

    /* customizable */
    margin: ${(props) => props.margin};
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
    width: ${(props) => props.width};
    min-width: ${(props) => props.minWidth};
    max-width: ${(props) => props.maxWidth};
    color: ${(props) => props.color && props.theme.colors[props.color]};
    text-align: ${(props) => props.textAlign};
    text-transform: ${(props) => props.textTransform};
`;

export default Header4;
