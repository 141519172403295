import styled from '@emotion/styled';
import React from 'react';
import CourseCard from '../../components/CourseCard/CourseCard';
import { useGlobalContext } from '../../GlobalContext/GlobalProvider';
import Layout from '../../types/layout';
import calculateTotalLessons from '../../utils/helpers/CalculateTotalLessons';
import CourseCardListView from '../CourseCard/CourseCardListView';

const TracksPageCourses = ({ view, trackCourses }) => {
    const [globalState] = useGlobalContext();
    const myCourses = globalState.user
        ? globalState.user.trackingLog.courseTracker
        : null;

    const calculateProgress = (course) => {
        const completedLessons =
            myCourses && myCourses[course.id]
                ? Number(
                      Object.keys(myCourses[course.id].lessonsCompleted).length
                  )
                : null;
        const progress =
            myCourses && myCourses[course.id] && completedLessons === 0
                ? '0'
                : Math.floor(
                      (completedLessons !== null
                          ? completedLessons / myCourses[course.id].totalLessons
                          : 0) * 100
                  ) + '';
        return progress;
    };

    const handleDelete = () => {
        console.log('On Delete Button Clicked');
    };

    return (
        <CoursesCardContainer view={view}>
            {trackCourses
                ? trackCourses.map((c) => {
                      const myCourse = myCourses && myCourses[c.id];

                      // use Strapi small optimized image size if available,
                      // else use original uploaded image
                      let smallImage =
                          c.courseImage.image.formats &&
                          c.courseImage.image.formats.small &&
                          c.courseImage.image.formats.small.url;
                      let nonOptimizedImage = c.courseImage.image.url;

                      return view === Layout.CARD_VIEW ? (
                          <CourseCard
                              course={c}
                              view={Layout.CARD_VIEW}
                              imageURL={
                                  smallImage ? smallImage : nonOptimizedImage
                              }
                              title={c.title}
                              description={c.description}
                              slug={c.slug}
                              progress={calculateProgress(c)}
                              lastLesson={myCourse && myCourse.lastLesson}
                              lastPage={myCourse && myCourse.lastPageIndex}
                              totalLessons={calculateTotalLessons(c)}
                              key={c.id}
                              id={c.id}
                              handleDelete={handleDelete}
                              width="36rem"
                              height="38rem"
                              imageHeight="20rem"
                              margin="2rem 3rem 2.5rem"
                              progressBarWidth="27rem"
                              marginBottom="4rem"
                          />
                      ) : (
                          <CourseCardListView
                              course={c}
                              view={Layout.LIST_VIEW}
                              title={c.title}
                              description={c.description}
                              slug={c.slug}
                              progress={calculateProgress(c)}
                              lastLesson={myCourse && myCourse.lastLesson}
                              lastPage={myCourse && myCourse.lastPageIndex}
                              totalLessons={calculateTotalLessons(c)}
                              key={c.id}
                              id={c.id}
                              first={trackCourses.indexOf(c) === 0}
                              last={
                                  trackCourses.indexOf(c) ===
                                  trackCourses.length - 1
                              }
                              handleDelete={handleDelete}
                          />
                      );
                  })
                : ''}
        </CoursesCardContainer>
    );
};

const CoursesCardContainer = styled.div`
    display: ${(props) => (props.view == Layout.CARD_VIEW ? 'flex' : '')};
    margin-top: ${(props) => props.theme.spaces.md};
    margin-bottom: ${(props) => props.theme.spaces.md};
    column-gap: ${(props) => (props.view == Layout.CARD_VIEW ? '4rem' : '')};
    flex-wrap: ${(props) => (props.view == Layout.CARD_VIEW ? 'wrap' : '')};
`;

export default TracksPageCourses;
