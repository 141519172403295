import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useQuery } from '@apollo/client';
import { GET_ALL_COURSES } from 'graphql/queries';
import ShowLoggedInCards from './ShowLoggedInCards';
import ShowLoggedOutCards from './ShowLoggedOutCards';

const CourseSlider = ({ devicesize, authUser, globalState }) => {
    const getCourses = useQuery(GET_ALL_COURSES);
    const allCourses = getCourses?.data?.courses;

    let [width, setWidth] = useState(0);
    let getWidth = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        // set width when component first mounts
        setWidth(window.innerWidth);
        // set width on resize
        window.addEventListener('resize', getWidth);
    }, []);

    function slidesToShow(w) {
        if (w >= 1680) return 4;
        else if (w >= 1350) return 3;
        else if (w >= 1056) return 2;
        else if (w >= 940) return 5;
        else if (w >= 700) return 4;
        else if (w >= 550) return 3;
        else if (w >= 360) return 2;
        else if (w >= 0) return 1;
    }

    function showDotsAndArrow() {
        return devicesize !== 'mobile' ? true : false;
    }

    const settings = {
        speed: 500,
        arrows: showDotsAndArrow(),
        infinite: true,
        dots: showDotsAndArrow(),
        lazyLoad: true,
        // slidesToShow: slidesToShow(),
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        swipe: true,
        slidesToShow: slidesToShow(width),
        initialSlide: 0,
    };

    const [myCourses, setMyCourses] = useState(
        globalState.user ? globalState.user.trackingLog.courseTracker : null
    );

    useEffect(() => {
        setMyCourses(
            globalState.user ? globalState.user.trackingLog.courseTracker : null
        );
        return () => setMyCourses(null);
    }, [globalState]);

    return (
        <StyledSlider {...settings} devicesize={devicesize}>
            {!authUser
                ? ShowLoggedOutCards(allCourses, globalState)
                : ShowLoggedInCards(globalState, allCourses, myCourses)}
        </StyledSlider>
    );
};

const StyledSlider = styled(Slider)`
    margin: 0.5rem;
    padding-bottom: ${(props) =>
        props.devicesize == 'mobile' ? '' : '5.6rem'};
    overflow-x: visible;

    .slick-slide {
        & > div {
            margin: 1rem;
            margin-left: 2.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    /* DOTS  */
    li button:before {
        color: ${(props) => props.theme.colors.greyText};
        font-size: 2rem;
    }

    li.slick-active button:before {
        color: ${(props) => props.theme.colors.secondary};
    }

    /* NAVIGATION BUTTONS */
    button {
        z-index: 1;

        &:before {
            font-size: 3.6rem;
            color: ${(props) => props.theme.colors.lightBackground};
        }
    }
`;

export default CourseSlider;
