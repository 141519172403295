import { React } from 'react';
import styled from '@emotion/styled';

const ToggleUserGroup = ({ group, onChange, isSignUp, isMobile }) => {
    return (
        <ToggleContainer isSignUp={isSignUp} isMobile={isMobile}>
            <ToggleLabel
                htmlFor="healthcareProfessional"
                isSignUp={isSignUp}
                isMobile={isMobile}
                className={
                    group === 'healthcareProfessional' ? 'left active' : 'left'
                }
            >
                Healthcare Prof.
                <ToggleInput
                    type="radio"
                    name="group"
                    id="healthcareProfessional"
                    value="healthcareProfessional"
                    onChange={onChange}
                />
            </ToggleLabel>

            <ToggleLabel
                htmlFor="patient"
                isSignUp={isSignUp}
                isMobile={isMobile}
                className={group === 'patient' ? 'right active' : 'right'}
            >
                Patient/Family
                <ToggleInput
                    type="radio"
                    name="group"
                    id="patient"
                    value="patient"
                    onChange={onChange}
                />
            </ToggleLabel>
        </ToggleContainer>
    );
};

const ToggleContainer = styled.div`
    display: flex;
    border: 0.1rem solid ${(props) => props.theme.colors.lightGrey};
    border-radius: ${(props) =>
        props.isSignUp || props.isMobile ? '2rem' : '3rem'};
    width: ${(props) => (props.isSignUp || props.isMobile ? '100%' : '40rem')};
    height: ${(props) => props.isSignUp || (props.isMobile && '4rem')};
    color: ${(props) => props.theme.colors.greyText};
    font-size: ${(props) =>
        props.isSignUp || props.isMobile ? '1.4rem' : '1.7rem'};
    font-family: 'DMSansMedium';
`;

const ToggleLabel = styled.label`
    width: 50%;
    height: 100%;
    padding: 1rem;
    background: ${(props) => props.theme.colors.lightBackground};
    display: flex;
    justify-content: center;
    align-items: center;

    &.active {
        background-color: ${(props) => props.theme.colors.tertiary};
    }

    &.left {
        border-top-left-radius: ${(props) =>
            props.isSignUp ? '2rem' : '3rem'};
        border-bottom-left-radius: ${(props) =>
            props.isSignUp ? '2rem' : '3rem'};
        border-right: 0.1rem solid ${(props) => props.theme.colors.lightGrey};
    }

    &.right {
        border-top-right-radius: ${(props) =>
            props.isSignUp ? '2rem' : '3rem'};
        border-bottom-right-radius: ${(props) =>
            props.isSignUp ? '2rem' : '3rem'};
    }

    :hover {
        cursor: pointer;
    }
`;

const ToggleInput = styled.input`
    /* hide the radio button from view  */
    display: none;
`;

export default ToggleUserGroup;
