import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import bcchLogo from 'assets/images/footer/bcchLogo.svg';
import digitalLabLogo from 'assets/images/footer/digitalLabLogo.svg';
import ubcLogo from 'assets/images/footer/ubcLogo.svg';
import H2 from 'assets/styledComponents/fonts/H2';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';

const LogoData = [
    { id: 1, image: digitalLabLogo, altText: 'Digital Lab Logo' },
    { id: 2, image: bcchLogo, altText: 'BC Childrens Hospital Logo' },
    { id: 3, image: ubcLogo, altText: 'UBC Logo' },
];

const LinkData = [
    { id: 1, title: 'Modules', to: '/modules' },
    { id: 2, title: 'Pathways', to: '/pathways' },
    { id: 3, title: 'Dashboard', to: '/dashboard' },
];

const Footer = () => {
    const [globalState] = useGlobalContext();

    return (
        <FooterMainContainer
            className="flex-center-justified"
            devicesize={globalState.deviceSize}
        >
            <FooterContentContainer
                className={
                    globalState.deviceSize === 'mobile'
                        ? 'flex-column'
                        : 'flex-justify-space-between flex-wrap'
                }
                devicesize={globalState.deviceSize}
            >
                <LinkAndContactContainer
                    devicesize={globalState.deviceSize}
                    className={
                        globalState.deviceSize === 'mobile'
                            ? 'flex-column'
                            : 'flex-wrap flex-justify-space-between'
                    }
                >
                    {/* links section */}
                    <section className="flex-column">
                        <H2
                            className={
                                globalState.deviceSize === 'mobile'
                                    ? 'body-4'
                                    : 'body-1'
                            }
                            marginBottom={
                                globalState.deviceSize === 'mobile'
                                    ? '0.6rem'
                                    : globalState.deviceSize === 'tablet'
                                    ? '1.2rem'
                                    : '2.4rem'
                            }
                        >
                            Quick Links
                        </H2>
                        {LinkData?.map((link) => {
                            return link.title === 'Dashboard' &&
                                !globalState.user ? (
                                ''
                            ) : (
                                <StyledLink
                                    key={link.id}
                                    to={link.to}
                                    className={
                                        globalState.deviceSize === 'mobile'
                                            ? 'body-3'
                                            : 'body-2'
                                    }
                                    devicesize={globalState.deviceSize}
                                >
                                    {link.title}
                                </StyledLink>
                            );
                        })}
                    </section>

                    {/* contact section  */}
                    <section className="flex-column">
                        <H2
                            className={
                                globalState.deviceSize === 'mobile'
                                    ? 'body-4'
                                    : 'body-1'
                            }
                            marginBottom={
                                globalState.deviceSize === 'mobile'
                                    ? '0.6rem'
                                    : globalState.deviceSize === 'tablet'
                                    ? '1.2rem'
                                    : '2.4rem'
                            }
                        >
                            Contact
                        </H2>
                        <Email
                            className={
                                globalState.deviceSize === 'mobile'
                                    ? 'body-3'
                                    : 'body-2'
                            }
                            href="mailto:Madeleine.Leung@phsa.ca"
                        >
                            info@learndiabetes.ca
                        </Email>
                    </section>
                </LinkAndContactContainer>

                {/* logo section */}
                <section className="flex-column">
                    <H2
                        className={
                            globalState.deviceSize === 'mobile'
                                ? 'body-3'
                                : 'petit-title-2'
                        }
                        marginBottom={
                            globalState.deviceSize === 'mobile'
                                ? '1.6rem'
                                : '2rem'
                        }
                    >
                        <p>
                            DEVELOPED BY THE BCCH DIABETES TEAM AND DIGITAL LAB
                        </p>
                    </H2>

                    <LogoContainer
                        className={
                            globalState.deviceSize === 'mobile'
                                ? 'flex-wrap'
                                : 'flex-space-between flex-wrap'
                        }
                        devicesize={globalState.deviceSize}
                    >
                        {LogoData?.map((logo) => {
                            return (
                                <Image
                                    key={logo.id}
                                    src={logo.image}
                                    alt={logo.altText}
                                />
                            );
                        })}
                    </LogoContainer>
                </section>
            </FooterContentContainer>
        </FooterMainContainer>
    );
};

const LinkAndContactContainer = styled.div`
    width: ${(props) =>
        props.devicesize === 'desktopSm'
            ? '36rem'
            : props.devicesize === 'desktopMd'
            ? '40rem'
            : '27.2rem'};
    margin-bottom: ${(props) => props.devicesize === 'mobile' && '4rem'};
`;

const Image = styled.img`
    margin-right: 1.8rem;
    margin-bottom: 1.8rem;

    :last-of-type {
        margin-right: 0;
    }
`;

const Email = styled.a`
    color: inherit;
    text-decoration: none;
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    color: inherit;
    margin-bottom: ${(props) =>
        props.devicesize === 'mobile'
            ? '0.6rem'
            : props.devicesize === 'tablet'
            ? '1.2rem'
            : '2.4rem'};
    :last-of-type {
        margin-bottom: ${(props) =>
            props.devicesize === 'tablet'
                ? '3.2rem'
                : props.devicesize === 'mobile' && '2.4rem'};
    }

    :hover {
        opacity: 0.7;
    }
`;

const LogoContainer = styled.div`
    max-width: ${(props) => props.devicesize === 'mobile' && '17rem'};
`;

const FooterMainContainer = styled.footer`
    width: 100%;
    background-color: ${(props) => props.theme.colors.primaryDarkest};
    color: ${(props) => props.theme.colors.lightText};
    min-height: 42rem;
    padding: ${(props) =>
        props.devicesize === 'mobile'
            ? '5.6rem 2.4rem 9rem'
            : props.devicesize === 'tablet'
            ? '8.8rem 4.8rem 10.2rem'
            : '9.8rem 12rem 10rem'};
`;

const FooterContentContainer = styled.div`
    width: 100%;
    max-width: 116rem;
    height: 100%;
    padding: ${(props) =>
        props.devicesize === 'tablet'
            ? '0 2.4rem 0 1.6rem'
            : props.devicesize === 'desktopMd' && '0 13.2rem 0 10rem'};
`;

export default Footer;
