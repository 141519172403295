import Container from 'assets/styledComponents/Container';
import UseDesktopBanner from 'assets/styledComponents/UseDesktopBanner';
import React, { useState, useRef } from 'react';
import { ActivityHeader } from '../../SharedComponentsInteractive';
import GameMobile from './GameMobile';

const InteractiveActivityMobile = () => {
    const [showHint, setShowHint] = useState(false);
    const hintRef = useRef(null);

    const scrollUp = () => {
        return hintRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    };

    const onHintClick = () => {
        setShowHint(true);
        scrollUp();
        setTimeout(() => {
            setShowHint(false);
        }, 3000);
    };
    return (
        <>
            <UseDesktopBanner marginTop="1rem" />
            <Container
                width="100%"
                className="flex-space-between"
                marginTop="3.2rem"
            >
                <ActivityHeader instructions="Choose one combination of food with carbohydrate." />
            </Container>
            <GameMobile
                onHintClick={onHintClick}
                showHint={showHint}
                hintRef={hintRef}
            />
        </>
    );
};

export default InteractiveActivityMobile;
