import React from 'react';
import StyledImage from 'assets/styledComponents/StyledImage';
import searchNoResult from 'assets/images/searchNoResult.svg';
import P from 'assets/styledComponents/fonts/P';
import Container from 'assets/styledComponents/Container';

const CourseSearchNoResult = ({ searchvalue }) => {
    return (
        <Container textAlign="center" width="100%">
            <StyledImage
                src={searchNoResult}
                alt="No result found"
                marginTop="6rem"
            />

            <P className="header-4" color="primaryDarkest" marginTop="3.2rem">
                No result found
            </P>

            <P className="body-4" color="greyText" marginTop="0.8rem">
                We couldn’t find any matching results for {`"${searchvalue}"`}
            </P>
        </Container>
    );
};
export default CourseSearchNoResult;
