import React, { useState, useRef } from 'react';
import ActivityHeaderWithInstructions from './ActivityHeaderWithInstructions';
import Game from './Game';
import proteinBroccoli from 'assets/images/interactive-course5-1/proteinBroccoli.svg';
import macaroni from 'assets/images/interactive-course5-1/macaroni.svg';
import milk from 'assets/images/interactive-course5-1/milk.svg';
import strawberries from 'assets/images/interactive-course5-1/strawberries.svg';
import sugarFreeWhippedCream from 'assets/images/interactive-course5-1/sugarFreeWhippedCream.svg';

const InteractiveActivity = ({ devicesize }) => {
    const [bounce, setBounce] = useState(false);
    const hintRef = useRef(null);

    const scrollUp = () => {
        return hintRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    };

    const onHintClick = () => {
        scrollUp();
        // Right answers begins to bounce
        setBounce(true);

        // Right answers stops to bounce after 4 seconds
        setTimeout(() => {
            setBounce(false);
        }, 4000);
    };
    const options = [
        {
            label: 'proteinBroccoli',
            altText: 'an illustration of a plate of protein and broccoli',
            image: proteinBroccoli,
        },
        {
            label: 'macaroni',
            altText: 'an illustration of a bowl of macaroni',
            image: macaroni,
        },
        {
            label: 'milk',
            altText: 'an illustration of a glass of milk beside a milk carton',
            image: milk,
        },
        {
            label: 'strawberries',
            altText: 'an illustration of a bowl of fresh strawberries',
            image: strawberries,
        },

        {
            label: 'sugarFreeWhippedCream',
            altText: 'an illustration of sugar free whipped cream',
            image: sugarFreeWhippedCream,
        },
    ];

    const correctAnswers = ['macaroni', 'milk', 'strawberries'];
    return (
        <>
            <ActivityHeaderWithInstructions hintRef={hintRef} />{' '}
            <Game
                options={options}
                correctAnswers={correctAnswers}
                bounce={bounce}
                devicesize={devicesize}
                onHintClick={onHintClick}
            />
        </>
    );
};

export default InteractiveActivity;
