import React from 'react';
import behavioralAdviceGroups from 'types/behaviouralAdviceGroups';
import HelpfulBehaviours from './HelpfulBehaviours';
import UnHelpfulBehaviours from './UnhelpfulBehaviours';

const BehaviouralContent = ({ activeTab, devicesize }) => {
    return activeTab === behavioralAdviceGroups.HELPFUL_BEHAVIOURS ? (
        <HelpfulBehaviours devicesize={devicesize} />
    ) : (
        <UnHelpfulBehaviours devicesize={devicesize} />
    );
};

export default BehaviouralContent;
