import React from 'react';
import StyledImage from 'assets/styledComponents/StyledImage';
import step2Img1 from 'assets/images/interactiveBook-course2/step2Img1.svg';
import step2Img2 from 'assets/images/interactiveBook-course2/step2Img2.svg';
import P from 'assets/styledComponents/fonts/P';
import H3 from 'assets/styledComponents/fonts/H3';
import { Step2ListItems } from './Step2ListItems';
import { ListItem } from '../Course4/SharedComponents';

const Step2Mobile = () => {
    return (
        <>
            <H3
                className="header-3"
                textAlign="left"
                width="100%"
                marginTop="4.8rem"
            >
                Step 2
            </H3>
            <StyledImage src={step2Img1} />

            <ul>
                <ListItem>Set up your lancing device</ListItem>
                {Step2ListItems.map((item) => (
                    <ListItem key={item.id}>{item.text}</ListItem>
                ))}
            </ul>
            <StyledImage src={step2Img2} height="34rem" marginTop="4rem" />
            <P className="body-2" marginTop="2.4rem">
                Your lancing device has different settings that allow you to
                change for depth of the finger test the depth of the lancet. The
                larger the number, the deeper the lancet will puncture the skin.
            </P>
            <P className="body-2" marginTop="1.6rem">
                Discuss with your diabetes team what setting to use. Set the
                lancing device aside. It is recommended to change your lancet
                after each finger check
            </P>
        </>
    );
};

export default Step2Mobile;
