import React, { useReducer, createContext, useContext } from 'react';
import { lightMode } from '../assets/styles/theme';

const initialState = {
    fontScale: 1,
    theme: lightMode,
    auth: false,
    user: null,
    deviceSize: '',
};

const Reducers = (state, action) => {
    switch (action.type) {
        case 'CHANGE_FONT_SIZE':
            return { ...state, fontScale: action.payload };
        case 'CHANGE_THEME':
            return {
                ...state,
                theme: action.payload === 'light' && lightMode,
            };
        case 'UPDATE_AUTH':
            return {
                ...state,
                auth: action.payload,
            };
        case 'SAVE_USER':
            return {
                ...state,
                user: action.payload,
            };
        case 'CLEAR_USER':
            return {
                ...state,
                ...initialState,
            };
        case 'UPDATE_DEVICE_SIZE':
            return {
                ...state,
                deviceSize: action.payload,
            };
        default:
            return state;
    }
};

const GlobalContext = createContext();

const GlobalProvider = ({ children }) => {
    const [state, dispatch] = useReducer(Reducers, initialState);
    return (
        <GlobalContext.Provider value={[state, dispatch]}>
            {children}
        </GlobalContext.Provider>
    );
};

// custom hook to provide context, throwing an error if outside GlobalProvider
const useGlobalContext = () => {
    const state = useContext(GlobalContext);

    if (state === undefined) {
        throw new Error('Component must be wrapped in GlobalProvider');
    }
    return state;
};

export { GlobalProvider, useGlobalContext };
