import { React, useState, useEffect } from 'react';
import { StepTitle } from '../SharedComponents';
import P from 'assets/styledComponents/fonts/P';
import plusPrimaryDarkest from 'assets/icons/math/plusPrimaryDarkest.svg';
import equalsPrimaryDarkest from 'assets/icons/math/equalsPrimaryDarkest.svg';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';
import StyledImage from 'assets/styledComponents/StyledImage';
import InteractiveActivityButton from 'assets/styledComponents/buttons/InteractiveActivityButton';
import {
    CalculatorContainer,
    StyledForm,
    StyledHintButton,
} from '../../SharedComponentsInteractive';
import Container from 'assets/styledComponents/Container';
import CalcInputItem from 'assets/styledComponents/CalcInputItem';
import InputItemLabelPosition from 'types/inputItemLabelPosition';
import CalcResult from 'assets/styledComponents/CalcResult';

const BolusCalcStep3 = ({ showHint3, setShowHint3 }) => {
    let initialFormfields = {
        correctionBolus: '',
        foodBolus: '',
        result: '',
    };

    let initialErrors = {
        correctionBolus: false,
        foodBolus: false,
        result: false,
    };

    const [formFields, setFormFields] = useState({
        ...initialFormfields,
    });

    const [error, setError] = useState({
        ...initialErrors,
    });

    const [correctionBolus, setCorrectionBolus] = useState('');
    const [foodBolus, setFoodBolus] = useState('');
    const [calcResult, setCalcRes] = useState('?');
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const onChange = (e) => {
        setFormFields({
            ...formFields,
            [e.target.name]: e.target.value,
        });
        if (e.target.name === 'correctionBolus') {
            setCorrectionBolus(e.target.value);
        } else if (e.target.name === 'foodBolus') {
            setFoodBolus(e.target.value);
        }
    };

    const calculateResult = async (e) => {
        e.preventDefault();
        setHasSubmitted(true);
        let { correctionBolus, foodBolus } = formFields;
        let result = (+correctionBolus + +foodBolus).toFixed(2);
        setCalcRes(result);

        if (error.correctionBolus || error.foodBolus || error.result) {
            setError({
                ...initialErrors,
            });
            setFormFields({
                ...initialFormfields,
            });
            // clear input and result fields
            setCorrectionBolus('');
            setFoodBolus('');
            setCalcRes('?');
        } else {
            setError({
                ...error,
                correctionBolus: correctionBolus != 2.03 ? true : false,
                foodBolus: foodBolus != 2 ? true : false,
                // all inputs and the result have to be correct for the result to be correct
                result:
                    result != 4.03 || correctionBolus != 2.03 || foodBolus != 2
                        ? true
                        : false,
            });
        }
    };

    const handleTryAgainAfterAllCorrect = (e) => {
        e.preventDefault();
        setFormFields({
            ...initialFormfields,
        });
        // clear input and result fields
        setCorrectionBolus('');
        setFoodBolus('');
        setCalcRes('?');
    };

    // remove error message if user clears an input field
    useEffect(() => {
        if (
            !formFields.correctionBolus.length ||
            !formFields.foodBolus.length
        ) {
            // for input box styling
            setHasSubmitted(false);
            // for submit button text
            setError({
                ...initialErrors,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formFields]);

    const handleHintClick = (e) => {
        e.preventDefault();
        setShowHint3(!showHint3);
    };

    // responsive vars
    const [globalState] = useGlobalContext();
    const { deviceSize } = globalState;

    const isTablet = Boolean(deviceSize === 'tablet');
    const isMobile = Boolean(deviceSize === 'mobile');
    const isDesktop = Boolean(!isTablet && !isMobile);

    const inputFieldWidth = isMobile ? '6.8rem' : isTablet ? '10rem' : '12rem';
    const operatorMargin = isMobile ? '1rem' : isTablet ? '1.5rem' : '4rem';
    const operatorWidth = isMobile ? '1.6rem' : isTablet ? '2rem' : '3.2rem';

    let allCorrect =
        !error.correctionBolus && !error.foodBolus && !error.result;

    return (
        <>
            <Container
                width="100%"
                className="flex-space-between"
                marginBottom="4rem"
            >
                <StepTitle
                    isMobile={isMobile}
                    isDesktop={isDesktop}
                    stepNumber="3"
                    instruction="Add together for total bolus dose"
                />
            </Container>
            {showHint3 && (
                <P
                    className="body-1"
                    marginBottom="1.6rem"
                    width={isTablet ? '10.4rem' : 'auto'}
                >
                    Double check to make sure your answers from step 1 and step
                    2 are correct!
                </P>
            )}
            <StyledForm devicesize={deviceSize}>
                <CalculatorContainer
                    className="flex-all-centered"
                    devicesize={deviceSize}
                    background="lightGreen"
                >
                    <div className="flex-column-centered">
                        <CalcInputItem
                            label="Food bolus"
                            labelPosition={InputItemLabelPosition.BELOW}
                            name="foodBolus"
                            value={foodBolus}
                            onChange={onChange}
                            error={error}
                            hasSubmitted={hasSubmitted}
                            inputFieldWidth={inputFieldWidth}
                            inputFieldHeight={inputFieldWidth}
                            background="tertiaryDark"
                            borderColor="tertiaryDark"
                            placeholder="0"
                            unit=""
                        />
                    </div>
                    <StyledImage
                        alt="plus symbol"
                        width={operatorWidth}
                        marginTop="-2rem"
                        marginLeft={operatorMargin}
                        marginRight={operatorMargin}
                        src={plusPrimaryDarkest}
                    />

                    <div className="flex-column-centered">
                        <CalcInputItem
                            label="Correction bolus"
                            labelPosition={InputItemLabelPosition.BELOW}
                            name="correctionBolus"
                            value={correctionBolus}
                            onChange={onChange}
                            error={error}
                            hasSubmitted={hasSubmitted}
                            inputFieldWidth={inputFieldWidth}
                            inputFieldHeight={inputFieldWidth}
                            borderColor="pinkDark"
                            background="pinkDark"
                            placeholder="0"
                            unit=""
                        />
                    </div>
                    <StyledImage
                        alt="equals symbol"
                        width={operatorWidth}
                        marginTop="-2rem"
                        marginLeft={operatorMargin}
                        marginRight={operatorMargin}
                        src={equalsPrimaryDarkest}
                    />
                    <div className="flex-column-centered">
                        <CalcResult
                            label="Total bolus"
                            name="result"
                            value={calcResult}
                            error={error}
                            hasSubmitted={hasSubmitted}
                            inputFieldWidth={inputFieldWidth}
                            inputFieldHeight={inputFieldWidth}
                            background="green"
                            borderColor="green"
                            marginTop="0"
                            devicesize={deviceSize}
                        />
                    </div>
                </CalculatorContainer>

                <Container marginBottom="0.8rem" className="flex-all-centered">
                    {allCorrect && hasSubmitted ? (
                        <InteractiveActivityButton
                            isCorrect={true}
                            onClick={handleTryAgainAfterAllCorrect}
                            className="secondary"
                        />
                    ) : (
                        <InteractiveActivityButton
                            isCorrect={false}
                            onClick={calculateResult}
                            className={
                                !formFields.correctionBolus.length ||
                                !formFields.foodBolus.length
                                    ? 'disabledBtn'
                                    : !allCorrect
                                    ? 'incorrect'
                                    : 'secondary'
                            }
                            hasError={!allCorrect}
                        />
                    )}
                    <StyledHintButton onClick={(e) => handleHintClick(e)} />
                </Container>
            </StyledForm>
        </>
    );
};

export default BolusCalcStep3;
