import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { React, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { saveUser } from '../GlobalContext/actionVariables';
import { useGlobalContext } from '../GlobalContext/GlobalProvider';
import { UPDATE_COURSE_TRACKER, UPDATE_VIEWCOUNT } from '../graphql/mutations';
import { GET_COURSE, GET_COURSE_TRACKER, GET_VIEWS } from '../graphql/queries';
import ShowCourseAndLessonPages from 'components/Courses/ShowCourseAndLessonPages';

import BadgesAchievement from 'assets/styledComponents/BadgesAchievement';
import badgesType from 'types/badgesType';

const CoursePage = () => {
    // component global variables derived from context, state and params
    const [lessonContent, setLessonContent] = useState();
    const [viewed, setViewed] = useState(false);

    const [isNextLessonAvaliable, setIsNextLessonAvaliable] = useState(false);

    const { slug, page } = useParams();

    const [globalState, dispatch] = useGlobalContext();
    const { data: course } = useQuery(GET_COURSE, {
        variables: { slug },
    });

    // queries and mutations defined and/or runned (some conditionally), and their data variables set
    const [updateViews] = useMutation(UPDATE_VIEWCOUNT);

    const [getUserViews, { data: viewData }] = useLazyQuery(GET_VIEWS);

    const mediaZone = course && course.courses[0].mediaZone;

    const [updateCourseTracker] = useMutation(UPDATE_COURSE_TRACKER);

    const { data: courseLogData } = useQuery(GET_COURSE_TRACKER, {
        variables: {
            id: globalState.user ? globalState.user.trackingLogId : '',
        },
    });

    const courseTrackerData = courseLogData
        ? courseLogData.trackingLog.courseTracker
        : '';

    // Check to redirect logged in user if trying to access 'locked' lessons directly via url
    let history = useHistory();
    let isUserLoggedIn = globalState.auth;

    const getStudiedTime = () => {
        if (isUserLoggedIn === false) return;

        const courseTracker =
            globalState.user && globalState.user.trackingLog.courseTracker;

        let studiedTime = 0;

        Object.values(courseTracker).map(
            (item) => (studiedTime += item.studiedTime)
        );

        return studiedTime;
    };

    const [studiedTime, setStudiedTime] = useState(null);
    useEffect(() => {
        setStudiedTime(getStudiedTime());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [course]);

    // timer for courses
    useEffect(() => {
        const minutesIntervalTimer = setInterval(() => {
            setStudiedTime((studiedTime) => (studiedTime += 1));
        }, 60000);

        return () => clearInterval(minutesIntervalTimer);
    }, []);

    useEffect(() => {
        if (globalState.user && course && courseTrackerData) {
            let currentCourse = course.courses[0];

            if (courseTrackerData[currentCourse.id]) {
                let currentCourseInLog = courseTrackerData[currentCourse.id];
                let numOflastLessonVisited = currentCourseInLog.lastPageIndex;

                if (Number(page) > numOflastLessonVisited + 1) {
                    history.push(`/modules/${slug}/0`);
                }
            } else if (!courseTrackerData[currentCourse.id]) {
                page > 1 && history.push(`/modules/${slug}/0`);
            }
        }
    }, [slug, page, globalState, course, courseTrackerData, history]);

    // The end of a lesson is marked by an 'endPage' in Strapi,
    // this arrayOfEndpages is used for getting lesson names and the total number of lessons corresponds to its length
    const arrayOfEndPages = mediaZone && mediaZone.filter((el) => el.endPage);

    const [numberOfLessons, setNumberofLessons] = useState(0);

    const [hasCourseStarted, setHasCourseStarted] = useState(false);

    const [isCourseCompleted, setIsCourseCompleted] = useState(false);

    const [lastLessonCompleted, setLastLessonCompleted] = useState('');
    const [lastLessonCompletedPage, setLastLessonCompletedPage] = useState(0);

    useEffect(() => {
        if (course) {
            for (let i in courseTrackerData) {
                let currentCourseTrackingLog = courseTrackerData[i];
                let currentCourseId = course.courses[0].id;
                if (currentCourseTrackingLog.id === currentCourseId) {
                    setLastLessonCompleted(currentCourseTrackingLog.lastLesson);

                    setLastLessonCompletedPage(
                        Number(currentCourseTrackingLog.lastPageIndex)
                    );

                    if (
                        Object.keys(currentCourseTrackingLog.lessonsCompleted)
                            .length > 0
                    ) {
                        setHasCourseStarted(true);
                    }

                    if (currentCourseTrackingLog.completionDate.length) {
                        setIsCourseCompleted(true);
                    }
                }
            }
        }
    }, [course, courseTrackerData]);

    useEffect(() => {
        lessonContent && setNumberofLessons(Object.keys(lessonContent).length);
    }, [lessonContent]);

    // useEffects are running on page change to run mutation to update view count and progress tracking

    useEffect(() => {
        const content = {};
        let ind = 0;
        let page = 1;
        if (mediaZone) {
            for (const e of mediaZone) {
                if (e.endPage) {
                    content[page] = mediaZone.slice(
                        ind,
                        mediaZone.indexOf(e) + 1
                    );
                    ind = mediaZone.indexOf(e) + 1;
                    page++;
                }
            }
        }
        setLessonContent(content);
    }, [mediaZone]);

    useEffect(() => {
        if (course && globalState.user && courseTrackerData) {
            const currentCourse = course.courses[0];

            const arrayOfTracksCurrentCourseBelongsTo =
                currentCourse.learning_tracks.map((track) => track.trackTitle);

            // add a course to courseTrackerData if it's the first time doing the course
            if (!courseTrackerData[currentCourse.id] && lessonContent) {
                // the URL will save if available, else save as undefined
                let thumbnailImageUrl =
                    currentCourse.courseImage.image &&
                    currentCourse.courseImage.image.formats.thumbnail &&
                    currentCourse.courseImage.image.formats.thumbnail.url;

                let smallImageUrl =
                    currentCourse.courseImage.image &&
                    currentCourse.courseImage.image.formats.small &&
                    currentCourse.courseImage.image.formats.small.url;

                let nonOptimizedImageUrl = currentCourse.courseImage.image.url;

                // variable to use in mutation to update course tracking log JSON object

                const updateCourseData = {
                    ...courseTrackerData,
                    [currentCourse.id]: {
                        title: currentCourse.title,
                        thumbnailImageUrl: thumbnailImageUrl,
                        smallImageUrl: smallImageUrl,
                        nonOptimizedImageUrl: nonOptimizedImageUrl,
                        id: currentCourse.id,
                        slug: currentCourse.slug,
                        startDate: new Date(),
                        lastUpdated: new Date(),
                        completionDate: '',
                        courseId: course.courses[0].id,
                        totalLessons: Object.keys(lessonContent).length,
                        // check to prevent incorrect page index saving if user tries to directly visit lesson 2 or higher's URL.
                        lastPageIndex: Number(page) > 1 ? 0 : Number(page),
                        lastLesson: '',
                        lessonsCompleted: {},
                        courseCompleted: false,
                        allTracks: arrayOfTracksCurrentCourseBelongsTo,
                    },
                };

                updateCourseTracker({
                    variables: {
                        id: globalState.user.trackingLogId,
                        data: {
                            courseTracker: updateCourseData,
                        },
                    },
                });

                dispatch({
                    type: saveUser,
                    payload: {
                        ...globalState.user,
                        trackingLog: {
                            ...globalState.user.trackingLog,
                            courseTracker: updateCourseData,
                        },
                    },
                });
            }
        }

        course &&
            getUserViews({
                variables: {
                    courseID: course.courses[0].id,
                },
            });

        //eslint-disable-next-line
    }, [course]);

    useEffect(() => {
        if (
            course &&
            globalState.user &&
            lessonContent &&
            Object.keys(lessonContent).length
        ) {
            const currentCourse = course.courses[0];
            // sets "lesson" to specific lessons endPage from where we can get lessons metadata easily
            const pageIndex = Number(page);
            const lesson =
                lessonContent && pageIndex !== 0
                    ? lessonContent[page][lessonContent[page].length - 1]
                    : null;
            // checks if the lesson we're currently on needs to be added to the tracking log

            if (
                courseTrackerData[currentCourse.id] &&
                pageIndex !== 0 &&
                lesson &&
                !courseTrackerData[currentCourse.id].lessonsCompleted[lesson.id]
            ) {
                // checks if we are completing the last lesson of the course so we can set course as completed and add completion date

                const isFinalLessonCompleted =
                    Object.keys(
                        courseTrackerData[currentCourse.id].lessonsCompleted
                    ).length ===
                    Object.keys(lessonContent).length - 1;

                // the URL will save if available, else save as undefined
                const thumbnailImageUrl =
                    currentCourse.courseImage.image &&
                    currentCourse.courseImage.image.formats.thumbnail &&
                    currentCourse.courseImage.image.formats.thumbnail.url;

                const smallImageUrl =
                    currentCourse.courseImage.image &&
                    currentCourse.courseImage.image.formats.small &&
                    currentCourse.courseImage.image.formats.small.url;

                const nonOptimizedImageUrl =
                    currentCourse.courseImage.image.url;

                // variable to use in mutation to update our tracking log JSON object
                const updateData = {
                    ...courseTrackerData,
                    [currentCourse.id]: {
                        // rewriting info instead of using spread operator to update info if changes made to course
                        title: currentCourse.title,
                        thumbnailImageUrl: thumbnailImageUrl,
                        smallImageUrl: smallImageUrl,
                        nonOptimizedImageUrl: nonOptimizedImageUrl,
                        id: currentCourse.id,
                        slug: currentCourse.slug,
                        startDate:
                            courseTrackerData[currentCourse.id].startDate,
                        lastUpdated: new Date(),
                        completionDate: isFinalLessonCompleted
                            ? new Date()
                            : '',
                        courseId: course.courses[0].id,
                        totalLessons: Object.keys(lessonContent).length,
                        lastPageIndex: Number(page),
                        lastLesson: lesson.lessonTitle,
                        lessonsCompleted: {
                            ...courseTrackerData[currentCourse.id]
                                .lessonsCompleted,
                            [lesson.id]: {
                                lessonTitle: lesson.lessonTitle,
                                lessonId: lesson.id,
                                lessonNumber: lesson.lessonNumber,
                                startDate: new Date(),
                                lastUpdated: new Date(),
                            },
                        },
                        courseCompleted: isFinalLessonCompleted,
                        studiedTime: studiedTime,
                    },
                };

                // variable to use in mutation to update tracks

                updateCourseTracker({
                    variables: {
                        id: globalState.user.trackingLogId,
                        data: {
                            courseTracker: updateData,
                        },
                    },
                });
                dispatch({
                    type: saveUser,
                    payload: {
                        ...globalState.user,
                        trackingLog: {
                            ...globalState.user.trackingLog,
                            courseTracker: updateData,
                        },
                    },
                });
            }
        }
        setViewed(false);
        //eslint-disable-next-line
    }, [page, courseLogData, lessonContent]);

    useEffect(() => {
        if (viewData && !viewed) {
            updateViews({
                variables: {
                    courseID: course.courses[0].id,
                    viewCount: viewData.course.viewCount + 1,
                },
            });
            setViewed(true);
        }
        //eslint-disable-next-line
    }, [viewed, viewData]);

    // to disable next button until mutations are done
    useEffect(() => {
        if (
            !courseTrackerData ||
            !(
                course &&
                globalState.user &&
                lessonContent &&
                Object.keys(lessonContent).length
            )
        ) {
            return;
        }

        const currentCourse = course.courses[0];
        // sets "lesson" to specific lessons endPage from where we can get lessons metadata easily
        const pageIndex = Number(page);
        const lesson =
            lessonContent && pageIndex !== 0
                ? lessonContent[page][lessonContent[page].length - 1]
                : null;
        // checks if the lesson we're currently on needs to be added to the tracking log

        if (
            pageIndex !== 0 &&
            courseTrackerData[currentCourse.id] &&
            courseTrackerData[currentCourse.id].lessonsCompleted[lesson.id]
        ) {
            setIsNextLessonAvaliable(true);
        }
        //eslint-disable-next-line
    }, [courseTrackerData, page, courseLogData, lessonContent]);

    return (
        <CoursePageContainer className="below-navbar">
            {studiedTime >= 10 && studiedTime < 30 ? (
                <BadgesAchievement id={badgesType.HUMBLE_BEGINNINGS} />
            ) : (
                ''
            )}
            {studiedTime >= 30 && studiedTime < 60 ? (
                <BadgesAchievement id={badgesType.ROAD_TO_SUCCESS} />
            ) : (
                ''
            )}
            {studiedTime >= 60 && studiedTime < 90 ? (
                <BadgesAchievement id={badgesType.EYES_ON_THE_PRIZE} />
            ) : (
                ''
            )}
            {studiedTime >= 90 ? (
                <BadgesAchievement id={badgesType.OVERACHIEVER} />
            ) : (
                ''
            )}
            <ShowCourseAndLessonPages
                page={page}
                last={arrayOfEndPages ? arrayOfEndPages.length : 0}
                numberOfLessons={numberOfLessons}
                course={course}
                isCourseCompleted={isCourseCompleted}
                globalState={globalState}
                hasCourseStarted={hasCourseStarted}
                slug={slug}
                lastLessonCompletedPage={lastLessonCompletedPage}
                arrayOfEndPages={arrayOfEndPages}
                lessonContent={lessonContent}
                lastLessonCompleted={lastLessonCompleted}
                isNextLessonAvaliable={isNextLessonAvaliable}
                setIsNextLessonAvaliable={setIsNextLessonAvaliable}
            />
        </CoursePageContainer>
    );
};

const CoursePageContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    min-height: 100vh;
    overflow-y: hidden;
`;

export default CoursePage;
