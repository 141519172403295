import styled from '@emotion/styled';

const CourseCardButtonSm = styled.button`
    background-color: ${(props) => props.theme.colors.lightBackground};
    color: ${(props) => props.theme.colors.secondary};
    border: 0.1rem solid ${(props) => props.theme.colors.secondary};
    border-radius: 4rem;
    height: 3rem;
    font-size: 1.4rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    /* customizable */
    margin: ${(props) => props.margin};
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
    width: ${(props) => props.width};
    min-width: ${(props) => props.minWidth};
    max-width: ${(props) => props.maxWidth};
    padding: ${(props) => props.padding};

    :hover {
        background-color: ${(props) => props.theme.colors.secondary};
        color: ${(props) => props.theme.colors.lightGreyBackground};
        cursor: pointer;
    }
    :active {
        opacity: 0.4;
    }
`;

export default CourseCardButtonSm;
