const badgesType = Object.freeze({
    FIRST_ONE: '8',
    STRATEGIC_LEARNER: '7',
    OFF_THE_GROUND: '4',
    FIRST_OF_MANY: '2',
    TRACK_STAR: '9',
    HUMBLE_BEGINNINGS: '3',
    ROAD_TO_SUCCESS: '6',
    EYES_ON_THE_PRIZE: '1',
    OVERACHIEVER: '5',
});

export default badgesType;
