import styled from '@emotion/styled';
import React from 'react';
import Body1 from '../../assets/styledComponents/fonts/Body1';
import Body2 from '../../assets/styledComponents/fonts/Body2';
import FilledButtonMd from '../../assets/styledComponents/buttons/FilledButtonMd';
import Header4 from '../../assets/styledComponents/fonts/Header4';
import ModalOverlay from '../../assets/styledComponents/ModalOverlay';
import PetitTitle2 from '../../assets/styledComponents/fonts/PetitTitle2';
import badgeStatus from '../../types/badgeStatus';
import CloseModalButton from '../../utils/helpers/CloseModalButton';
import BadgeImage from '../../assets/styledComponents/StyledImage';

const BadgeModal = ({
    setShowModal,
    showModal,
    status,
    title,
    description,
    image,
    achievementDate,
    devicesize,
    badgeOutline,
}) => {
    return (
        <div>
            <ModalOverlay setShowModal={setShowModal} showModal={showModal} />
            <Container
                className="flex-column-centered card-shadow"
                devicesize={devicesize}
            >
                <CloseModalButton
                    setShowModal={setShowModal}
                    top="3rem"
                    right="3rem"
                />
                <BadgeImage
                    marginTop="5rem"
                    status={status}
                    src={!achievementDate ? badgeOutline : image}
                />
                {status === badgeStatus.NEW ? (
                    <PetitTitle2 color="secondary">Badge Acquired!</PetitTitle2>
                ) : (
                    ''
                )}
                <BadgeInfo status={status} devicesize={devicesize}>
                    <Header4>{title}</Header4>
                    <Body1 marginTop={badgeStatus.NEW ? '2rem;' : '3.2rem'}>
                        {description}
                    </Body1>
                    <BottomSection status={status} devicesize={devicesize}>
                        {status === badgeStatus.NEW ? (
                            <FilledButtonMd
                                margin="auto"
                                width="20rem"
                                onClick={() => {
                                    setShowModal(false);
                                }}
                            >
                                Got it!
                            </FilledButtonMd>
                        ) : achievementDate ? (
                            <Body2 color="greyText">
                                Unlocked {achievementDate}
                            </Body2>
                        ) : (
                            <Body2 color="greyText">Not Unlocked Yet!</Body2>
                        )}
                    </BottomSection>
                </BadgeInfo>
            </Container>
        </div>
    );
};

const Container = styled.div`
    width: ${(props) => (props.devicesize === 'mobile' ? '90%' : '50rem')};
    background-color: ${(props) => props.theme.colors.lightBackground};
    border-radius: 1.6rem;
    position: fixed;
    right: ${(props) =>
        props.devicesize === 'mobile' ? '5%' : 'calc(50vw - 25rem)'};
    top: ${(props) =>
        props.devicesize === 'mobile' ? '15%' : 'calc(50vh - 26rem)'};
    z-index: 10;
    overflow: scroll;
`;

const BadgeInfo = styled.div`
    margin: ${(props) =>
        props.status === badgeStatus.NEW
            ? '2rem 8rem 3.5rem 8rem;'
            : props.devicesize === 'mobile'
            ? '1rem 2rem 4.7rem 2rem;'
            : '1rem 8rem 4.7rem 8rem;'};
    text-align: center;
    padding-bottom: 7rem;
    height: auto;
`;

const BottomSection = styled.div`
    position: ${(props) => (props.devicesize === 'mobile' ? '' : 'absolute')};
    width: ${(props) => (props.devicesize === 'mobile' ? 'auto' : '34rem')};
    margin-top: ${(props) => (props.devicesize === 'mobile' ? '2rem' : '')};
    bottom: ${(props) =>
        props.status === badgeStatus.NEW ? '3.5rem' : '4.7rem'};
`;

export default BadgeModal;
