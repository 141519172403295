import React from 'react';
import AllCoursePageAuthContent from 'components/Courses/AllCoursePageAuthContent';
import AllCoursePageNotAuthContent from 'components/Courses/AllCoursePageNotAuthContent';
import CoursesHeaderSection from '../components/Courses/CoursesHeaderSection';

const AllCoursesPageContent = ({
    onSearchChange,
    view,
    setView,
    filterValue,
    myCourses,
    allCourses,
    coursesToShow,
    setCoursesToShow,
    authUser,
    numOfCourses,
    isDesktop,
}) => {
    return (
        <>
            <CoursesHeaderSection
                view={view}
                setView={setView}
                coursesToShow={coursesToShow}
                setCoursesToShow={setCoursesToShow}
                numOfCourses={numOfCourses}
                onSearchChange={onSearchChange}
                isDesktop={isDesktop}
            />
            {authUser ? (
                <AllCoursePageAuthContent
                    filterValue={filterValue}
                    view={view}
                    allCourses={allCourses}
                    myCourses={myCourses}
                    coursesToShow={coursesToShow}
                    setCoursesToShow={setCoursesToShow}
                />
            ) : (
                <AllCoursePageNotAuthContent
                    filterValue={filterValue}
                    view={view}
                    allCourses={allCourses}
                />
            )}
        </>
    );
};

export default AllCoursesPageContent;
