import styled from '@emotion/styled';
import React from 'react';
import cardViewActive from '../../assets/icons/cardViewActive.svg';
import cardViewInactive from '../../assets/icons/cardViewInactive.svg';
import listViewActive from '../../assets/icons/listViewActive.svg';
import listViewInactive from '../../assets/icons/listViewInactive.svg';
import { useGlobalContext } from '../../GlobalContext/GlobalProvider';
import Layout from '../../types/layout';
import SearchBar from './SearchBar';
import IconsContainer from '../../assets/styledComponents/Container';
import P from 'assets/styledComponents/fonts/P';
import Container from '../../assets/styledComponents/Container';

const CoursesCardsNavigation = ({
    view,
    setView,
    coursesToShow,
    setCoursesToShow,
    numOfCourses,
    onSearchChange,
    isDashboard,
    isDesktop,
}) => {
    const [globalState] = useGlobalContext();
    const isDesktopSm = globalState.deviceSize === 'desktopSm';
    const isDesktopMd = globalState.deviceSize === 'desktopMd';
    return (
        <Container
            width={isDesktopMd ? '116rem' : isDesktopSm ? '88rem' : ''}
            className={!isDesktop ? '' : 'flex-space-between'}
        >
            {!isDesktop && (
                <SearchAndFilterContainer>
                    <SearchBar onSearchChange={onSearchChange} />
                </SearchAndFilterContainer>
            )}
            <Navigation className="flex">
                {!isDashboard && (
                    <NavItem
                        onClick={() => setCoursesToShow('all')}
                        className={
                            coursesToShow === 'all'
                                ? 'active petit-title-1'
                                : 'petit-title-1'
                        }
                    >
                        ALL <span>{numOfCourses.all}</span>
                    </NavItem>
                )}

                {globalState.auth ? (
                    <>
                        <NavItem
                            onClick={() => setCoursesToShow('inProgress')}
                            className={
                                coursesToShow === 'inProgress'
                                    ? 'active petit-title-1'
                                    : 'petit-title-1'
                            }
                        >
                            IN PROGRESS <span>{numOfCourses.inProgress}</span>
                        </NavItem>
                        <NavItem
                            onClick={() => setCoursesToShow('completed')}
                            className={
                                coursesToShow === 'completed'
                                    ? 'active petit-title-1'
                                    : 'petit-title-1'
                            }
                        >
                            COMPLETED <span>{numOfCourses.completed}</span>
                        </NavItem>
                    </>
                ) : (
                    ''
                )}
            </Navigation>
            {isDesktop ? (
                <SearchAndFilterContainer className="flex-end-aligned">
                    <SearchBar onSearchChange={onSearchChange} />

                    <IconsContainer
                        className="search-shadow flex"
                        width="6.4rem"
                        height="3.2rem"
                        borderRadius="0.5rem"
                        backgroundColor="lightBackground"
                        marginLeft="1rem"
                    >
                        <Icon
                            src={
                                view === Layout.CARD_VIEW
                                    ? cardViewActive
                                    : cardViewInactive
                            }
                            onClick={() => setView(Layout.CARD_VIEW)}
                            alt="Icon representing card view"
                        />
                        <IconDividerLine />
                        <Icon
                            src={
                                view === Layout.LIST_VIEW
                                    ? listViewActive
                                    : listViewInactive
                            }
                            onClick={() => setView(Layout.LIST_VIEW)}
                            alt="Icon representing list view"
                        />
                    </IconsContainer>
                </SearchAndFilterContainer>
            ) : (
                ''
            )}
        </Container>
    );
};

const Navigation = styled.nav`
    margin-top: ${(props) => props.theme.spaces.md};
`;

const SearchAndFilterContainer = styled.div`
    align-self: flex-end;
    margin-bottom: 1rem;
    margin-top: ${(props) => props.theme.breakPoints.tablet && '1.5rem'};
`;

const IconDividerLine = styled.div`
    border-right: 0.1rem solid ${(props) => props.theme.colors.tertiary};
    margin: 0;
`;

const Icon = styled.img`
    :hover {
        cursor: pointer;
        opacity: 0.5;
        transition: all 0.2s ease-in-out;
    }
`;

const NavItem = styled(P)`
    margin-left: 2rem;
    margin-right: 2rem;
    text-decoration: none;
    color: ${(props) => props.theme.colors.greyText};
    position: relative;
    :hover {
        color: ${(props) => props.theme.colors.lightGrey};
        cursor: pointer;
    }

    &.active {
        color: ${(props) => props.theme.colors.secondary};
        span {
            color: inherit;
        }

        :before {
            content: ' ';
            position: absolute;
            bottom: -1rem;
            width: calc(100% + 6rem);
            left: -3rem;
            border: 0.2rem solid ${(props) => props.theme.colors.secondary};
            background-color: ${(props) => props.theme.colors.secondary};
        }

        :visited {
            color: inherit;
        }
    }

    span {
        color: ${(props) => props.theme.colors.lightGrey};
    }
`;

export default CoursesCardsNavigation;
