import React from 'react';
import styled from '@emotion/styled';
import StyledTrackCard from 'assets/styledComponents/Container';
import StyledImage from 'assets/styledComponents/StyledImage';
import ImageContainer from 'assets/styledComponents/Container';
import H2 from 'assets/styledComponents/fonts/H2';
import H1 from 'assets/styledComponents/fonts/H1';
import P from 'assets/styledComponents/fonts/P';
import IconAndInfo from 'assets/styledComponents/IconAndInfo';
import CardButtonMd from 'assets/styledComponents/buttons/CardButtonMd';
import LinkWrappingButton from 'assets/styledComponents/buttons/LinkWrappingButton';

const TracksCardVertical = ({
    track,
    slug,
    trackImage,
    infoIcons,
    isDashboard,
    isMobile,
    underDevelopment,
}) => {
    return (
        <>
            {!underDevelopment ? (
                <StyledTrackCard
                    className="card-shadow flex-column-centered"
                    borderRadius="2rem"
                    height="46rem"
                    width={isDashboard ? '' : '27rem'}
                    marginBottom="1.6rem"
                    position="relative"
                    background="lightBackground"
                >
                    <ImageContainer
                        height="10.5rem"
                        width={isMobile ? '100%' : '27rem'}
                    >
                        <StyledImage
                            width="100%"
                            height="100%"
                            objectFit="cover"
                            borderRadius="2rem 2rem 0 0"
                            border
                            src={trackImage}
                            alt={track.trackTitle}
                        />
                    </ImageContainer>
                    <InfoSection>
                        {track.subtitle.length > 0 && (
                            <H2
                                className="petit-title-serif maxOneLineText"
                                color="greyText"
                                marginBottom="1rem"
                            >
                                {track.subtitle}
                            </H2>
                        )}
                        <H1
                            className="header-3 maxOneLineText"
                            marginBottom="1.6rem"
                        >
                            {track.trackTitle}
                        </H1>
                        <P
                            className="body-4 maxFourLineText"
                            color="greyText"
                            marginBottom="1rem"
                        >
                            {track.trackDescription}
                        </P>
                        <div>
                            {infoIcons.map((item, idx) => (
                                <IconAndInfo
                                    key={idx}
                                    icon={item.icon}
                                    text={item.title}
                                    marginBottom={idx !== 2 ? '0.8rem' : '0'}
                                />
                            ))}
                        </div>
                    </InfoSection>
                    <ButtonContainer className="btn-container">
                        <LinkWrappingButton to={`/pathway/${slug}`}>
                            <CardButtonMd width="24rem">
                                View Pathway Syllabus
                            </CardButtonMd>
                        </LinkWrappingButton>
                    </ButtonContainer>
                </StyledTrackCard>
            ) : (
                <DisabledCardContainer>
                    <StyledTrackCard
                        className="card-shadow flex-column-centered"
                        borderRadius="2rem"
                        height="41rem"
                        width={isDashboard ? '' : '27rem'}
                        marginBottom="1.6rem"
                        position="relative"
                        background="lightBackground"
                    >
                        <UnderDevelopment width="16.875rem">
                            Under Development
                        </UnderDevelopment>
                        <OpacityContainer>
                            <ImageContainer
                                height="10.5rem"
                                width={isMobile ? '100%' : '27rem'}
                            >
                                <StyledImage
                                    width="100%"
                                    height="100%"
                                    objectFit="cover"
                                    borderRadius="2rem 2rem 0 0"
                                    border
                                    src={trackImage}
                                    alt={track.trackTitle}
                                />
                            </ImageContainer>
                            <InfoSection>
                                {track.subtitle.length > 0 && (
                                    <H2
                                        className="petit-title-serif maxOneLineText"
                                        color="greyText"
                                        marginBottom="1rem"
                                    >
                                        {track.subtitle}
                                    </H2>
                                )}
                                <H1
                                    className="header-3 maxOneLineText"
                                    marginBottom="1.6rem"
                                >
                                    {track.trackTitle}
                                </H1>
                                <P
                                    className="body-4 maxFourLineText"
                                    color="greyText"
                                    marginBottom="1rem"
                                >
                                    {track.trackDescription}
                                </P>
                                <div>
                                    {infoIcons.map((item, idx) => (
                                        <IconAndInfo
                                            key={idx}
                                            icon={item.icon}
                                            text={item.title}
                                            marginBottom={
                                                idx !== 2 ? '0.8rem' : '0'
                                            }
                                        />
                                    ))}
                                </div>
                            </InfoSection>
                        </OpacityContainer>
                    </StyledTrackCard>
                </DisabledCardContainer>
            )}
        </>
    );
};

const InfoSection = styled.div`
    margin: 2rem 2.4rem 3.2rem 2.4rem;
`;

const DisabledCardContainer = styled.div`
    pointer-events: none;
`;

const OpacityContainer = styled.div`
    /* customizable */
    opacity: 30%;
`;

const UnderDevelopment = styled.div`
    position: absolute;
    margin-top: auto;
    margin-bottom: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    z-index: 2;

    font-family: 'MontserratSemiBold', sans-serif;
    font-size: 1.8rem;
    height: 4rem;
    color: white;

    background-color: ${(props) => props.theme.colors.primaryDark};

    display: flex;
    align-items: center;
    justify-content: center;
`;

// overlay that displays the button when card is hovered on
const ButtonContainer = styled.div`
    width: 100%;
    z-index: 1;
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default TracksCardVertical;
