import { React, Fragment, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import PetitTitle2 from '../../assets/styledComponents/fonts/PetitTitle2';
import FilledButtonMd from '../../assets/styledComponents/buttons/FilledButtonMd';
import OutlinedButtonMd from '../../assets/styledComponents/buttons/OutlinedButtonMd';
import Body1 from '../../assets/styledComponents/fonts/Body1';
import { useMutation } from '@apollo/client';
import { UPDATE_GOALS_TRACKER } from '../../graphql/mutations';
import { saveUser } from '../../GlobalContext/actionVariables';
import NumText from '../../assets/styledComponents/fonts/Header1';

const CreateStudyPlan = ({
    isDesktopSm,
    daysArr,
    goals,
    hasGoals,
    globalState,
    dispatch,
    setShowCreateStudyPlan,
}) => {
    const [goalNum, setGoalNum] = useState(
        hasGoals ? goals.daysPerWeekGoal : 0
    );
    const [hoverGoalNum, setHoverGoalNum] = useState(0);
    const today = new Date().toISOString();

    useEffect(() => {
        hasGoals && setGoalNum(goals.daysPerWeekGoal);
    }, [goals, hasGoals]);

    const [updateGoalsTracker] = useMutation(UPDATE_GOALS_TRACKER, {
        onCompleted: () => {
            setShowCreateStudyPlan(false);
        },
    });

    const onChange = (event) => {
        setGoalNum(Number(event.target.value));
    };

    const handleSave = () => {
        const updateGoalsData = {
            ...goals,
            daysPerWeekGoal: goalNum,
            lastDateVisited: today,
            totalVisitsForWeek: goals?.totalVisitsForWeek
                ? goals.totalVisitsForWeek
                : 0,
            daysVisited: goals?.daysVisited ? [...goals.daysVisited] : [],
        };

        updateGoalsTracker({
            variables: {
                id: globalState.user.trackingLogId,
                data: {
                    goalTracker: updateGoalsData,
                },
            },
        });

        dispatch({
            type: saveUser,
            payload: {
                ...globalState.user,
                trackingLog: {
                    ...globalState.user.trackingLog,
                    goalTracker: updateGoalsData,
                },
            },
        });
    };

    const handleCancel = () => {
        setGoalNum(goals ? goals.daysPerWeekGoal : 0);
        setShowCreateStudyPlan(false);
    };

    return (
        <Fragment>
            <DaysBar
                className="flex-all-centered"
                role="group"
                isDesktopSm={isDesktopSm}
            >
                <InstructionalText>
                    select a weekly learning target
                </InstructionalText>
                {daysArr.map((day, idx) => {
                    const optionInfo = `weeklyTarget-${idx + 1}`;
                    const ariaText = `Weekly target of ${idx + 1} per day`;

                    let goalIdx = goalNum - 1;
                    let hoverIdx = hoverGoalNum - 1;

                    return (
                        <StyledLabel
                            key={day}
                            htmlFor={optionInfo}
                            highlight={
                                idx <= hoverIdx
                                    ? true
                                    : hoverIdx > 0 &&
                                      idx <= hoverIdx &&
                                      hoverIdx < goalIdx
                                    ? true
                                    : idx <= goalIdx && hoverIdx === -1
                                    ? true
                                    : false
                            }
                        >
                            <div
                                onMouseEnter={() => setHoverGoalNum(idx + 1)}
                                onMouseLeave={() => setHoverGoalNum(0)}
                            >
                                <input
                                    className="flex-all-centered"
                                    type="radio"
                                    name="numOfDays"
                                    id={optionInfo}
                                    aria-label={ariaText}
                                    value={idx + 1}
                                    onChange={onChange}
                                />
                            </div>
                        </StyledLabel>
                    );
                })}
            </DaysBar>
            <Info className="flex-all-centered">
                <NumText color="secondary" marginRight="1.5rem">
                    {hoverGoalNum ? hoverGoalNum : goalNum}
                </NumText>
                <Body1 color="greyText" marginTop="1.5rem">
                    {(hoverGoalNum === 1 && goalNum >= 1) ||
                    (goalNum === 1 && hoverGoalNum === 0) ||
                    (goalNum === 0 && hoverGoalNum === 1)
                        ? 'day'
                        : 'days'}{' '}
                    / week
                </Body1>
            </Info>
            <ButtonContainer className="flex-all-centered">
                <OutlinedButtonMd onClick={handleCancel} width="13rem">
                    Cancel
                </OutlinedButtonMd>
                <FilledButtonMd
                    type="submit"
                    marginLeft="1rem"
                    width="23rem"
                    marginTop="0"
                    onClick={handleSave}
                >
                    Save study plan
                </FilledButtonMd>
            </ButtonContainer>
        </Fragment>
    );
};

const DaysBar = styled.div`
    width: 56rem;
    height: 6rem;
    border-radius: 4rem;
    background-color: ${(props) => props.theme.colors.tertiary};
    position: relative;
`;

const InstructionalText = styled(PetitTitle2)`
    position: absolute;
    color: ${(props) => props.theme.colors.greyText};
    top: -3rem;
`;

const StyledLabel = styled.label`
    background-color: ${(props) =>
        props.highlight
            ? props.theme.colors.secondary
            : props.theme.colors.lightBackground};
    transition-property: background-color;
    transition-timing-function: ease-in-out;
    transition-duration: 0.7s;
    width: 3.6rem;
    height: 3.6rem;
    border-radius: 50%;
    margin: 0 1.8rem;

    :hover {
        cursor: pointer;
    }

    /* this div allows the last hovered number to keep displaying 
    before the mouse touches the next circle. Prevents flickering */
    div {
        height: 100%;
        padding: 0 3.6rem;
    }

    :last-of-type {
        div {
            padding: 0;
        }
    }

    input {
        width: 100%;
        height: 100%;
        display: none;
    }
`;

const ButtonContainer = styled.div`
    width: 37rem;
`;

const Info = styled.section`
    height: 4.2rem;
    margin-top: 3.7rem;
    margin-bottom: 7.5rem;
`;

export default CreateStudyPlan;
