import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useGlobalContext } from '../GlobalContext/GlobalProvider';

const ProtectedRoute = ({ component: Component, ...rest }) => {
    const [state] = useGlobalContext();

    if (state.user) {
        return (
            <Route
                {...rest}
                render={(props) => {
                    return <Component {...rest} {...props} />;
                }}
            />
        );
    } else {
        return <Redirect push to="/" />;
    }
};
export default ProtectedRoute;
