import React from 'react';
import StyledImage from 'assets/styledComponents/StyledImage';
import P from 'assets/styledComponents/fonts/P';
import Container from 'assets/styledComponents/Container';
import { Span } from './SharedComponents';
import steps from './StepsContent';
import nextChevron from 'assets/images/interactiveBook-course2/nextChevron.svg';
import NextOrPrevPageButton from '../Course2/NextOrPrevPageButton';

const Step1 = ({ setActivePage }) => {
    const { Step1 } = steps;
    const { highlightContent, content, image } = Step1;
    return (
        <>
            <Container
                width="50%"
                className="flex-all-centered"
                padding="0 4rem"
            >
                <P>
                    <Span>{highlightContent}</Span> {content}
                </P>
            </Container>
            <Container width="50%" className="flex-all-centered">
                <StyledImage src={image} width="60%" />
                <NextOrPrevPageButton
                    className={'flex-all-centered next'}
                    image={nextChevron}
                    alt={'orange chevron pointing right'}
                    setActivePage={setActivePage}
                    setActivePageNum={2}
                    title={'NEXT'}
                />
            </Container>
        </>
    );
};

export default Step1;
