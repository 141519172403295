import React from 'react';
import StyledImage from 'assets/styledComponents/StyledImage';
import saveLessonInstruction from 'assets/images/saveLessonInstruction.svg';
import H1 from 'assets/styledComponents/fonts/H1';
import H2 from 'assets/styledComponents/fonts/H2';
import Container from 'assets/styledComponents/Container';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';

const NoSavedLessonCard = () => {
    const [globalState] = useGlobalContext();
    const { deviceSize } = globalState;
    return (
        <Container
            className="flex-column-all-centered"
            padding={
                deviceSize === 'mobile'
                    ? '3rem 3rem 0'
                    : deviceSize === 'tablet'
                    ? '5rem 5rem 0'
                    : '5rem 12rem 0rem 12rem'
            }
            borderRadius="2rem"
            border="0.2rem solid #D2DDE2"
        >
            <H1 className="body-1" marginBottom="1rem">
                There&apos;s nothing here yet!
            </H1>
            <H2
                className="body-4"
                color="greyText"
                textAlign="center"
                padding={deviceSize === 'mobile' ? '0 2rem' : '0 3rem'}
            >
                Save your progress with this icon on each lesson page as you go,
                and access them later on the saved tab! When you save your
                progress, lessons will appear here.
            </H2>
            <StyledImage
                src={saveLessonInstruction}
                alt="example of lesson with highlighted save button in the top right corner"
                width="100%"
                marginTop="4rem"
            />
        </Container>
    );
};

export default NoSavedLessonCard;
