import React from 'react';
import StyledImage from 'assets/styledComponents/StyledImage';
import step5Img from 'assets/images/interactiveBook-course2/step5Img.svg';
import P from 'assets/styledComponents/fonts/P';
import H3 from 'assets/styledComponents/fonts/H3';

const Step5Mobile = () => {
    return (
        <>
            <H3
                className="header-3"
                textAlign="left"
                width="100%"
                marginTop="4.8rem"
            >
                Step 5
            </H3>
            <StyledImage src={step5Img} />
            <P className="body-2" marginTop="3.2rem">
                Dispose of sharps and strips. Pull the used strip out of the
                meter and dispose of it in a sharps container. Your diabetes
                team will show you how to safely remove the lancet from the
                lancing device. Place the used lancet into a sharps container.
            </P>
            <P className="body-2" marginTop="1.6rem">
                Many of the available glucose meters have apps. These apps can
                link your blood glucose readings to your phone and display them
                on a graph. Meters have a history that holds a memory of your
                previous blood glucose checks.
            </P>
        </>
    );
};

export default Step5Mobile;
