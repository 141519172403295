import styled from '@emotion/styled';
import React from 'react';
import { useHistory } from 'react-router-dom';
import CourseCardButtonSm from '../../assets/styledComponents/buttons/CourseCardButtonSm';

const CardSmallButtonSection = ({ progress, slug, lastPage }) => {
    const history = useHistory();

    return (
        <ButtonContainer>
            <CourseCardButtonSm
                width="100%"
                onClick={() => history.push(`/modules/${slug}/0`)}
            >
                View Syllabus
            </CourseCardButtonSm>
            {progress === '100' ? (
                <CourseCardButtonSm
                    width="100%"
                    marginTop="0.5rem"
                    onClick={() => history.push(`/modules/${slug}/${lastPage}`)}
                >
                    View Module
                </CourseCardButtonSm>
            ) : (
                <CourseCardButtonSm
                    width="100%"
                    marginTop="0.5rem"
                    onClick={() => history.push(`/modules/${slug}/${lastPage}`)}
                >
                    Resume Module
                </CourseCardButtonSm>
            )}
        </ButtonContainer>
    );
};

const ButtonContainer = styled.div`
    position: relative;
    z-index: 2;
    height: 12rem;
    padding: 2.3rem 1rem 3rem 1rem;
`;

export default CardSmallButtonSection;
