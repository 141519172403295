import React, { useState, useEffect } from 'react';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';
import Container from 'assets/styledComponents/Container';
import {
    StyledForm,
    CalculatorContainer,
} from '../../SharedComponentsInteractive';
import CalcInputItem from 'assets/styledComponents/CalcInputItem';
import CalcResult from 'assets/styledComponents/CalcResult';
import InputItemLabelPosition from 'types/inputItemLabelPosition';
import InteractiveActivityButton from 'assets/styledComponents/buttons/InteractiveActivityButton';
import multiplyPrimaryDarkest from 'assets/icons/math/multiplyPrimaryDarkest.svg';
import equalsPrimaryDarkest from 'assets/icons/math/equalsPrimaryDarkest.svg';
import StyledImage from 'assets/styledComponents/StyledImage';
import P from 'assets/styledComponents/fonts/P';
import { StyledHintButton } from '../../SharedComponentsInteractive';

const Step3Calc = ({ handleHintClickA }) => {
    let initialFormfields = {
        percentage: '',
        BGUnits: '',
        result: '',
    };

    let initialErrors = {
        percentage: false,
        BGUnits: false,
        result: false,
    };

    const [formFields, setFormFields] = useState({
        ...initialFormfields,
    });

    const [error, setError] = useState({
        ...initialErrors,
    });

    const [percentage, setPercentage] = useState('');
    const [BGUnits, setBGUnits] = useState('');
    const [calcResult, setCalcRes] = useState('?');
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const onChange = (e) => {
        setFormFields({
            ...formFields,
            [e.target.name]: e.target.value,
        });
        if (e.target.name === 'percentage') {
            setPercentage(e.target.value);
        } else if (e.target.name === 'BGUnits') {
            setBGUnits(e.target.value);
        }
    };

    const calculateResult = async (e) => {
        e.preventDefault();
        setHasSubmitted(true);
        let { percentage, BGUnits } = formFields;

        let result = ((+BGUnits / 100) * 15).toFixed(2);
        setCalcRes(result);

        if (error.percentage || error.BGUnits || error.result) {
            setError({
                ...initialErrors,
            });
            setFormFields({
                ...initialFormfields,
            });
            // clear input and result fields
            setPercentage('');
            setBGUnits('');
            setCalcRes('?');
        } else {
            setError({
                ...error,
                BGUnits: BGUnits != 22 ? true : false,
                percentage: percentage != 15 ? true : false,
                // both inputs and the result have to be correct for the result to be correct
                result:
                    result != 3.3 || BGUnits != 22 || percentage != 15
                        ? true
                        : false,
            });
        }
    };

    const handleTryAgainAfterAllCorrect = (e) => {
        e.preventDefault();
        setFormFields({
            ...initialFormfields,
        });
        // clear input and result fields
        setPercentage('');
        setBGUnits('');
        setCalcRes('?');
    };

    // remove error message if user clears an input field
    useEffect(() => {
        if (!formFields.BGUnits.length || !formFields.percentage.length) {
            // TODO add error classname for styling
            // for input box styling
            setHasSubmitted(false);
            // for submit button text
            setError({
                ...error,
                percentage: false,
                BGUnits: false,
                result: false,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formFields]);

    const allCorrect = !error.percentage && !error.BGUnits && !error.result;

    // responsive vars
    let [globalState] = useGlobalContext();
    let devicesize = globalState.deviceSize;

    let inputFieldWidth =
        devicesize === 'mobile'
            ? '6.8rem'
            : devicesize === 'tablet'
            ? '10rem'
            : '12rem';

    let inputFieldHeight =
        devicesize === 'mobile'
            ? '6.8rem'
            : devicesize === 'tablet'
            ? '10rem'
            : '12rem';

    let operatorMargin =
        devicesize === 'mobile'
            ? '1rem'
            : devicesize === 'tablet'
            ? '1.5rem'
            : '4rem';
    let operatorWidth =
        devicesize === 'mobile'
            ? '1.6rem'
            : devicesize === 'tablet'
            ? '2rem'
            : '3.2rem';

    return (
        <>
            <StyledForm devicesize={devicesize}>
                <CalculatorContainer
                    className="flex-all-centered"
                    devicesize={devicesize}
                    background="tertiary"
                >
                    <div className="flex-column-centered">
                        <CalcInputItem
                            label="%"
                            labelPosition={InputItemLabelPosition.BELOW}
                            name="percentage"
                            value={percentage}
                            onChange={onChange}
                            error={error}
                            hasSubmitted={hasSubmitted}
                            inputFieldWidth={inputFieldWidth}
                            inputFieldHeight={inputFieldHeight}
                            placeholder="0%"
                            unit="%"
                        />
                    </div>
                    <StyledImage
                        alt="multiply symbol"
                        width={operatorWidth}
                        marginTop="-3rem"
                        marginLeft={operatorMargin}
                        marginRight={operatorMargin}
                        src={multiplyPrimaryDarkest}
                    />
                    <div className="flex-column-centered">
                        <CalcInputItem
                            label="Units"
                            labelPosition={InputItemLabelPosition.BELOW}
                            name="BGUnits"
                            value={BGUnits}
                            onChange={onChange}
                            error={error}
                            hasSubmitted={hasSubmitted}
                            inputFieldWidth={inputFieldWidth}
                            inputFieldHeight={inputFieldHeight}
                            placeholder="0"
                            unit="g"
                        />
                    </div>

                    <StyledImage
                        alt="equals symbol"
                        width={operatorWidth}
                        marginTop="-3rem"
                        marginLeft={operatorMargin}
                        marginRight={operatorMargin}
                        src={equalsPrimaryDarkest}
                    />
                    <div className="flex-column-centered">
                        <CalcResult
                            label="Units"
                            name="result"
                            value={calcResult}
                            error={error}
                            hasSubmitted={hasSubmitted}
                            inputFieldWidth={inputFieldWidth}
                            inputFieldHeight={inputFieldHeight}
                            borderColor="tertiaryDark"
                            background="tertiaryDark"
                            devicesize={devicesize}
                        />
                    </div>
                </CalculatorContainer>

                <Container marginBottom="3.2rem" className="flex-all-centered">
                    {allCorrect && hasSubmitted ? (
                        <InteractiveActivityButton
                            isCorrect={true}
                            onClick={handleTryAgainAfterAllCorrect}
                            className="secondary"
                        />
                    ) : (
                        <InteractiveActivityButton
                            isCorrect={false}
                            onClick={calculateResult}
                            className={
                                !formFields.percentage.length ||
                                !formFields.BGUnits.length
                                    ? 'disabledBtn'
                                    : !allCorrect
                                    ? 'incorrect'
                                    : 'secondary'
                            }
                            hasError={!allCorrect}
                        />
                    )}
                    <StyledHintButton
                        padding="1.2rem 2rem"
                        onClick={(e) => handleHintClickA(e)}
                    />
                </Container>
            </StyledForm>
            {allCorrect && hasSubmitted ? (
                <P
                    className={
                        devicesize === 'desktopSm' || devicesize === 'desktopMd'
                            ? 'body-4'
                            : 'body-2'
                    }
                    marginBottom="1.6rem"
                >
                    Correct! 15% of 22 is 3.3, so you would round that up to
                    3.5.
                </P>
            ) : (
                ''
            )}
        </>
    );
};

export default Step3Calc;
