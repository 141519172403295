import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import ModalOverlay from 'assets/styledComponents/ModalOverlay';
import CloseModalButton from 'utils/helpers/CloseModalButton';
import P from 'assets/styledComponents/fonts/P';
import StyledImage from 'assets/styledComponents/StyledImage';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';

const HintModal = ({
    setShowModal,
    showModal,
    imgA,
    altA,
    maxHeightA,
    imgB,
    altB,
    maxHeightB,
}) => {
    let [globalState] = useGlobalContext();
    let devicesize = globalState.deviceSize;

    const [isSmallHeight, setIsSmallHeight] = useState(
        window.innerHeight < 750
    );

    // modal fof landscape view
    useEffect(() => {
        setIsSmallHeight(window.innerHeight < 750);
    }, []);

    return (
        <>
            <ModalOverlay setShowModal={setShowModal} showModal={showModal} />
            <ModalContainer
                className={
                    devicesize === 'mobile'
                        ? 'flex-column-centered card-shadow body-2'
                        : 'flex-column-centered card-shadow body-4'
                }
                devicesize={devicesize}
                isSmallHeight={isSmallHeight}
            >
                <CloseModalButton
                    setShowModal={setShowModal}
                    top={devicesize === 'mobile' ? '1.5rem' : '3rem'}
                    right={devicesize === 'mobile' ? '1.5rem' : '3rem'}
                />
                <P
                    className={devicesize === 'mobile' ? 'body-2' : 'body-4'}
                    marginBottom="0.8rem"
                >
                    Select the correct food.
                </P>
                <StyledImage
                    src={imgA}
                    alt={altA}
                    width="100%"
                    maxHeight={maxHeightA}
                />
                <P
                    className={devicesize === 'mobile' ? 'body-2' : 'body-4'}
                    marginTop="1.6rem"
                    marginBottom="0.8rem"
                >
                    Select the unit (e.g. cup, berry, gram, etc.) and type in
                    the quantity according to the activity.
                </P>
                <StyledImage
                    src={imgB}
                    alt={altB}
                    width="100%"
                    maxHeight={maxHeightB}
                />
                <P
                    className={devicesize === 'mobile' ? 'body-2' : 'body-4'}
                    marginTop="2.4rem"
                >
                    Record your answer for the carbs and fibre amounts, then
                    come back to the activity to fill in the values in the boxes
                    provided.
                </P>
            </ModalContainer>
        </>
    );
};

const ModalContainer = styled.div`
    width: ${(props) => (props.devicesize === 'mobile' ? '90vw' : '67rem')};
    height: 74.5rem;
    max-height: 90vh;
    overflow: scroll;
    background-color: ${(props) => props.theme.colors.lightBackground};
    color: ${(props) => props.theme.colors.darkText};
    border-radius: 1.6rem;
    position: fixed;
    right: ${(props) =>
        props.devicesize === 'mobile' ? '5vw' : 'calc(50vw - 33.5rem)'};
    top: ${(props) => (props.isSmallHeight ? '5vh' : 'calc(50vh - 37rem)')};
    z-index: 10;
    text-align: center;
    padding: ${(props) =>
        props.devicesize === 'mobile' ? '3.6rem 2.4rem' : '4.8rem 4rem 5.8rem'};
`;

export default HintModal;
