import React from 'react';
import styled from '@emotion/styled';
import close from 'assets/icons/close.svg';

const CloseModalButton = ({
    setShowModal,
    setShowSignInForm,
    top,
    right,
    mobileSignInOrUp,
    setShowMobileSignUpForm,
    setShowMobileSignInForm,
}) => {
    const handleClick = () => {
        if (mobileSignInOrUp) {
            setShowMobileSignUpForm && setShowMobileSignUpForm(false);
            setShowMobileSignInForm && setShowMobileSignInForm(false);
        } else {
            // set signin form to show if user closed from signup form
            setShowSignInForm && setShowSignInForm(true);
            setShowModal(false);
        }
    };
    return (
        <StyledButton top={top} right={right} onClick={() => handleClick()}>
            <img src={close} alt="x-shaped close modal icon" />
        </StyledButton>
    );
};

const StyledButton = styled.button`
    background-color: inherit;
    position: absolute;
    top: ${(props) => props.top};
    right: ${(props) => props.right};
    padding: 1rem;

    :hover {
        cursor: pointer;
        opacity: 0.4;
    }
`;

export default CloseModalButton;
