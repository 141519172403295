import React from 'react';
import { ActivityHeader } from '../../SharedComponentsInteractive';
import Container from 'assets/styledComponents/Container';

const ActivityHeaderWithInstructions = ({ hintRef }) => {
    return (
        <Container
            ref={hintRef}
            className="flex-space-between"
            marginBottom="4rem"
        >
            <ActivityHeader instructions="Drag and drop the foods with carbohydrates into the circles below." />
        </Container>
    );
};

export default ActivityHeaderWithInstructions;
