import React from 'react';
import BadgesAchievement from '../../assets/styledComponents/BadgesAchievement';
import badgesType from '../../types/badgesType';
import CardSize from '../../types/cardSize';
import CardAddTrack from '../DashboardHome/CardAddTrack';
import TracksCard from './TracksCard';
import trackCardClock from '../../assets/icons/trackCardClock.svg';
import trackCardCourse from '../../assets/icons/trackCardCourse.svg';
import FormatEstimatedTime from '../../utils/helpers/FormatEstimatedTime';
import TracksCardVertical from './TracksCardVertical';

const ShowDashboardMyTracks = ({ tracksWithProgress, myCourses, isMobile }) => {
    const cardsToDisplay = [];
    const tracksLength = tracksWithProgress && tracksWithProgress.length;
    let trackCompleted = false;
    for (let i = 0; i < tracksLength; i++) {
        const track = tracksWithProgress[i];
        if (track.progress === 100) {
            trackCompleted = true;
        }
        let coursesInTrack = track.courses.map((t) => t.title);
        let coursesStarted = [];
        let coursesCompleted = [];
        myCourses &&
            Object.values(myCourses).forEach((c) => {
                if (coursesInTrack.includes(c.title)) {
                    if (!c.courseCompleted) {
                        coursesStarted.push(c.title);
                    } else if (c.courseCompleted) {
                        coursesCompleted.push(c.title);
                    }
                }
            });

        const infoIcons = [
            {
                icon: `${trackCardCourse}`,
                title: `${track.courses.length} MODULES`,
            },
            {
                icon: `${trackCardClock}`,
                title: `${FormatEstimatedTime(track.estimatedTimeInMinutes)}`,
            },
        ];

        let smallImage =
            track.trackImage.image.formats &&
            track.trackImage.image.formats.small &&
            track.trackImage.image.formats.small.url;
        let nonOptimizedImage = track.trackImage.image.url;
        {
            isMobile
                ? cardsToDisplay.push(
                      <TracksCardVertical
                          key={track.id}
                          track={track}
                          trackImage={
                              smallImage ? smallImage : nonOptimizedImage
                          }
                          infoIcons={infoIcons}
                          isDashboard={true}
                          isMobile={isMobile}
                      />
                  )
                : cardsToDisplay.push(
                      <TracksCard
                          key={track.id}
                          numOfCourses={track.courses.length}
                          coursesStarted={coursesStarted}
                          coursesCompleted={coursesCompleted}
                          estimatedTime={track.estimatedTimeInMinutes}
                          slug={track.slug}
                          trackTitle={track.trackTitle}
                          trackSubtitle={track.subtitle}
                          trackDescription={track.trackDescription}
                          trackImage={
                              smallImage ? smallImage : nonOptimizedImage
                          }
                          trackImageAlternativeText={
                              track.trackImage.alternativeText
                          }
                          progress={track.progress}
                          infoIcons={infoIcons}
                      />
                  );
        }
    }
    {
        isMobile
            ? cardsToDisplay.push(
                  <CardAddTrack size={CardSize.SMALL} key="Add track key" />
              )
            : cardsToDisplay.push(
                  <CardAddTrack size={CardSize.REGULAR} key="Add track key" />
              );
    }

    return (
        <div>
            {trackCompleted ? (
                <BadgesAchievement id={badgesType.TRACK_STAR} />
            ) : (
                ''
            )}
            {cardsToDisplay}
        </div>
    );
};

export default ShowDashboardMyTracks;
