import React from 'react';
import styled from '@emotion/styled';
import { useGlobalContext } from 'GlobalContext/GlobalProvider.js';
import P from 'assets/styledComponents/fonts/P';
import InteractiveActivity from './InteractiveActivity.js';
import InteractiveActivityMobile from './InteractiveActivityMobile.js';

const FoodChoices = () => {
    const [globalState] = useGlobalContext();
    const devicesize = globalState.deviceSize;

    const categories = [
        { label: 'Carbohydrates' },
        { label: 'Protein' },
        { label: 'Fat' },
        { label: 'Combination' },
    ];

    const FoodCategories = ({ devicesize }) => {
        return categories.map((item) => {
            return (
                <ListItem
                    key={item.label}
                    className={devicesize === 'mobile' ? 'body-2' : 'body-4'}
                >
                    {item.label}
                </ListItem>
            );
        });
    };

    return (
        <>
            <P
                marginBottom="3rem"
                className={devicesize === 'mobile' ? 'body-2' : 'body-4'}
            >
                Now using the information you have learned so far, let&apos;s
                put this into action! Looking at this meal, which foods contain
                carbohydrates?
            </P>

            {devicesize === 'mobile' ? (
                <InteractiveActivityMobile devicesize={devicesize} />
            ) : (
                <InteractiveActivity devicesize={devicesize} />
            )}

            <P
                marginBottom="2rem"
                className={devicesize === 'mobile' ? 'body-2' : 'body-4'}
            >
                Think about the foods you enjoy eating, which category would
                they fall under?
            </P>
            <ul>
                <FoodCategories devicesize={devicesize} />
            </ul>
        </>
    );
};

export const ListItem = styled.li`
    position: relative;
    margin-left: 3rem;
    margin-bottom: 1.5rem;

    :before {
        position: absolute;
        content: ' ';
        border-radius: 50%;
        width: 0.8rem;
        height: 0.8rem;
        background: ${(props) => props.theme.colors.primary};
        color: ${(props) => props.theme.colors.darkText};
        bottom: calc(50% - 0.3rem);
        left: -1.5rem;
    }
`;

export default FoodChoices;
