import Container from 'assets/styledComponents/Container';
import H3 from 'assets/styledComponents/fonts/H3';
import React from 'react';
import Step1Activity from './Step1Activity';
import Step2Activity from './Step2Activity';
import Step3Activity from './Step3Activity';
import Step4 from './Step4';
import { stepItems } from './stepItems';

export default function ContentMobile({ devicesize }) {
    const getContent = (id) => {
        switch (id) {
            case 1:
                return <Step1Activity />;
            case 2:
                return <Step2Activity />;
            case 3:
                return <Step3Activity />;
            case 4:
                return <Step4 />;
        }
    };

    return (
        <Container marginTop={devicesize === 'mobile' ? '3.2rem' : '4rem'}>
            {stepItems.map((item) => (
                <Container key={item.id}>
                    <H3 className="body-1">
                        {item.id}
                        {'. '}
                        {item.title}
                    </H3>
                    <Container>{getContent(item.id)}</Container>
                </Container>
            ))}
        </Container>
    );
}
