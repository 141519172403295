import styled from '@emotion/styled';
import P from 'assets/styledComponents/fonts/P';

const StyledBody2 = styled(P)`
    text-align: ${(props) => (props.center ? 'center' : 'left')};

    button {
        color: inherit;
        font-size: inherit;
        background-color: inherit;
        :hover {
            cursor: pointer;
        }
    }

    img {
        margin-right: 0.8rem;
        &.twoLineErrorText {
            margin-top: -1.8rem;
        }
    }
`;

export default StyledBody2;
