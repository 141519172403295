import React, { useState } from 'react';
import BehaviouralAdviceNav from './BehaviouralAdviceNav';
import behavioralAdviceGroups from 'types/behaviouralAdviceGroups';
import BehaviouralContent from './BehaviouralContent';
import Container from 'assets/styledComponents/Container';

const BehaviouralAdvice = ({ devicesize }) => {
    const [activeTab, setActiveTab] = useState(
        behavioralAdviceGroups.HELPFUL_BEHAVIOURS
    );

    const onTabClick = (value) => {
        setActiveTab(value);
    };

    return (
        <Container
            width="100%"
            height={devicesize === 'tablet' ? '60rem' : '54rem'}
            marginBottom="6.4rem"
        >
            <BehaviouralAdviceNav
                activeTab={activeTab}
                onTabClick={onTabClick}
            />
            <Container background="tertiary" padding="6.4rem 0">
                <BehaviouralContent
                    activeTab={activeTab}
                    devicesize={devicesize}
                />
            </Container>
        </Container>
    );
};

export default BehaviouralAdvice;
