import React from 'react';
import styled from '@emotion/styled';
import course7BookPages from 'types/course7BookPages';

const PageNumberTabs = ({ activePage, onPageTabClick }) => {
    return (
        <>
            {Object.values(course7BookPages).map((item) => {
                return (
                    <PageTab
                        key={item}
                        num={item}
                        className={
                            activePage === item
                                ? 'active header-4 flex-all-centered'
                                : 'header-4 flex-all-centered'
                        }
                        activePage={activePage}
                        onClick={() => onPageTabClick(item)}
                    >
                        <p>{item}</p>
                    </PageTab>
                );
            })}
        </>
    );
};

const PageTab = styled.button`
    background: ${(props) => props.theme.colors.success};
    color: ${(props) => props.theme.colors.lightText};
    width: 5rem;
    height: 6rem;
    position: absolute;
    left: ${(props) => props.activePage >= props.num && '-5rem'};
    right: ${(props) => props.activePage < props.num && '-5rem'};
    top: ${(props) => (props.num - 1) * 8 + 'rem'};

    z-index: 1;

    border-radius: ${(props) =>
        props.activePage >= props.num ? '3rem 0 0 3rem' : '0 3rem 3rem 0'};

    p,
    img {
        margin-right: ${(props) =>
            props.activePage >= props.num ? '-1rem' : '1rem'};
        padding: 1rem;
        :hover {
            opacity: 0.7;
        }
    }

    &.active {
        background: ${(props) => props.theme.colors.lightBackground};
        color: ${(props) => props.theme.colors.success};
    }

    :hover {
        cursor: pointer;
    }
`;

export default PageNumberTabs;
