import React from 'react';
import StyledImage from 'assets/styledComponents/StyledImage';
import page1Img from 'assets/images/interactiveBook-course2/page1Img.svg';
import P from 'assets/styledComponents/fonts/P';

const IntroPageMobile = () => {
    return (
        <>
            <StyledImage src={page1Img} width="100%" height="24rem" />
            <P className="body-2" marginTop="2.4rem">
                This is a glucose meter, it is used to check your blood glucose.
                Included in your kit is a meter, test strips, a lancing device,
                lancets and an instruction manual.
            </P>
            <P className="body-2" marginTop="1.6rem">
                Keep in mind that each glucometer may be slightly different, but
                the following general steps explain how to check a blood glucose
                level:
            </P>
        </>
    );
};
export default IntroPageMobile;
