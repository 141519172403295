import { React, useState } from 'react';
import { PageContainer } from './SharedComponents';
import Step4A from './Step4A';
import Step4B from './Step4B';

const Step4 = ({ devicesize, setActivePage }) => {
    const [pageToShow, setPageToShow] = useState(1);

    return (
        <PageContainer>
            {pageToShow === 1 ? (
                <Step4A
                    setActivePage={setActivePage}
                    setPageToShow={setPageToShow}
                    devicesize={devicesize}
                />
            ) : (
                <Step4B
                    setActivePage={setActivePage}
                    setPageToShow={setPageToShow}
                    devicesize={devicesize}
                />
            )}
        </PageContainer>
    );
};

export default Step4;
