import React from 'react';
import styled from '@emotion/styled';
import TabContent from './TabButtons';

const ShowTabButtons = ({ page, setPage }) => {
    return (
        <OuterContainer>
            {TabContent.map((item) => {
                return (
                    <Container key={item.id} className="flex-all-centered">
                        <NumberCircle
                            className={
                                page === item.id
                                    ? 'active flex-all-centered body-1'
                                    : 'flex-all-centered body-1'
                            }
                        >
                            {item.id}
                        </NumberCircle>
                        <TabButton
                            TabButton
                            onClick={() => setPage(item.id)}
                            className={
                                page === item.id ? 'active body-4' : 'body-4'
                            }
                        >
                            {item.content}
                        </TabButton>
                    </Container>
                );
            })}
        </OuterContainer>
    );
};

const inactiveSecondary = '#FFBC79';

const NumberCircle = styled.div`
    min-width: 4rem;
    height: 4rem;
    background: ${inactiveSecondary};
    border-radius: 50%;
    color: ${(props) => props.theme.colors.lightText};
    margin-left: 0.8rem;

    &.active {
        background: ${(props) => props.theme.colors.secondary};
    }
`;

const OuterContainer = styled.div`
    position: relative;

    :before {
        height: calc(100% - 8rem);
        background: ${(props) => props.theme.colors.lightGreyBackground};
        width: 0.3rem;
        content: ' ';
        z-index: -1;
        bottom: -2rem;
        top: 1.5rem;
        left: 2.6rem;
        position: absolute;
    }
`;

const Container = styled.div`
    width: 100%;
    height: fit-content;
    margin-bottom: 1.4rem;

    :last-of-type {
        margin-bottom: 4.8rem;
    }
`;

const TabButton = styled.button`
    border: 0.2rem solid ${(props) => props.theme.colors.tertiary};
    border-radius: 2rem;
    padding: 1.4rem 2.2rem;
    width: 95%;
    margin-left: 2.4rem;
    text-align: left;

    background: ${(props) => props.theme.colors.lightBackground};
    color: ${(props) => props.theme.colors.darkText};

    &.active {
        background: ${(props) => props.theme.colors.tertiary};
    }

    :hover {
        cursor: pointer;
    }
`;

export default ShowTabButtons;
