import styled from '@emotion/styled';

const PageContentContainer = styled.div`
    width: ${(props) => props.width};
    max-width: ${(props) => props.theme.spaces.contentWidth};
    margin-top: ${(props) => props.marginTop};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
    padding-left: ${(props) => props.paddingLeft};
    padding-right: ${(props) => props.paddingRight};

    @media only screen and (max-width: 1056px) {
        margin: 4.8rem;
        margin-top: 8.2rem;
    }
    @media only screen and (max-width: 768px) {
        margin: 2.4rem;
    }
`;

export default PageContentContainer;
