import React from 'react';
import styled from '@emotion/styled';
import calculateProgress from 'utils/helpers/CalculateProgress';
import doneBadge from '../../assets/icons/finishedCourseSmall.svg';
import CourseImage from '../../assets/styledComponents/StyledImage';
import P from 'assets/styledComponents/fonts/P';
import bookSm from '../../assets/icons/bookSm.svg';
import clockSm from '../../assets/icons/clockSm.svg';
import FormatEstimatedTime from 'utils/helpers/FormatEstimatedTime';
import StyledIcon from 'assets/styledComponents/StyledImage';
import { useQuery } from '@apollo/client';
import { GET_COURSE } from 'graphql/queries';
import calculateEstimatedCourseTime from 'utils/helpers/CalculateEstimatedCourseTime';

function CourseCardSmallHorizontal({ course }) {
    const progress = calculateProgress(course);

    const slug = course?.slug;

    const { data: fullCourse } = useQuery(GET_COURSE, {
        variables: { slug },
    });

    const infoIcons = [
        { icon: `${bookSm}`, title: `${course.totalLessons} Lessons` },
        {
            icon: `${clockSm}`,
            title: FormatEstimatedTime(
                fullCourse?.courses[0] &&
                    calculateEstimatedCourseTime(fullCourse.courses[0])
            ),
        },
    ];

    return (
        <CardContainer className="card-shadow">
            {progress == 100 ? <StyledDoneBadge src={doneBadge} /> : ''}
            <CourseImage
                height="100%"
                width="11.5rem"
                objectFit="cover"
                borderRadius="2rem 0rem 0rem 2rem"
                src={
                    course.thumbnailImageUrl
                        ? course.thumbnailImageUrl
                        : course.nonOptimizedImageUrl
                }
                alt={course.title}
            ></CourseImage>
            <InfoSection>
                <Title>
                    <P
                        className="maxTwoLineText petit-title-2"
                        textTransform="uppercase"
                        height="3.2rem"
                    >
                        {course.title}
                    </P>
                </Title>
                {progress > 0 && progress < 100 ? (
                    <div className="flex-center-aligned">
                        <ProgressBarBackground>
                            <ProgressBarFill progress={progress} />
                        </ProgressBarBackground>
                        <P className="petit-title-2" color="greyText">
                            {progress}%
                        </P>
                    </div>
                ) : (
                    infoIcons.map((item) => (
                        <div className="flex" key={item.title}>
                            <StyledIcon
                                width="1.3rem"
                                height="1.3rem"
                                marginRight="0.5rem"
                                marginTop="0.3rem"
                                src={item.icon}
                                alt=""
                            />
                            <P
                                className="petit-title-2"
                                textTransform="uppercase"
                                color="greyText"
                                marginBottom="0.3rem"
                            >
                                {item.title}
                            </P>
                        </div>
                    ))
                )}
            </InfoSection>
        </CardContainer>
    );
}

const CardContainer = styled.div`
    width: 100%;
    height: 11.2rem;
    border-radius: 2rem;
    display: flex;
    margin-bottom: 1.6rem;
    position: relative;
    background-color: ${(props) => props.theme.colors.lightBackground};
    :hover .btn-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border-radius: 2rem;
    }
`;

const InfoSection = styled.div`
    display: flex;
    flex-direction: column;
    margin: 2.2rem 1.6rem 1.6rem 1.6rem;
    width: 100%;
`;

const Title = styled.div`
    width: 80%;
    margin-bottom: 0.8rem;
`;

const StyledDoneBadge = styled.img`
    position: absolute;
    top: 0;
    right: 1.6rem;
`;

const ProgressBarBackground = styled.div`
    width: 90%;
    height: 0.8rem;
    border-radius: 1rem;
    background: ${(props) => props.theme.colors.lightGreyBackground};
    margin-right: 0.8rem;
`;

const ProgressBarFill = styled.div`
    width: ${(props) => props.progress}%;
    height: 0.8rem;
    border-radius: 1rem;
    background: ${(props) => props.theme.colors.secondary};
`;

export default CourseCardSmallHorizontal;
