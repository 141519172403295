import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import nextLessonWithChevronDown from 'assets/icons/nextLessonWithChevronDown.svg';
import nextLessonWithChevronDownDisabled from 'assets/icons/nextLessonWithChevronDownDisabled.svg';
import FilledButtonLg from 'assets/styledComponents/buttons/FilledButtonLg';
import P from 'assets/styledComponents/fonts/P';
import Container from 'assets/styledComponents/Container';

const LessonBottomSection = ({
    setHasCurrentLessonEnded,
    page,
    last,
    slug,
    isMobile,
    isNextLessonAvaliable,
    isUserLoggedIn,
}) => {
    const history = useHistory();
    const nextLessonButtonRef = useRef();

    const isElementInViewport = (el) => {
        if (!el) {
            return false;
        }
        var rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.bottom <=
                (window.innerHeight || document.documentElement.clientHeight)
        );
    };

    useEffect(() => {
        if (
            isElementInViewport(nextLessonButtonRef.current) ||
            !isUserLoggedIn
        ) {
            setHasCurrentLessonEnded(true);
        }

        return () => setHasCurrentLessonEnded(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const redirectToNextLesson = () => {
        if (isNextLessonAvaliable || !isUserLoggedIn) {
            history.push(`/modules/${slug}/${Number(page) + 1}`);
        }
    };

    return (
        <Container
            className="flex-center-justified"
            marginTop="4.8rem"
            paddingBottom={isMobile ? '1.2rem' : '7.2rem'}
        >
            {Number(page) < last ? (
                <NextLessonButton
                    ref={nextLessonButtonRef}
                    onClick={() => redirectToNextLesson()}
                    activateNext={isNextLessonAvaliable || !isUserLoggedIn}
                >
                    <img
                        src={
                            isNextLessonAvaliable || !isUserLoggedIn
                                ? nextLessonWithChevronDown
                                : nextLessonWithChevronDownDisabled
                        }
                        alt="Downward pointing chevron"
                    />
                </NextLessonButton>
            ) : (
                <CourseCompleted>
                    <P
                        color="secondary"
                        marginBottom="0.8rem"
                        className="body-1"
                    >
                        This is the end of this module.
                    </P>
                    <FilledButtonLg
                        marginTop="3.2rem"
                        padding={isMobile ? '2rem 4.5rem' : '2rem 9rem'}
                        onClick={() => history.push(`/modules`)}
                    >
                        Return to Modules
                    </FilledButtonLg>
                </CourseCompleted>
            )}
        </Container>
    );
};

const NextLessonButton = styled.button`
    background-color: inherit;

    :hover {
        opacity: ${(props) => (props.activateNext ? '0.3' : '')};
        cursor: ${(props) => (props.activateNext ? 'pointer' : '')};
    }
`;

const CourseCompleted = styled.div`
    text-align: center;
`;

export default LessonBottomSection;
