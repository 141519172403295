import { React, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { StepTitle, Divider } from '../SharedComponents';
import {
    StyledForm,
    StyledHintButton,
} from '../../SharedComponentsInteractive';
import StyledImage from 'assets/styledComponents/StyledImage';
import equalsPrimaryDarkest from 'assets/icons/math/equalsPrimaryDarkest.svg';
import InteractiveActivityButton from 'assets/styledComponents/buttons/InteractiveActivityButton';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';
import Container from 'assets/styledComponents/Container';
import InputItemLabelPosition from 'types/inputItemLabelPosition';
import CalcInputItem from 'assets/styledComponents/CalcInputItem';
import CalcResult from 'assets/styledComponents/CalcResult';

const BolusCalcStep2 = ({ setShowHint2, scrollUp }) => {
    let initialFormfields = {
        carbMeal: '',
        carbRatio: '',
        result: '',
    };

    let initialErrors = {
        carbMeal: false,
        carbRatio: false,
        result: false,
    };

    const [formFields, setFormFields] = useState({
        ...initialFormfields,
    });

    const [error, setError] = useState({
        ...initialErrors,
    });

    const [carbMeal, setCarbMeal] = useState('');
    const [carbRatio, setCarbRatio] = useState('');
    const [calcResult, setCalcRes] = useState('?');
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const onChange = (e) => {
        setFormFields({
            ...formFields,
            [e.target.name]: e.target.value,
        });
        if (e.target.name === 'carbMeal') {
            setCarbMeal(e.target.value);
        } else if (e.target.name === 'carbRatio') {
            setCarbRatio(e.target.value);
        }
    };

    const calculateResult = async (e) => {
        e.preventDefault();
        setHasSubmitted(true);
        let { carbMeal, carbRatio } = formFields;
        let result = (carbMeal / carbRatio).toFixed(2);
        setCalcRes(result);

        if (error.carbMeal || error.carbRatio || error.result) {
            setError({
                ...initialErrors,
            });
            setFormFields({
                ...initialFormfields,
            });
            // clear input and result fields
            setCarbMeal('');
            setCarbRatio('');
            setCalcRes('?');
        } else {
            setError({
                ...error,
                carbMeal: carbMeal != 30 ? true : false,
                carbRatio: carbRatio != 15 ? true : false,
                // all inputs and the result have to be correct for the result to be correct
                result:
                    result != 2 || carbMeal != 30 || carbRatio != 15
                        ? true
                        : false,
            });
        }
    };

    const handleTryAgainAfterAllCorrect = (e) => {
        e.preventDefault();
        setFormFields({
            ...initialFormfields,
        });
        // clear input and result fields
        setCarbMeal('');
        setCarbRatio('');
        setCalcRes('?');
    };

    // remove error message if user clears an input field
    useEffect(() => {
        if (!formFields.carbMeal.length || !formFields.carbRatio.length) {
            // for input box styling
            setHasSubmitted(false);
            // for submit button text
            setError({
                ...error,
                carbMeal: false,
                carbRatio: false,
                result: false,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formFields]);

    const handleHintClick = (e) => {
        e.preventDefault();
        setShowHint2(true);
        scrollUp();
        setTimeout(() => {
            setShowHint2(false);
        }, 3000);
    };

    // responsive vars
    const [globalState] = useGlobalContext();
    const { deviceSize } = globalState;

    const isTablet = Boolean(deviceSize === 'tablet');
    const isMobile = Boolean(deviceSize === 'mobile');
    const isDesktop = Boolean(!isTablet && !isMobile);

    const inputFieldWidth = isMobile ? '6.8rem' : isTablet ? '10rem' : '12rem';
    const operatorMargin = isMobile ? '1rem' : isTablet ? '1.5rem' : '4rem';
    const operatorWidth = isMobile ? '1.6rem' : isTablet ? '2rem' : '3.2rem';

    let allCorrect = !error.carbMeal && !error.carbRatio && !error.result;

    return (
        <>
            <Container
                width="100%"
                className="flex-space-between"
                marginBottom="4rem"
            >
                <StepTitle
                    isMobile={isMobile}
                    isDesktop={isDesktop}
                    stepNumber="2"
                    instruction="Insulin for food to be eaten (food bolus)"
                />
            </Container>
            <StyledForm>
                <CalculatorContainer
                    className="flex-all-centered"
                    isMobile={isMobile}
                >
                    <div className="flex-center-aligned">
                        <Container
                            className="flex-column-centered"
                            width={isDesktop ? '20rem' : '15rem'}
                        >
                            <CalcInputItem
                                label="Carbs in meal"
                                labelPosition={InputItemLabelPosition.ABOVE}
                                name="carbMeal"
                                value={carbMeal}
                                onChange={onChange}
                                error={error}
                                hasSubmitted={hasSubmitted}
                                inputFieldWidth={inputFieldWidth}
                                inputFieldHeight={inputFieldWidth}
                                placeholder="0"
                                unit=""
                            />
                            <Divider
                                marginBottom={isDesktop ? '2.4rem' : '1.2rem'}
                            />

                            <CalcInputItem
                                label="Carb ratio"
                                labelPosition={InputItemLabelPosition.BELOW}
                                name="carbRatio"
                                value={carbRatio}
                                onChange={onChange}
                                error={error}
                                hasSubmitted={hasSubmitted}
                                inputFieldWidth={inputFieldWidth}
                                inputFieldHeight={inputFieldWidth}
                                placeholder="0"
                                unit=""
                            />
                        </Container>
                    </div>

                    <StyledImage
                        alt="equals symbol"
                        width={operatorWidth}
                        marginLeft={operatorMargin}
                        marginRight={operatorMargin}
                        src={equalsPrimaryDarkest}
                    />
                    <div
                        className="flex-column-centered"
                        style={{ marginTop: '3rem' }}
                    >
                        <CalcResult
                            label="Units"
                            name="result"
                            value={calcResult}
                            error={error}
                            hasSubmitted={hasSubmitted}
                            inputFieldWidth={inputFieldWidth}
                            inputFieldHeight={inputFieldWidth}
                            marginTop={isDesktop ? '2.4rem' : '0'}
                            background="tertiaryDark"
                            borderColor="tertiaryDark"
                            devicesize={deviceSize}
                        />
                    </div>
                </CalculatorContainer>

                <Container marginBottom="4.8rem" className="flex-all-centered">
                    {allCorrect && hasSubmitted ? (
                        <InteractiveActivityButton
                            isCorrect={true}
                            onClick={handleTryAgainAfterAllCorrect}
                            className="secondary"
                        />
                    ) : (
                        <InteractiveActivityButton
                            isCorrect={false}
                            onClick={calculateResult}
                            className={
                                !formFields.carbMeal.length ||
                                !formFields.carbRatio.length
                                    ? 'disabledBtn'
                                    : !allCorrect
                                    ? 'incorrect'
                                    : 'secondary'
                            }
                            hasError={!allCorrect}
                        />
                    )}
                    <StyledHintButton onClick={(e) => handleHintClick(e)} />
                </Container>
            </StyledForm>
        </>
    );
};

let CalculatorContainer = styled.div`
    background: ${(props) => props.theme.colors.tertiary};
    border-radius: ${({ isMobile }) => (isMobile ? `none` : `2rem`)};
    width: ${({ isMobile }) => (isMobile ? `100vw` : `100%`)};
    padding: 3.5rem 2rem 3.9rem;
    margin-bottom: 4rem;
    margin-left: ${({ isMobile }) => (isMobile ? `-2.5rem` : `0`)};
`;

export default BolusCalcStep2;
