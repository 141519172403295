import React from 'react';
import styled from '@emotion/styled';

const ModalOverlay = ({
    showModal,
    setShowModal,
    isLandingPage,
    isFocusOnSignIn,
    setIsFocusOnSignIn,
    setShowSignInForm,
    isForLessons,
    setExpandNavbar,
    isSignInForm,
}) => {
    const handleClick = () => {
        if (isLandingPage && isFocusOnSignIn) {
            setIsFocusOnSignIn(false);
        } else if (isForLessons) {
            setExpandNavbar(false);
        } else {
            setShowModal(false);
            setShowSignInForm && setShowSignInForm(true);
        }
    };

    return showModal || isFocusOnSignIn ? (
        <StyledBackground
            onClick={handleClick}
            isLandingPage={isLandingPage}
            isForLessons={isForLessons}
            isSignInForm={isSignInForm}
        />
    ) : (
        ''
    );
};

const StyledBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: ${(props) =>
        props.isLandingPage
            ? 1
            : props.isForLessons
            ? 2
            : props.isSignInForm
            ? 5
            : 5};
    background: rgba(0, 0, 0, 0.4);
    :hover {
        cursor: pointer;
    }
`;

export default ModalOverlay;
