import React from 'react';
import { ActivityHeader } from '../../SharedComponentsInteractive';
import Container from 'assets/styledComponents/Container';

const ActivityHeaderWithInstructions = () => {
    return (
        <Container marginBottom="3rem">
            <ActivityHeader instructions="Solve the question below" />
        </Container>
    );
};

export default ActivityHeaderWithInstructions;
