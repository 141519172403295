import React from 'react';
import styled from '@emotion/styled';
import CardImage from '../../assets/styledComponents/StyledImage';
import P from 'assets/styledComponents/fonts/P';
import LinkWrappingButton from '../../assets/styledComponents/buttons/LinkWrappingButton';
import FilledButtonSm from 'assets/styledComponents/buttons/FilledButtonSm';

const CardAddStudyHorizontal = ({ image, title, activity, link }) => {
    return (
        <CardContainer className="card-shadow">
            <CardImage
                height="100%"
                width="11.5rem"
                objectFit="cover"
                borderRadius="2rem 0rem 0rem 2rem"
                src={image}
                alt={title}
            ></CardImage>
            <InfoSection>
                <div className="flex-center-aligned">
                    <P
                        className="maxTwoLineText petit-title-2"
                        marginBottom="1.6rem"
                        textTransform="uppercase"
                    >
                        {title}
                    </P>
                </div>
                <LinkWrappingButton to={link}>
                    <FilledButtonSm width="13rem">{activity}</FilledButtonSm>
                </LinkWrappingButton>
            </InfoSection>
        </CardContainer>
    );
};

const CardContainer = styled.div`
    width: 100%;
    height: 11.2rem;
    border-radius: 2rem;
    display: flex;
    margin-bottom: 1.6rem;
    position: relative;
    background-color: ${(props) => props.theme.colors.lightBackground};
    :hover .btn-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border-radius: 2rem;
    }
`;

const InfoSection = styled.div`
    display: flex;
    flex-direction: column;
    margin: 2.2rem 1.6rem 3.2rem 1.6rem;
    width: 100%;
`;

export default CardAddStudyHorizontal;
