import React from 'react';
import StyledImage from 'assets/styledComponents/StyledImage';
import styled from '@emotion/styled';

const Figure = ({
    src,
    altText,
    captionIntro,
    captionExample,
    captionExample2,
    devicesize,
    urlExample,
    urlExample2,
}) => {
    return (
        <figure>
            <StyledImage
                src={src}
                alt={altText}
                width="25rem"
                height="14.4rem"
                marginTop={devicesize === 'mobile' ? '4rem' : '2.4rem'}
            />
            <FigCaption>
                <span className="intro body-1">{captionIntro}</span>
                <a href={urlExample} target="_blank" rel="noreferrer noopener">
                    <span className="example petit-title-serif">
                        {captionExample}
                    </span>
                </a>
                <a href={urlExample2} target="_blank" rel="noreferrer noopener">
                    <span className="example petit-title-serif">
                        {captionExample2}
                    </span>
                </a>
            </FigCaption>
        </figure>
    );
};

const FigCaption = styled.figcaption`
    white-space: pre-wrap;

    span.intro {
        display: block;
        margin-top: 2.8rem;
        color: ${(props) => props.theme.colors.greyText};
    }

    span.example {
        display: block;
        margin-top: 1.6rem;
        color: ${(props) => props.theme.colors.primary};
        text-decoration: none;
    }
`;

export default Figure;
