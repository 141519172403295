import { React, useState } from 'react';
import styled from '@emotion/styled';
import Container from 'assets/styledComponents/Container';
import Step1Activity from './Step1Activity';
import Step2Activity from './Step2Activity';
import Step3Activity from './Step3Activity';
import Step4 from './Step4';
import CircularNumber from 'assets/styledComponents/CircularNumber';
import H3 from 'assets/styledComponents/fonts/H3';
import plusSecondary from 'assets/icons/plusSecondary.svg';
import minusSecondary from 'assets/icons/minusSecondary.svg';

const AccordionItem = ({ id, title }) => {
    let [isOpen, setIsOpen] = useState(false);
    return (
        <Container marginBottom="1.6rem" marginLeft="0.8rem">
            <Container className="flex-center-aligned">
                <CircularNumber
                    className="body-1 flex-all-centered"
                    number={id}
                    zIndex="1"
                />
                <ItemTitleContainer
                    isOpen={isOpen}
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <H3 className="body-1">{title}</H3>
                    <img
                        src={isOpen ? minusSecondary : plusSecondary}
                        alt={
                            isOpen
                                ? 'an orange minus symbol'
                                : 'an orange  plus symbol'
                        }
                    />
                </ItemTitleContainer>
            </Container>
            <Container role="region" aria-labelledby={'accordion' + id}>
                {isOpen &&
                    (id === 1 ? (
                        <Step1Activity />
                    ) : id === 2 ? (
                        <Step2Activity />
                    ) : id === 3 ? (
                        <Step3Activity />
                    ) : (
                        id === 4 && <Step4 />
                    ))}
            </Container>
        </Container>
    );
};

const ItemTitleContainer = styled.button`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 5.6rem;
    padding: 1.6rem 3.2rem;
    background: ${(props) => props.theme.colors.tertiary};
    border-radius: 2rem;
    margin-left: 2.4rem;
    text-align: left;

    img {
        margin-left: 1rem;
    }
    :hover {
        cursor: pointer;
    }
`;

export default AccordionItem;
