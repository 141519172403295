import React from 'react';
import styled from '@emotion/styled';
import SignInForm from './SignInForm';
import ModalOverlay from '../../assets/styledComponents/ModalOverlay';
import SignUpForm from './SignUpForm';

const SignInFormWithModal = ({
    showModal,
    setShowModal,
    showSignInForm,
    setShowSignInForm,
}) => {
    return (
        <div>
            <ModalOverlay
                showModal={showModal}
                setShowModal={setShowModal}
                setShowSignInForm={setShowSignInForm}
                isSignInForm
            />
            <StyledDiv top={showSignInForm ? '26.5rem' : '31.5rem'}>
                {showSignInForm ? (
                    <SignInForm
                        withModal={true}
                        setShowModal={setShowModal}
                        setShowSignInForm={setShowSignInForm}
                    />
                ) : (
                    <SignUpForm
                        withModal={true}
                        setShowModal={setShowModal}
                        setShowSignInForm={setShowSignInForm}
                    />
                )}
            </StyledDiv>
        </div>
    );
};

const StyledDiv = styled.div`
    z-index: 10;
    display: flex;
    align-content: center;
    position: absolute;
    /* center by factoring in half of the signInForm size */
    right: calc(50% + 20.5rem);
    top: calc(50vh - ${(props) => props.top});
`;

export default SignInFormWithModal;
