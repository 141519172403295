import { React, Fragment } from 'react';
import styled from '@emotion/styled';
import { InformationSection, ImageContainer } from './SharedComponentsThisWeek';
import FilledButtonMd from '../../assets/styledComponents/buttons/FilledButtonMd';
import P from 'assets/styledComponents/fonts/P';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';
import Container from 'assets/styledComponents/Container';
// single button below info section

const HasStudyPlan = ({
    daysRemaining,
    daysPerWeekGoal,
    totalVisitsForWeek,
    setShowCreateStudyPlan,
    isOnDashboardHomePage,
}) => {
    let [globalState] = useGlobalContext();
    let devicesize = globalState.deviceSize;
    return (
        <Fragment>
            <InformationSection
                className="flex-all-centered"
                isOnDashboardHomePage={isOnDashboardHomePage}
                devicesize={devicesize}
            >
                {devicesize !== 'mobile' && (
                    <ImageContainer>
                        <DaysCompletedCircle
                            className="flex-column-all-centered"
                            isOnDashboardHomePage={isOnDashboardHomePage}
                        >
                            <DayCount>
                                <span>{totalVisitsForWeek}</span> /{' '}
                                {daysPerWeekGoal}
                            </DayCount>
                            <P className="petit-title-2">
                                {daysPerWeekGoal === 1 ? 'DAY' : 'DAYS'}
                            </P>
                        </DaysCompletedCircle>
                    </ImageContainer>
                )}

                {daysRemaining === 0 ? (
                    <P
                        className={
                            devicesize === 'mobile' || isOnDashboardHomePage
                                ? 'body-2 maxFourLineText'
                                : 'petit-title-2 maxFourLineText'
                        }
                        color="greyText"
                        width={devicesize === 'mobile' ? '100%' : '22rem'}
                        maxWidth={devicesize === 'mobile' && '46rem'}
                        marginLeft={devicesize !== 'mobile' && '4rem'}
                        isOnDashboardHomePage={isOnDashboardHomePage}
                        textAlign={devicesize === 'mobile' && 'center'}
                    >
                        <Span>Congratulations!</Span> You achieved your study
                        goal for the week!
                    </P>
                ) : daysRemaining > 0 ? (
                    <P
                        className={
                            devicesize === 'mobile' || isOnDashboardHomePage
                                ? 'body-2 maxFourLineText'
                                : 'body-4 maxFourLineText'
                        }
                        color="greyText"
                        width={devicesize === 'mobile' ? '100%' : '22rem'}
                        maxWidth={devicesize === 'mobile' && '46rem'}
                        marginLeft={devicesize !== 'mobile' && '4rem'}
                        textAlign={devicesize === 'mobile' && 'center'}
                    >
                        <Span>
                            {' '}
                            {daysRemaining === 1
                                ? daysRemaining + ' day'
                                : daysRemaining + ' days'}
                            &nbsp;left
                        </Span>
                        &nbsp;to meet your weekly target. Keep up the good work!
                    </P>
                ) : daysRemaining < 0 ? (
                    <P
                        className={
                            devicesize === 'mobile' || isOnDashboardHomePage
                                ? 'body-2 maxFourLineText'
                                : 'body-4 maxFourLineText'
                        }
                        color="greyText"
                        width={devicesize === 'mobile' ? '100%' : '22rem'}
                        maxWidth={devicesize === 'mobile' && '46rem'}
                        marginLeft={devicesize !== 'mobile' && '4rem'}
                        isOnDashboardHomePage={isOnDashboardHomePage}
                        textAlign={devicesize === 'mobile' && 'center'}
                    >
                        {' '}
                        <Span>Amazing! </Span> You have exceeded your study goal
                        for the week.
                    </P>
                ) : (
                    ''
                )}
            </InformationSection>

            {!isOnDashboardHomePage && devicesize !== 'mobile' ? (
                <FilledButtonMd
                    width="37rem"
                    onClick={() => setShowCreateStudyPlan(true)}
                >
                    Change your study plan
                </FilledButtonMd>
            ) : devicesize === 'mobile' ? (
                <Container padding="0 1.8rem">
                    <P
                        className={
                            devicesize === 'mobile' || isOnDashboardHomePage
                                ? 'body-2 maxFourLineText'
                                : 'body-4 maxFourLineText'
                        }
                        color="greyText"
                        width={devicesize === 'mobile' ? '100%' : '22rem'}
                        maxWidth={devicesize === 'mobile' && '46rem'}
                        marginLeft={devicesize !== 'mobile' && '4rem'}
                        textAlign={devicesize === 'mobile' && 'center'}
                    >
                        Please <Span>use a desktop </Span> to edit your study
                        plan.
                    </P>
                </Container>
            ) : (
                ''
            )}
        </Fragment>
    );
};

const DaysCompletedCircle = styled.div`
    height: ${(props) => (props.isOnDashboardHomePage ? '10rem' : '10.5rem')};
    width: ${(props) => (props.isOnDashboardHomePage ? '10rem' : '10.5rem')};
    border-radius: 50%;
    border: 0.1rem solid ${(props) => props.theme.colors.tertiary};
    text-transform: uppercase;
    background-color: inherit;
    color: ${(props) => props.theme.colors.greyText};
    font-family: MontserratBold;
    ${(props) => props.theme.letterSpacing.md};
    font-size: 2.8rem;

    span {
        color: ${(props) => props.theme.colors.secondary};
    }
`;

const DayCount = styled.div`
    margin-bottom: 0.2rem;
`;

const Span = styled.span`
    color: ${(props) => props.theme.colors.secondary};
`;
export default HasStudyPlan;
