import styled from '@emotion/styled';
import React from 'react';
import { useHistory } from 'react-router-dom';
import backWhite from 'assets/icons/backWhite.svg';
import SideNavBarCourseTitle from 'assets/styledComponents/fonts/Header3';
import P from 'assets/styledComponents/fonts/P';

const LessonSideNavBarTopSection = ({
    currentCourse,
    slug,
    progress,
    isUserLoggedIn,
    expandNavbar,
}) => {
    const history = useHistory();

    return (
        <LessonSideNavBarTopSectionContainer
            currentCourse={currentCourse}
            padding={!expandNavbar ? '2rem 0.8rem' : '2rem'}
        >
            <Button
                className="flex-all-centered petit-title-2"
                onClick={() => history.push(`/modules/${slug}/0`)}
                width={expandNavbar ? '12rem' : '4rem'}
            >
                <img src={backWhite} alt="chevron pointing left" />
                {expandNavbar ? 'Syllabus' : ''}
            </Button>
            <SideNavBarCourseTitle
                color="lightText"
                className="maxFourLineText"
            >
                {expandNavbar ? currentCourse.title : ''}
            </SideNavBarCourseTitle>

            {isUserLoggedIn ? (
                <ProgressContainer>
                    <ProgressBar>
                        <ProgressLine progress={progress} />
                    </ProgressBar>

                    <P
                        className="petit-title-2"
                        color="lightText"
                        marginTop="1rem"
                        marginLeft="2rem"
                    >
                        {progress}% {expandNavbar ? 'complete' : ''}
                    </P>
                </ProgressContainer>
            ) : (
                ''
            )}
        </LessonSideNavBarTopSectionContainer>
    );
};

const LessonSideNavBarTopSectionContainer = styled.div`
    position: relative;
    width: 100%;
    min-height: 27rem;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
        linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        url(${(props) => props.currentCourse.courseImage.image.url});
    background-size: cover;
    background-position: center;
    padding: ${(props) => props.padding};
`;

const Button = styled.button`
    background-color: transparent;
    color: ${(props) => props.theme.colors.lightText};
    border: 0.1rem solid ${(props) => props.theme.colors.lightText};
    border-radius: 4rem;
    height: 3rem;
    width: ${(props) => props.width};
    margin-bottom: 1rem;
    :hover {
        opacity: 1;
        background-color: #ffffff66;
        cursor: pointer;
    }

    img {
        margin-bottom: -0.2rem;
    }
`;

const ProgressContainer = styled.div`
    position: absolute;
    bottom: 1.5rem;
    left: 0;
    right: 0;
`;

const ProgressBar = styled.div`
    background-color: ${(props) => props.theme.colors.lightBackground};
`;

const ProgressLine = styled.div`
    background-color: ${(props) => props.theme.colors.secondary};
    width: ${(props) => props.progress}%;
    height: 1rem;
`;

export default LessonSideNavBarTopSection;
