import styled from '@emotion/styled';
import React, { useState } from 'react';
import bookSm from 'assets/icons/bookSm.svg';
import clockSm from 'assets/icons/clockSm.svg';
import eyeSm from 'assets/icons/eyeSm.svg';
import P from 'assets/styledComponents/fonts/P';
import FormatCoursesViewCount from 'utils/helpers/FormatCoursesViewCount';
import FormatEstimatedTime from 'utils/helpers/FormatEstimatedTime';
import CourseInfoIcons from './CourseInfoIcons';
import StyledIcon from 'assets/styledComponents/StyledImage';
import calculateEstimatedCourseTime from 'utils/helpers/CalculateEstimatedCourseTime';

const CourseCardDetails = ({
    course,
    progress,
    lastPage,
    lastLesson,
    totalLessons,
    small,
}) => {
    const [isNotInProgress] = useState(progress === '0' || progress === '100');
    const viewCount = FormatCoursesViewCount(
        course?.courses[0]?.viewCount && course.courses[0].viewCount
    );

    const infoIcons = [
        { icon: `${bookSm}`, title: `${totalLessons} Lessons` },
        {
            icon: `${clockSm}`,
            title: FormatEstimatedTime(
                course?.courses[0] &&
                    calculateEstimatedCourseTime(course.courses[0])
            ),
        },
        {
            icon: `${eyeSm}`,
            title: `${viewCount ? viewCount : 0} Views`,
        },
    ];

    return (
        <CourseCardDetailsContainer>
            {isNotInProgress && !small ? (
                <CourseInfoIconsContainer className="flex-space-between">
                    <CourseInfoIcons infoIcons={infoIcons} />
                </CourseInfoIconsContainer>
            ) : (
                !isNotInProgress && (
                    <>
                        <ProgressContainer className="flex-center-aligned">
                            <ProgressBar small={small}>
                                <ProgressLine progress={progress} />
                            </ProgressBar>
                            <P
                                color="greyText"
                                marginLeft="0.5rem"
                                className="petit-title-2"
                            >
                                {progress}%
                            </P>
                        </ProgressContainer>
                        <Metadata small={small}>
                            <StyledIcon
                                marginRight="0.5rem"
                                src={bookSm}
                                alt="book icon"
                            />
                            <span>
                                <P
                                    className="maxOneLineText petit-title-2"
                                    color="greyText"
                                    marginBottom="0.1rem"
                                >
                                    {lastPage}. {lastLesson}
                                </P>
                            </span>
                        </Metadata>
                    </>
                )
            )}
        </CourseCardDetailsContainer>
    );
};

const CourseCardDetailsContainer = styled.div`
    margin-top: 1.7rem;
`;

const CourseInfoIconsContainer = styled.div`
    margin-top: 2rem;
    /* icons should be fixed to bottom of course card so they dont move when title or description line nums vary */
    position: absolute;
    bottom: 0;
`;

const ProgressContainer = styled.div`
    position: absolute;
    bottom: 2.1rem;
`;

const ProgressBar = styled.div`
    background-color: ${(props) => props.theme.colors.lightGreyBackground};
    border-radius: 1rem;
    height: 0.8rem;
    width: ${(props) => (props.small ? '9.6rem' : '27rem')};
`;

const ProgressLine = styled.div`
    background-color: ${(props) => props.theme.colors.secondary};
    width: ${(props) => (props.progress < 3 ? 3 : props.progress)}%;
    max-width: 100%;
    height: 0.8rem;
    border-radius: 1rem;
`;

const Metadata = styled.span`
    margin-top: ${(props) => (props.small ? '0.1rem' : '0.9rem')};
    display: flex;
    align-items: center;
    /* icons should be fixed to bottom of course card so they dont move when title or description line nums vary */
    position: absolute;
    bottom: 0;
`;

export default CourseCardDetails;
