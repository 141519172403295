import styled from '@emotion/styled';
import React from 'react';

const MyStudyTracksCardPlaceholder = () => {
    return <TracksCardPlaceholder />;
};

const TracksCardPlaceholder = styled.div`
    border: 0.3rem dashed ${(props) => props.theme.colors.lightBackground};
    border-radius: 2rem;
    height: 11.2rem;
    width: 100%;
    margin-bottom: 2.2rem;
`;

export default MyStudyTracksCardPlaceholder;
