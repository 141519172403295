import { React } from 'react';
import styled from '@emotion/styled';
import clockMd from '../../assets/icons/clockMd.svg';
import coursesMd from '../../assets/icons/coursesMd.svg';
import ShowIconsSection from '../../utils/helpers/ShowIconsSection';
import Header1 from '../../assets/styledComponents/fonts/Header1';
import Header2 from '../../assets/styledComponents/fonts/Header2';
import Body1 from '../../assets/styledComponents/fonts/Body1';
import FormatEstimatedTime from '../../utils/helpers/FormatEstimatedTime';

const IndividualTracksPageHeader = ({ data, isMobile }) => {
    const infoIcons = [
        {
            icon: `${coursesMd}`,
            title: `${
                data &&
                data.learningTracks &&
                data.learningTracks[0].courses.length
            } MODULES`,
        },
        {
            icon: `${clockMd}`,
            title: FormatEstimatedTime(
                data &&
                    data.learningTracks &&
                    data.learningTracks[0].estimatedTimeInMinutes
            ),
        },
    ];

    return (
        <HeaderContainer isMobile={isMobile}>
            {data && data.learningTracks[0].subtitle.length > 0 && (
                <Subtitle>{data.learningTracks[0].subtitle}</Subtitle>
            )}
            {!isMobile ? (
                <Header1 color="primary" marginBottom="4.8rem">
                    {data && data.learningTracks[0].trackTitle}
                </Header1>
            ) : (
                <Header2 color="primary" marginBottom="4.8rem">
                    {data && data.learningTracks[0].trackTitle}
                </Header2>
            )}
            {ShowIconsSection(infoIcons)}
            <Body1>{data && data.learningTracks[0].trackDescription}</Body1>
        </HeaderContainer>
    );
};

const HeaderContainer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 7.4rem;
    padding-top: 8rem;
    max-width: 76rem;
`;

const Subtitle = styled.h3`
    font-family: 'EBGaramondSemiBoldItalic';
    font-weight: 600;
    font-size: 3.3rem;
    color: ${(props) => props.theme.colors.greyText};
    margin-bottom: 0.5rem;
`;

export default IndividualTracksPageHeader;
