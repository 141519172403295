import React from 'react';
import course7BookPages from 'types/course7BookPages';
import Container from 'assets/styledComponents/Container';
import { PageContainer } from './SharedComponents';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';

const ShowPage = ({ activePage, setActivePage }) => {
    const setPage = () => {
        switch (activePage) {
            case course7BookPages.ONE:
                return <Step1 setActivePage={setActivePage} />;
            case course7BookPages.TWO:
                return <Step2 setActivePage={setActivePage} />;
            case course7BookPages.THREE:
                return <Step3 setActivePage={setActivePage} />;
            case course7BookPages.FOUR:
                return <Step4 setActivePage={setActivePage} />;

            default:
                return <Step1 setActivePage={setActivePage} />;
        }
    };
    return (
        <PageContainer>
            <Container className="flex" height="100%">
                {setPage()}
            </Container>
        </PageContainer>
    );
};

export default ShowPage;
