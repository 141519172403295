/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { GET_LANDING_PAGE_HERO } from 'graphql/queries';
import styled from '@emotion/styled';
import SignInForm from 'components/SignInAndSignUp/SignInForm';
import SignUpForm from '../SignInAndSignUp/SignUpForm';
import StyledLink from 'assets/styledComponents/StyledLink';
import { BackgroundUnderBorderRadius } from './SharedLandingPageComponents';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';
import H1 from 'assets/styledComponents/fonts/H1';
import FilledButtonMd from 'assets/styledComponents/buttons/FilledButtonMd';
import MobileSignInForm from 'components/SignInAndSignUp/MobileSignInForm';
import MobileSignUpForm from 'components/SignInAndSignUp/MobileSignUpForm';
import Container from 'assets/styledComponents/Container';

const Hero = () => {
    const { data, loading, error } = useQuery(GET_LANDING_PAGE_HERO);
    const [showSignInForm, setShowSignInForm] = useState(true);
    const [showMobileSignInForm, setShowMobileSignInForm] = useState(false);
    const [showMobileSignUpForm, setShowMobileSignUpForm] = useState(false);
    const [heroImageNum, setHeroImageNum] = useState(0);

    const [globalState] = useGlobalContext();
    let userData = globalState.user;

    // Prevent background content scrolling when mobile signin or signup open
    if (showMobileSignUpForm || showMobileSignInForm) {
        document.body.style.overflow = 'hidden';
    } else if (!showMobileSignUpForm && !showMobileSignInForm) {
        document.body.style.overflow = 'unset';
    }

    useEffect(() => {
        const timer = setInterval(() => {
            setHeroImageNum((count) => count + 1);
        }, 10000);

        return () => clearInterval(timer);
    }, []);

    // show empty container while loading to prevent other component's animation from starting before if they load faster than the hero
    if (loading) return <Container height="100vh" width="100vw" />;

    if (error) return <p>Error, can not fetch data</p>;

    const HeroImagesArray = data?.landingPage?.hero?.image;

    const OptimzedHeroImagesArray = HeroImagesArray.map((image) => {
        let optimizedImageUrl = image?.formats?.large
            ? image.formats.large.url
            : image?.url;
        return optimizedImageUrl;
    });

    const currentHeroImage =
        OptimzedHeroImagesArray[heroImageNum % OptimzedHeroImagesArray.length];

    return (
        <HeroOuterWrapper>
            <BackgroundUnderBorderRadius role="presentation" bottom={true} />
            <HeroWrapper
                className="below-navbar flex"
                devicesize={globalState.deviceSize}
            >
                <HeroBackgroundImage src={currentHeroImage} />
                {globalState.deviceSize !== 'mobile' ? (
                    <HeroContentContainer devicesize={globalState.deviceSize}>
                        {showSignInForm ? (
                            <SignInForm
                                setShowSignInForm={setShowSignInForm}
                                withModal={false}
                            />
                        ) : (
                            <SignUpForm setShowSignInForm={setShowSignInForm} />
                        )}
                    </HeroContentContainer>
                ) : (
                    <>
                        {showMobileSignInForm ? (
                            <MobileSignInForm
                                showMobileSignInForm={showMobileSignInForm}
                                setShowMobileSignInForm={
                                    setShowMobileSignInForm
                                }
                                setShowMobileSignUpForm={
                                    setShowMobileSignUpForm
                                }
                            />
                        ) : showMobileSignUpForm ? (
                            <MobileSignUpForm
                                showMobileSignUpForm={showMobileSignUpForm}
                                setShowMobileSignUpForm={
                                    setShowMobileSignUpForm
                                }
                                setShowMobileSignInForm={
                                    setShowMobileSignInForm
                                }
                            />
                        ) : (
                            ''
                        )}
                        <HeroMobileContentContainer className="flex-column-end-justified">
                            <ContentAboveGradient>
                                <H1 className="header-3">
                                    {userData ? 'Welcome' : 'Learn'}
                                </H1>{' '}
                                <H1 className="header-3-serif">
                                    {userData
                                        ? userData.firstName + '!'
                                        : 'Diabetes.'}
                                </H1>
                                {userData ? (
                                    <FilledButtonMd
                                        margin="0.8rem 0"
                                        width="100%"
                                        onClick={() =>
                                            setShowMobileSignInForm(true)
                                        }
                                    >
                                        <StyledLink to="/dashboard">
                                            My Dashboard
                                        </StyledLink>
                                    </FilledButtonMd>
                                ) : (
                                    <>
                                        <FilledButtonMd
                                            margin="0.8rem 0"
                                            width="100%"
                                            onClick={() =>
                                                setShowMobileSignInForm(true)
                                            }
                                        >
                                            SIGN IN
                                        </FilledButtonMd>
                                        <Container className="petit-title-2 flex-center-justified">
                                            <p>NOT A MEMBER?&nbsp;</p>
                                            <Button
                                                type="button"
                                                className="petit-title-2"
                                                onClick={() =>
                                                    setShowMobileSignUpForm(
                                                        true
                                                    )
                                                }
                                            >
                                                SIGN UP
                                            </Button>
                                        </Container>
                                    </>
                                )}
                            </ContentAboveGradient>
                        </HeroMobileContentContainer>
                    </>
                )}
            </HeroWrapper>
        </HeroOuterWrapper>
    );
};

// outer wrapper needed to show colored background behind rounded corner
const HeroOuterWrapper = styled.div`
    position: relative;
`;

const HeroWrapper = styled.div`
    max-height: 76rem;
    position: relative;
    overflow: hidden;
    padding: ${(props) =>
        props.devicesize === 'mobile'
            ? 0
            : props.devicesize === 'tablet'
            ? '0 10rem'
            : '0 14rem'};
    border-bottom-right-radius: ${(props) =>
        props.devicesize === 'desktopMd'
            ? '25rem'
            : props.devicesize === 'mobile'
            ? '4rem'
            : '17rem'};
    margin-top: ${(props) =>
        props.devicesize === 'mobile'
            ? props.theme.spaces.navbarMobile
            : props.devicesize === 'tablet'
            ? props.theme.spaces.navbarTablet
            : props.theme.spaces.navbar};
    height: ${(props) =>
        props.devicesize === 'mobile'
            ? `calc(100vh - ${props.theme.spaces.navbarMobile})`
            : props.devicesize === 'tablet'
            ? `calc(100vh - ${props.theme.spaces.navbarTablet})`
            : `calc(100vh - ${props.theme.spaces.navbar})`};
`;

const HeroBackgroundImage = styled.img`
    object-fit: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
`;

const HeroContentContainer = styled(Container)`
    position: relative;
    margin-top: ${(props) =>
        props.devicesize === 'tablet'
            ? props.theme.spaces.navbarTablet
            : props.theme.spaces.navbar};
`;

const HeroMobileContentContainer = styled.section`
    position: relative;
    color: ${(props) => props.theme.colors.lightText};
    width: 100%;

    :after {
        content: '';
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
            360deg,
            rgba(0, 0, 0, 0.5) 0%,
            rgba(0, 0, 0, 0) 82.41%
        );
    }
`;

const ContentAboveGradient = styled.div`
    z-index: 1;
    padding: 0 2.4rem 4rem;
`;

const Button = styled.button`
    background: transparent;
    color: ${(props) => props.theme.colors.tertiary};
    :hover {
        cursor: pointer;
        opacity: 0.7;
    }
`;

export default Hero;
