import { React } from 'react';
import styled from '@emotion/styled';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';

const SignInOrUpContainer = ({
    children,
    borderStyle,
    withModal,
    isSignUp,
    inView,
    innerRef,
}) => {
    const [globalState] = useGlobalContext();
    const { deviceSize } = globalState;
    const isDesktop = deviceSize === 'desktopSm' || deviceSize === 'desktopMd';

    return (
        <Container
            className={
                !withModal && inView
                    ? 'card-shadow flex-column animate-form'
                    : 'card-shadow flex-column'
            }
            borderStyle={withModal ? '2rem' : borderStyle}
            withModal={withModal}
            isSignUp={isSignUp}
            ref={innerRef}
            isDesktop={isDesktop}
        >
            {children}
        </Container>
    );
};

const Container = styled.div`
    min-height: ${(props) => (props.isSignUp ? `62.5rem` : `48rem`)};
    width: 41rem;
    background-color: ${(props) => props.theme.colors.lightBackground};
    border-radius: ${(props) =>
        props.withModal ? '2rem' : '0 7rem 2rem 2rem'};
    top: ${(props) => (props.isSignUp ? '2.6rem' : '8rem')};
    position: absolute;
    z-index: 1;
    padding: ${(props) =>
        props.isDesktop ? '5rem 4.8rem 5.2rem' : '5rem 3.8rem 5.2rem'};

    transform: ${(props) =>
        !props.isSignUp && !props.withModal && `translateY(30%)`};
    transition: transform 400ms ease-in;

    &.animate-form {
        transform: translateY(0%);
    }
`;
export default SignInOrUpContainer;
