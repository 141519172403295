import React from 'react';
import Layout from 'types/layout';
import calculateTotalLessons from 'utils/helpers/CalculateTotalLessons';
import filterCoursesBySearchValue from 'utils/helpers/filterCoursesBySearchValue';
import CourseCard from '../CourseCard/CourseCard';
import CourseCardListView from '../CourseCard/CourseCardListView';
import CourseSearchNoResult from 'assets/styledComponents/CourseSearchNoResult';

const ShowAllCourses = ({ allCourses, searchValue, progress, view }) => {
    let filteredItems = allCourses
        ? filterCoursesBySearchValue(allCourses, searchValue).map((c, idx) => {
              // use Strapi small optimized image size if available,
              // else use original uploaded image
              let smallImage =
                  c.courseImage.image.formats &&
                  c.courseImage.image.formats.small &&
                  c.courseImage.image.formats.small.url;
              let nonOptimizedImage = c.courseImage.image.url;

              return view === Layout.CARD_VIEW ? (
                  <CourseCard
                      course={c}
                      key={c.id}
                      imageURL={smallImage ? smallImage : nonOptimizedImage}
                      title={c.title}
                      description={c.description}
                      id={c.id}
                      slug={c.slug}
                      totalLessons={calculateTotalLessons(c)}
                      progress={progress}
                      width="36rem"
                      height="38rem"
                      imageHeight="20rem"
                      margin="2rem 3rem 2.5rem"
                      progressBarWidth="27rem"
                      marginBottom="4rem"
                  />
              ) : (
                  <CourseCardListView
                      course={c}
                      key={c.id}
                      view={Layout.LIST_VIEW}
                      title={c.title}
                      description={c.description}
                      slug={c.slug}
                      progress={'0'}
                      totalLessons={calculateTotalLessons(c)}
                      id={c.id}
                      first={idx === 0}
                      last={
                          idx ===
                          filterCoursesBySearchValue(allCourses, searchValue)
                              .length -
                              1
                      }
                  />
              );
          })
        : '';
    return filteredItems && filteredItems.length === 0 ? (
        <CourseSearchNoResult searchvalue={searchValue} />
    ) : (
        filteredItems
    );
};

export default ShowAllCourses;
