import React from 'react';
import DashboardMobileNavbar from './DashboardMobileNavbar';
import SideNavBar from './SideNavBar';
import dbHomeNav from '../../assets/icons/dbHomeNav.svg';
import dbMyCoursesNav from '../../assets/icons/dbMyCoursesNav.svg';
import dbStudyPlanNav from '../../assets/icons/dbStudyPlanNav.svg';
import dbMyTracksNav from '../../assets/icons/dbMyTracksNav.svg';
import dbSavedNav from '../../assets/icons/dbSavedNav.svg';
import dbHomeNavActive from '../../assets/icons/dbHomeNavActive.svg';
import dbMyCoursesNavActive from '../../assets/icons/dbMyCoursesNavActive.svg';
import dbStudyPlanNavActive from '../../assets/icons/dbStudyPlanNavActive.svg';
import dbMyTracksNavActive from '../../assets/icons/dbMyTracksNavActive.svg';
import dbSavedNavActive from '../../assets/icons/dbSavedNavActive.svg';

const DashboardNavbar = ({ isDesktop, isTablet }) => {
    const dashboardNavItems = [
        {
            icon: dbHomeNav,
            iconActive: dbHomeNavActive,
            route: '/dashboard',
            text: 'DASHBOARD',
        },
        {
            icon: dbMyCoursesNav,
            iconActive: dbMyCoursesNavActive,
            route: '/dashboard/mymodules',
            text: 'MY MODULES',
        },
        {
            icon: dbMyTracksNav,
            iconActive: dbMyTracksNavActive,
            route: '/dashboard/mypathways',
            text: 'MY PATHWAYS',
        },
        {
            icon: dbSavedNav,
            iconActive: dbSavedNavActive,
            route: '/dashboard/saved',
            text: 'SAVED',
        },
        {
            icon: dbStudyPlanNav,
            iconActive: dbStudyPlanNavActive,
            route: '/dashboard/studyplan',
            text: 'STUDY PLAN',
        },
    ];
    return (
        <>
            {isDesktop || isTablet ? (
                <SideNavBar dashboardNavItems={dashboardNavItems} />
            ) : (
                <DashboardMobileNavbar dashboardNavItems={dashboardNavItems} />
            )}
        </>
    );
};

export default DashboardNavbar;
