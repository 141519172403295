import React from 'react';
import P from 'assets/styledComponents/fonts/P';
import H3 from 'assets/styledComponents/fonts/H3';

const TipMobile = ({ item }) => {
    return (
        <>
            {' '}
            <H3 className="body-1" marginTop="3.2rem" marginBottom="1.6rem">
                {item.id}. {item.title}
            </H3>
            <P className="body-4">{item.description}</P>
        </>
    );
};

export default TipMobile;
