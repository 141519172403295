import styled from '@emotion/styled';
import React from 'react';
import { useHistory } from 'react-router-dom';
import CardButtonMd from 'assets/styledComponents/buttons/CardButtonMd';

const ButtonSection = ({ progress, slug, lastPage, small }) => {
    const history = useHistory();

    return (
        <ButtonContainer small={small}>
            <CourseCardButtonStyled
                small={small}
                width="100%"
                onClick={() => history.push(`/modules/${slug}/0`)}
            >
                View Syllabus
            </CourseCardButtonStyled>
            {progress === '0' ? (
                <CourseCardButtonStyled
                    small={small}
                    width="100%"
                    onClick={() => history.push(`/modules/${slug}/1`)}
                >
                    Start Module
                </CourseCardButtonStyled>
            ) : progress === '100' ? (
                <CourseCardButtonStyled
                    small={small}
                    width="100%"
                    onClick={() => history.push(`/modules/${slug}/${lastPage}`)}
                >
                    View Module
                </CourseCardButtonStyled>
            ) : (
                <CourseCardButtonStyled
                    small={small}
                    width="100%"
                    onClick={() => history.push(`/modules/${slug}/${lastPage}`)}
                >
                    Resume Module
                </CourseCardButtonStyled>
            )}
        </ButtonContainer>
    );
};

const ButtonContainer = styled.div`
    padding: ${(props) =>
        props.small ? '2.3rem 1rem 3rem' : '3.5rem 5rem 5rem'};
    z-index: 1;
`;

const CourseCardButtonStyled = styled(CardButtonMd)`
    height: ${(props) => (props.small ? '3rem' : '4rem')};

    font-family: ${(props) => props.small && 'DMSansMedium, sans-serif'};
    font-size: ${(props) => props.small && '1.4rem'};

    :first-of-type {
        margin-bottom: ${(props) => (props.small ? '0.5rem' : '1.5rem')};
    }
`;

export default ButtonSection;
