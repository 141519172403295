import styled from '@emotion/styled';
import React from 'react';
import DeleteButton from 'assets/styledComponents/buttons/DeleteButton';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';
import CompletedBadgeContainer from './CompletedBadgeContainer';
import Container from 'assets/styledComponents/Container';
import StyledImage from 'assets/styledComponents/StyledImage';

const CardTopSection = ({
    imageURL,
    title,
    progress,
    hovered,
    handleDelete,
    isDashboard,
    small,
}) => {
    const [globalState] = useGlobalContext();
    return (
        <Container height={small ? '13rem' : '20rem'}>
            <StyledImage
                width="100%"
                height="100%"
                objectFit="cover"
                borderRadius="2rem 2rem 0 0"
                border
                src={imageURL}
                alt={title}
            />
            {progress === '100' ? <CompletedBadgeContainer /> : ''}
            {hovered && globalState.auth && isDashboard ? (
                <DeleteButtonContainer>
                    <DeleteButton handleDelete={handleDelete} />
                </DeleteButtonContainer>
            ) : (
                ''
            )}
        </Container>
    );
};

const DeleteButtonContainer = styled.div`
    position: absolute;
    top: 3rem;
    right: 3rem;
    display: block;
    width: 4.8rem;
    height: 4.8rem;
    z-index: 1;
`;

export default CardTopSection;
