import H3 from 'assets/styledComponents/fonts/H3';
import React from 'react';

const Title = ({ title }) => {
    return (
        <H3 className="header-3-serif" marginBottom="3.2rem">
            {title}
        </H3>
    );
};

export default Title;
