export const activity1Data = [
    { id: '1', label: '1 cup butter' },
    { id: '2', label: '1 cup white sugar' },
    { id: '3', label: '1 cup brown sugar' },
    { id: '4', label: '3 tsp. vanilla extract' },
    { id: '5', label: '2 large eggs' },
    { id: '6', label: '3 cups all-purpose flour' },
    { id: '7', label: '1/2 tsp. baking powder' },
    { id: '8', label: '1 tsp. baking soda' },
    { id: '9', label: '1 tsp. salt' },
    { id: '10', label: '1 cup chocolate chips' },
];
