import H3 from 'assets/styledComponents/fonts/H3';
import React from 'react';
import behaviouralAdviceGroups from 'types/behaviouralAdviceGroups';
import HelpfulBehaviours from './HelpfulBehaviours';
import UnHelpfulBehaviours from './UnhelpfulBehaviours';

const BehaviouralAdviceMobile = ({ devicesize }) => {
    return (
        <>
            <H3 className="body-1" marginTop="4rem" marginBottom="1.6rem">
                {behaviouralAdviceGroups.HELPFUL_BEHAVIOURS}
            </H3>
            <HelpfulBehaviours devicesize={devicesize} />
            <H3 className="body-1" marginTop="4rem" marginBottom="1.6rem">
                {behaviouralAdviceGroups.UNHELPFUL_BEHAVIOURS}
            </H3>
            <UnHelpfulBehaviours devicesize={devicesize} />
        </>
    );
};

export default BehaviouralAdviceMobile;
