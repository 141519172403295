import React from 'react';
import { PageButton } from './SharedComponents';

const NextOrPrevPageButton = ({
    className,
    title,
    setActivePage,
    setActivePageNum,
    setPageToShow,
    setPageToShowNum,
    image,
    alt,
}) => {
    return (
        <PageButton
            className={className}
            onClick={() =>
                setActivePage
                    ? setActivePage(setActivePageNum)
                    : setPageToShow(setPageToShowNum)
            }
        >
            {title === 'PREV' && <img src={image} alt={alt} />}
            {title}
            <br /> PAGE
            {title === 'NEXT' && <img src={image} alt={alt} />}
        </PageButton>
    );
};

export default NextOrPrevPageButton;
