import { React, useState, useEffect } from 'react';
import DesignOne from '../components/PageNotFound/DesignOne';
import DesignTwo from '../components/PageNotFound/DesignTwo';
import DesignThree from '../components/PageNotFound/DesignThree';

const PageNotFound = () => {
    const [design, setDesign] = useState(null);
    let random = Math.ceil(Math.random() * 3);

    useEffect(() => {
        if (random === 1) setDesign(<DesignOne />);
        else if (random === 2) setDesign(<DesignTwo />);
        else if (random === 3) setDesign(<DesignThree />);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <div className="flex-all-centered">{design}</div>;
};

export default PageNotFound;
