import styled from '@emotion/styled';
import React, { useState } from 'react';
import FluidAmountTip from './FluidAmountTip';
import P from 'assets/styledComponents/fonts/P';
import H3 from 'assets/styledComponents/fonts/H3';
import plusSecondary from 'assets/icons/plusSecondary.svg';
import minusSecondary from 'assets/icons/minusSecondary.svg';
import Container from 'assets/styledComponents/Container';

const Tip = ({ item }) => {
    const [isCollapse, setIsCollapse] = useState(true);
    const onCollapseBtnClick = () => {
        setIsCollapse(!isCollapse);
    };
    return (
        <Container marginBottom="1.6rem" marginLeft="0.8rem">
            <Container className="flex-center-aligned">
                <CircularNumber className="body-1 flex-all-centered">
                    {item.id}
                </CircularNumber>
                <TipTitleContainer
                    isCollapse={isCollapse}
                    onClick={() => onCollapseBtnClick()}
                >
                    <H3 className="body-1">{item && item.title}</H3>
                    <img
                        src={isCollapse ? plusSecondary : minusSecondary}
                        alt={
                            isCollapse
                                ? 'an orange plus symbol'
                                : 'an orange minus symbol'
                        }
                    />
                </TipTitleContainer>
            </Container>
            {!isCollapse && (
                <TipDescriptionContainer>
                    {item.id === 5 ? (
                        <FluidAmountTip description={item.description} />
                    ) : (
                        <P className="body-4">{item && item.description}</P>
                    )}
                </TipDescriptionContainer>
            )}
        </Container>
    );
};

const TipTitleContainer = styled.button`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 5.6rem;
    padding: 1.6rem 3.2rem;
    background: ${(props) => props.theme.colors.tertiary};
    border-radius: 2rem;
    margin-left: 2.4rem;
    text-align: left;

    img {
        margin-left: 1rem;
    }
    :hover {
        cursor: pointer;
    }
`;

const TipDescriptionContainer = styled.div`
    /* additional top padding to account for negative top margin */
    padding: 5.2rem 3.2rem 4rem 3.2rem;
    margin-top: -2rem;
    margin-left: 6.4rem;
    border-radius: 0 0 2rem 2rem;
    border: 0.3rem solid ${(props) => props.theme.colors.tertiary};
`;

const CircularNumber = styled.div`
    background: ${(props) => props.theme.colors.secondary};
    color: ${(props) => props.theme.colors.lightText};
    min-height: 4rem;
    min-width: 4rem;
    border-radius: 50%;
    /* z-index to position circle above grey line */
    z-index: 1;
`;

export default Tip;
