import React from 'react';
import styled from '@emotion/styled';

const Video = ({ data }) => {
    return (
        <VideoContainer>
            <Iframe
                title={data && data.videoUrl}
                src={data && data.videoUrl}
                frameBorder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></Iframe>
        </VideoContainer>
    );
};

const VideoContainer = styled.div`
    display: flex;
    justify-content: center;
    margin: 4rem 0;
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    @media screen and (max-width: ${(props) =>
            props.theme.breakPoints.tablet}) {
        margin: 2.4rem 0;
    }
`;

const Iframe = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

export default Video;
