import React from 'react';
import Container from 'assets/styledComponents/Container';
import StyledImage from 'assets/styledComponents/StyledImage';
import P from 'assets/styledComponents/fonts/P';
import months6_9 from 'assets/images/interactive-course5-8/months6_9.svg';
import Title from './Title';
import H1 from 'assets/styledComponents/fonts/H1';

const Months6_9 = ({ devicesize }) => {
    return (
        <Container
            className={
                devicesize === 'mobile' || devicesize === 'tablet'
                    ? 'flex-column-left'
                    : 'flex-start-aligned'
            }
            marginTop={
                devicesize === 'mobile' || devicesize === 'tablet'
                    ? '4.8rem'
                    : ''
            }
        >
            {devicesize === 'mobile' || devicesize === 'tablet' ? (
                <H1 className="header-4">6-9 months old</H1>
            ) : (
                ''
            )}
            <center>
                {' '}
                <StyledImage
                    src={months6_9}
                    alt="baby in feeding chair"
                    marginTop={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? ''
                            : '3.8rem'
                    }
                />
            </center>
            <Container
                marginLeft={
                    devicesize === 'mobile' || devicesize === 'tablet'
                        ? ''
                        : '4rem'
                }
            >
                {devicesize !== 'mobile' && devicesize !== 'tablet' ? (
                    <Title title="6-9 months old" />
                ) : (
                    ''
                )}
                <P
                    className={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? 'body-2'
                            : 'body-4'
                    }
                >
                    There is no need to prepare specialized food for your baby
                    with diabetes. Offer them the same foods as everyone else,
                    in age-appropriate textures when they are ready for solids.
                    Continue to breastfeed as desired.
                </P>
                <P
                    className={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? 'body-2'
                            : 'body-4'
                    }
                    marginTop={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? '2.4rem'
                            : '3.2rem'
                    }
                >
                    When starting to eat solid foods, it is unlikely that your
                    baby will eat amounts large enough to need insulin for their
                    food; but in some cases, insulin may be required. Discuss
                    with your care team for further guidance as needed.
                </P>
                <P
                    className={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? 'body-2'
                            : 'body-4'
                    }
                    marginTop={
                        devicesize === 'mobile' || devicesize === 'tablet'
                            ? '2.4rem'
                            : '3.2rem'
                    }
                >
                    Consistent feeding times can help set your baby up for
                    success in terms of planning the insulin dose schedule.
                </P>
            </Container>
        </Container>
    );
};

export default Months6_9;
