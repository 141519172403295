import React from 'react';
import styled from '@emotion/styled';
import H3 from 'assets/styledComponents/fonts/H3';
import P from 'assets/styledComponents/fonts/P';
import MixedFontSectionHeader from './MixedFontSectionHeader';
import highlights3 from 'assets/images/highlights3.svg';
import rightArrowLink from 'assets/icons/rightArrowLink.svg';
import StyledLink from 'assets/styledComponents/StyledLink';
import Container from 'assets/styledComponents/Container';
import { useInView } from 'react-intersection-observer';
import ScrollToTop from 'utils/helpers/ScrollToTop';
import { BackgroundUnderBorderRadius } from './SharedLandingPageComponents';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';

const Highlights = ({
    coloredBackground,
    flexDirection,
    imageURL,
    imageAltText,
    title,
    content,
    num,
    animatedImg,
    animatedImgAltText,
}) => {
    const [globalState] = useGlobalContext();
    let { deviceSize } = globalState;

    const isTablet = Boolean(deviceSize === 'tablet');
    const isMobile = Boolean(deviceSize === 'mobile');
    const isDesktop = Boolean(!isTablet && !isMobile);

    const [ref, inView] = useInView({
        threshold: 0,
        triggerOnce: true,
    });

    return (
        <Container position="relative" overflow="hidden">
            <BackgroundUnderBorderRadius
                role="presentation"
                num={num}
                coloredBackground={coloredBackground}
            />
            <BackgroundContainer
                className="flex-column-centered"
                coloredBackground={coloredBackground}
                isMobile={isMobile}
                num={num}
            >
                {num === 1 ? (
                    <MixedFontSectionHeader
                        inView={inView}
                        innerRef={ref}
                        title="Why LearnDiabetes"
                        whyLD={true}
                    />
                ) : (
                    ''
                )}
                <Container
                    flexDirection={flexDirection}
                    className={deviceSize === 'mobile' ? '' : 'flex'}
                    width="100%"
                    maxWidth="116rem"
                >
                    {isMobile && (
                        <StyledImage
                            src={imageURL}
                            alt={imageAltText}
                            num={num}
                            isMobile={isMobile}
                        />
                    )}
                    <ContentSide
                        className="flex-column-center-justified"
                        num={num}
                        isMobile={isMobile}
                    >
                        <ContentContainer
                            role="presentation"
                            inView={inView}
                            ref={ref}
                            className={inView ? 'animate-text' : ''}
                            isMobile={isMobile}
                        >
                            <StyledNum className="header-1" color="primary">
                                {'0' + num}
                            </StyledNum>
                            <H3 className="header-5" marginBottom="2.4rem">
                                {title}
                            </H3>
                            <P className="body-4" marginBottom="2.4rem">
                                {content}
                            </P>
                            {/* LINKS */}
                            {num === 2 || num === 3 ? (
                                <LinkContainer
                                    className="flex-center-aligned"
                                    onClick={() =>
                                        num === 3 ? ScrollToTop() : ''
                                    }
                                >
                                    <StyledLink
                                        className="petit-title-1"
                                        to={num === 2 ? '/modules' : ''}
                                    >
                                        {num === 2
                                            ? 'See the modules page'
                                            : 'Sign up to study'}
                                    </StyledLink>
                                    <img
                                        src={rightArrowLink}
                                        alt="orange arrow pointing right"
                                    />
                                </LinkContainer>
                            ) : (
                                ''
                            )}

                            {/* Steps IMAGE */}
                            {num === 3 && isDesktop ? (
                                <Section3ContentAnimatedImg
                                    src={highlights3}
                                    alt="An illustration of fruit and a glucose monitor on steps"
                                />
                            ) : (
                                ''
                            )}
                        </ContentContainer>
                    </ContentSide>
                    <ImageSide
                        inView={inView}
                        num={num}
                        ref={ref}
                        className={inView ? 'animate-images' : ''}
                    >
                        {!isMobile && (
                            <StyledImage
                                src={imageURL}
                                alt={imageAltText}
                                num={num}
                            />
                        )}
                        {isDesktop && (
                            <StyledAnimatedImage
                                src={animatedImg}
                                alt={animatedImgAltText}
                                num={num}
                            />
                        )}
                    </ImageSide>
                </Container>
            </BackgroundContainer>
        </Container>
    );
};

const ContentContainer = styled.div`
    min-width: 25.6rem;
    max-width: ${(props) => (props.isMobile ? '100%' : '33.2rem')};
    opacity: 0;
    transition: opacity 500ms ease-in;

    &.animate-text {
        opacity: 1;
    }
`;

const BackgroundContainer = styled.div`
    width: 100%;
    overflow: hidden;

    background-color: ${(props) =>
        props.coloredBackground
            ? props.theme.colors.tertiary
            : props.theme.colors.lightBackground};

    padding-left: ${(props) => (props.isMobile ? '2.4rem' : '12rem')};
    padding-right: ${(props) => (props.isMobile ? '2.4rem' : '12rem')};

    /* variable styling  */
    border-top-right-radius: ${(props) =>
        props.coloredBackground && !props.isMobile && `15rem`};
    border-top-left-radius: ${(props) =>
        !props.coloredBackground && !props.isMobile && `15rem`};

    padding-top: ${(props) =>
        props.num === 1 && !props.isMobile
            ? `11.6rem`
            : props.num === 2 && !props.isMobile
            ? `15rem`
            : props.num === 3 && !props.isMobile
            ? `14.4rem`
            : props.isMobile && '5.6rem'};

    padding-bottom: ${(props) =>
        props.num === 1 && !props.isMobile
            ? `9rem`
            : (props.num === 1 || props.num === 2) && props.isMobile
            ? '5.6rem'
            : props.num === 2 && !props.isMobile
            ? `9rem`
            : props.num === 3 && `10.5rem`};

    @media screen and (max-width: ${(props) =>
            props.theme.breakPoints.desktopMd}) {
        padding-bottom: ${(props) => props.num === 3 && '9rem'};
    }
`;

const ContentSide = styled.div`
    color: ${(props) => props.theme.colors.primaryDarkest};
    position: relative;

    /* variable styling  */
    padding-right: ${(props) =>
        props.num === 1 ? '6.7rem' : props.num === 3 && '12.4rem'};

    @media screen and (max-width: ${(props) =>
            props.theme.breakPoints.desktopMd}) {
        padding-right: ${(props) =>
            props.num === 1 ? '3.2rem' : props.num === 3 && '4rem'};
    }

    padding-left: ${(props) => props.num === 2 && !props.isMobile && '12.4rem'};

    @media screen and (max-width: ${(props) =>
            props.theme.breakPoints.desktopMd}) {
        padding-left: ${(props) =>
            props.num === 2 && !props.isMobile && '7.2rem'};
    }

    /* width 116rem minus image section width */
    width: ${(props) =>
        props.isMobile
            ? '100%'
            : props.num === 1
            ? '40rem'
            : props.num === 2
            ? '49rem'
            : props.num === 3 && '45.4'};
`;

const StyledNum = styled(H3)`
    position: relative;
    margin-bottom: 2.8rem;

    :before {
        position: absolute;
        content: ' ';
        width: 7.6rem;
        height: 0.2rem;
        background: ${(props) => props.theme.colors.primary};
        bottom: 0.5rem;
    }
`;

const Section3ContentAnimatedImg = styled.img`
    margin-top: 4.8rem;
    width: 25rem;
    float: right;
`;

const LinkContainer = styled.div`
    color: ${(props) => props.theme.colors.secondary};
    text-transform: uppercase;

    img {
        margin-left: 1.2rem;
        width: 1.6rem;
        height: 2.4rem;
    }

    :hover {
        opacity: 0.7;
    }
`;

const ImageSide = styled.div`
    position: relative;

    /* variable styling  */
    width: ${(props) =>
        props.num === 1
            ? '76rem'
            : props.num === 2
            ? '67rem'
            : props.num === 3 && '70.6rem'};

    @media screen and (max-width: ${(props) =>
            props.theme.breakPoints.desktopMd}) {
        width: ${(props) =>
            props.num === 1
                ? '52.8rem'
                : props.num === 2
                ? '52.8rem'
                : props.num === 3 && '52.8rem'};
    }

    transform: translateY(50%);
    transition: transform 300ms ease-in;

    &.animate-images {
        transform: translateY(0%);
    }
`;

const StyledImage = styled.img`
    float: ${(props) => (props.num === 2 ? 'right' : 'left')};

    margin-left: ${(props) => (props.isMobile ? '-2.6rem' : '')};

    margin-right: ${(props) =>
        props.num === 1
            ? '-8rem'
            : props.num === 2 && !props.isMobile
            ? '3rem'
            : props.num === 2 && props.isMobile
            ? '-2.6rem'
            : props.num === 3 && '-3rem'};

    width: ${(props) =>
        props.num === 1 && !props.isMobile
            ? '76rem'
            : props.num === 1 && props.isMobile
            ? '48rem'
            : props.num === 2 && !props.isMobile
            ? '83.3rem'
            : props.num === 2 && props.isMobile
            ? '42rem'
            : props.num === 3 && !props.isMobile
            ? '88.5rem'
            : props.num === 3 && props.isMobile && '44rem'};
    height: ${(props) =>
        props.num === 1 && !props.isMobile
            ? '57.7rem'
            : props.num === 2 && !props.isMobile
            ? '58rem'
            : props.num === 3 && !props.isMobile
            ? '65.6rem'
            : props.isMobile && 'auto'};
`;

const StyledAnimatedImage = styled.img`
    position: absolute;
    right: ${(props) => (props.num === 1 || props.num === 3) && '-3rem'};
    left: ${(props) => props.num === 2 && '-3rem'};
    bottom: 0;
`;

export default Highlights;
