import styled from '@emotion/styled';

const FilledButtonMd = styled.button`
    font-family: 'MontserratSemiBold', sans-serif;
    font-size: 1.8rem;
    height: 4rem;
    border-radius: 4rem;

    background-color: ${(props) => props.theme.colors.secondary};
    color: ${(props) => props.theme.colors.lightText};
    border: 0.1rem solid ${(props) => props.theme.colors.secondary};

    display: flex;
    align-items: center;
    justify-content: center;

    /* customizable */
    margin: ${(props) => props.margin};
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
    width: ${(props) => props.width};
    min-width: ${(props) => props.minWidth};
    max-width: ${(props) => props.maxWidth};
    padding: ${(props) => props.padding};

    :hover {
        opacity: 0.4;
        cursor: pointer;
    }

    :active {
        opacity: 1;
    }

    &.disabledBtn {
        background-color: ${(props) => props.theme.colors.lightGreyBackground};
        border: 0.1rem solid
            ${(props) => props.theme.colors.lightGreyBackground};
        color: ${(props) => props.theme.colors.greyText};

        :hover {
            cursor: default;
            opacity: 1;
        }
    }
`;

export default FilledButtonMd;
