import React from 'react';
import { ListItem } from './SharedComponents';

const HelpfulBehaviours = ({ devicesize }) => {
    const helpfulBehaviours = [
        `Suggest coping strategies and support child to implement them e.g. distraction techniques`,
        `Modelling being calm, find a way to manage your own worries/anxieties appropriately`,
        `Providing distraction and support`,
        `Give honest answers to your child's questions e.g. it may feel like a pinch`,
        `Bringing a familiar toy or blanket`,
        `Praising your child e.g. for holding arm or leg still`,
    ];
    return (
        <ul>
            {helpfulBehaviours.map((item) => (
                <ListItem
                    className="body-4 flex-center-aligned"
                    key={item}
                    devicesize={devicesize}
                >
                    {item}
                </ListItem>
            ))}
        </ul>
    );
};

export default HelpfulBehaviours;
