import styled from '@emotion/styled';
import React from 'react';
import PetitTitle2 from '../../assets/styledComponents/fonts/PetitTitle2';
import doneBadge from '../../assets/icons/finishedCourseSmall.svg';
import LinkWrappingButton from '../../assets/styledComponents/buttons/LinkWrappingButton';
import FilledButtonSm from '../../assets/styledComponents/buttons/FilledButtonSm';
import TrackIconAndInfo from '../../assets/styledComponents/TrackIconAndInfo';
import TrackImage from '../../assets/styledComponents/StyledImage';

const TrackCardSmall = ({
    numOfCourses,
    estimatedTime,
    numOfViews,
    slug,
    trackTitle,
    trackImage,
    trackImageAlternativeText,
    progress,
    isMobile,
}) => {
    return (
        <CardContainer className="card-shadow">
            {progress === 100 ? <StyledDoneBadge src={doneBadge} /> : ''}
            <TrackImage
                height="100%"
                width="11.5rem"
                objectFit="cover"
                borderRadius="2rem 0rem 0rem 2rem"
                src={trackImage}
                alt={trackImageAlternativeText}
            />
            <InfoSection>
                <div className="flex-center-aligned">
                    <PetitTitle2
                        className="maxOneLineText"
                        marginBottom="0.9rem"
                    >
                        {trackTitle}
                    </PetitTitle2>
                </div>
                {progress > 0 && progress < 100 ? (
                    <div className="flex-center-aligned">
                        <ProgressBarBackground>
                            <ProgressBarFill progress={progress} />
                        </ProgressBarBackground>
                        <PetitTitle2 color="greyText">{progress}%</PetitTitle2>
                    </div>
                ) : (
                    <TrackIconAndInfo
                        numOfCourses={numOfCourses}
                        estimatedTime={estimatedTime}
                        numOfViews={numOfViews}
                    />
                )}
                {isMobile ? (
                    ''
                ) : (
                    <ButtonContainer className="btn-container">
                        <LinkWrappingButton to={`/pathway/${slug}`}>
                            <FilledButtonSm width="26.9rem">
                                View Pathway Syllabus
                            </FilledButtonSm>
                        </LinkWrappingButton>
                    </ButtonContainer>
                )}
            </InfoSection>
        </CardContainer>
    );
};

const CardContainer = styled.div`
    height: 11.2rem;
    border-radius: 2rem;
    display: flex;
    margin-bottom: 2.2rem;
    position: relative;
    background-color: ${(props) => props.theme.colors.lightBackground};
    :hover .btn-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border-radius: 2rem;
    }
`;

const InfoSection = styled.div`
    display: flex;
    flex-direction: column;
    margin: 3rem 3.5rem 3.5rem 2rem;
    width: 100%;
`;

const StyledDoneBadge = styled.img`
    position: absolute;
    top: 0;
    left: 1.7rem;
`;

const ProgressBarBackground = styled.div`
    width: 90%;
    height: 0.8rem;
    border-radius: 1rem;
    background: ${(props) => props.theme.colors.lightGreyBackground};
    margin-right: 0.8rem;
`;

const ProgressBarFill = styled.div`
    width: ${(props) => props.progress}%;
    height: 0.8rem;
    border-radius: 1rem;
    background: ${(props) => props.theme.colors.secondary};
`;

// overlay that displays the button when card is hovered on
const ButtonContainer = styled.div`
    width: 100%;
    background: linear-gradient(
        84.82deg,
        rgba(255, 255, 255, 0) -19.76%,
        rgba(255, 255, 255, 0.443182) 0.86%,
        #ffffff 28.29%
    );
    height: 100%;
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    display: none;
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
    padding: 4rem 3rem;
`;

export default TrackCardSmall;
