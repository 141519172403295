import step2removeCap from 'assets/images/interactiveBook-course2/step2removeCap.svg';
import step2exposeNeedle from 'assets/images/interactiveBook-course2/step2exposeNeedle.svg';
import step2insertLancet from 'assets/images/interactiveBook-course2/step2addCap.svg';
import step2addCap from 'assets/images/interactiveBook-course2/step2addCap.svg';

export const Step2ListItems = [
    {
        id: 1,
        text: 'Take your lancing device and remove the cap.',
        img: step2removeCap,
        imgAltText:
            'Illustration of an Chevron pointing up beside a cap that is above a lancing device',
    },
    {
        id: 2,
        text: 'Insert a lancet into the lancing device.',
        img: step2insertLancet,
        imgAltText:
            'Illustration of a lancet being inserted into a lancing device',
    },
    {
        id: 3,
        text: 'Once it is secured, twist the plastic top and remove the cap to expose the needle.',
        img: step2exposeNeedle,
        imgAltText: 'Illustration of a lancing device with a needle in it',
    },
    {
        id: 4,
        text: 'Place the cap back onto the lancing device.',
        img: step2addCap,
        imgAltText:
            'Illustration of a chevron pointing down beside a cap that is above a lancing device',
    },
];
