import React from 'react';
import LinkButton from '../../assets/styledComponents/buttons/LinkButton';
import PetitTitle2 from '../../assets/styledComponents/fonts/PetitTitle2';
import Container from '../../assets/styledComponents/Container';

const SectionSubHeader = ({
    title,
    linkTitle,
    path,
    marginTop,
    paddingBottom,
}) => {
    return (
        <Container
            className="flex-space-between"
            color="darkText"
            paddingBottom={paddingBottom}
            marginTop={marginTop}
        >
            <PetitTitle2>{title}</PetitTitle2>
            <LinkButton linkTitle={linkTitle} path={path} />
        </Container>
    );
};

export default SectionSubHeader;
