import React, { useState } from 'react';
import styled from '@emotion/styled';
import profileIcon from '../../assets/icons/profileIcon.svg';
import profileIconHovered from '../../assets/icons/profileIconHovered.svg';
import ShowProfileAvatar from '../../utils/helpers/ShowProfileAvatar';
import AvatarModal from './AvatarModal';
import H1 from 'assets/styledComponents/fonts/H1';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';

const ProfilePic = ({ data }) => {
    const [globalState] = useGlobalContext();
    const [showModal, setShowModal] = useState(false);

    const isMobile = Boolean(globalState.deviceSize === 'mobile');

    // Prevent background content scrolling when modal open
    if (showModal) {
        document.body.style.overflow = 'hidden';
    } else if (!showModal) {
        document.body.style.overflow = 'unset';
    }

    return (
        <ColoredContainer isMobile={isMobile}>
            {showModal && (
                <AvatarModal
                    setShowModal={setShowModal}
                    showModal={showModal}
                    data={data}
                    isMobile={isMobile}
                />
            )}
            <AvatarContainer>
                <Avatar src={ShowProfileAvatar(data)} alt="Avatar" />
                <AvatarButton
                    pic={profileIcon}
                    picHovered={profileIconHovered}
                    onClick={() => setShowModal(true)}
                />
            </AvatarContainer>

            <H1
                className="header-3"
                marginBottom={isMobile ? '4.8rem' : '7.2rem'}
            >
                {data.firstName} {data.lastName}
            </H1>
        </ColoredContainer>
    );
};

const ColoredContainer = styled.div`
    width: 100%;
    background-color: ${(props) => props.theme.colors.tertiary};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 4.8rem;
    margin-bottom: ${(props) => (props.isMobile ? '3.2rem' : '10rem')};
`;

const AvatarContainer = styled.div`
    position: relative;
`;

const AvatarButton = styled.button`
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background: url(${(props) => props.pic});
    position: absolute;
    right: 1.5rem;
    bottom: 1.5rem;
    :hover {
        cursor: pointer;
        background: url(${(props) => props.picHovered});
    }
    :active {
        background: url(${(props) => props.pic});
    }
`;

const Avatar = styled.img`
    width: 17rem;
    height: 17rem;
    border-radius: 50%;
    margin-bottom: 2rem;
    object-fit: cover;
`;

export default ProfilePic;
