import { useLazyQuery } from '@apollo/client';
import { GET_FULL_PROFILE } from '../graphql/queries';
import { useGlobalContext } from '../GlobalContext/GlobalProvider';
import { saveUser } from '../GlobalContext/actionVariables';

const useUserData = (id) => {
    const userId = id ? id : null;
    const [, dispatch] = useGlobalContext();

    const [getUserData] = useLazyQuery(GET_FULL_PROFILE, {
        /* Apollo Client executes the full query against both the cache and your GraphQL server. 
        The query automatically updates if the result of the server-side query modifies cached fields.

        Provides a fast response while also helping to keep cached data consistent with server data. */

        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            let user = {
                id: data.user.id,
                registeredUserID: data.user.registered_user.id,
                username: data.user.username || '',
                email: data.user.email || '',
                firstName: data.user.registered_user.firstName || '',
                lastName: data.user.registered_user.lastName || '',
                group: data.user.registered_user.group
                    ? data.user.registered_user.group
                    : '',
                profilePic: data.user.registered_user.profilePic
                    ? data.user.registered_user.profilePic.formats
                    : '',
                profilePicId: data.user.registered_user.profilePic
                    ? data.user.registered_user.profilePic.id
                    : '',
                trackingLogId: data.user.registered_user.tracking_log
                    ? data.user.registered_user.tracking_log.id
                    : '',
                trackingLog: data.user.registered_user.tracking_log,
            };
            // Set User ID and Patient ID

            dispatch({
                type: saveUser,
                payload: user || null,
            });
        },
        onError: (error) => console.log('An error occured ', error),
    });

    const loadUserData = async (passedId) => {
        const queryId = passedId || userId;

        if (!queryId) return;
        getUserData({
            variables: {
                id: queryId,
            },
        });
    };

    return { loadUserData };
};

export default useUserData;
