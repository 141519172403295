import React from 'react';
import styled from '@emotion/styled';
import { ActivityHeader } from '../../SharedComponentsInteractive';
import Container from 'assets/styledComponents/Container';
import step1 from 'assets/images/interactive-course5-2/step1.svg';
import step2 from 'assets/images/interactive-course5-2/step2.svg';
import step3 from 'assets/images/interactive-course5-2/step3.svg';
import CalorieKingCalculator1 from './CalorieKingCalculator1';
import CalorieKingCalculator2 from './CalorieKingCalculator2';
import P from 'assets/styledComponents/fonts/P';
import UseDesktopBanner from 'assets/styledComponents/UseDesktopBanner';

const CalorieKingActivity = ({ devicesize }) => {
    return (
        <Container>
            {devicesize === 'mobile' && (
                <UseDesktopBanner marginBottom="3.2rem" />
            )}
            <ActivityHeader
                instructions="Solve the questions below"
                marginBottom={devicesize === 'mobile' ? '' : '5.6rem'}
            />

            <ResourceContainer
                devicesize={devicesize}
                className={
                    devicesize === 'mobile'
                        ? 'body-4 flex-column-centered'
                        : 'body-1 flex'
                }
            >
                <StepContainer
                    className="flex-column-centered"
                    devicesize={devicesize}
                >
                    <StepNumber
                        className="flex-all-centered"
                        devicesize={devicesize}
                    >
                        1
                    </StepNumber>
                    <Figure devicesize={devicesize}>
                        <img src={step1} alt="Calorie King Logo" />
                        <figcaption>
                            Go to website <br />{' '}
                            <a
                                href="https://www.calorieking.com"
                                target="_blank"
                                rel="noreferrer"
                            >
                                {' '}
                                www.calorieking.com
                            </a>
                        </figcaption>
                    </Figure>
                </StepContainer>

                <StepContainer
                    className="flex-column-centered"
                    devicesize={devicesize}
                >
                    <StepNumber
                        className="flex-all-centered"
                        devicesize={devicesize}
                    >
                        2
                    </StepNumber>
                    <Figure devicesize={devicesize}>
                        <img
                            src={step2}
                            alt="An illustration of a searchbar with the word raspberry typed in it"
                        />
                        <figcaption>
                            Search <span>Raspberry</span> in Calorie King
                        </figcaption>
                    </Figure>
                </StepContainer>

                <StepContainer
                    className="flex-column-centered"
                    devicesize={devicesize}
                >
                    <StepNumber
                        className="flex-all-centered"
                        devicesize={devicesize}
                    >
                        3
                    </StepNumber>
                    <Figure devicesize={devicesize}>
                        <img
                            src={step3}
                            alt="An illustration of text - How many calories in raspberries, raw"
                        />
                        <figcaption>
                            Find out how many carbohydrates are in 1 cup of
                            raspberries
                        </figcaption>
                    </Figure>
                </StepContainer>
            </ResourceContainer>

            <CalorieKingCalculator1 devicesize={devicesize} />
            <CalorieKingCalculator2 devicesize={devicesize} />
            <TextSection
                className={devicesize === 'mobile' ? 'body-2' : 'body-4'}
                devicesize={devicesize}
            >
                <P marginBottom="2.4rem">
                    {' '}
                    <span>You can also use a food scale </span>
                    along with the app if you have one at home. <br /> How: You
                    can weigh out how much of the food you would want to eat on
                    your scale, and enter the weight in the quantity on
                    CalorieKing and serving size as grams (g).{' '}
                </P>
                <div className="borderedContainer">
                    <P
                        marginBottom={
                            devicesize === 'mobile' ? '2.4rem' : '4.8rem'
                        }
                    >
                        For example, 100g of raspberries: 11.9g carbs – 6.5g
                        fibre = 5.4g carbs
                    </P>
                </div>
                <P marginBottom={devicesize === 'mobile' ? '2.4rem' : '4.8rem'}>
                    For foods you cannot find in any of the apps,{' '}
                    <span>you can also search in Google.</span> If you search a
                    food and then “nutrition facts”, usually a nutrition facts
                    table will be available for that food.{' '}
                </P>
                <P marginBottom="2.4rem">
                    <span>For restaurants</span> and fast food, many of their
                    websites will have nutrition information online. Many chain
                    restaurants have their nutrition information listed on their
                    websites.{' '}
                </P>
                <div className="borderedContainer">
                    <P marginBottom="0.8rem">
                        Check out this handout for the complete list of helpful
                        websites and resources:
                    </P>
                    <a
                        className="body-2"
                        href="http://www.bcchildrens.ca/endocrinology-diabetes-site/documents/diabapps.pdf"
                        target="_blank"
                        rel="noreferrer"
                    >
                        http://www.bcchildrens.ca/endocrinology-diabetes-site/documents/diabapps.pdf
                    </a>
                </div>
            </TextSection>
        </Container>
    );
};

const ResourceContainer = styled.div`
    margin-bottom: ${(props) => (props.devicesize === 'mobile' ? '' : '3rem')};
    min-height: 22.6rem;
    width: 100%;
    text-align: center;
`;

const StepContainer = styled.div`
    flex: 1;
    width: ${(props) => (props.devicesize === 'mobile' ? '100%' : '')};
    border-right: ${(props) =>
        props.devicesize === 'mobile'
            ? ''
            : `0.2rem solid  ${props.theme.colors.tertiary}`};

    border-bottom: ${(props) =>
        props.devicesize === 'mobile'
            ? `0.3rem solid ${props.theme.colors.tertiary}`
            : ''};
    :first-of-type {
        border-left: ${(props) =>
            props.devicesize === 'mobile'
                ? ``
                : `0.2rem solid ${props.theme.colors.tertiary}`};
    }
    :last-of-type {
        border-bottom: ${(props) =>
            props.devicesize === 'mobile' ? `none` : ``};
    }
`;

const StepNumber = styled.div`
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background: ${(props) => props.theme.colors.secondary};
    color: ${(props) => props.theme.colors.lightText};
    margin-top: ${(props) =>
        props.devicesize === 'mobile' ? '4rem' : '0.8rem'};
`;

const Figure = styled.figure`
    padding: 0.8rem 2.4rem 0;
    margin-bottom: ${(props) => (props.devicesize === 'mobile' ? '4rem' : '')};
    width: 100%;
    img {
        margin-top: 1.6rem;
        margin-bottom: 0.8rem;
        width: 100%;
    }
    figcaption {
        a {
            color: ${(props) => props.theme.colors.primary};
        }

        span {
            color: ${(props) => props.theme.colors.secondary};
        }
    }
`;

const TextSection = styled.section`
    margin-top: ${(props) =>
        props.devicesize === 'mobile'
            ? '4.8rem'
            : props.devicesize === 'tablet'
            ? '5.6rem'
            : '7.2rem'};
    p {
        span {
            color: ${(props) => props.theme.colors.success};
        }
    }

    div.borderedContainer {
        border-left: 0.8rem solid ${(props) => props.theme.colors.tertiary};
        padding-left: ${(props) =>
            props.devicesize === 'mobile' ? '1.2rem' : '2.4rem'};
        a {
            text-decoration: none;
            color: ${(props) => props.theme.colors.primary};
        }
    }
`;

export default CalorieKingActivity;
