let FormatTracksViewCount = (courses) => {
    let totalViewsForAllCourses = 0;

    courses.forEach((c) => {
        totalViewsForAllCourses += c.viewCount;
    });

    if (totalViewsForAllCourses > 999 && totalViewsForAllCourses < 950000) {
        totalViewsForAllCourses =
            Math.round(totalViewsForAllCourses / 1000) + 'K';
    }

    if (totalViewsForAllCourses >= 950000) {
        totalViewsForAllCourses =
            Math.round(totalViewsForAllCourses / 1000000) + 'M';
    }

    return totalViewsForAllCourses;
};

export default FormatTracksViewCount;
