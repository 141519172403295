const calculateEstimatedCourseTime = (course) => {
    let estimatedCourseTime = 0;
    course.mediaZone.map((item) =>
        item.estimatedLessonTimeInMinutes === null ||
        item.estimatedLessonTimeInMinutes === undefined
            ? (estimatedCourseTime += 0)
            : (estimatedCourseTime += item.estimatedLessonTimeInMinutes)
    );
    return estimatedCourseTime;
};

export default calculateEstimatedCourseTime;
