import styled from '@emotion/styled';
import React from 'react';
import { useHistory } from 'react-router-dom';
import trackPlaceholderMd from '../../assets/images/trackPlaceholderMd.svg';
import trackPlaceholderSm from '../../assets/images/trackPlaceholderSm.svg';
import FilledButtonSm from '../../assets/styledComponents/buttons/FilledButtonSm';
import CardTitleSmall from '../../assets/styledComponents/fonts/PetitTitle2';
import CardSize from '../../types/cardSize';
import CourseImage from '../../assets/styledComponents/StyledImage';
import H2 from 'assets/styledComponents/fonts/H2';
import P from 'assets/styledComponents/fonts/P';

const CardAddTrack = ({ size }) => {
    const history = useHistory();
    return (
        <Card className="card-shadow" size={size}>
            <CourseImage
                height="100%"
                width={size === CardSize.SMALL ? '11.5rem' : '30rem'}
                objectFit="cover"
                src={
                    size === CardSize.SMALL
                        ? trackPlaceholderSm
                        : trackPlaceholderMd
                }
                alt="trackPlaceholder"
            />
            <CardContent size={size}>
                {size === CardSize.SMALL ? (
                    <CardTitleSmall marginBottom="0.9rem">
                        Ready to learn?
                    </CardTitleSmall>
                ) : (
                    <CardTitleContainer>
                        <H2 className="header-3">Ready for more?</H2>
                        <P className="body-4" marginTop="1rem" color="greyText">
                            Let’s find and add another pathway to our learning!
                        </P>
                    </CardTitleContainer>
                )}
                <div className="flex">
                    <FilledButtonSm
                        width={size === CardSize.SMALL ? '13rem' : '25.4rem'}
                        onClick={() => history.push(`/pathways`)}
                    >
                        {size === CardSize.SMALL
                            ? 'Add a pathway'
                            : '+ Add a pathway'}
                    </FilledButtonSm>
                </div>
            </CardContent>
        </Card>
    );
};

const Card = styled.div`
    display: flex;
    border-radius: 2rem;
    height: ${(props) => (props.size === CardSize.SMALL ? '11.2rem' : '30rem')};
    width: 100%;
    margin-bottom: ${(props) =>
        props.size === CardSize.SMALL ? '2.2rem' : '4rem'};
    background-color: ${(props) => props.theme.colors.lightBackground};
`;

const CardContent = styled.div`
    margin: ${(props) =>
        props.size === CardSize.SMALL
            ? '3rem 0rem 3rem 2rem'
            : '8.7rem 0rem 8.7rem 6rem'};
`;

const CardTitleContainer = styled.div`
    margin-bottom: 3.3rem;
`;

export default CardAddTrack;
