import gql from 'graphql-tag';

export const UPDATE_PROFILE = gql`
    mutation updateAll(
        $userID: ID!
        $registeredID: ID!
        $email: String
        $firstName: String
        $lastName: String
        $group: ENUM_REGISTEREDUSER_GROUP
    ) {
        updateUser(input: { where: { id: $userID }, data: { email: $email } }) {
            user {
                id
                email
            }
        }

        updateRegisteredUser(
            input: {
                where: { id: $registeredID }
                data: {
                    firstName: $firstName
                    lastName: $lastName
                    group: $group
                }
            }
        ) {
            registeredUser {
                id
                firstName
                lastName
                group
            }
        }
    }
`;

export const UPDATE_PROFILE_PIC = gql`
    mutation updatePic($registeredId: ID!, $imgId: ID!) {
        updateRegisteredUser(
            input: {
                where: { id: $registeredId }
                data: { profilePic: $imgId }
            }
        ) {
            registeredUser {
                id
                profilePic {
                    id
                    formats
                }
            }
        }
    }
`;

export const DELETE_PROFILE_PIC = gql`
    mutation deletePic($oldPicId: ID!) {
        deleteFile(input: { where: { id: $oldPicId } }) {
            __typename
            file {
                id
            }
        }
    }
`;

export const UPDATE_VIEWCOUNT = gql`
    mutation updateViewCount($courseID: ID!, $viewCount: Int!) {
        updateCourse(
            input: { where: { id: $courseID }, data: { viewCount: $viewCount } }
        ) {
            course {
                viewCount
                id
            }
        }
    }
`;

export const UPDATE_COURSE_TRACKER = gql`
    mutation updateCourseTracker($id: ID!, $data: editTrackingLogInput) {
        updateTrackingLog(input: { where: { id: $id }, data: $data }) {
            trackingLog {
                id
                courseTracker
                __typename
            }
        }
    }
`;

export const UPDATE_SAVED_LESSONS_TRACKER = gql`
    mutation updateSavedLessonsTracker($id: ID!, $data: editTrackingLogInput) {
        updateTrackingLog(input: { where: { id: $id }, data: $data }) {
            trackingLog {
                id
                savedLessonsTracker
                __typename
            }
        }
    }
`;

export const UPDATE_GOALS_TRACKER = gql`
    mutation updateGoalTracker($id: ID!, $data: editTrackingLogInput) {
        updateTrackingLog(input: { where: { id: $id }, data: $data }) {
            trackingLog {
                id
                goalTracker
                __typename
            }
        }
    }
`;

export const UPDATE_BADGE_TRACKER = gql`
    mutation updateBadgeTracker($id: ID!, $data: editTrackingLogInput) {
        updateTrackingLog(input: { where: { id: $id }, data: $data }) {
            trackingLog {
                id
                badgeTracker
                __typename
            }
        }
    }
`;
