import step4a from 'assets/images/interactiveBook-course2/step4a.svg';
import step4b from 'assets/images/interactiveBook-course2/step4b.svg';
import step4c from 'assets/images/interactiveBook-course2/step4c.svg';

export const Step4ListItems = [
    {
        id: 1,
        text: 'Choose a finger to be checked.',
        img: step4a,
        imgAltText:
            'Illustration of a folded hand with an extended index finger',
    },
    {
        id: 2,
        text: 'Place your lancing device on the side of your finger pad.',
        img: step4b,
        imgAltText:
            'Illustration of a lancing device on the side of a finger pad',
    },
    {
        id: 3,
        text: 'If you were to divide your finger pad into 3 sections, you would want to place your lancing device on either of the outer edges. The middle section often is more painful as there are more nerves located there.',
        img: step4c,
        imgAltText:
            'Illustration of two blue arrows pointing to the outer edges of a finger tip',
    },
];
