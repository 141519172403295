import styled from '@emotion/styled';
import React, { useState } from 'react';
import bookSm from '../../assets/icons/bookSm.svg';
import clockSm from '../../assets/icons/clockSm.svg';
import Layout from '../../types/layout';
import FormatEstimatedTime from '../../utils/helpers/FormatEstimatedTime';
import CourseInfoIcons from '../CourseCard/CourseInfoIcons';
import StyledIcon from '../../assets/styledComponents/StyledImage';
import calculateEstimatedCourseTime from 'utils/helpers/CalculateEstimatedCourseTime';
import P from 'assets/styledComponents/fonts/P';
import IconLayout from 'types/iconLayout';

const CourseCardDetails = ({
    view,
    course,
    progress,
    lastPage,
    lastLesson,
    totalLessons,
    isCardView,
    isMobile,
}) => {
    const [isNotInProgress] = useState(progress === '0' || progress === '100');

    const infoIcons = [
        { icon: `${bookSm}`, title: `${totalLessons} Lessons` },
        {
            icon: `${clockSm}`,
            title: FormatEstimatedTime(
                course?.courses[0] &&
                    calculateEstimatedCourseTime(course.courses[0])
            ),
        },
    ];

    return (
        <CourseCardDetailsContainer view={view}>
            {isNotInProgress ? (
                <CourseInfoIconsContainer
                    className="flex flex-wrap"
                    view={view}
                    isCardView={isCardView}
                >
                    <CourseInfoIcons
                        infoIcons={infoIcons}
                        iconLayout={IconLayout.HORISONTAL}
                    />
                </CourseInfoIconsContainer>
            ) : (
                <>
                    <ProgressContainer
                        className="flex-space-between"
                        view={view}
                    >
                        <ProgressBar>
                            <ProgressLine progress={progress} />
                        </ProgressBar>
                        <P
                            className="petit-title-2"
                            color="greyText"
                            marginLeft="0.5rem"
                        >
                            {progress}%
                        </P>
                    </ProgressContainer>
                    <Metadata isCardView={isCardView}>
                        <StyledIcon
                            marginRight="0.5rem"
                            src={bookSm}
                            alt="book icon"
                        />
                        <span>
                            <P
                                className="petit-title-2 maxOneLineText"
                                color="greyText"
                                marginBottom="0.1rem"
                                textTransform="uppercase"
                            >
                                {lastPage}. {lastLesson}
                            </P>
                        </span>
                    </Metadata>
                </>
            )}
            {isMobile && !isNotInProgress && (
                <>
                    {parseInt(progress) !== 0 || parseInt(progress) == !100 ? (
                        <ProgressContainer
                            className="flex-space-between"
                            view={view}
                        >
                            <ProgressBar>
                                <ProgressLine progress={progress} />
                            </ProgressBar>
                            <P
                                className="petit-title-2"
                                color="greyText"
                                marginLeft="0.5rem"
                            >
                                {progress}%
                            </P>
                        </ProgressContainer>
                    ) : (
                        ''
                    )}
                    <Metadata isCardView={isCardView}>
                        <StyledIcon
                            marginRight="0.5rem"
                            src={bookSm}
                            alt="book icon"
                        />
                        <span>
                            <P
                                className="petit-title-2 maxOneLineText"
                                color="greyText"
                                marginBottom="0.1rem"
                                textTransform="uppercase"
                            >
                                {lastPage}. {lastLesson}
                            </P>
                        </span>
                    </Metadata>
                </>
            )}
        </CourseCardDetailsContainer>
    );
};

const CourseCardDetailsContainer = styled.div`
    ${(props) =>
        props.view === Layout.CARD_VIEW
            ? `margin-top: 1.7rem;`
            : `margin: 2.5rem 5rem 2.5rem 0rem;
    `}
`;

const CourseInfoIconsContainer = styled.div`
    width: 100%;
    margin-top: ${(props) =>
        props.view === Layout.CARD_VIEW ? '2rem' : '0.4rem'};
    /* icons should be fixed to bottom of course card so they dont move when title or description line nums vary */
    position: ${(props) => props.isCardView && 'absolute'};
    bottom: ${(props) => props.isCardView && 0};
    display: flex;
    justify-content: ${(props) => !props.isCardView && 'flex-end'};
`;

const ProgressContainer = styled.div`
    width: 100%;
    margin-top: ${(props) => props.view === Layout.LIST_VIEW && '0.4rem'};
    position: ${(props) => props.view === Layout.CARD_VIEW && 'absolute'};
    bottom: ${(props) => props.view === Layout.CARD_VIEW && '2.1rem'};
`;

const ProgressBar = styled.div`
    background-color: ${(props) => props.theme.colors.lightGreyBackground};
    border-radius: 1rem;
    height: 0.8rem;
    width: 90%;
`;

const ProgressLine = styled.div`
    background-color: ${(props) => props.theme.colors.secondary};
    width: ${(props) => (props.progress < 3 ? 3 : props.progress)}%;
    max-width: 100%;
    height: 0.8rem;
    border-radius: 1rem;
`;

const Metadata = styled.span`
    margin-top: 0.9rem;
    display: flex;
    align-items: center;
    /* icons should be fixed to bottom of course card so they dont move when title or description line nums vary */
    position: ${(props) => props.isCardView && 'absolute'};
    bottom: ${(props) => props.isCardView && 0};
`;

export default CourseCardDetails;
