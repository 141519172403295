import styled from '@emotion/styled';
import P from 'assets/styledComponents/fonts/P';

const StyledPetitTitle = styled(P)`
    text-align: ${(props) => (props.center ? 'center' : 'left')};

    span {
        color: ${(props) => props.theme.colors.greyText};
    }

    button {
        color: inherit;
        background-color: transparent;
        font-size: inherit;
        font-family: inherit;
        :hover {
            cursor: pointer;
            opacity: 0.4;
        }
    }
`;

export default StyledPetitTitle;
