import React from 'react';
import StyledImage from 'assets/styledComponents/StyledImage';
import step3Img from 'assets/images/interactiveBook-course2/step3Img.svg';
import P from 'assets/styledComponents/fonts/P';
import H3 from 'assets/styledComponents/fonts/H3';
import { Step3ListItems } from './Step3ListItems';

const Step3Mobile = () => {
    return (
        <>
            <H3
                className="header-3"
                textAlign="left"
                width="100%"
                marginTop="4.8rem"
            >
                Step 3
            </H3>
            <StyledImage src={step3Img} />
            <P className="body-2" marginTop="2.4rem" width="100%">
                Prepare your meter:
            </P>
            {Step3ListItems.map((item) => (
                <P
                    className="body-2"
                    marginTop="1.6rem"
                    width="100%"
                    key={item.id}
                >
                    {item.text}
                </P>
            ))}
        </>
    );
};

export default Step3Mobile;
