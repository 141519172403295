import two from 'assets/images/interactive-course5-2/two.svg';
import three from 'assets/images/interactive-course5-2/three.svg';
import four from 'assets/images/interactive-course5-2/four.svg';
const CalorieCalcContent = [
    {
        desctiption:
            '1/3 cup of rice is 15g carbohydrates, so if you were to eat 1 cup of rice, it would be 15 x 3 to get to a full cup = 45g of carbohydrates.',
        src: two,
        alt: 'an illustration of one cup of long grain rice beside a table of carbohydrate numbers found in grains and starches',
    },
    {
        desctiption: 'For 1 cup of milk, that adds another 15g carbohydrates.',
        src: three,
        alt: 'an illustration of a cup of milk beside a table of carbohydrate numbers found in milk and milk alternatives',
    },
    {
        desctiption: 'Same as with the 1 cup of peas.',
        src: four,
        alt: 'an illustration of a cup of peas beside a table of carbohydrate numbers found in starchy vegetables',
    },
];

export default CalorieCalcContent;
