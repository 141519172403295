import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import tryAgain from 'assets/icons/tryAgain.svg';
import thatsRightOneCycle from 'assets/images/thatsRightOneCycle.svg';
import isLoadingGif from 'assets/images/loading.svg';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';

const InteractiveActivityButton = ({
    onClick,
    className,
    hasError,
    isCorrect,
}) => {
    const [showCorrectMessage, setShowCorrectMessage] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isCorrect) {
            setIsLoading(true);
            setTimeout(() => {
                setIsLoading(false);
                setShowCorrectMessage(true);
            }, 2000);
        }
        setTimeout(() => {
            setShowCorrectMessage(false);
        }, 4500);
        return () => {
            setIsLoading(false);
            setShowCorrectMessage(false);
        };
    }, [isCorrect]);

    let [globalState] = useGlobalContext();
    let devicesize = globalState.deviceSize;

    const errorMessage =
        devicesize === 'mobile'
            ? 'Not right.. Let’s try again'
            : 'That’s not quite right.. Let’s try again';

    return (
        <>
            <Btn
                className={className}
                onClick={onClick}
                disabled={className === 'disabledBtn'}
                devicesize={devicesize}
                isLoading={isLoading}
            >
                {isLoading ? (
                    <object
                        className="loading-animation"
                        data={isLoadingGif}
                        type="image/svg+xml"
                        aria-label={`An animation displaying a loading gif`}
                        style={{
                            width: '100%',
                            height: 'auto',
                            position: 'absolute',
                        }}
                    />
                ) : hasError ? (
                    <StyledSpan>
                        {errorMessage}{' '}
                        <img src={tryAgain} alt="try again icon" />
                    </StyledSpan>
                ) : isCorrect && showCorrectMessage ? (
                    <object
                        className="thats-right"
                        data={thatsRightOneCycle}
                        type="image/svg+xml"
                        aria-label={`An animation displaying "that's right" with confetti`}
                    />
                ) : isCorrect && !showCorrectMessage ? (
                    <StyledSpan>
                        Try again <img src={tryAgain} alt="try again icon" />
                    </StyledSpan>
                ) : (
                    'Check my answer'
                )}
            </Btn>
        </>
    );
};

const Btn = styled.button`
    display: flex;
    align-items: center;
    font-family: ${(props) =>
            props.devicesize === 'mobile'
                ? 'DMSansMedium'
                : 'MontserratSemiBold'},
        sans-serif;
    font-size: ${(props) =>
        props.devicesize === 'mobile' ? '1.7rem' : '1.8rem'};
    line-height: 2.4rem;
    letter-spacing: ${(props) =>
        props.devicesize === 'mobile' ? '0.01rem' : '0.02rem'};
    border-radius: 3rem;
    height: 4.8rem;
    padding: ${(props) =>
        props.devicesize === 'mobile' ? ' 0 2.4rem' : ' 0 4.8rem'};
    width: fit-content;
    margin: 0;
    color: ${(props) => props.theme.colors.lightText};
    position: relative;
    :hover {
        cursor: pointer;
        opacity: 0.7;
    }

    img {
        margin-left: 0.4rem;
    }

    object.loading-animation {
        z-index: 2;
        position: absolute;
        background-color: white;
        height: 5rem;
        width: 28rem;
        left: ${(props) =>
            props.devicesize === 'mobile' ? '-8.5rem' : '-4.5rem'};
    }

    object.thats-right {
        z-index: 2;
        position: absolute;
        /* additional width + height to account for confetti */
        width: 28rem;
        height: 11rem;
        /* negative left position to give text centered appearance */
        left: ${(props) =>
            props.devicesize === 'mobile' ? '-8.5rem' : '-4.5rem'};
        margin-top: -1.3rem;
    }

    &.secondary {
        background: ${(props) =>
            props.isLoading ? 'transparent' : props.theme.colors.secondary};
    }

    &.incorrect {
        background: ${(props) => props.theme.colors.alertRed};
    }

    &.tryAgainAfterCorrect {
        background: ${(props) => props.theme.colors.secondary};
    }

    &.disabledBtn {
        background-color: ${(props) => props.theme.colors.grey};
        border: 0.1rem solid ${(props) => props.theme.colors.grey};
        color: ${(props) => props.theme.colors.lightText};

        :hover {
            cursor: default;
            opacity: 1;
        }
    }
`;

const StyledSpan = styled.span`
    display: flex;
`;

export default InteractiveActivityButton;
