/* Highlights sections, alterating colors & direction based on index */
import React from 'react';
import Highlights from 'components/LandingPage/Highlights';
import { useQuery } from '@apollo/client';
import { GET_LANDING_PAGE_HIGHLIGHTS } from 'graphql/queries';

const ShowHighlights = () => {
    const {
        loading: highlightsLoading,
        data: highlightsData,
        error: highlightsError,
    } = useQuery(GET_LANDING_PAGE_HIGHLIGHTS);

    if (highlightsLoading) return <p>Loading highlights...</p>;
    if (highlightsError) return <p>Highlights error</p>;

    return (
        highlightsData &&
        highlightsData.landingPage.highlights.map((section, index) => {
            const optimizedImageUrl = section?.sectionImage?.image?.formats
                ?.large
                ? section.sectionImage.image.formats.large.url
                : section.sectionImage.image.url;

            return (
                <div key={section.id}>
                    {index % 2 === 0 ? (
                        <Highlights
                            num={index + 1}
                            title={section.title}
                            content={section.content}
                            imageURL={optimizedImageUrl}
                            imageAltText={
                                section.sectionImage.imageAlternativeText
                            }
                            animatedImg={section.animatedCharacter.image.url}
                            animatedImgAltText={
                                section.animatedCharacter.imageAlternativeText
                            }
                        />
                    ) : (
                        <Highlights
                            flexDirection="row-reverse"
                            num={index + 1}
                            coloredBackground
                            title={section.title}
                            content={section.content}
                            imageURL={optimizedImageUrl}
                            imageAltText={
                                section.sectionImage.imageAlternativeText
                            }
                            animatedImg={section.animatedCharacter.image.url}
                            animatedImgAltText={
                                section.animatedCharacter.imageAlternativeText
                            }
                        />
                    )}
                </div>
            );
        })
    );
};

export default ShowHighlights;
