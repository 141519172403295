import styled from '@emotion/styled';
import React from 'react';
import H3 from 'assets/styledComponents/fonts/H3';
import behavioralAdviceGroups from 'types/behaviouralAdviceGroups';

const BehaviouralAdviceNav = ({ activeTab, onTabClick }) => {
    return (
        <NavContainer>
            <ul className="flex">
                {Object.values(behavioralAdviceGroups).map((value) => (
                    <NavItem
                        key={value}
                        isActive={activeTab === value ? true : false}
                        onClick={() => onTabClick(value)}
                        className="flex-all-centered"
                    >
                        <H3 className="body-1">{value}</H3>
                    </NavItem>
                ))}
            </ul>
        </NavContainer>
    );
};

const NavContainer = styled.nav`
    height: 6.4rem;

    ul {
        height: 100%;
    }
`;

const NavItem = styled.li`
    background: ${(props) =>
        props.isActive
            ? props.theme.colors.tertiary
            : props.theme.colors.tertiaryGradient};
    border-radius: 2rem 2rem 0 0;
    border-bottom: none;
    color: ${(props) => props.theme.colors.darkText};
    cursor: pointer;
    width: 50%;
    padding: 1.2rem;
    text-align: center;
`;

export default BehaviouralAdviceNav;
