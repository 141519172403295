import styled from '@emotion/styled';
import React from 'react';
import doneBadge from '../../assets/icons/doneBadge.svg';
import Layout from '../../types/layout';

const CompletedBadgeContainer = ({ view }) => {
    return (
        <CompletedBadge view={view}>
            <img
                src={doneBadge}
                alt="orange badge indicating that course has been completed"
            />
        </CompletedBadge>
    );
};

const CompletedBadge = styled.div`
    ${(props) =>
        props.view === Layout.LIST_VIEW
            ? `margin-right: 2rem; 
            margin-top: -2.5rem;`
            : `position: absolute; 
            top: 0;
            left: 10%;
            `}
`;

export default CompletedBadgeContainer;
