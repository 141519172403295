import React from 'react';
import MyStudyCoursesCard from './MyStudyCoursesCard';
import SectionSubHeader from './SectionSubHeader';

const MyCoursesSection = ({ isTablet, isDesktopSm }) => {
    return (
        <div>
            <SectionSubHeader
                title="My modules"
                linkTitle="see all"
                path={`/dashboard/mymodules`}
                marginTop="1.6rem"
                paddingBottom="1.6rem"
            />
            <MyStudyCoursesCard isTablet={isTablet} isDesktopSm={isDesktopSm} />
        </div>
    );
};

export default MyCoursesSection;
