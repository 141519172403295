import React from 'react';
import H3 from 'assets/styledComponents/fonts/H3';
import StyledImage from 'assets/styledComponents/StyledImage';
import step5Img from 'assets/images/interactiveBook-course2/step5Img.svg';
import P from 'assets/styledComponents/fonts/P';
import Container from 'assets/styledComponents/Container';
import { PageContainer } from './SharedComponents';
import prevChevron from 'assets/images/interactiveBook-course2/prevChevron.svg';
import NextOrPrevPageButton from './NextOrPrevPageButton';

const Step5 = ({ devicesize, setActivePage }) => {
    return (
        <PageContainer>
            <H3
                className="header-3"
                textAlign="center"
                width="50%"
                marginBottom={devicesize === 'tablet' ? '3rem' : '6.5rem'}
            >
                Step 5
            </H3>
            <Container className="flex" height="100%">
                <Container
                    width="50%"
                    padding={devicesize === 'tablet' ? '0 2rem' : '0 4rem'}
                >
                    <P marginBottom="1.5rem">
                        Dispose of sharps and strips. Pull the used strip out of
                        the meter and dispose of it in a sharps container. Your
                        diabetes team will show you how to safely remove the
                        lancet from the lancing device. Place the used lancet
                        into a sharps container.
                    </P>
                    <P marginBottom="1.5rem">
                        Many of the available glucose meters have apps. These
                        apps can link your blood glucose readings to your phone
                        and display them on a graph. Meters have a history that
                        holds a memory of your previous blood glucose checks.
                    </P>
                </Container>

                <StyledImage
                    src={step5Img}
                    width="50%"
                    padding={
                        devicesize === 'tablet'
                            ? '0 3rem 7.2rem'
                            : '0 6rem 7.2rem'
                    }
                />
            </Container>
            <NextOrPrevPageButton
                className="flex-all-centered prev"
                image={prevChevron}
                alt={'orange chevron pointing left'}
                setActivePage={setActivePage}
                setActivePageNum={4}
                title={'PREV'}
            />
        </PageContainer>
    );
};

export default Step5;
