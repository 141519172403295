import styled from '@emotion/styled';
import React from 'react';
import MyCoursesSection from './MyCoursesSection';
import MyStudyTracksSection from './MyStudyTracksSection';
import SectionHeader from './SectionHeader';

const MyStudyContainer = ({ isTablet, isDesktopSm }) => {
    return (
        <Container>
            <SectionHeader title="My study" />
            <ContentSection
                isTablet={isTablet}
                isDesktopSm={isDesktopSm}
                className={isTablet ? 'flex-column' : 'flex'}
            >
                <MyCoursesSection
                    isTablet={isTablet}
                    isDesktopSm={isDesktopSm}
                />
                <MyStudyTracksSection />
            </ContentSection>
        </Container>
    );
};

const Container = styled.div`
    padding: 3rem 3rem 0.8rem 3rem;
    border-radius: 2rem;
    background: ${(props) => props.theme.colors.tertiary};
`;

const ContentSection = styled.div`
    width: 100%;
    column-gap: ${(props) => (props.isDesktopSm ? '2.5rem' : '4rem')};
`;

export default MyStudyContainer;
