import React from 'react';
import StyledImage from 'assets/styledComponents/StyledImage';
import step4Img1 from 'assets/images/interactiveBook-course2/step4Img1.svg';
import step4Img2 from 'assets/images/interactiveBook-course2/step4Img2.svg';
import H3 from 'assets/styledComponents/fonts/H3';
import { Step4ListItems } from './Step4ListItems';
import { ListItem } from '../Course4/SharedComponents';
import P from 'assets/styledComponents/fonts/P';

const Step4Mobile = () => {
    return (
        <>
            <H3
                className="header-3"
                textAlign="left"
                width="100%"
                marginTop="4.8rem"
            >
                Step 4
            </H3>
            <StyledImage src={step4Img1} marginBottom="2.4rem" />

            <ul>
                {Step4ListItems.map((item) => (
                    <ListItem className="body-2" width="100%" key={item.id}>
                        {item.text}
                    </ListItem>
                ))}
                <ListItem className="body-2" width="100%">
                    Once positioned, press the button on your lancing device to
                    puncture the skin.
                </ListItem>
            </ul>
            <P className="body-2" marginTop="2.4rem">
                Bring your finger below the level of your heart and squeeze from
                the base of your finger up to the tip to get a large enough
                sample. If you cannot get a good sample, you can reset your
                lancing device to do another finger check. Your diabetes team
                can show you how to do this.
            </P>
            <StyledImage src={step4Img2} marginTop="2.4rem" />
            <P className="body-2" marginTop="3.2rem">
                When you have your sample, take your meter and use the test
                strip to collect the sample. The meter will count down when the
                sample is collected and display your blood glucose level once
                the test is complete. This takes about 5 seconds. If the sample
                is too small, an error message will appear on the screen.
                Replace the test strip with a new one and repeat the check.
            </P>
        </>
    );
};

export default Step4Mobile;
