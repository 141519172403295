import React from 'react';
import styled from '@emotion/styled';
import Container from './Container';
import H1 from './fonts/H1';
import H2 from './fonts/H2';

const PageHeaderWithSquare = ({
    headerTitle,
    headerDescription,
    isOnTracksPage,
    isMobile,
    isDesktop,
}) => {
    return (
        <Container
            className="flex"
            maxWidth={isMobile ? '' : '90rem'}
            marginTop={isDesktop ? '8rem' : '1.5rem'}
            marginBottom={isOnTracksPage ? '4rem' : '2rem'}
        >
            <ColoredSquare />
            <H1
                className="header-3"
                marginTop="-0.3rem"
                marginLeft="1.7rem"
                marginRight="4.9rem"
                textTransform="upperCase"
            >
                {headerTitle}{' '}
            </H1>
            <H2 className="body-4" color="greyText">
                {headerDescription}
            </H2>
        </Container>
    );
};

const ColoredSquare = styled.div`
    margin-top: 0.6rem;
    background-color: ${(props) => props.theme.colors.secondary};
    height: 2rem;
    /* need both to keep the width on courses page */
    min-width: 0.7rem;
    max-width: 0.7rem;
`;

export default PageHeaderWithSquare;
