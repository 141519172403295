import { React, Fragment } from 'react';
import noStudyPlan from '../../assets/images/noStudyPlan.svg';
import FilledButtonSm from '../../assets/styledComponents/buttons/FilledButtonSm';
import LinkWrappingButton from '../../assets/styledComponents/buttons/LinkWrappingButton';
import {
    InformationSection,
    ImageContainer,
    DescriptiveText,
} from './SharedComponentsThisWeek';
import FilledButtonMd from '../../assets/styledComponents/buttons/FilledButtonMd';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';
import Container from 'assets/styledComponents/Container';

const NoStudyPlan = ({ setShowCreateStudyPlan, isOnDashboardHomePage }) => {
    let [globalState] = useGlobalContext();
    let devicesize = globalState.deviceSize;
    return (
        <Fragment>
            <InformationSection
                className="flex-all-centered"
                isOnDashboardHomePage={isOnDashboardHomePage}
                devicesize={devicesize}
            >
                {devicesize !== 'mobile' && (
                    <ImageContainer>
                        <img
                            src={noStudyPlan}
                            alt="Illustration of a calendar against a blue background with white stars"
                        />
                    </ImageContainer>
                )}

                <DescriptiveText
                    className="maxFourLineText"
                    color="greyText"
                    width={devicesize === 'mobile' ? '100%' : '22rem'}
                    maxWidth={devicesize === 'mobile' && '46rem'}
                    marginLeft={devicesize !== 'mobile' && '4rem'}
                    isOnDashboardHomePage={isOnDashboardHomePage}
                    textAlign={devicesize === 'mobile' && 'center'}
                >
                    You do not have a study plan yet!
                    {isOnDashboardHomePage && devicesize !== 'mobile' && (
                        <LinkWrappingButton to="/dashboard/studyplan">
                            <FilledButtonSm marginTop="1rem" width="100%">
                                Set up your study plan
                            </FilledButtonSm>
                        </LinkWrappingButton>
                    )}
                </DescriptiveText>
            </InformationSection>

            {!isOnDashboardHomePage && devicesize !== 'mobile' ? (
                <FilledButtonMd
                    width="37rem"
                    onClick={() => setShowCreateStudyPlan(true)}
                >
                    Set up your study plan
                </FilledButtonMd>
            ) : devicesize === 'mobile' ? (
                <Container padding="0 1.8rem">
                    <DescriptiveText
                        className="maxFourLineText"
                        color="greyText"
                        width={devicesize === 'mobile' ? '100%' : '22rem'}
                        maxWidth={devicesize === 'mobile' && '46rem'}
                        marginLeft={devicesize !== 'mobile' && '4rem'}
                        isOnDashboardHomePage={isOnDashboardHomePage}
                        textAlign={devicesize === 'mobile' && 'center'}
                    >
                        Please <span>use a desktop </span> to set up your study
                        plan.
                    </DescriptiveText>
                </Container>
            ) : (
                ''
            )}
        </Fragment>
    );
};

export default NoStudyPlan;
