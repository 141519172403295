import React from 'react';
import P from 'assets/styledComponents/fonts/P';
import Container from 'assets/styledComponents/Container';
import StyledIcon from 'assets/styledComponents/StyledImage';

const CourseInfoIcons = ({ infoIcons }) => {
    return (
        <>
            {infoIcons.map((item) => (
                <Container
                    className="flex-center-aligned"
                    key={item.icon}
                    marginRight="0.5rem"
                >
                    <StyledIcon
                        width="1.3rem"
                        height="1.3rem"
                        marginRight="0.5rem"
                        src={item.icon}
                        alt=""
                    />
                    <P className="petit-title-2" color="greyText">
                        {item.title}
                    </P>
                </Container>
            ))}
        </>
    );
};

export default CourseInfoIcons;
