import styled from '@emotion/styled';
import React from 'react';
import ageGroups from 'types/ageGroups';
import Adolescent from './Adolescent';
import AllAges from './AllAges';
import Less6Months from './Less6Months';
import Months12_24 from './Months12_24';
import Months6_9 from './Months6_9';
import Months9_12 from './Months9_12';
import TodlerSchoolAge from './TodlerSchoolAge';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';

const Content = ({ activeTab }) => {
    let [globalState] = useGlobalContext();
    let devicesize = globalState.deviceSize;
    const getContent = () => {
        switch (activeTab) {
            case ageGroups.LESS_6_MONTHS:
                return <Less6Months />;
            case ageGroups.MONTHS_6_9:
                return <Months6_9 />;
            case ageGroups.MONTHS_9_12:
                return <Months9_12 />;
            case ageGroups.MONTHS_12_24:
                return <Months12_24 />;
            case ageGroups.TODLER_SCHOOL:
                return <TodlerSchoolAge />;
            case ageGroups.ADOLESCENT:
                return <Adolescent />;
            case ageGroups.ALL:
                return <AllAges />;
            default:
                return <AllAges />;
        }
    };
    return (
        <>
            {devicesize === 'mobile' || devicesize === 'tablet' ? (
                <>
                    {' '}
                    <Less6Months devicesize={devicesize} />
                    <Months6_9 devicesize={devicesize} />
                    <Months9_12 devicesize={devicesize} />
                    <Months12_24 devicesize={devicesize} />
                    <TodlerSchoolAge devicesize={devicesize} />
                    <Adolescent devicesize={devicesize} />
                    <AllAges devicesize={devicesize} />
                </>
            ) : (
                <Container>{getContent()}</Container>
            )}
        </>
    );
};
const Container = styled.div`
    width: 100%;
    background-color: ${(props) =>
        props.devicesize === 'mobile' || props.devicesize === 'tablet'
            ? ''
            : props.theme.colors.tertiary};
    padding: 5.6rem 4rem 7.2rem 4rem;
`;
export default Content;
