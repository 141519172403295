import { React } from 'react';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';
import BloodGlucoseCheckMobile from './BloodGlucoseCheckMobile';
import BloodGlucoseCheckDesktop from './BloodGlucoseCheckDesktop';

const CompletingBloodGlucoseCheck = () => {
    const [globalState] = useGlobalContext();
    let devicesize = globalState.deviceSize;

    return (
        <>
            {devicesize === 'mobile' ? (
                <BloodGlucoseCheckMobile />
            ) : (
                <BloodGlucoseCheckDesktop devicesize={devicesize} />
            )}
        </>
    );
};

export default CompletingBloodGlucoseCheck;
