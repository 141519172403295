// Function for interactive lesson inputs

const allowedChars = {
    Backspace: true,
    Delete: true,
    '.': true,
    ArrowLeft: true,
    ArrowRight: true,
    ArrowUp: true,
    ArrowDown: true,
    Tab: true,
};

const LimitInputValues = (e) => {
    // replace non-numeric inputs with an empty string
    if (isNaN(+e.key) && !allowedChars[e.key]) {
        e.preventDefault();
    }

    // prevent further inputs if val >= 99
    // TODO follow up with design about adding a message for the user
    if (Math.round(+e.target.value) >= 99) {
        e.target.value = e.target.value.slice(0, e.target.value.length - 2);
    }
};

export default LimitInputValues;
