import React from 'react';
import foodsToCount from 'assets/documents/foodsToCount.pdf';
import PDFCarousel from '../../../PDFCarousel';
import P from 'assets/styledComponents/fonts/P';

const FoodsToCount = ({ devicesize }) => {
    return (
        <div>
            <P
                className={devicesize === 'mobile' ? 'body-2' : 'body-4'}
                marginTop={devicesize === 'mobile' ? '5rem' : '8rem'}
                marginBottom={devicesize === 'mobile' ? '2.4rem' : '4rem'}
            >
                Carbohydrate containing foods <br /> Click on a tab to see
                examples:
            </P>
            <PDFCarousel
                pdf={foodsToCount}
                isFoodsToCount
                devicesize={devicesize}
            />
        </div>
    );
};

export default FoodsToCount;
