import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import React, { useState } from 'react';
import bookSm from '../../assets/icons/bookSm.svg';
import clockSm from '../../assets/icons/clockSm.svg';
import PetitTitle2 from '../../assets/styledComponents/fonts/PetitTitle2';
import { GET_COURSE } from '../../graphql/queries';
import calculateProgress from '../../utils/helpers/CalculateProgress';
import FormatEstimatedTime from '../../utils/helpers/FormatEstimatedTime';
import CompletedBadgeContainer from '../CourseCard/CompletedBadgeContainer';
import CourseInfoIcons from '../CourseCard/CourseInfoIcons';
import CardSmallButtonSection from './CardSmallButtonSection';
import CourseImage from '../../assets/styledComponents/StyledImage';
import Container from '../../assets/styledComponents/Container';
import calculateEstimatedCourseTime from 'utils/helpers/CalculateEstimatedCourseTime';
import P from 'assets/styledComponents/fonts/P';
import IconLayout from 'types/iconLayout';

const CourseCardSmall = ({ course }) => {
    const [hovered, setHovered] = useState(false);

    const slug = course?.slug;

    const { data: fullCourse } = useQuery(GET_COURSE, {
        variables: { slug },
    });

    const progress = calculateProgress(course);

    const infoIcons = [
        { icon: `${bookSm}`, title: `${course?.totalLessons} Lessons` },
        {
            icon: `${clockSm}`,
            title: FormatEstimatedTime(
                fullCourse?.courses[0] &&
                    calculateEstimatedCourseTime(fullCourse.courses[0])
            ),
        },
    ];

    return (
        <Card
            className="card-shadow"
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            {hovered ? <CardLinearGradientBackground /> : ''}
            <ThumbnailWrap>
                <CourseImage
                    width="100%"
                    height="13rem"
                    borderRadius="2rem 2rem 0 0"
                    objectFit="cover"
                    src={
                        course.thumbnailImageUrl
                            ? course.thumbnailImageUrl
                            : course.nonOptimizedImageUrl
                    }
                    alt={course.title}
                />
                {progress === '100' ? <CompletedBadgeContainer /> : ''}
            </ThumbnailWrap>
            {hovered ? (
                <CardSmallButtonSection
                    progress={progress}
                    slug={slug}
                    lastPage={course.lastPageIndex}
                />
            ) : (
                <CourseInfoSection>
                    <PetitTitle2
                        className="maxTwoLineText"
                        marginBottom="0.9rem"
                    >
                        {course.title}
                    </PetitTitle2>
                    {progress === '100' || progress === '0' ? (
                        <Container position="absolute" bottom="1.5rem">
                            <CourseInfoIcons
                                infoIcons={infoIcons}
                                iconLayout={IconLayout.VERTICAL}
                            />
                        </Container>
                    ) : (
                        <>
                            <Container
                                className="flex-center-aligned"
                                position="absolute"
                                //bottom 1.5rem card bottom padding + 3.4rem of lesson title
                                bottom="4.9rem"
                            >
                                <ProgressBar>
                                    <ProgressLine progress={progress} />
                                </ProgressBar>
                                <P
                                    className="petit-title-2"
                                    color="greyText"
                                    marginLeft="0.6rem"
                                >
                                    {progress}%
                                </P>
                            </Container>
                            <Container
                                position="absolute"
                                bottom="1.5rem"
                                height="3.4rem"
                            >
                                <P
                                    className="petit-title-2 maxTwoLineText"
                                    textTransform="uppercase"
                                    color="greyText"
                                    marginRight="1.5rem"
                                >
                                    {course?.lastPageIndex}.{' '}
                                    {course?.lastLesson}
                                </P>
                            </Container>
                        </>
                    )}
                </CourseInfoSection>
            )}
        </Card>
    );
};

const Card = styled.div`
    border-radius: 2rem;
    background-color: ${(props) => props.theme.colors.lightBackground};
    width: 16rem;
    height: 25rem;
    position: relative;
`;

const ThumbnailWrap = styled.div`
    position: relative;
    display: inline-block;
    width: 100%;
`;

const CardLinearGradientBackground = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 2rem;
    background: linear-gradient(
        179.89deg,
        rgba(255, 255, 255, 0) 11.51%,
        rgba(255, 255, 255, 0.443182) 31.49%,
        #ffffff 55.57%
    );
    z-index: 1;
`;

const CourseInfoSection = styled.section`
    height: 12rem;
    padding: 1rem 1.5rem 1.5rem 1.5rem;
`;

const ProgressBar = styled.div`
    background-color: ${(props) => props.theme.colors.lightGreyBackground};
    border-radius: 1rem;
    height: 0.8rem;
    width: 9.6rem;
`;

const ProgressLine = styled.div`
    background-color: ${(props) => props.theme.colors.secondary};
    width: ${(props) => (props.progress < 3 ? 3 : props.progress)}%;
    max-width: 100%;
    height: 0.8rem;
    border-radius: 1rem;
`;

export default CourseCardSmall;
