import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { client } from './apolloClient';
import Routes from './routes';
import { BrowserRouter as Router } from 'react-router-dom';
import { GlobalProvider } from './GlobalContext/GlobalProvider';

function App() {
    return (
        <ApolloProvider client={client}>
            <GlobalProvider>
                <Router>
                    <Routes />
                </Router>
            </GlobalProvider>
        </ApolloProvider>
    );
}

export default App;
