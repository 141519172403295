/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import { useGlobalContext } from '../GlobalContext/GlobalProvider';
import { updateAuth } from '../GlobalContext/actionVariables';

const useAuth = () => {
    const [, dispatch] = useGlobalContext();
    const [isAuth, setIsAuth] = useState(false);

    const token = localStorage.getItem('token');
    const checkLocalStorageToken = () => {
        if (!token) return false;
        else return true;
    };

    useEffect(() => {
        if (checkLocalStorageToken()) {
            const decoded = jwtDecode(token);
            const currentDate = Date.now().valueOf() / 1000;
            if (decoded.exp < currentDate) {
                setIsAuth(false);
                dispatch({ type: updateAuth, payload: false });
            } else {
                setIsAuth(true);
                dispatch({ type: updateAuth, payload: true });
            }
        } else {
            setIsAuth(false);
        }
    }, []);

    return isAuth;
};

export default useAuth;
