import { React, useState, useRef } from 'react';
import Step1Table from './Step1Table';
import Step1Calc from './Step1Calc';
import Container from 'assets/styledComponents/Container';
import { ActivityHeader } from '../../SharedComponentsInteractive';
import P from 'assets/styledComponents/fonts/P';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';
import UseDesktopBanner from 'assets/styledComponents/UseDesktopBanner';

const Step1Activity = () => {
    let [globalState] = useGlobalContext();
    let devicesize = globalState.deviceSize;
    const [showHint1, setShowHint1] = useState(false);
    const hintRef = useRef(null);

    const scrollUp = () => {
        return hintRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    };

    const handleHintClick = (e) => {
        e.preventDefault();
        setShowHint1(true);
        scrollUp();
        setTimeout(() => {
            setShowHint1(false);
        }, 3000);
    };

    return (
        <Container
            marginTop={
                devicesize === 'desktopSm' || devicesize === 'desktopMd'
                    ? '5.6rem'
                    : devicesize === 'tablet'
                    ? '2.4rem'
                    : '1.6rem'
            }
            marginLeft={
                devicesize === 'desktopSm' || devicesize === 'desktopMd'
                    ? '6rem'
                    : ''
            }
        >
            {devicesize === 'mobile' || devicesize === 'tablet' ? (
                <UseDesktopBanner marginBottom="3.2rem" />
            ) : (
                ''
            )}
            <Container width="100%">
                <ActivityHeader instructions="Read the example above to fill in the values in the worksheet below." />
            </Container>

            <Container className="flex-all-centered">
                <Step1Table hintRef={hintRef} showHint1={showHint1} />
            </Container>

            <P
                className={
                    devicesize === 'desktopSm' || devicesize === 'desktopMd'
                        ? 'body-4'
                        : 'body-2'
                }
                marginTop={
                    devicesize === 'desktopSm' || devicesize === 'desktopMd'
                        ? '4.8rem'
                        : '2.4rem'
                }
            >
                Take a look at the chart to see what your typical doses of
                insulin are during the day. Based on the chart, what would be
                the total daily dose?
            </P>

            <Step1Calc handleHintClick={handleHintClick} />
        </Container>
    );
};

export default Step1Activity;
