import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';
import { GET_COURSE } from 'graphql/queries';
import LessonSideNavBarLessonInfo from './LessonSideNavBarLessonInfo';
import LessonSideNavBarTopSectionContainer from './LessonSideNavBarTopSection';
import chevronRight from 'assets/icons/chevronRight.svg';

const LessonSideNavbar = ({
    slug,
    currentLesson,
    hasCurrentLessonEnded,
    expandNavbar,
    setExpandNavbar,
    isNextLessonAvaliable,
}) => {
    const [globalState] = useGlobalContext();
    const { data: course } = useQuery(GET_COURSE, {
        variables: { slug },
    });
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(globalState.auth);
    const currentCourse = course.courses[0];

    useEffect(() => {
        setIsUserLoggedIn(globalState.auth);
        return () => {
            setIsUserLoggedIn(null);
        };
    }, [globalState]);

    const getLessons = (course) => {
        return course.mediaZone.filter((mediaZone) => mediaZone.endPage);
    };

    const lessons = getLessons(currentCourse);

    const getIsLessonCompleted = (lesson) => {
        const isLessonCompleted =
            globalState.user &&
            globalState.user.trackingLog.courseTracker[course.courses[0].id] &&
            globalState.user.trackingLog.courseTracker[course.courses[0].id]
                .lessonsCompleted[lesson.id]
                ? true
                : false;
        return isLessonCompleted;
    };

    const completedLessons = lessons.map((lesson) =>
        getIsLessonCompleted(lesson)
    );

    const getNumberOfCompletedLessons = (completedLessons) => {
        return completedLessons.filter((value) => value === true).length;
    };

    const getCourseProgress = (lessons, completedLessons) => {
        const numberOfCompletedLessons =
            getNumberOfCompletedLessons(completedLessons);
        const progress =
            numberOfCompletedLessons === 0
                ? '0'
                : Math.floor(
                      (numberOfCompletedLessons * 100) / lessons.length
                  ) + '';
        return progress;
    };

    const getIsCurrentLesson = (lesson) => {
        return (
            lesson.lessonNumber ===
            currentLesson.filter((el) => el.lessonNumber)[0].lessonNumber
        );
    };

    const currentLessonIndex = lessons.findIndex((lesson) =>
        getIsCurrentLesson(lesson)
    );

    return (
        <NavOuterContainer
            role="presentation"
            devicesize={globalState.deviceSize}
        >
            <NavContainer width={expandNavbar ? '24rem' : '5.6rem'}>
                <LessonSideNavBarTopSectionContainer
                    setExpandNavbar={setExpandNavbar}
                    expandNavbar={expandNavbar}
                    currentCourse={currentCourse}
                    slug={slug}
                    progress={getCourseProgress(lessons, completedLessons)}
                    isUserLoggedIn={isUserLoggedIn}
                />
                {lessons.map((lesson, indexToRender) => (
                    <LessonSideNavBarLessonInfo
                        setExpandNavbar={setExpandNavbar}
                        expandNavbar={expandNavbar}
                        slug={slug}
                        key={lesson.id}
                        lessonTitle={lesson.lessonTitle}
                        isLessonCompleted={getIsLessonCompleted(lesson)}
                        lessonNumber={lessons.indexOf(lesson) + 1}
                        isCurrentLesson={getIsCurrentLesson(lesson)}
                        isNextLessonAvaliable={
                            indexToRender === currentLessonIndex + 1 &&
                            hasCurrentLessonEnded &&
                            isNextLessonAvaliable
                        }
                        isUserLoggedIn={isUserLoggedIn}
                    />
                ))}
            </NavContainer>
            <ExpandBtn onClick={() => setExpandNavbar(!expandNavbar)}>
                <ExpandBtnArrow src={chevronRight} expanded={expandNavbar} />
            </ExpandBtn>
        </NavOuterContainer>
    );
};

const NavOuterContainer = styled.div`
    height: calc(
        100vh -
            ${(props) => (props.devicesize === 'tablet' ? '7.2rem' : '9rem')}
    );
    position: fixed;
    bottom: 0;
    /* z index 3 as overlay on lessons is 2 when side navbar open on small screens */
    z-index: 3;
`;

const NavContainer = styled.nav`
    background-color: ${(props) => props.theme.colors.lightGreyBackground};
    width: ${(props) => props.width};
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    /* Hide scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
        display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
`;

const ExpandBtn = styled.button`
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.lightGreyBackground};
    position: absolute;
    right: -2rem;
    bottom: 1rem;
    :hover {
        cursor: pointer;
    }
`;

const ExpandBtnArrow = styled.img`
    margin-right: -1rem;
    transform: ${(props) =>
        props.expanded === true ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

export default LessonSideNavbar;
