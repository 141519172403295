import styled from '@emotion/styled';

export const PageContainer = styled.div`
    background: transparent;
    width: 100%;
    height: 100%;
    padding: 3.2rem 0;
    overflow: hidden;
    position: relative;
    z-index: 1;
`;

export const PageButton = styled.button`
    color: ${(props) => props.theme.colors.secondary};
    position: absolute;
    bottom: 2.5rem;
    text-align: left;

    background: transparent;

    :hover {
        cursor: pointer;
        opacity: 0.4;
    }

    &.prev {
        left: 2.5rem;
        img {
            margin-right: 0.7rem;
        }
    }

    &.next {
        right: 2.5rem;
        img {
            margin-left: 0.7rem;
        }
    }
`;

// GREEN TEXT
export const Span = styled.span`
    color: ${(props) => props.theme.colors.success};
`;
