import styled from '@emotion/styled';

const PageMainContainer = styled.div`
    margin: auto;
    color: ${(props) => props.color && props.theme.colors[props.color]};
    background-color: ${(props) =>
        props.backgroundColor && props.theme.colors[props.backgroundColor]};
    background: ${(props) =>
        props.background && props.theme.colors[props.background]};
    padding-bottom: 15rem;
`;

export default PageMainContainer;
