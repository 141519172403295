import React from 'react';
import styled from '@emotion/styled';

const Picture100 = ({ src, alt }) => {
    return (
        <PictureContainer>
            <Image src={src} alt={alt}></Image>
        </PictureContainer>
    );
};

const PictureContainer = styled.div`
    display: flex;
    justify-content: center;
    margin: 4rem 0;
    @media screen and (max-width: ${(props) =>
            props.theme.breakPoints.tablet}) {
        margin: 2.4rem 0;
    }
`;

const Image = styled.img`
    width: 100%;
`;

export default Picture100;
