import React, { useState, useEffect } from 'react';
import {
    StyledForm,
    CalculatorContainer,
} from '../../SharedComponentsInteractive';
import StyledImage from 'assets/styledComponents/StyledImage';
import cupOfBlueberries from 'assets/images/interactive-course5-6/cupOfBlueberries.svg';
import equalsPrimaryDarkest from 'assets/icons/math/equalsPrimaryDarkest.svg';
import minusPrimaryDarkest from 'assets/icons/math/minusPrimaryDarkest.svg';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';
import InteractiveActivityButton from 'assets/styledComponents/buttons/InteractiveActivityButton';
import ActivityHeaderAndHint from './ActivityHeaderAndHint';
import Container from 'assets/styledComponents/Container';
import P from 'assets/styledComponents/fonts/P';
import CalcInputItem from 'assets/styledComponents/CalcInputItem';
import InputItemLabelPosition from 'types/inputItemLabelPosition';
import CalcResult from 'assets/styledComponents/CalcResult';
import HintModal from '../../HintModal';
import hint2A from 'assets/images/interactive-course5-6/hint2A.svg';
import hint2B from 'assets/images/interactive-course5-6/hint2B.svg';
import { StyledHintButton } from '../../SharedComponentsInteractive';

const ActivityCalc = () => {
    let initialFormfields = {
        carbs: '',
        fibre: '',
        result: '',
    };

    let initialErrors = {
        carbs: false,
        fibre: false,
        result: false,
    };

    const [formFields, setFormFields] = useState({
        ...initialFormfields,
    });

    const [error, setError] = useState({
        ...initialErrors,
    });

    const [carbVal, setCarbVal] = useState('');
    const [fibreVal, setFibreVal] = useState('');
    const [calcResult, setCalcRes] = useState('?');
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const onChange = (event) => {
        setFormFields({
            ...formFields,
            [event.target.name]: event.target.value,
        });

        if (event.target.name === 'carbs') {
            setCarbVal(event.target.value);
        } else if (event.target.name === 'fibre') {
            setFibreVal(event.target.value);
        }
    };

    const resetValues = () => {
        setCarbVal('');
        setFibreVal('');
        setCalcRes('?');

        return;
    };

    const calculateResult = async (e) => {
        e.preventDefault();
        setHasSubmitted(true);
        let { carbs, fibre } = formFields;
        let result = (+carbs - +fibre).toFixed(2);
        setCalcRes(result + 'g');

        if (error.carbs || error.fibre || error.result) {
            setError({
                ...initialErrors,
            });
            setFormFields({
                ...initialFormfields,
            });
            // clear input and result fields
            document.getElementById('calculator-5.6').reset();
            resetValues();
        } else {
            setError({
                ...error,
                carbs: carbs != 21.4 ? true : false,
                fibre: fibre != 3.6 ? true : false,
                // both inputs and the result have to be correct for the result to be correct
                result:
                    result != 17.8 || carbs != 21.4 || fibre != 3.6
                        ? true
                        : false,
            });
        }
    };

    const handleTryAgainAfterAllCorrect = (e) => {
        e.preventDefault();
        setFormFields({
            ...initialFormfields,
        });
        // clear input and result fields
        document.getElementById('calculator-5.6').reset();
        resetValues();
    };

    // remove error message if user clears an input field
    useEffect(() => {
        if (!formFields.carbs.length || !formFields.fibre.length) {
            setHasSubmitted(false);
            setError({
                ...error,
                carbs: false,
                fibre: false,
                result: false,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formFields]);

    const [showModal, setShowModal] = useState(false);

    // Prevent background content scrolling when modal open
    if (showModal) {
        document.body.style.overflow = 'hidden';
    } else if (!showModal) {
        document.body.style.overflow = 'unset';
    }

    // responsive vars
    let [globalState] = useGlobalContext();
    let devicesize = globalState.deviceSize;

    let inputFieldWidth =
        devicesize === 'mobile'
            ? '6.8rem'
            : devicesize === 'tablet'
            ? '10rem'
            : '12rem';

    let inputFieldHeight =
        devicesize === 'mobile'
            ? '6.8rem'
            : devicesize === 'tablet'
            ? '10rem'
            : '12rem';

    let operatorMargin =
        devicesize === 'mobile'
            ? '1rem'
            : devicesize === 'tablet'
            ? '1.5rem'
            : '4rem';
    let operatorWidth =
        devicesize === 'mobile'
            ? '1.6rem'
            : devicesize === 'tablet'
            ? '2rem'
            : '3.2rem';

    let allCorrect = !error.carbs && !error.fibre && !error.result;

    const onHintClick = (e) => {
        e.preventDefault();
        setShowModal(!showModal);
    };

    return (
        <>
            {showModal && (
                <HintModal
                    setShowModal={setShowModal}
                    showModal={showModal}
                    imgA={hint2A}
                    altA="a screenshot of a search field with the word 'blueberries' in it"
                    maxHeightA="15rem"
                    imgB={hint2B}
                    altB="a screenshot of search results for 'Blueberries, raw' with a green circle around the 'carbs 21.4g' and 'fiber 3.6g' fields"
                    maxHeightB="28rem"
                />
            )}

            <ActivityHeaderAndHint
                instructions={`Search the carbohydrate count for the 1 cup of
                blueberries in `}
                link={
                    <a
                        target="_blank"
                        href="https://www.calorieking.com/us/en/"
                        rel="noreferrer"
                    >
                        Calorie King
                    </a>
                }
                devicesize={devicesize}
            />

            <StyledForm id="calculator-5.6" devicesize={devicesize}>
                <CalculatorContainer
                    className="flex-all-centered"
                    devicesize={devicesize}
                    background="tertiary"
                >
                    {devicesize === 'mobile' ? (
                        ''
                    ) : (
                        <Container
                            className="flex-column-centered"
                            marginRight={
                                devicesize === 'tablet' ? '2rem' : '6.4rem'
                            }
                            textAlign="left"
                        >
                            <StyledImage
                                src={cupOfBlueberries}
                                alt="An illustration of a cup of blueberries"
                                marginTop={
                                    devicesize === 'tablet'
                                        ? '2.5rem'
                                        : '3.5rem'
                                }
                                marginBottom={
                                    devicesize === 'tablet'
                                        ? '4.1rem'
                                        : '5.1rem'
                                }
                            />
                            <P
                                className="body-1"
                                width="100%"
                                color="primaryDarkest"
                            >
                                1 Cup
                            </P>
                        </Container>
                    )}

                    <div className="flex-column-centered">
                        <CalcInputItem
                            label="Carbs"
                            labelPosition={InputItemLabelPosition.BELOW}
                            name="carbs"
                            value={carbVal}
                            onChange={onChange}
                            error={error}
                            hasSubmitted={hasSubmitted}
                            inputFieldWidth={inputFieldWidth}
                            inputFieldHeight={inputFieldHeight}
                            placeholder="0g"
                            unit="g"
                        />
                    </div>
                    <StyledImage
                        alt="minus symbol"
                        width={operatorWidth}
                        marginTop="-3rem"
                        marginLeft={operatorMargin}
                        marginRight={operatorMargin}
                        src={minusPrimaryDarkest}
                    />
                    <div className="flex-column-centered">
                        <CalcInputItem
                            label="Fibre"
                            labelPosition={InputItemLabelPosition.BELOW}
                            name="fibre"
                            value={fibreVal}
                            onChange={onChange}
                            error={error}
                            hasSubmitted={hasSubmitted}
                            inputFieldWidth={inputFieldWidth}
                            inputFieldHeight={inputFieldHeight}
                            placeholder="0g"
                            unit="g"
                        />
                    </div>

                    <StyledImage
                        alt="equals symbol"
                        width={operatorWidth}
                        marginTop="-3rem"
                        marginLeft={operatorMargin}
                        marginRight={operatorMargin}
                        src={equalsPrimaryDarkest}
                    />
                    <div className="flex-column-centered">
                        <CalcResult
                            label="Carbs"
                            name="result"
                            value={calcResult}
                            error={error}
                            hasSubmitted={hasSubmitted}
                            inputFieldWidth={inputFieldWidth}
                            inputFieldHeight={inputFieldHeight}
                            borderColor="tertiaryDark"
                            background="tertiaryDark"
                            devicesize={devicesize}
                        />
                    </div>
                </CalculatorContainer>

                {/* Buttons */}
                <Container className="flex-all-centered">
                    {allCorrect && hasSubmitted ? (
                        <InteractiveActivityButton
                            isCorrect={true}
                            onClick={handleTryAgainAfterAllCorrect}
                            className="secondary"
                        />
                    ) : (
                        <InteractiveActivityButton
                            isCorrect={false}
                            onClick={calculateResult}
                            className={
                                !formFields.carbs.length ||
                                !formFields.fibre.length
                                    ? 'disabledBtn'
                                    : error.carbs || error.fibre || error.result
                                    ? 'incorrect'
                                    : 'secondary'
                            }
                            hasError={!allCorrect}
                        />
                    )}
                    <StyledHintButton onClick={(e) => onHintClick(e)} />
                </Container>
            </StyledForm>
        </>
    );
};

export default ActivityCalc;
