import styled from '@emotion/styled';
import React from 'react';
import ShowTracksCards from '../Tracks/ShowTracksCards';
import SectionSubHeader from './SectionSubHeader';

const MyStudyTracksSection = () => {
    return (
        <MyTracksSectionContainer>
            <SectionSubHeader
                title="My pathways"
                linkTitle="see all"
                path={`/dashboard/mypathways`}
                marginTop="1.6rem"
                paddingBottom="1.6rem"
            />
            <ShowTracksCards isDashboard={true} />
        </MyTracksSectionContainer>
    );
};

const MyTracksSectionContainer = styled.div`
    width: 100%;
    height: 100%;
`;

export default MyStudyTracksSection;
