import React from 'react';
import styled from '@emotion/styled';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';
import P from 'assets/styledComponents/fonts/P';

const DaysOfTheWeekBar = ({
    daysArr,
    daysVisited,
    todayName,
    isOnDashboardHomePage,
}) => {
    let [globalState] = useGlobalContext();
    let devicesize = globalState.deviceSize;
    return (
        <DaysBar
            className="flex-justify-space-between"
            isOnDashboardHomePage={isOnDashboardHomePage}
            devicesize={devicesize}
        >
            {daysArr.map((day) => {
                return (
                    <StyledLetter
                        className="flex-all-centered"
                        key={day}
                        visited={daysVisited && daysVisited.includes(day)}
                        isOnDashboardHomePage={isOnDashboardHomePage}
                        devicesize={devicesize}
                    >
                        {day[0]}

                        {todayName === day && (
                            <TodayMarker
                                isOnDashboardHomePage={isOnDashboardHomePage}
                                className="flex-column-centered"
                            >
                                <P className="petit-title-2">TODAY</P>
                                <Triangle
                                    isOnDashboardHomePage={
                                        isOnDashboardHomePage
                                    }
                                />
                            </TodayMarker>
                        )}
                    </StyledLetter>
                );
            })}
        </DaysBar>
    );
};

const DaysBar = styled.div`
    width: ${(props) => (props.isOnDashboardHomePage ? '44rem' : '')};
    min-height: ${(props) =>
        props.isOnDashboardHomePage
            ? '4.5rem'
            : props.devicesize === 'mobile'
            ? '4rem'
            : '6rem'};
    border-radius: ${(props) =>
        props.isOnDashboardHomePage ? '2rem' : '4rem'};
    background-color: ${(props) => props.theme.colors.tertiary};
    font-size: ${(props) =>
        props.isOnDashboardHomePage || props.devicesize === 'mobile'
            ? '1.6rem'
            : '2.2rem'};
    font-family: 'MontserratBold';
    text-transform: uppercase;
    width: 100%;
    max-width: ${(props) =>
        props.devicesize === 'mobile' ? '46rem' : '56rem'};
    align-items: center;
    padding: ${(props) =>
        props.devicesize === 'mobile' ? '0 2rem' : '0 4.8rem'};
`;

const StyledLetter = styled.div`
    background-color: ${(props) =>
        props.visited ? props.theme.colors.secondary : 'transparent'};
    color: ${(props) =>
        props.visited
            ? props.theme.colors.lightText
            : props.theme.colors.greyText};
    width: ${(props) =>
        props.isOnDashboardHomePage
            ? '3.1rem'
            : props.devicesize === 'mobile'
            ? '2.4rem'
            : '3.6rem'};
    height: ${(props) =>
        props.isOnDashboardHomePage
            ? '3.1rem'
            : props.devicesize === 'mobile'
            ? '2.4rem'
            : '3.6rem'};
    border-radius: 50%;

    position: relative;
`;

const TodayMarker = styled.div`
    position: absolute;
    color: ${(props) => props.theme.colors.secondary};
    top: ${(props) => (props.isOnDashboardHomePage ? '-3.8rem' : '-4.4rem')};
`;

const Triangle = styled.div`
    width: 0;
    height: 0;
    border-left: 0.8rem solid transparent;
    border-right: 0.8rem solid transparent;
    border-top: ${(props) => (props.isOnDashboardHomePage ? '0.8rem' : '1rem')}
        solid ${(props) => props.theme.colors.secondary};
    margin-top: 0.1rem;
`;

export default DaysOfTheWeekBar;
