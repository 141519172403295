import React from 'react';
import styled from '@emotion/styled';
import P from 'assets/styledComponents/fonts/P';
import Container from '../../../../../assets/styledComponents/Container';

const InfoTable = ({ showHint1, showHint2, isDesktop, isTablet, isMobile }) => {
    const marginLeft = isDesktop ? '0' : isTablet ? '-4.8rem' : '-2.5rem';
    const colTitle = isDesktop
        ? 'petit-title-1'
        : isTablet
        ? 'body-4'
        : 'body-3';
    const rowTitle = isDesktop ? 'body-1' : isTablet ? 'body-4' : 'body-3';

    return (
        <Container
            className="flex-row-flex-start"
            marginLeft={marginLeft}
            width={isDesktop ? '100%' : '100vw'}
            borderRadius={isDesktop ? '2rem' : '0'}
            background="tertiary"
            padding={
                isDesktop
                    ? '1.2rem 0rem 2.4rem 6.1rem'
                    : isTablet
                    ? '1.2rem 0rem 2.4rem 1.6rem'
                    : '1.2rem 0rem 2.4rem 0.8rem'
            }
        >
            <Table>
                <tbody>
                    <tr>
                        <td></td>
                        <th scope="col" className={colTitle}>
                            {isDesktop ? 'BREAKFAST' : 'Breakfast'}
                        </th>
                        <th scope="col" className={colTitle}>
                            {isDesktop ? 'LUNCH' : 'Lunch'}
                        </th>
                        <th scope="col" className={colTitle}>
                            {isDesktop ? 'DINNER' : 'Dinner'}
                        </th>
                        <th scope="col" className={colTitle}>
                            {isDesktop ? 'NIGHT' : 'Night'}
                        </th>
                    </tr>
                    <tr>
                        <th scope="row" className={rowTitle}>
                            <P
                                textAlign="right"
                                marginRight={isDesktop ? '3rem' : '0.8rem'}
                            >
                                Carb Ratio
                            </P>
                        </th>
                        <TD
                            className={
                                showHint2
                                    ? isMobile
                                        ? 'showHint2 body-2 '
                                        : 'showHint2 body-1 '
                                    : isMobile
                                    ? 'body-2'
                                    : 'body-1'
                            }
                            isDesktop={isDesktop}
                            isMobile={isMobile}
                        >
                            15
                        </TD>
                        <TD
                            className={isMobile ? 'body-2' : 'body-1'}
                            isDesktop={isDesktop}
                            isMobile={isMobile}
                        >
                            15
                        </TD>
                        <TD
                            className={isMobile ? 'body-2' : 'body-1'}
                            isDesktop={isDesktop}
                            isMobile={isMobile}
                        >
                            15
                        </TD>
                        <TD
                            className={isMobile ? 'body-2' : 'body-1'}
                            isDesktop={isDesktop}
                            isMobile={isMobile}
                        >
                            15
                        </TD>
                        <th scope="row" className={rowTitle}>
                            <P
                                textAlign="left"
                                marginLeft={
                                    isDesktop
                                        ? '2.4rem'
                                        : isTablet
                                        ? '2.1rem'
                                        : '0.8rem'
                                }
                            >
                                Grams
                            </P>
                        </th>
                    </tr>
                    <tr>
                        <th scope="row" className={rowTitle}>
                            <P
                                textAlign="right"
                                marginRight={isDesktop ? '3rem' : '0.8rem'}
                            >
                                ISF
                            </P>
                        </th>
                        <TD
                            className={isMobile ? 'body-2' : 'body-1'}
                            isDesktop={isDesktop}
                            isMobile={isMobile}
                        >
                            3
                        </TD>
                        <TD
                            className={isMobile ? 'body-2' : 'body-1'}
                            isDesktop={isDesktop}
                            isMobile={isMobile}
                        >
                            3
                        </TD>
                        <TD
                            className={isMobile ? 'body-2' : 'body-1'}
                            isDesktop={isDesktop}
                            isMobile={isMobile}
                        >
                            3
                        </TD>
                        <TD
                            className={isMobile ? 'body-2' : 'body-1'}
                            isDesktop={isDesktop}
                            isMobile={isMobile}
                        >
                            3
                        </TD>
                        <th scope="row" className={rowTitle}>
                            <P
                                textAlign="left"
                                marginLeft={
                                    isDesktop
                                        ? '2.4rem'
                                        : isTablet
                                        ? '2.1rem'
                                        : '0.8rem'
                                }
                            >
                                mmol/l
                            </P>
                        </th>
                    </tr>
                    <tr>
                        <th scope="row" className={rowTitle}>
                            <P
                                textAlign="right"
                                marginRight={isDesktop ? '3rem' : '0.8rem'}
                            >
                                Target Glucose
                            </P>
                        </th>
                        <TD
                            className={
                                showHint1
                                    ? isMobile
                                        ? 'showHint1 body-2 '
                                        : 'showHint1 body-1 '
                                    : isMobile
                                    ? 'body-2'
                                    : 'body-1'
                            }
                            isDesktop={isDesktop}
                            isMobile={isMobile}
                        >
                            6
                        </TD>
                        <TD
                            className={isMobile ? 'body-2' : 'body-1'}
                            isDesktop={isDesktop}
                            isMobile={isMobile}
                        >
                            6
                        </TD>
                        <TD
                            className={isMobile ? 'body-2' : 'body-1'}
                            isDesktop={isDesktop}
                            isMobile={isMobile}
                        >
                            6
                        </TD>
                        <TD
                            className={isMobile ? 'body-2' : 'body-1'}
                            isDesktop={isDesktop}
                            isMobile={isMobile}
                        >
                            8
                        </TD>
                    </tr>
                </tbody>
            </Table>
        </Container>
    );
};

const tableDataBorder = `#FFD97D`;
const tableDataBackground = `#FFEF9F`;

const Table = styled.table`
    border-collapse: collapse;
`;

const TD = styled.td`
    background: ${tableDataBackground};
    text-align: center;
    width: ${({ isDesktop }) => (isDesktop ? `10.5rem` : `12.3vw`)};
    padding: ${({ isMobile }) => (isMobile ? `.5rem` : `1rem`)};
    border: 0.2rem ${tableDataBorder} solid;

    &.showHint1 {
        color: ${(props) => props.theme.colors.success};
    }

    &.showHint2 {
        color: ${(props) => props.theme.colors.success};
    }
`;
export default InfoTable;
