import Container from 'assets/styledComponents/Container';
import P from 'assets/styledComponents/fonts/P';
import StyledImage from 'assets/styledComponents/StyledImage';
import React from 'react';

const ImageWithDescription = ({ desctiption, src, alt, devicesize }) => {
    return (
        <>
            <Container
                marginLeft={devicesize === 'mobile' && '-2.5rem'}
                width={devicesize === 'mobile' && '100vw'}
            >
                <StyledImage src={src} alt={alt} width="100%" />
            </Container>
            <P
                margin={
                    devicesize === 'mobile'
                        ? '1.6rem 0 3.2rem'
                        : '2.4rem 0 4rem'
                }
                className={devicesize === 'mobile' ? 'body-2' : 'body-4'}
            >
                {desctiption}
            </P>
        </>
    );
};

export default ImageWithDescription;
