import styled from '@emotion/styled';
import React from 'react';

const FluidAmountTable = () => {
    const data = [
        { weight: 'under 10 kg (22 lb)', fluidAmount: '30-40 ml (1-1.5 oz)' },
        { weight: '10-20 kg (22-44 lb)', fluidAmount: '40-60 ml (1.5-2 oz)' },
        { weight: '20-50 kg (44-110 lb)', fluidAmount: '60-90 ml (2-3 oz)' },
        { weight: '50-90 kg (110-198 lb)', fluidAmount: '90-120 ml (3-4 oz)' },
    ];
    return (
        <Table>
            <thead>
                <tr className="petit-title-1">
                    <TableHeader backgroundColor={orange}>
                        Weight of the Child
                    </TableHeader>
                    <TableHeader backgroundColor={tableBackground}>
                        Suggested Fluid Amounts (per hour)
                    </TableHeader>
                </tr>
            </thead>
            <tbody>
                {data.map((item) => (
                    <tr key={item.weight} className="body-2">
                        <TableData backgroundColor={beige}>
                            {item.weight}
                        </TableData>
                        <TableData backgroundColor={mint}>
                            {item.fluidAmount}
                        </TableData>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};

//Color vars specific to table
const tableBackground = `#BEE9E8`;
const orange = `#FFAC73`;
const mint = `#DCF4F3`;
const beige = `#FCE4D8`;

const Table = styled.table`
    border-radius: 2rem;
    margin: 3.2rem auto auto;
    border-spacing: 0.4rem;
    overflow: hidden;
    max-width: 58.8rem;

    thead {
        tr {
            min-height: 6rem;
        }
    }
`;

const TableHeader = styled.th`
    background-color: ${(props) => props.backgroundColor};
    font-size: 1.3rem;
    line-height: 1.2;
    padding: 1.8rem 2rem;
    height: 100%;
`;

const TableData = styled.td`
    background-color: ${(props) => props.backgroundColor};
    padding: 1.8rem 3rem;
    text-align: center;
`;

export default FluidAmountTable;
