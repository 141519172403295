import { React, useState } from 'react';
import { PageContainer } from './SharedComponents';
import Step2A from './Step2A';
import Step2B from './Step2B';

const Step2 = ({ devicesize, setActivePage }) => {
    const [pageToShow, setPageToShow] = useState(1);

    return (
        <PageContainer devicesize={devicesize}>
            {pageToShow === 1 ? (
                <Step2A
                    setActivePage={setActivePage}
                    setPageToShow={setPageToShow}
                    devicesize={devicesize}
                />
            ) : (
                <Step2B
                    setActivePage={setActivePage}
                    setPageToShow={setPageToShow}
                    devicesize={devicesize}
                />
            )}
        </PageContainer>
    );
};

export default Step2;
