import React from 'react';
import LessonCard from './Lessons/LessonCard';

// splits all dynamic zone into arrays-pages
const ShowLessonCards = (
    endPages,
    content,
    globalState,
    course,
    slug,
    lastLessonCompleted,
    hasCourseStarted,
    isCourseCompleted
) => {
    if (endPages && content && Object.keys(content).length) {
        return (
            <div>
                {endPages.map((lesson) => {
                    const isLessoncompleted =
                        globalState.user &&
                        globalState.user.trackingLog.courseTracker[
                            course.courses[0].id
                        ] &&
                        globalState.user.trackingLog.courseTracker[
                            course.courses[0].id
                        ].lessonsCompleted[lesson.id]
                            ? true
                            : false;

                    return (
                        <LessonCard
                            key={lesson.id}
                            lessonTitle={lesson.lessonTitle}
                            isLessonCompleted={isLessoncompleted}
                            url={`/modules/${slug}/${
                                endPages.indexOf(lesson) + 1
                            }`}
                            lessonNumber={endPages.indexOf(lesson) + 1}
                            isUserLoggedIn={globalState.user ? true : false}
                            lastLessonCompleted={lastLessonCompleted}
                            hasCourseStarted={hasCourseStarted}
                            isCourseCompleted={isCourseCompleted}
                            estimatedLessonTimeInMinutes={
                                lesson.estimatedLessonTimeInMinutes
                            }
                        />
                    );
                })}
            </div>
        );
    }
};

export default ShowLessonCards;
