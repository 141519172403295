import React from 'react';
import styled from '@emotion/styled';
import Container from 'assets/styledComponents/Container';
import H1 from 'assets/styledComponents/fonts/H1';
import H2 from 'assets/styledComponents/fonts/H2';

const LessonHeader = ({ isMobile, endPage }) => {
    return (
        <HeaderContainer isMobile={isMobile}>
            <Container className="flex-space-between" width="100%">
                <H2 className="petit-title-serif" color="greyText">
                    Lesson {endPage.lessonNumber}
                </H2>
            </Container>
            <H1 color="primary" className={isMobile ? 'header-3' : 'header-2'}>
                {endPage.lessonTitle}
            </H1>
        </HeaderContainer>
    );
};

const HeaderContainer = styled.header`
    max-width: 98rem;
    width: 100%;
    margin-left: 0;
    padding-top: 3rem;
    padding-bottom: 3.6rem;
    align-items: center;
    @media screen and (max-width: ${(props) =>
            props.theme.breakPoints.tablet}) {
        padding-top: 3.5rem;
        padding-bottom: 2.4rem;
    }
`;

export default LessonHeader;
