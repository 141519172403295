import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import CourseCardContentListView from './CourseCardContentListView';

const CourseCardListView = ({
    slug,
    view,
    title,
    description,
    progress,
    lastLesson,
    totalLessons,
    lastPage,
    handleDelete,
    isDashboard,
    last,
    first,
}) => {
    const [hovered, setHovered] = useState(false);

    useEffect(() => {
        setHovered(false);
        return setHovered(false);
    }, []);

    return (
        <StyledCourseListItemCard
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            last={last}
            first={first}
        >
            <CourseCardContentListView
                slug={slug}
                view={view}
                title={title}
                description={description}
                progress={progress}
                lastLesson={lastLesson}
                totalLessons={totalLessons}
                lastPage={lastPage}
                hovered={hovered}
                handleDelete={handleDelete}
                isDashboard={isDashboard}
            />
        </StyledCourseListItemCard>
    );
};

const getBorderRadius = (isFirst, isLast) => {
    if (isFirst && isLast) {
        return '1rem';
    } else if (isFirst) {
        return '1rem 1rem 0 0';
    } else if (isLast) {
        return '0 0 1rem 1rem';
    }
    return 'none';
};

const StyledCourseListItemCard = styled.div`
    height: 10.8rem;
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2.6rem;
    background-color: ${(props) => props.theme.colors.lightBackground};
    border-bottom: 0.1rem solid ${(props) => props.theme.colors.lightGrey};
    border-radius: ${(props) => getBorderRadius(props.first, props.last)};

    /* left and right shadows only */
    box-shadow: -3px 0px 3px rgba(210, 210, 210, 0.4),
        3px 0px 3px rgba(210, 210, 210, 0.4);
    -webkit-box-shadow: -3px 0px 3px rgba(210, 210, 210, 0.4),
        3px 0px 3px rgba(210, 210, 210, 0.4);
    -webkit-box-shadow: -3px 0px 3px rgba(210, 210, 210, 0.4),
        3px 0px 3px rgba(210, 210, 210, 0.4);

    :first-of-type {
        /* shadow on top and sides  */
        box-shadow: 3px -4px 8px 0px rgba(210, 210, 210, 0.4),
            -3px 0px 5px 0px rgba(210, 210, 210, 0.4);
        -webkit-box-shadow: 3px -4px 8px 0px rgba(210, 210, 210, 0.4),
            -3px 0px 5px 0px rgba(210, 210, 210, 0.4);
        -webkit-box-shadow: 3px -4px 8px 0px rgba(210, 210, 210, 0.4),
            -3px 0px 5px 0px rgba(210, 210, 210, 0.4);
    }

    :last-of-type {
        border-bottom: none;
        /* shadow on bottom and sides  */
        box-shadow: 3px 5px 5px 0px rgba(210, 210, 210, 0.4),
            -3px 5px 5px 0px rgba(210, 210, 210, 0.4);
        -webkit-box-shadow: 3px 5px 5px 0px rgba(210, 210, 210, 0.4),
            -3px 5px 5px 0px rgba(210, 210, 210, 0.4);
        -moz-box-shadow: 3px 5px 5px 0px rgba(210, 210, 210, 0.4),
            -3px 5px 5px 0px rgba(210, 210, 210, 0.4);
    }
`;

export default CourseCardListView;
