import { React } from 'react';
import styled from '@emotion/styled';
import AccordionItem from './AccordionItem';
import { stepItems } from './stepItems';

const Accordion = () => {
    return (
        <Container>
            {stepItems.map((item) => (
                <AccordionItem key={item.id} id={item.id} title={item.title} />
            ))}
        </Container>
    );
};

const Container = styled.div`
    margin-top: 3.6rem;
    width: 100%;
    position: relative;

    :before {
        position: absolute;
        content: ' ';
        width: 0.3rem;
        background: ${(props) => props.theme.colors.lightGreyBackground};
        height: calc(100% - 4rem);
        top: 2.5rem;
        left: 2.6rem;
    }
`;

export default Accordion;
