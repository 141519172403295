import React from 'react';
import styled from '@emotion/styled';
import download from 'assets/icons/download.svg';
import Container from '../Container';
import StyledImage from '../StyledImage';
import { useGlobalContext } from 'GlobalContext/GlobalProvider';

const DownloadButton = ({ text, marginTop, className, pdf }) => {
    let [globalState] = useGlobalContext();
    let devicesize = globalState.deviceSize;
    return (
        <Container className={className}>
            <Link href={pdf} download>
                <Btn marginTop={marginTop} devicesize={devicesize}>
                    <StyledImage
                        src={download}
                        alt="download icon"
                        marginRight="1.2rem"
                    />
                    {text}
                </Btn>
            </Link>
        </Container>
    );
};

const Btn = styled.button`
    font-family: 'MontserratMedium', sans-serif;
    font-size: 1.8rem;
    height: 4.8rem;
    border-radius: 3rem;
    letter-spacing: 0.02em;

    background-color: ${(props) => props.theme.colors.secondary};
    color: ${(props) => props.theme.colors.lightText};
    border: 0.1rem solid ${(props) => props.theme.colors.secondary};
    padding: ${(props) =>
        props.devicesize === 'mobile' ? '1.2rem 2.4rem' : '1.2rem 4.8rem'};

    display: flex;
    align-items: center;
    justify-content: center;

    /* customizable */
    margin: ${(props) => props.margin};
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
    width: ${(props) => props.width};
    min-width: ${(props) => props.minWidth};
    max-width: ${(props) => props.maxWidth};
    padding: ${(props) => props.padding};

    :hover {
        opacity: 0.4;
        cursor: pointer;
    }

    :active {
        opacity: 1;
    }

    &.disabledBtn {
        background-color: ${(props) => props.theme.colors.lightGreyBackground};
        border: 0.1rem solid
            ${(props) => props.theme.colors.lightGreyBackground};
        color: ${(props) => props.theme.colors.greyText};

        :hover {
            cursor: default;
            opacity: 1;
        }
    }
`;

const Link = styled.a`
    text-decoration: none;
`;

export default DownloadButton;
