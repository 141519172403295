import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';

import chevronRight from '../../assets/icons/chevronRight.svg';
import SideDashboardNavbarContent from './SideDashboardNavbarContent';

const SideNavBar = ({ dashboardNavItems }) => {
    const screenSize = 1440;
    const [expandNavbar, setExpandNavbar] = useState(
        window.innerWidth > screenSize ? true : false
    );

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth > screenSize && !expandNavbar) {
                setExpandNavbar(true);
            } else if (window.innerWidth < screenSize && expandNavbar) {
                setExpandNavbar(false);
            }
        }

        window.addEventListener('resize', handleResize);
    });

    return (
        <MainContainer expanded={expandNavbar}>
            <SideDashboardNavbarContent
                expandNavbar={expandNavbar}
                dashboardNavItems={dashboardNavItems}
            />
            <ExpandBtn onClick={() => setExpandNavbar(!expandNavbar)}>
                <ExpandBtnArrow src={chevronRight} expanded={expandNavbar} />
            </ExpandBtn>
        </MainContainer>
    );
};

const MainContainer = styled.nav`
    background-color: ${(props) => props.theme.colors.tertiary};
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-right: 3rem;
    padding-left: 1rem;
    padding-top: 1.8rem;
    position: relative;
`;

const ExpandBtn = styled.button`
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.tertiary};
    position: absolute;
    right: -2rem;
    bottom: 1rem;
    :hover {
        cursor: pointer;
        opacity: 0.7;
    }
`;

const ExpandBtnArrow = styled.img`
    margin-right: -1rem;
    transform: ${(props) =>
        props.expanded === true ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

export default SideNavBar;
