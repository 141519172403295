import styled from '@emotion/styled';
import React from 'react';
import TracksCard from './TracksCard';
import TracksCardVertical from './TracksCardVertical';
import trackCardClock from '../../assets/icons/trackCardClock.svg';
import trackCardCourse from '../../assets/icons/trackCardCourse.svg';
import FormatEstimatedTime from '../../utils/helpers/FormatEstimatedTime';

const ShowAllTracks = ({ data, myCourses, getProgress, isMobile }) => {
    return (
        <TracksContainer>
            {data &&
                data.map((track) => {
                    let coursesInTrack = track.courses.map((t) => t.title);
                    let coursesStarted = [];
                    let coursesCompleted = [];

                    myCourses &&
                        Object.values(myCourses).forEach((c) => {
                            if (coursesInTrack.includes(c.title)) {
                                if (!c.courseCompleted) {
                                    coursesStarted.push(c.title);
                                } else if (c.courseCompleted) {
                                    coursesCompleted.push(c.title);
                                }
                            }
                        });

                    let progress = getProgress(track);

                    const infoIcons = [
                        {
                            icon: `${trackCardCourse}`,
                            title: `${track.courses.length} MODULES`,
                        },
                        {
                            icon: `${trackCardClock}`,
                            title: `${FormatEstimatedTime(
                                track.estimatedTimeInMinutes
                            )}`,
                        },
                    ];

                    // // use Strapi small optimized image size if available,
                    // // else use original uploaded image

                    let smallImage =
                        track.trackImage.image.formats &&
                        track.trackImage.image.formats.small &&
                        track.trackImage.image.formats.small.url;
                    let nonOptimizedImage = track.trackImage.image.url;
                    return (
                        <div key={track.id}>
                            {isMobile ? (
                                <TracksCardVertical
                                    key={track.id}
                                    track={track}
                                    trackImage={
                                        smallImage
                                            ? smallImage
                                            : nonOptimizedImage
                                    }
                                    infoIcons={infoIcons}
                                    isMobile={isMobile}
                                    slug={track.slug}
                                    underDevelopment={track.underDevelopment}
                                />
                            ) : (
                                <TracksCard
                                    key={track.id}
                                    coursesStarted={coursesStarted}
                                    coursesCompleted={coursesCompleted}
                                    slug={track.slug}
                                    trackTitle={track.trackTitle}
                                    trackSubtitle={track.subtitle}
                                    trackDescription={track.trackDescription}
                                    trackImage={
                                        smallImage
                                            ? smallImage
                                            : nonOptimizedImage
                                    }
                                    trackImageAlternativeText={
                                        track.trackImage.alternativeText
                                    }
                                    progress={progress}
                                    infoIcons={infoIcons}
                                    underDevelopment={track.underDevelopment}
                                />
                            )}
                        </div>
                    );
                })}
        </TracksContainer>
    );
};

const TracksContainer = styled.div`
    display: flex;
    flex-direction: column-reverse;
`;

export default ShowAllTracks;
