import { React } from 'react';
import Step2DataCell from './Step2DataCell';

const Step2TableRow = ({
    data,
    isAnswer2Correct,
    setIsAnswer2Correct,
    rowNumber,
    hasSubmitted2,
    setValSelected,
    valSelected,
}) => {
    return (
        <tr>
            {data.map((item, idx) => {
                return (
                    <Step2DataCell
                        key={idx}
                        cellContent={item}
                        isAnswer2Correct={isAnswer2Correct}
                        setIsAnswer2Correct={setIsAnswer2Correct}
                        rowNumber={rowNumber}
                        colNumber={idx + 1}
                        hasSubmitted2={hasSubmitted2}
                        setValSelected={setValSelected}
                        valSelected={valSelected}
                    />
                );
            })}
        </tr>
    );
};

export default Step2TableRow;
