const handleResetPassword = (
    event,
    forgotPasswordInput,
    setResetSuccess,
    setErrorMessage
) => {
    event.preventDefault();

    const forgotPasswordUrl = `${process.env.REACT_APP_API_URL}/auth/forgot-password`;

    const formData = {
        email: forgotPasswordInput.email,
    };

    const forgotPW = async (url, data) => {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        return response.json();
    };

    forgotPW(forgotPasswordUrl, formData).then((res) => {
        if (!res.statusCode) {
            console.log('Successfully sent reset password email');
            setResetSuccess(true);
        } else {
            console.log(res.message[0].messages[0].message);
            setErrorMessage("We couldn't find this email.");
        }
    });
};

export default handleResetPassword;
